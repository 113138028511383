import React from "react";

const StackedList = ({
  draggable,
  data,
  render,
  separatorElement = null,
  setData = null,
  ignoreLastElementForDrag = false
}) => {
  const handleDragStart = (
    ev: React.DragEvent<HTMLDivElement>,
    idx: number
  ) => {
    ev.dataTransfer.setData("pos", idx.toString());
  };

  const handleDragDrop = (
    ev: React.DragEvent<HTMLDivElement>,
    idx: number
  ) => {
    const pos = Number(ev.dataTransfer.getData("pos"));
    const _list = [...data];
    const item = _list[pos];
    if (ignoreLastElementForDrag && idx === _list.length - 1) return;
    _list.splice(pos, 1);
    _list.splice(idx, 0, item);
    setData && setData(_list);
  };

  return (
    <div className="flex flex-col flex-grow gap-2">
      {data.map((item, ind) => {
        return (
          <>
            <div
              key={ind}
              draggable={
                draggable &&
                (ignoreLastElementForDrag ? ind !== data.length - 1 : true)
              }
              className="nodrag"
              onDragStart={(ev) => handleDragStart(ev, ind)}
              onDrop={(ev) => handleDragDrop(ev, ind)}
              onDragOver={(ev) => ev.preventDefault()}
            >
              {render(item, ind, data)}
            </div>
            {
              // If the separatorElement  is not null and the current item is not the last item in the list
              // then render the separatorElement
              separatorElement && ind !== data.length - 1
                ? separatorElement(ind)
                : null
            }
          </>
        );
      })}
    </div>
  );
};

export default StackedList;
