import {
  copyToClipboard,
  parseX509Subject
} from "@app/shared/utils/helper.util";
import dateService from "@services/date.service";
import { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CertificateInfoTabs from "./fad-certificate-tabs.component";
import { useFleetAndDevicesStore } from "@store/index";
import ShowLoading from "@app/shared/components/loading.component";
import * as x509 from "@peculiar/x509";
import { useGetCertificateDetails } from "@app/shared/hooks/get/certificate-details";

const SUBJECT_KEYS_TO_LABELS = {
  C: "Country",
  ST: "State",
  L: "Locality",
  O: "Organization",
  OU: "Organizational Unit",
  CN: "Common Name"
};

const FADCertificateInfo = () => {
  const params = useParams();
  const [activeTab, setActiveTab] = useState<string>("details");
  const [certificate, setCertificate] = useState<x509.X509Certificate>();

  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const { isLoading, isError } = useGetCertificateDetails(
    params.id,
    {
      fleet_id: selectedFleet.id
    },
    (certificate) => {
      const cert = new x509.X509Certificate(atob(certificate.cert));
      setCertificate(cert);
    }
  );

  const subject = useMemo(
    () => (certificate?.subject ? parseX509Subject(certificate.subject) : {}),
    [certificate]
  );

  const issuedBy = useMemo(
    () => (certificate?.issuer ? parseX509Subject(certificate.issuer) : {}),
    [certificate]
  );

  return (
    <>
      <div className="bg-background border-b border-background-layer3 lg:px-8 sm:px-6 ">
        <div className="relative flex flex-col justify-bet"></div>
        <div className="flex flex-col justify-between items-start pt-4 w-full">
          <h4 className="text-xl font-semibold text-contentColor">
            {"Certificates"}
          </h4>
        </div>
        <div className="mt-4 mb-2 flex items-center gap-1">
          <Link
            to={`/fleet-and-devices/certificates/`}
            className="text-sm text-contentColorLight hover:underline"
          >
            {"certificates >"}
          </Link>
          <div className="text-sm text-contentColorLight">{params.id}</div>
        </div>
      </div>
      <main className="rounded-md bg-background text-contentColor m-4 p-4">
        <CertificateInfoTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {isLoading ? (
          <ShowLoading />
        ) : isError ? (
          <div className="flex justify-center items-center h-[70vh] text-contentColorLight text-sm">
            Could not find certificate!
          </div>
        ) : (
          <div className="flex sm:flex-row flex-col gap-8 mt-4 ml-4">
            <div className="w-[50%]">
              <div className="w-full ">
                <h3 className="font-bold mb-4">General Details</h3>
                <DetailsItem
                  label={"Issued On:"}
                  value={dateService.convertUTCToLocalDate(
                    certificate?.notBefore.toString()
                  )}
                />
                <DetailsItem
                  label={"Expires On:"}
                  value={dateService.convertUTCToLocalDate(
                    certificate?.notAfter.toString()
                  )}
                />
              </div>

              <hr className="border-background-layer3 my-4 w-[60%]" />

              <div className="w-full ">
                <h3 className="font-bold mb-4">Issues To</h3>
                <div className="">
                  {Object.keys(subject)
                    .filter((k) => k in SUBJECT_KEYS_TO_LABELS)
                    .map((key) => (
                      <DetailsItem
                        label={SUBJECT_KEYS_TO_LABELS[key]}
                        value={subject[key]}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="max-w-[80vw] min-w-[40vw] max-h-[80vh]">
              <div className="w-full ">
                <h3 className="font-bold mb-4">Issues By</h3>
                <div className="">
                  {Object.keys(issuedBy)
                    .filter((k) => k in SUBJECT_KEYS_TO_LABELS)
                    .map((key) => (
                      <DetailsItem
                        label={SUBJECT_KEYS_TO_LABELS[key]}
                        value={issuedBy[key]}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default FADCertificateInfo;

const DetailsItem = ({ label, value }) => (
  <div className="mb-5">
    <label className="text-sm text-contentColor">{label}</label>

    <div className="flex items-end w-full">
      <input
        type="text"
        id="certificate.notAfter"
        name="certificate.notAfter"
        value={value}
        placeholder="Shadow ID"
        className="block w-[300px] p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
      />
      <div>
        <button
          type="button"
          onClick={() => copyToClipboard(value)}
          className="block ml-3.5 bg-[#166ff629] p-3 rounded"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
              fill="#546CCC"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
);
