import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";
import { IInvites, IInvitesResponse } from "@interfaces/invites.interface";

export const getInvites = async (orgId: string, params) => {
  const { data, ok } = await networkService.get<IInvitesResponse>(
    `organizations/invites`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data || [];
  } else return null;
};

export const useGetInvites = (
  params: any = {},
  successCb: ((data: IInvites[]) => void) | null = null
) => {
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getInvites", user.selectedOrg.id, params],
    async () => {
      const invites = await getInvites(user.selectedOrg.id, params);

      successCb && successCb(invites);

      return invites;
    },
    {
      enabled: !!user.selectedOrg.id
    }
  );

  return res;
};
