import ShadowEditor from "@app/fleet-and-devices/components/fad-shadow-editor.component";
import {
  decodeBase64toUTF8,
  downloadFile
} from "@app/shared/utils/helper.util";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import dateService from "@services/date.service";
import { loggerService } from "@services/logger";
import networkService from "@services/network.service";
import useAuthStore from "@store/auth.store";
import { useFleetAndDevicesStore } from "@store/index";
import React, { useEffect, useState } from "react";

interface IDataPointDefinitionVersionsProps {
  dataPointDef: any;
}

interface IDeprecatedDataPointDefinitionResponse {
  data: { deprecatedDataPointDefinitions: any[] };
  ok: number;
}

const DataPointDefinitionVersions: React.FC<
  IDataPointDefinitionVersionsProps
> = ({ dataPointDef }) => {
  const [user] = useAuthStore((state) => [state.user]);
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const [versions, setVersions] = useState([dataPointDef]);
  const [selectedVersion, setSelectedVersion] = useState({
    ...dataPointDef,
    version: "Latest"
  });

  useEffect(() => {
    const fetchDeprecatedDataPointDef = async () => {
      try {
        const response =
          await networkService.get<IDeprecatedDataPointDefinitionResponse>(
            `projects/${selectedProject.id}/data_point_definitions/${dataPointDef.id}/deprecated`,
            {},
            {
              headers: {
                "ORG-ID": user.selectedOrg.id
              }
            }
          );
        const { deprecatedDataPointDefinitions } = response.data;
        if (!deprecatedDataPointDefinitions?.length) {
          return;
        }

        const dataPointDefHistory = deprecatedDataPointDefinitions;
        setVersions([
          { ...dataPointDef, version: "Latest" },
          ...dataPointDefHistory.sort(
            (a, b) => parseInt(b.version) - parseInt(a.version)
          )
        ]);
      } catch (error) {
        loggerService.error(error);
      }
    };

    fetchDeprecatedDataPointDef();
  }, [dataPointDef, selectedProject.id, user.selectedOrg.id]);

  return (
    <div className="flex gap-8 mt-4 overflow-y-scroll">
      <div className="w-[60%] flex flex-col gap-4 mt-6 overflow-y-scroll max-h-[60vh]">
        {versions.map((deprecatedDataPointDef) => {
          return (
            <div
              key={deprecatedDataPointDef.id + deprecatedDataPointDef.version}
              className="flex relative flex-col gap-2"
            >
              <h2 className="font-medium text-base">
                Version {deprecatedDataPointDef.version}
              </h2>
              <p className="text-contentColorLight text-sm">
                {deprecatedDataPointDef.deleted_at
                  ? "Deleted At: " +
                    dateService.convertUTCToLocalDate(
                      deprecatedDataPointDef.deleted_at
                    )
                  : "Published At: " +
                    dateService.convertUTCToLocalDate(
                      deprecatedDataPointDef.created_at
                    )}
              </p>
              <hr className="mt-3 border-background-layer3" />
              <div className="absolute top-0 right-2">
                <button
                  onClick={() => {
                    setSelectedVersion(deprecatedDataPointDef);
                  }}
                >
                  <ArrowRightIcon
                    width={20}
                    className={`${
                      deprecatedDataPointDef.version ===
                      selectedVersion.version
                        ? "text-primary"
                        : "text-contentColorLight"
                    }`}
                  />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="border-4 border-background-layer3 rounded mx-3"></div>
      <div className="max-w-[80vw] min-w-[40vw] max-h-[80vh]">
        <ShadowEditor
          isShadow={false}
          label={
            dataPointDef.name + "-V" + (selectedVersion.version || "Latest")
          }
          shadowDef={selectedVersion}
          readOnly={true}
          // setShadowEditorReadOnly={setShadowEditorReadOnly}
          customShadow={decodeBase64toUTF8(
            selectedVersion.data_point_proto || selectedVersion.proto
          )}
          handleMaximizeEditor={() => {}}
          downloadFile={() =>
            downloadFile(
              dataPointDef.name + "-V" + selectedVersion.version,
              decodeBase64toUTF8(
                selectedVersion.data_point_proto || selectedVersion.proto
              )
            )
          }
        />
      </div>
    </div>
  );
};

export default DataPointDefinitionVersions;
