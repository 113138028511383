import { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { IDataPointDefinition, IOption } from "@interfaces/index";
import {
  convertToCamelCase,
  copyToClipboard,
  downloadFile,
  reactSelectClassNames,
  shadowFieldsGenerator
} from "@utils/helper.util";
import {
  BoltIcon,
  ClipboardDocumentListIcon,
  CloudArrowDownIcon,
  PencilSquareIcon
} from "@heroicons/react/24/outline";
import { useGetShadowDefinitions } from "@/app/shared/hooks/get/shadow-definitions";
import { useGetDeviceDataPoints } from "@/app/shared/hooks/get/device-data-points";
import { useGetDataPointDefinitions } from "@/app/shared/hooks/get";
import { Badge, Button } from "@tremor/react";
import JSZip from "jszip";
import { Liquid } from "liquidjs";
import Modal from "@/app/shared/components/modal.component";
import usePyScrtiptStore from "@/store/pyscript.store";
import { useNavigate } from "react-router-dom";
import { useGetTags } from "@/app/shared/hooks/get/tags";
import ReactSelectCreatable from "react-select/creatable";
import { Tooltip } from "react-tooltip";
import { useCreateTag } from "@/app/shared/hooks/post/create-tag";
import { useCreateDeviceTag } from "@/app/shared/hooks/post/create-device-tag";
import { useGetDeviceTags } from "@/app/shared/hooks/get/device-tag";
import { useDeleteDeviceTag } from "@/app/shared/hooks/delete/delete-device-tag";

const liquidEngine = new Liquid({
  root: `${process.env.REACT_APP_STATIC_URL}/`,
  extname: ".template"
});

liquidEngine.registerFilter("camelCase", convertToCamelCase);
liquidEngine.registerFilter(
  "GolainDataPoint",
  (dataPoint: IDataPointDefinition) => "I" + dataPoint.name.replace(/\W/g, "")
);
liquidEngine.registerFilter(
  "DataVariable",
  (dataPoint: IDataPointDefinition) =>
    convertToCamelCase(dataPoint.name) + "Data"
);

function DetailsTab({ device }) {
  const navigate = useNavigate();

  const [downloadTemplateModalOpen, setDownloadTemplateModalOpen] =
    useState(false);
  const [loadingState] = usePyScrtiptStore((state) => [state.loadingState]);
  const [downloadingSrcFile, setDownloadingSrcFile] = useState(false);

  const { data: tags } = useGetTags();
  const { data: deviceTags } = useGetDeviceTags(device.fleet_id, device.id);

  const createTagMutation = useCreateTag();
  const createDeviceTagMutation = useCreateDeviceTag(device.fleet_id);
  const deleteDeviceTagMutation = useDeleteDeviceTag(
    device.fleet_id,
    device.id
  );

  const tagsOptions: IOption[] = useMemo(() => {
    return !tags
      ? []
      : tags
          .filter(
            (tag) => !deviceTags?.some((deviceTag) => deviceTag.id === tag.id)
          )
          .map((tag) => ({ label: tag.name, value: tag.id }));
  }, [deviceTags, tags]);

  const handleTagCreate = (tagName: string) => {
    createTagMutation.mutate(
      { tag_name: tagName },
      {
        onSuccess: (tagId) => {
          setSelectedTags([...selectedTags, { value: tagId, label: tagName }]);
          createDeviceTagMutation.mutate(
            {
              data: { tag_ids: [tagId] },
              deviceId: device.id
            },
            {
              onSuccess: (success) => {
                if (success) {
                  toast.success(
                    `Created tag '${tagName}' and added to device ${device.device_name}!`
                  );
                }
              }
            }
          );
        }
      }
    );
  };

  const handleTagChange = (opts: IOption[]) => {
    if (opts.length > selectedTags.length) {
      // added tag
      const newTag = opts.filter(
        (opt) => !selectedTags.some((t) => t.label === opt.label)
      )[0];

      createDeviceTagMutation.mutate(
        {
          data: { tag_ids: [tags?.find((t) => t.name === newTag.label).id] },
          deviceId: device.id
        },
        {
          onSuccess: (success) => {
            if (success) {
              toast.success(`Added tag '${newTag.label}'. Note: Adding a tag will also 
              add all the parent tags associated with it!`);
            }
          }
        }
      );
    } else {
      // removed tag
      const tagsToRemove = selectedTags.filter(
        (opt) => !opts.some((t) => t.label === opt.label)
      );

      deleteDeviceTagMutation.mutate(
        {
          tag_ids: tagsToRemove
            .map(
              (tagToRemove) =>
                tags?.find((t) => t.name === tagToRemove.label).id
            )
            .join(",")
        },
        {
          onSuccess: (success) => {
            if (success) {
              toast.success(`Removed tag(s) from device!`);
            }
          }
        }
      );
    }
    setSelectedTags(opts);
  };

  const handleSubmit = () => {};

  const [selectedTags, setSelectedTags] = useState<IOption[]>([]);
  const [editTagsTooltipOpen, setEditTagsTooltipOpen] = useState(false);

  useEffect(() => {
    setSelectedTags(
      deviceTags?.map((tag) => ({ value: tag.id, label: tag.name })) ?? []
    );
  }, [deviceTags]);

  const { data: shadowDefs, ...shadowDefsQuery } = useGetShadowDefinitions({
    fields: "shadow_proto_descriptor,shadow_proto_structure"
  });
  const { data: deviceDataPoints, ...deviceDataPointsQuery } =
    useGetDeviceDataPoints();

  const { data: dpDefs, ...dataPointDefsQuery } = useGetDataPointDefinitions({
    fields:
      "message_name,data_point_proto_descriptor,data_point_proto_structure"
  });

  function copyTo(id: string) {
    let c = document.getElementById(id);
    copyToClipboard(c["value"]);
  }

  const onArduinoZipDownload = async () => {
    if (loadingState === "error") {
      toast.error(
        "Error loading the template generation files, please reload the page and try again."
      );
      return;
    }

    const shadow = shadowDefs?.find(
      (shadow) => shadow.id === device.shadow_definition_id
    );

    const ddP = deviceDataPoints?.filter((ddp) => ddp.device_id === device.id);
    const deviceDPs = ddP?.map((ddp) =>
      dpDefs.find((dpDef) => dpDef.id === ddp.data_point_definition_id)
    );

    const primitives = ["number", "string", "bool"];

    const dataPointsWithHeaders = deviceDPs?.map((dp) => {
      const fileName = dp.name.replace(/[\W]/gi, "");

      const headers = globalThis["generate_header_and_source"](
        dp.data_point_proto_descriptor,
        fileName
      );

      const pbHeader: string = headers[0];

      const fields = shadowFieldsGenerator(dp.data_point_proto_structure);

      let { exampleFieldName, exampleFieldValue } = getExampleFieldAndValue(
        primitives,
        fields
      );

      if (!exampleFieldName) {
        exampleFieldName = "water_temperature";
        exampleFieldValue = 40;
      }

      return {
        ...dp,
        headers,
        hasSizeMacro: pbHeader.indexOf(`#define ${dp.message_name}_size`) > 0,
        exampleFieldName,
        exampleFieldValue,
        fileName
      };
    });

    const shadowFields = shadowFieldsGenerator(shadow.shadow_proto_structure);

    let { exampleFieldName, exampleFieldValue } = getExampleFieldAndValue(
      primitives,
      shadowFields
    );

    if (!exampleFieldName) {
      exampleFieldName = "buttonPressedCount";
      exampleFieldValue = 30;
    }

    const shadowHeaders = globalThis["generate_header_and_source"](
      shadow.shadow_proto_descriptor,
      "shadow"
    );
    setDownloadingSrcFile(true);
    const res = await fetch(
      `${process.env.REACT_APP_STATIC_URL}/arduino-project-template.json`
    );

    const data = await res.json();

    const zip = new JSZip();
    const main = zip.folder("main");
    const src = main.folder("src");

    Object.keys(data.files).forEach((folderName) => {
      if (folderName === ".") {
        Object.keys(data.files[folderName]).forEach((fileName) => {
          main.file(fileName, data.files[folderName][fileName]);
        });
      } else if (folderName === "src") {
        Object.keys(data.files[folderName]).forEach((fileName) => {
          src.file(fileName, data.files[folderName][fileName]);
        });
      } else {
        const folder = main.folder(folderName);

        Object.keys(data.files[folderName]).forEach((fileName) => {
          folder.file(fileName, data.files[folderName][fileName]);
        });
      }
    });

    src.file("shadow.pb.h", shadowHeaders[0]);
    src.file("shadow.pb.c", shadowHeaders[1]);

    dataPointsWithHeaders?.forEach((dp) => {
      src.file(dp.fileName + ".pb.h", dp.headers[0]);
      src.file(dp.fileName + ".pb.c", dp.headers[1]);
    });

    const renderedFile = await liquidEngine.renderFile(
      "arduino-ino-template",
      {
        dataPoints: dataPointsWithHeaders || [],
        shadow: {
          ...shadow,
          headers: shadowHeaders,
          exampleFieldName,
          exampleFieldValue
        }
      }
    );

    main.file("main.ino", renderedFile);

    zip.file(".keep", "");
    const zipBlob = await zip.generateAsync({ type: "blob" });

    downloadFile("arduino", zipBlob, "zip");

    setDownloadTemplateModalOpen(true);
    setDownloadingSrcFile(false);
  };

  return (
    <>
      {!!device && (
        <Formik initialValues={{ deviceId: "" }} onSubmit={handleSubmit}>
          <Form>
            <div>
              <h1 className="text-lg text-left font-medium mb-2.5">
                Device Details
              </h1>

              <div className="flex">
                <div className="w-[396px]">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Device ID
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="deviceId"
                        name="deviceId"
                        value={device.id}
                        placeholder="Device ID"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("deviceId")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Device Name
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="deviceName"
                        name="deviceName"
                        value={device.device_name}
                        placeholder="Device Name"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("deviceName")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Certificate ID
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="certificateId"
                        name="certificateId"
                        value={device.certificate_id}
                        placeholder="Certificate ID"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("certificateId")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <div className="w-10/12 form-group mb-5 flex justify-between">
                                    <label className="flex font-medium text-sm mb-2">Connect to IOT hub</label>
                                    <div>
                                        <label className="inline-flex relative items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                defaultChecked
                                                className="sr-only peer"
                                            />
                                            <div className="w-11 h-6 bg-gray-200  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-background-layer3 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#0AB63B]"></div>
                                            <span className="ml-3 text-sm font-medium">Active</span>
                                        </label>
                                    </div>
                                </div> */}
                </div>
                <div className="mx-7"></div>
                <div className="w-[396px]">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Topics Slug
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="topicsSlug"
                        name="topicsSlug"
                        value={device.topic_slug}
                        placeholder="Topics Slug"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("topicsSlug")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Created At
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="createdAt"
                        name="createdAt"
                        value={device.created_at}
                        placeholder="Created At"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("createdAt")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <label className="flex items-center text-sm font-medium text-contentColorLight">
                      Tags{" "}
                      <Button
                        data-tooltip-id="device-details-add-tag-tooltip"
                        key={"create-new-tag"}
                        size="xs"
                        variant="light"
                        onClick={() => setEditTagsTooltipOpen(true)}
                        className="cursor-pointer ml-2 !rounded-md"
                      >
                        <PencilSquareIcon width={18} />
                      </Button>
                    </label>
                    <Tooltip
                      id="device-details-add-tag-tooltip"
                      isOpen={editTagsTooltipOpen}
                      openOnClick
                      // setIsOpen={setEditTagsTooltipOpen}
                      clickable
                      border={"1px solid black"}
                      variant="light"
                      className="!opacity-100 flex gap-2 flex-col items-center"
                    >
                      Add / Remove Tags
                      <ReactSelectCreatable
                        isMulti
                        value={selectedTags}
                        onChange={handleTagChange}
                        isLoading={false}
                        options={tagsOptions}
                        onCreateOption={handleTagCreate}
                        menuPortalTarget={document.body}
                        className="w-[400px]"
                        classNames={reactSelectClassNames}
                      />
                      <Button
                        variant="light"
                        onClick={() => setEditTagsTooltipOpen(false)}
                        className="ml-auto mt-2"
                      >
                        Done
                      </Button>
                    </Tooltip>

                    <div className="flex items-end">
                      <div className="flex mt-4 flex-row flex-wrap items-center gap-2">
                        {deviceTags?.map((tag) => (
                          <Badge
                            key={tag.id}
                            size="xs"
                            color="slate"
                            onClick={() =>
                              navigate(
                                `/fleet-and-devices/projects?tag=${tag.name}`
                              )
                            }
                            className="cursor-pointer"
                          >
                            {tag.name}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}
      <div className="mt-2 mb-6">
        <Button
          loading={
            loadingState === "loading" ||
            shadowDefsQuery.isLoading ||
            dataPointDefsQuery.isLoading ||
            deviceDataPointsQuery.isLoading ||
            downloadingSrcFile
          }
          icon={CloudArrowDownIcon}
          onClick={onArduinoZipDownload}
        >
          Download Arduino Project Template
        </Button>
      </div>
      <Modal
        open={downloadTemplateModalOpen}
        setOpen={setDownloadTemplateModalOpen}
      >
        <div className="flex flex-col max-w-lg gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">
            🎉 &nbsp;&nbsp;Downloaded Arduino Template!
          </h1>
          <p>
            This template facilitates a quick start to your development process
            on your device!
            <br />
            It includes all the necessary files, except for the 'certs.h' file
            that was provided to you when you initially created this device.
            <br />
            Ensure you paste the 'certs.h' file in the root folder of this
            template.
            <br />
            <br />
            Happy coding!
          </p>

          <div className="flex gap-4 justify-end">
            <Button
              color="green"
              icon={BoltIcon}
              onClick={() => setDownloadTemplateModalOpen(false)}
            >
              Awesome!
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

function getExampleFieldAndValue(primitives: string[], fields: any[]) {
  let exampleFieldName, exampleFieldValue;

  fields.forEach((field) => {
    if (primitives.includes(field.structure)) {
      exampleFieldName = field.name;

      switch (field.structure) {
        case "number":
          exampleFieldValue = 10;
          break;
        case "string":
          exampleFieldValue = '"test string"';
          break;
        case "bool":
          exampleFieldValue = true;
          break;
      }
    } else if (field.hasOwnProperty("options")) {
      exampleFieldName = field.name;

      exampleFieldValue = field.options[0];
    }
  });

  return { exampleFieldName, exampleFieldValue };
}

export default DetailsTab;
