import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import {
  ICreatePaneldResponse,
  IPanelCreatePayload
} from "@interfaces/dashboard.interface";

export const updateDashboardPanel = async (
  panelId: string,
  projectId: string,
  orgId: string,
  dashboardId: string,
  payload: IPanelCreatePayload
) => {
  const { data, ok } = await networkService.patch<ICreatePaneldResponse>(
    `projects/${projectId}/dashboards/${dashboardId}/panels/${panelId}`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.dashboardPanelId;
  } else return null;
};

export const useUpdateDashboardPanel = (dashboardId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({
      data,
      panelId
    }: {
      data: IPanelCreatePayload;
      panelId: string;
    }) =>
      await updateDashboardPanel(
        panelId,
        selectedProject.id,
        user.selectedOrg.id,
        dashboardId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDashboardPanels");
      }
    }
  );

  return res;
};
