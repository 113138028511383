import Editor from "@monaco-editor/react";
import { copyToClipboard } from "../../shared/utils/helper.util";
import { Button } from "@tremor/react";
import { useRef, useState } from "react";
import { ArrowDownTrayIcon, ClipboardIcon } from "@heroicons/react/24/outline";
import useThemeStore from "@store/theme.store";
import Modal from "@app/shared/components/modal.component";
import { IUserContextDefinition } from "@interfaces/user-contexts.interface";

interface IContextEditorProps {
  label?: string;
  readOnly?: boolean;
  context: IUserContextDefinition["definition"];
  downloadFile: () => void;
  setCustomShadow?: (val: string) => void;
  onEdit?: () => void;
}

const UserContextEditor: React.FC<IContextEditorProps> = ({
  label,
  readOnly,
  context,
  downloadFile,
  onEdit = null
}) => {
  const editorRef = useRef(null);
  const [contextMaximizeOpen, setContextMaximizeOpen] = useState(false);

  const [theme] = useThemeStore((state) => [state.theme]);

  return (
    <>
      <div className="form-group mb-5 w-full h-full max-h-[60vh]">
        <label className="flex font-medium text-sm mb-2">{label || ""}</label>
        <div className="p-2 w-full h-full bg-background rounded-lg border border-solid border-background-layer3">
          <div>
            <div className="flex justify-between items-center">
              <ul className="flex justify-center items-center">
                <li className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      copyToClipboard(JSON.stringify(context, null, 2))
                    }
                    className="text-xs text-contentColorLight flex justify-center items-center"
                  >
                    Copy
                  </button>
                  <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                </li>

                <li className="flex items-center">
                  <button
                    type="button"
                    onClick={downloadFile}
                    className="text-xs text-contentColorLight flex justify-center items-center"
                  >
                    Download
                  </button>
                  <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                </li>

                {!readOnly && (
                  <li className="flex items-center">
                    <button
                      type="button"
                      onClick={() => {
                        onEdit ? onEdit() : setContextMaximizeOpen(true);
                      }}
                      className="text-xs text-contentColorLight flex justify-center items-center"
                    >
                      Edit
                    </button>
                  </li>
                )}
              </ul>

              <div className="flex justify-center items-center">
                <button
                  onClick={() => setContextMaximizeOpen(true)}
                  type="button"
                  className="p-1 bg-background rounded"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6663 2H14.6663V6H13.333V3.33333H10.6663V2ZM1.33301 2H5.33301V3.33333H2.66634V6H1.33301V2ZM13.333 12.6667V10H14.6663V14H10.6663V12.6667H13.333ZM2.66634 12.6667H5.33301V14H1.33301V10H2.66634V12.6667Z"
                      fill="#546CCC"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="my-2 border border-solid border-background-layer3"></div>
          </div>
          <Editor
            height="calc(100% - 40px)"
            language="json"
            value={JSON.stringify(context, null, 2)}
            theme={theme === "golain" || theme === "none" ? "vs" : "vs-dark"}
            onMount={(editor) => {
              editorRef.current = editor;
            }}
            options={{
              readOnly: true,
              minimap: { enabled: false },
              scrollbar: { vertical: "hidden" },
              overviewRulerBorder: false,
              overviewRulerLanes: 0,
              folding: true,
              matchBrackets: "always",
              lineNumbers: "on",
              theme: theme === "golain" || theme === "none" ? "vs" : "vs-dark"
            }}
          />
        </div>
      </div>

      <Modal
        open={!!(contextMaximizeOpen && theme)}
        setOpen={setContextMaximizeOpen}
        className="w-[70vw] lg:w-[50vw] !bg-background"
      >
        <div className="relative w-full px-3 py-3 bg-background text-contentColor rounded-lg rounded-b-none border border-solid border-background-layer3">
          <div className="flex justify-between items-center ">
            <div className="flex justify-between items-center px-6 flex-grow">
              <h1 className="font-medium text-sm sm:text-lg whitespace-nowrap">
                User Context Definition
              </h1>
              <div className="flex justify-between items-center w-full ml-6">
                <div className="flex gap-4">
                  <button
                    onClick={() =>
                      copyToClipboard(JSON.stringify(context, null, 2))
                    }
                    className="text-sm flex justify-center items-center"
                  >
                    <ClipboardIcon width={14} />

                    <span className="pl-1 text-xs ">Copy</span>
                  </button>

                  <button
                    onClick={downloadFile}
                    className="text-sm flex justify-center items-center"
                  >
                    <ArrowDownTrayIcon width={14} />

                    <span className="pl-1 text-xs">Download</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 bg-background border-b border-background-layer3">
          <Editor
            height="60vh"
            language="cpp"
            value={JSON.stringify(context, null, 2)}
            theme={theme === "golain" || theme === "none" ? "vs" : "vs-dark"}
            options={{
              readOnly: true,
              minimap: { enabled: false },
              scrollbar: { vertical: "hidden" },
              overviewRulerBorder: false,
              overviewRulerLanes: 0,
              folding: true,
              matchBrackets: "always",
              lineNumbers: "on",
              theme: theme === "golain" || theme === "none" ? "vs" : "vs-dark"
            }}
          />
        </div>

        <div className="flex justify-end p-4 bg-background">
          <Button
            variant="secondary"
            onClick={() => {
              setContextMaximizeOpen(false);
            }}
            className="mr-2 text-primary border-primary"
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UserContextEditor;
