import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { DeploymentProjects } from ".";
import CreateReleaseForm from "../components/ota-create-release-form.component";

const OTADeploymentProvider: React.FC = () => {
  const location = useLocation();
  const flag =
    location.pathname === "/ota-updates/projects/deployment" ||
    location.pathname === "/ota-updates/projects/deployment/";

  return (
    <>
      <CreateReleaseForm />
      {flag ? (
        <>
          <DeploymentProjects />
        </>
      ) : (
        <>
          <Outlet />
        </>
      )}
    </>
  );
};

export default OTADeploymentProvider;
