import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";

export const deleteInvite = async (orgId: string, inviteId: string) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `organizations/invites/${inviteId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteInvite = () => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (inviteId: string) =>
      await deleteInvite(user.selectedOrg.id, inviteId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getInvites");
      }
    }
  );

  return res;
};
