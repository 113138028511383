import React from "react";
import { useLocation } from "react-router-dom";
import { useServiceProvider } from "../../shared/hooks";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

interface IDeploymentCreationStep {
    name: string;
    status: string;
}

const DEPLOYMENTCREATION_PROGRESS: Array<IDeploymentCreationStep> = [
    { name: "Software And Device", status: "upcoming" },
    { name: "Rollout Schedule", status: "upcoming" },
    { name: "Deploy Details", status: "upcoming" },
];

export default function DeploymentCreationSteps() {
    const serviceProvider = useServiceProvider(),
        { pathname } = useLocation(),
        [progress, setProgress] = React.useState<Array<IDeploymentCreationStep>>([]);

    React.useEffect(() => {
        if (!pathname || !serviceProvider) {
            return;
        }

        if (pathname === "/ota-updates/projects") {
            setProgress([]);
            return;
        }

        const deploymentCreationStatus = serviceProvider?.deploymentCreationStatus,
            progressStatus = parseInt(deploymentCreationStatus ? deploymentCreationStatus : "0", 10);

        setProgress(
            DEPLOYMENTCREATION_PROGRESS.map((step, index) => {
                return index === progressStatus
                    ? { ...step, status: "current" }
                    : index > progressStatus
                        ? { ...step, status: "upcoming" }
                        : { ...step, status: "complete" };
            })
        );
    }, [pathname, serviceProvider]);

    if (!progress.length) {
        return null;
    }

    return (
        <nav aria-label="Progress">
            <ol className="flex items-center">
                {progress.map((step, stepIdx) => (
                    <li
                        key={step.name}
                        className={classNames(
                            stepIdx !== progress.length - 1 ? "pr-8 sm:pr-20 w-full" : "",
                            "relative"
                        )}>
                        {step.status === "complete" ? (
                            <React.Fragment>
                                <div
                                    className="absolute inset-0 flex items-center"
                                    aria-hidden="true">
                                    <div className="h-0.5 w-full bg-[#0AB63B]" />
                                </div>
                                <div className="relative flex items-center justify-center w-8 h-8 rounded-full bg-green-primary">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="16" fill="#0AB63B" />
                                        <path d="M21.3337 12L14.0003 19.3333L10.667 16" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className="sr-only">{step.name}</span>
                                </div>
                            </React.Fragment>
                        ) : step.status === "current" ? (
                            <React.Fragment>
                                <div
                                    className="absolute inset-0 flex items-center"
                                    aria-hidden="true">
                                    <div className="h-0.5 w-full bg-gray-200" />
                                </div>
                                <div
                                    className="relative flex items-center justify-center w-8 h-8 bg-white border rounded-full border-primary"
                                    aria-current="step">
                                    <span
                                        className="w-4 h-4 rounded-full bg-primary"
                                        aria-hidden="true"
                                    />
                                    <span className="sr-only">{step.name}</span>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div
                                    className="absolute inset-0 flex items-center"
                                    aria-hidden="true">
                                    <div className="h-0.5 w-full bg-gray-200" />
                                </div>
                                <div className="relative flex items-center justify-center w-8 h-8 bg-white border border-gray-300 rounded-full group hover:border-gray-400">
                                    <span
                                        className="w-4 h-4 bg-transparent rounded-full group-hover:bg-gray-300"
                                        aria-hidden="true"
                                    />
                                    <span className="sr-only">{step.name}</span>
                                </div>
                            </React.Fragment>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
}
