import { ITrigger } from "@/interfaces/triggers.interface";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { useQuery } from "react-query";

interface ITriggersResponse {
  ok: number;
  data: {
    triggers: ITrigger[];
  };
}

export const getTriggers = async (
  projectId: string,
  orgId: string,
  params
) => {
  const { data, ok } = await networkService.get<ITriggersResponse>(
    `projects/${projectId}/triggers/`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.triggers;
  } else return null;
};

export const useGetTriggers = (
  params: any = {},
  successCb: ((data: any[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getTriggers", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const triggers = await getTriggers(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(triggers);

      return triggers;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
