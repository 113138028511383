import React from "react";

interface ISettingsCommonHeaderProps {
  title: string;
}

function SettingsCommonHeader({ title }: ISettingsCommonHeaderProps) {
  return (
    <div className="bg-background text-contentColor border-background-layer3 border-b lg:px-8 sm:px-6 ">
      <div className="flex items-center py-5">
        <div className="flex items-center mt-3">
          <h1 className="text-2xl font-medium mr-1.5">{title}</h1>
        </div>
      </div>
    </div>
  );
}

export default SettingsCommonHeader;
