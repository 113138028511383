import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const deleteDeployments = async (
  projectId: string,
  orgId: string,
  deploymentId: string
) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `projects/${projectId}/ota_release_deployments/${deploymentId}/`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteDeployments = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (deploymentId: string) =>
      await deleteDeployments(
        selectedProject.id,
        user.selectedOrg.id,
        deploymentId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDeployments");
      }
    }
  );

  return res;
};
