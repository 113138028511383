import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface ICertificate {
  id: string;
  cert: string;
  type: string;
  project_id: string;
  fleet_id: string;
  org_id: string;
  created_at: string;
}

interface ICertificatesResponse {
  ok: number;
  data: { certificates: ICertificate[] };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export const getCertificates = async (
  id: string,
  projectId: string,
  orgId: string,
  params
) => {
  const { data, ok, meta } = await networkService.get<ICertificatesResponse>(
    `projects/${projectId}/certificates/${id}`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return { data, meta };
  } else return null;
};

export const useGetCertificates = (
  id: string = "",
  params: any = {},
  successCb: ((data: Omit<ICertificatesResponse, "ok">) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);

  const res = useQuery(
    [
      "getCertificates",
      selectedProject.id,
      user.selectedOrg.id,
      ...Object.keys(params).map((p) => `params-${p}-${params[p]}`)
    ],
    async () => {
      const certs = await getCertificates(
        id,
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(certs);

      return certs;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
