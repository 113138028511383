import { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import { Field, Form, Formik } from "formik";
import { FieldError } from "../../shared/components";
import DeploymentSuggestionPanel from "../components/ota-create-deployment-suggestion-panel.component";
import DeploymentCreationSteps from "../components/ota-deployment-creation-steps.component";
import { useNavigate } from "react-router-dom";
import { useAuthStore, useFleetAndDevicesStore } from "../../../store";
import useOTAReleaseStore from "../../../store/ota/ota-release.store";
import { useGetReleases } from "@app/shared/hooks/get";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import { IFleet } from "@interfaces/fad.interface";

interface IOption {
  label: string;
  value: string;
}

const SelectTargetSoftwareAndDevice = () => {
  const navigate = useNavigate();
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [fleets, setFleets, setSelectedFleet, selectedProject] =
    useFleetAndDevicesStore((state) => [
      state.fleets,
      state.setFleets,
      state.setSelectedFleet,
      state.selectedProject
    ]);
  const [
    releaseList,
    setReleaseList,
    setActiveRelease,
    setShowCreateForm,
    createDeploymentStepsData,
    setCreateDeploymentStepsData
  ] = useOTAReleaseStore((state) => [
    state.releaseList,
    state.setReleaseList,
    state.setActiveRelease,
    state.setShowCreateForm,
    state.createDeploymentStepsData,
    state.setCreateDeploymentStepsData
  ]);

  const [inputFieldValues, setInputFieldValues] = useState({
    deploymentName: createDeploymentStepsData.deploymentName || ""
  });
  const [inputFieldErrors, setInputFieldErrors] = useState({
    deploymentName: "",
    release: "",
    fleetTargets: ""
  });
  const [selectedRelease, setSelectedRelease] = useState<IOption | undefined>(
    createDeploymentStepsData.release || undefined
  );
  const [selectedFleetTargets, setSelectedFleetTargets] = useState<IOption[]>(
    createDeploymentStepsData.targetFleets || []
  );

  useEffect(() => {
    updateAuthUser({ deploymentCreationStatus: "0" });
  }, [updateAuthUser]);

  useGetReleases({}, (releases) => {
    setReleaseList(releases);
    if (releases.length) {
      setActiveRelease(releases[0]);
    }
  });

  useGetFleets({}, (fleets) => {
    if (fleets?.length) {
      setFleets(fleets);
      setSelectedFleet(fleets[0]);
    } else {
      setFleets([]);
      setSelectedFleet({} as IFleet);
    }
  });

  const validate = () => {
    const errors = { ...inputFieldErrors };

    // Depolyment name validation
    if (!inputFieldValues.deploymentName.trim()) {
      errors.deploymentName = "Please enter deployment name.";
    } else if (
      inputFieldValues.deploymentName.trim() &&
      errors.deploymentName
    ) {
      errors.deploymentName = "";
    }

    // Release Validation
    if (!selectedRelease?.value) {
      errors.release = "Please select release.";
    } else if (selectedRelease?.value && errors.release) {
      errors.release = "";
    }

    // Target Fleet Validation
    if (!selectedRelease?.value) {
      errors.fleetTargets = "Please select target fleets.";
    } else if (selectedRelease?.value && errors.release) {
      errors.fleetTargets = "";
    }

    if (errors.deploymentName || errors.release || errors.fleetTargets) {
      setInputFieldErrors({ ...errors });
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validate()) return;

    const payload = {
      deploymentName: inputFieldValues.deploymentName,
      release: selectedRelease,
      targetFleets: selectedFleetTargets
    };

    setCreateDeploymentStepsData(payload);

    navigate(
      "/ota-updates/projects/deployment/create-deployment/rollout-schedule"
    );
  };

  const handleFieldChange = (e: any, id: string) => {
    const payload = { ...inputFieldValues };
    payload[id] = e.target.value;
    setInputFieldValues({ ...payload });
  };

  const handleReleaseSelect = (release: { value: string; label: string }) => {
    if (release.value === "CREATE_RELEASE") {
      setShowCreateForm(true);
      return;
    }

    if (inputFieldErrors.release) {
      setInputFieldErrors({ ...inputFieldErrors, release: "" });
    }
    setSelectedRelease(release);
  };

  const handleFleetTargetSelect = (
    fleet: { value: string; label: string }[]
  ) => {
    if (fleet.length && fleet[fleet.length - 1].value === "+ Create Fleet") {
      navigate("/fleet-and-devices/projects/about-fleet/");
      return;
    }

    if (inputFieldErrors.fleetTargets) {
      setInputFieldErrors({ ...inputFieldErrors, fleetTargets: "" });
    }
    setSelectedFleetTargets([...fleet]);
  };

  const releaseOptions = useMemo(() => {
    const opts = releaseList.map(({ props, id }) => {
      return { value: id, label: props.release_name };
    });
    opts.push({ value: "CREATE_RELEASE", label: "+ Create Release" });

    return opts;
  }, [releaseList]);

  const fleetOptions = useMemo(() => {
    const opts = fleets.map(({ fleet_name, id }) => {
      return { value: id, label: fleet_name };
    });
    opts.push({ value: "+ Create Fleet", label: "+ Create Fleet" });

    return opts;
  }, [fleets]);

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12  pb-8">
          <div className="mt-7 mb-5 w-10/12">
            <DeploymentCreationSteps />
          </div>
          <Formik
            initialValues={{ deploymentName: "" }}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col flex-1">
              <div>
                <h1 className="text-lg text-left font-medium mb-2.5">
                  Select target software and device
                </h1>

                <div className="flex-1">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-gray-600">
                      Deployment Name
                    </label>

                    <Field
                      type="text"
                      id="deploymentName"
                      name="deploymentName"
                      placeholder="Deployment Name"
                      value={inputFieldValues.deploymentName}
                      onChange={(e) => handleFieldChange(e, "deploymentName")}
                      className="block w-10/12 p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                    />
                    {inputFieldErrors.deploymentName ? (
                      <FieldError message={inputFieldErrors.deploymentName} />
                    ) : null}
                  </div>

                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Release
                    </label>
                    <Select
                      placeholder="Select"
                      isSearchable={false}
                      options={releaseOptions}
                      value={selectedRelease}
                      onChange={handleReleaseSelect}
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                    {inputFieldErrors.release ? (
                      <FieldError message={inputFieldErrors.release} />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Fleet Targets
                    </label>
                    <Select
                      placeholder="Select"
                      isSearchable={false}
                      options={fleetOptions}
                      value={selectedFleetTargets}
                      onChange={handleFleetTargetSelect}
                      isClearable={true}
                      isMulti
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                    {inputFieldErrors.fleetTargets ? (
                      <FieldError message={inputFieldErrors.fleetTargets} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="">
                <button
                  type="submit"
                  className="w-44 inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  Next
                </button>
              </div>
            </Form>
          </Formik>
        </div>

        <div className="w-7/12">
          <DeploymentSuggestionPanel />
        </div>
      </div>
    </>
  );
};

export default SelectTargetSoftwareAndDevice;
