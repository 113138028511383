import { deserializeVariable } from "@/app/shared/utils/helper.util";
import { NodeType } from "@app/rule-engine/node-types.helper";
import {
  ActionType,
  IContext,
  IInput,
  getEdgeId,
  getId
} from "@app/rule-engine/rule-engine.helper";
import { IAction, getActions } from "@app/shared/hooks/get/actions";
import { toast } from "react-toastify";
import { Edge, Node } from "reactflow";

interface ISerializedActionData {
  actionType: string;
  name: string;
  inputs: IInput[];
  fetchedContexts: IContext[];
  actionDefinition: any;
}

export const evalConditionResponse = (curNode, condition, resType, ind) => {
  const newId = getId();

  const _newResponseData = {
    status_code: condition[resType].status_code,
    body: condition[resType].body
  };

  const _newNode: Node = {
    id: newId,
    type: NodeType.httpResponseNode,
    data: {},
    position: {
      x: curNode?.position.x + 1000,
      y: curNode?.position.y + 250 * ind
    }
  };

  const _newEdgeId = getEdgeId();
  const _newEdge: Edge = {
    id: _newEdgeId,
    sourceHandle: `conditions-${ind}-${
      resType === "true_response" ? "true" : "false"
    }`,
    targetHandle: `http-response-${newId}`,
    source: curNode.id,
    target: newId,
    style: {
      stroke: resType === "true_response" ? "green" : "red"
    },
    type: "buttonEdge"
  };

  return {
    node: _newNode,
    edge: _newEdge,
    response: _newResponseData
  };
};

export const evalConditionSequence = (
  curNode,
  condition,
  seqType,
  ind,
  orgId,
  projId,
  setEditAction,
  onGetAction
) => {
  const newId = getId();

  const _newSeqData = {};

  const _newNode: Node = {
    id: newId,
    type: NodeType.actionSequenceNode,
    data: {
      setEditAction
    },
    position: {
      x: curNode?.position.x + 1500,
      y: curNode?.position.y + 350 * ind
    }
  };

  condition[seqType].forEach(async (action) => {
    const actions = await getActions(projId, orgId, {
      action_id: action.action_id
    });

    if (!actions?.length) {
      toast.error("Could not find action with ID: " + action.action_id);
      return;
    }

    const actionData = actions[0];
    onGetAction(_newNode, actionData, action);
  });

  const _newEdgeId = getEdgeId();
  const _newEdge: Edge = {
    id: _newEdgeId,
    sourceHandle: `conditions-${ind}-${
      seqType === "true_sequence" ? "true" : "false"
    }`,
    targetHandle: `action-sequence-${newId}`,
    source: curNode.id,
    target: newId,
    style: {
      stroke: seqType === "true_sequence" ? "green" : "red"
    },
    type: "buttonEdge"
  };

  return {
    node: _newNode,
    edge: _newEdge,
    response: _newSeqData
  };
};

export const deserializeActionData = (actionData: IAction) => {
  const _actionData: ISerializedActionData = {
    actionType: actionData.action_type,
    name: actionData.name,
    inputs: Object.keys(actionData.additional_params ?? {}).map((key) => ({
      key,
      type: actionData.additional_params[key]
    })),
    fetchedContexts: actionData.fetched_context?.map((context) => ({
      device_id: deserializeVariable(context.device_id),
      user_id: deserializeVariable(context.user_id),
      fleet_id: deserializeVariable(context.fleet_id),
      key: context.key,
      type: context.type,
      error: false
    })),
    actionDefinition: null
  };

  switch (actionData.action_type as ActionType) {
    case ActionType.USER_METADATA:
      _actionData.actionDefinition = {
        userId: deserializeVariable(actionData.definition.user_id),
        payload: actionData.definition.payload
      };
      break;

    case ActionType.DEVICE_SHADOW:
      _actionData.actionDefinition = {
        deviceId: deserializeVariable(actionData.definition.device_id),
        payload: actionData.definition.payload,
        shadow_definition_id: actionData.definition.meta?.shadow_definition_id
      };
      break;

    case ActionType.HTTP:
      _actionData.actionDefinition = {
        url: actionData.definition.url,
        method: actionData.definition.method.toLowerCase(),
        body: actionData.definition.body,
        headers: Object.keys(actionData.definition.headers ?? {}).map(
          (key) => ({
            key,
            value: deserializeVariable(actionData.definition.headers[key])
          })
        )
      };
      break;

    case ActionType.FCMNotification:
      _actionData.actionDefinition = {
        credentials: deserializeVariable(actionData.definition.credentials),
        environments: actionData.definition.environments.map((env: string) =>
          deserializeVariable(env)
        ),
        fcm_message: {
          body: deserializeVariable(actionData.definition.fcm_message.body),
          title: deserializeVariable(actionData.definition.fcm_message.title),
          token: deserializeVariable(actionData.definition.fcm_message.token),
          topic: deserializeVariable(actionData.definition.fcm_message.topic),
          data: Object.keys(actionData.definition.headers ?? {}).map(
            (key) => ({
              key,
              value: deserializeVariable(actionData.definition.headers[key])
            })
          )
        }
      };
      break;

    default:
      _actionData.actionDefinition = null;
  }

  return _actionData;
};
