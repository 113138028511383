import { useQuery } from "react-query";

import { IProject, IProjectResponse } from "@interfaces/shared.interface";
import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";

export const getProjects = async (orgId, params) => {
  const { data, ok } = await networkService.get<IProjectResponse>(
    `projects`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.projects;
  } else return null;
};

export const useGetProjects = (
  params: any = {},
  successCb: ((data: IProject[]) => void) | null = null
) => {
  const [user] = useAuthStore((state) => [state.user]);

  const res = useQuery(
    ["getProjects", user.selectedOrg?.id, params],
    async () => {
      const projects = await getProjects(user.selectedOrg.id, params);
      successCb && successCb(projects);

      return projects;
    },
    {
      enabled: !!user.selectedOrg?.id
    }
  );

  return res;
};
