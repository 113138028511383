import { useState, useMemo, useRef } from "react";
import { Formik, Field, Form } from "formik";
import { useGetFleets } from "@/app/shared/hooks/get/fleets";
import ReactSelect from "react-select";
import { Color, Switch } from "@tremor/react";
import { IOption, IPopupConfig } from "@/interfaces";
import { Disclosure } from "@headlessui/react";
import { Badge, Button } from "@tremor/react";
import {
  ChevronDownIcon,
  Cog6ToothIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { useGetTags } from "@/app/shared/hooks/get/tags";
import { useGetShadowDefinitions } from "@/app/shared/hooks/get/shadow-definitions";
import { useDashboardStore } from "@/store";

import {
  reactSelectClassNames,
  shadowFieldsGenerator
} from "@/app/shared/utils/helper.util";

interface IInputValues {
  filters: {
    fleetIds?: string[];
    tagIds?: string[];
  };
  shadowDefIDs: string[];
  configuringShadowDefId?: string;
  popupConfigs: Record<string, IPopupConfig>;
  shadowDefProps: Record<string, any>;
}

const badgeColors: Color[] = [
  "gray",
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose"
];

const badgeOptions = badgeColors.map((color) => ({
  value: color,
  label: <Badge color={color}>{color}</Badge>
}));

export const GeoMapForm = ({ onChangeHandler, setCreateButtonDisabled }) => {
  const disclosureButtonRefs = useRef([]);
  const disclosureOpenedRef = useRef(null);

  const [panelName, setPanelName] = useState("");
  const [errors, setErrors] = useState({
    name: true
  });

  const [inputValues, setInputValues] = useState<IInputValues>({
    filters: {
      fleetIds: [],
      tagIds: []
    },
    shadowDefIDs: [],
    popupConfigs: {},
    shadowDefProps: {}
  });

  const [setCreatePanelAppearance, setCreatePanelState] = useDashboardStore(
    (state) => [state.setCreatePanelAppearance, state.setCreatePanelState]
  );

  const { data: fleets, isLoading: isFleetsLoading } = useGetFleets();
  const { data: tags, isLoading: isTagsLoading } = useGetTags();
  const { data: shadowDefs, isLoading: isShadowDefsLoading } =
    useGetShadowDefinitions({ fields: "shadow_proto_structure" });

  const fleetOptions = useMemo<IOption[]>(() => {
    return fleets?.map((fleet) => ({
      label: fleet.fleet_name,
      value: fleet.id
    }));
  }, [fleets]);

  const tagOptions = useMemo<IOption[]>(() => {
    return tags?.map((tag) => ({
      label: tag.name,
      value: tag.id
    }));
  }, [tags]);

  const shadowDefOptions = useMemo<IOption[]>(() => {
    return shadowDefs?.map((shadowDef) => ({
      label: shadowDef.name,
      value: shadowDef.id
    }));
  }, [shadowDefs]);

  const handleChange = (key, value) => {
    const newInputs: IInputValues = {
      ...inputValues
    };

    if (key === "tags") {
      newInputs.filters = {
        ...newInputs.filters,
        tagIds: value?.map((opt) => opt.value) ?? []
      };
    } else if (key === "fleets") {
      newInputs.filters = {
        ...newInputs.filters,
        fleetIds: value?.map((opt) => opt.value) ?? []
      };
    } else if (key === "shadowDefs") {
      newInputs.shadowDefIDs = value?.map((opt) => opt.value) ?? [];
      newInputs.shadowDefIDs
        .map((id) => shadowDefs.find((s) => s.id === id))
        .forEach((def) => {
          if (!newInputs.shadowDefProps?.[def.id]) {
            const fields = shadowFieldsGenerator(def.shadow_proto_structure);
            newInputs.shadowDefProps = {
              ...newInputs.shadowDefProps,
              [def.id]: fields.map((field) => ({
                value: field,
                label: `${field.name} [ ${field.structure} ]`,
                data: field
              }))
            };
          }
        });
    } else if (key === "configuringShadowDefId") {
      newInputs.configuringShadowDefId = value;
    } else if (key === "popupConfigs") {
      newInputs.popupConfigs = {
        ...newInputs.popupConfigs,
        [value.id]: {
          ...newInputs.popupConfigs[value.id],
          [value.config]: value.value
        }
      };
    }

    onChangeHandler([{ filters: newInputs.filters }]);

    setInputValues(newInputs);
    setCreatePanelAppearance(newInputs);
  };

  const clickRecent = (index) => {
    const clickedButton = disclosureButtonRefs.current[index];
    if (clickedButton === disclosureOpenedRef.current) {
      disclosureOpenedRef.current = null;
      return;
    }

    if (Boolean(disclosureOpenedRef.current?.getAttribute("data-value"))) {
      disclosureOpenedRef.current?.click();
    }
    disclosureOpenedRef.current = clickedButton;
  };

  return (
    <Formik initialValues={{ selectedFleets: [] }} onSubmit={() => {}}>
      <Form className="w-10/12">
        <div className="w-10/12">
          <label className="text-sm font-medium text-contentColor">
            Panel Name
          </label>
          <Field
            type="text"
            id={"table-data-points"}
            name={"table-data-points"}
            value={panelName}
            placeholder="Name"
            className={`block w-10/12 p-3 mt-2 rounded-md bg-background border-background-layer3 border focus:ring focus:ring-opacity-40 sm:text-sm ${
              errors.name
                ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                : "border-background-layer3 focus:ring-primaryLight focus:border-primary"
            }`}
            onChange={(e) => {
              setPanelName(e.target.value);
              if (e.target.value.trim()) {
                setErrors((err) => ({
                  ...err,
                  name: false
                }));
                setCreateButtonDisabled(false);
                setCreatePanelState((prev) => ({
                  ...prev,
                  panelName: e.target.value.trim()
                }));
              } else {
                setErrors((err) => ({
                  ...err,
                  name: true
                }));
                setCreateButtonDisabled(true);
              }
            }}
          />
        </div>
        {errors.name && (
          <p className="text-xs text-red-500 mt-1">Please enter panel name!</p>
        )}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button as="div">
                <button
                  data-value={open}
                  ref={(ref) => {
                    disclosureButtonRefs.current[1] = ref;
                  }}
                  onClick={() => clickRecent(1)}
                  className="flex w-full mt-4 gap-2 items-center bg-background-layer1 p-3 justify-between"
                >
                  <span className="flex gap-2">
                    <span>Filters</span>

                    {inputValues.filters.fleetIds?.length ? (
                      <Badge className="bg-background-layer2">
                        Fleets: {inputValues.filters.fleetIds.length}
                      </Badge>
                    ) : null}
                    {inputValues.filters.tagIds?.length ? (
                      <Badge className="bg-background-layer2">
                        Tags: {inputValues.filters.tagIds?.length}
                      </Badge>
                    ) : null}
                    {!inputValues.filters.fleetIds?.length &&
                    !inputValues.filters.tagIds?.length ? (
                      <Badge className="bg-background-layer2">
                        All Devices
                      </Badge>
                    ) : null}
                  </span>
                  <ChevronDownIcon
                    width={16}
                    className={`transition-transform ${
                      open ? "rotate-180" : "rotate-0"
                    } `}
                  />
                </button>
              </Disclosure.Button>
              <Disclosure.Panel
                unmount={false}
                className={`py-3 px-6 border border-background-layer3 bg-background-layer1`}
              >
                <div className="w-10/12">
                  <label className="text-sm font-medium text-contentColorLight">
                    Fleets
                  </label>
                  <Field
                    as="select"
                    id={"selectedFleets"}
                    name={"selectedFleets"}
                    value={fleetOptions?.filter((opt) =>
                      (inputValues.filters?.fleetIds).includes(opt.value)
                    )}
                    placeholder="Select Fleets"
                    options={fleetOptions}
                    component={({ field, form, ...props }) => (
                      <ReactSelect
                        {...field}
                        {...props}
                        isMulti
                        isLoading={isFleetsLoading}
                        isSearchable
                        menuPortalTarget={document.body}
                      />
                    )}
                    className={`block w-10/12 2xl mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm`}
                    onChange={(opts: IOption[]) => {
                      handleChange("fleets", opts);
                    }}
                  />
                </div>

                <div className="w-10/12 mt-2">
                  <label className="text-sm w-10/12 font-medium text-contentColorLight">
                    Tags
                  </label>
                  <Field
                    as="select"
                    id={"selectedTags"}
                    name={"selectedTags"}
                    value={tagOptions?.filter((opt) =>
                      (inputValues.filters?.tagIds).includes(opt.value)
                    )}
                    placeholder="Select Tags"
                    isMulti
                    options={tagOptions}
                    component={({ field, form, ...props }) => (
                      <ReactSelect
                        {...field}
                        {...props}
                        isMulti
                        isLoading={isTagsLoading}
                        isSearchable
                        menuPortalTarget={document.body}
                      />
                    )}
                    className={`block w-10/12 2xl mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm 
                                border-gray-300 focus:ring-blue-300 focus:border-blue-400`}
                    onChange={(opts: IOption[]) => {
                      handleChange("tags", opts);
                    }}
                  />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button as="div">
                <button
                  data-value={open}
                  ref={(ref) => {
                    disclosureButtonRefs.current[2] = ref;
                  }}
                  onClick={() => clickRecent(2)}
                  className={`${
                    open ? "" : "rounded-b-md"
                  } flex w-full gap-2 items-center bg-background-layer1 p-3 justify-between`}
                >
                  <span>
                    <span>Appearance</span>
                  </span>
                  <ChevronDownIcon
                    width={16}
                    className={`transition-transform ${
                      open ? "rotate-180" : "rotate-0"
                    } `}
                  />
                </button>
              </Disclosure.Button>
              <Disclosure.Panel
                unmount={false}
                className={`py-3 rounded-b-md px-6 border border-background-layer3 bg-background-layer1`}
              >
                <div className="w-10/12">
                  <label className="text-sm font-medium text-contentColorLight">
                    Shadow Definition
                  </label>
                  <Field
                    as="select"
                    id={"selectedFleets"}
                    name={"selectedFleets"}
                    value={shadowDefOptions?.filter((opt) =>
                      inputValues.shadowDefIDs.includes(opt.value)
                    )}
                    placeholder="Select Shadow Definitions"
                    options={shadowDefOptions}
                    component={({ field, form, ...props }) => (
                      <ReactSelect
                        {...field}
                        {...props}
                        isMulti
                        isLoading={isShadowDefsLoading}
                        isSearchable
                        menuPortalTarget={document.body}
                      />
                    )}
                    className={`block w-10/12 2xl mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm`}
                    onChange={(opts: IOption[]) => {
                      handleChange("shadowDefs", opts);
                    }}
                  />
                </div>
                <hr className="mt-4" />
                <div className="mt-4">
                  {inputValues.shadowDefIDs?.length ? (
                    <h3 className="text-sm text-contentColorLight mb-2">
                      Select how devices with different shadow definitions will
                      be displayed on the map:
                    </h3>
                  ) : null}
                  <div className="flex flex-col gap-2">
                    {inputValues.shadowDefIDs
                      ?.map((id) => shadowDefs?.find((def) => def.id === id))
                      ?.map((shadow) => (
                        <div
                          key={shadow.id}
                          className="p-3 border border-background-layer3 rounded-md"
                        >
                          <div className="flex justify-between items-center">
                            <span className="text-sm">{shadow.name}</span>
                            <Button
                              icon={
                                inputValues.configuringShadowDefId ===
                                shadow.id
                                  ? XMarkIcon
                                  : Cog6ToothIcon
                              }
                              type="button"
                              iconPosition="right"
                              variant="light"
                              size="xs"
                              onClick={() =>
                                handleChange(
                                  "configuringShadowDefId",
                                  inputValues.configuringShadowDefId ===
                                    shadow.id
                                    ? ""
                                    : shadow.id
                                )
                              }
                            >
                              {inputValues.configuringShadowDefId === shadow.id
                                ? "Close"
                                : "Configure"}
                            </Button>
                          </div>
                          {inputValues.configuringShadowDefId === shadow.id ? (
                            <div className="mt-2 flex flex-col gap-2">
                              <label className="text-sm flex gap-2 font-medium text-contentColorLight">
                                Show Location:
                                <Switch
                                  color={"green"}
                                  checked={
                                    inputValues.popupConfigs[shadow.id]
                                      ?.showLocation
                                  }
                                  onChange={(val) => {
                                    handleChange("popupConfigs", {
                                      id: shadow.id,
                                      config: "showLocation",
                                      value: val
                                    });
                                  }}
                                />
                              </label>
                              <label className="text-sm whitespace-nowrap  flex gap-2 items-center font-medium text-contentColorLight">
                                Show Device Navigation:
                                <Switch
                                  color={"green"}
                                  checked={
                                    inputValues.popupConfigs[shadow.id]
                                      ?.showNavigateToDevice
                                  }
                                  onChange={(val) => {
                                    handleChange("popupConfigs", {
                                      id: shadow.id,
                                      config: "showNavigateToDevice",
                                      value: val
                                    });
                                  }}
                                />
                              </label>
                              <label className="text-sm whitespace-nowrap flex flex-col gap-2  font-medium text-contentColorLight">
                                Select Shadow Params to show:
                                <ReactSelect
                                  value={
                                    inputValues.popupConfigs[shadow.id]
                                      ?.shadowPropsToShow
                                  }
                                  options={
                                    inputValues.shadowDefProps[shadow.id]
                                  }
                                  onChange={(opts: IOption[]) => {
                                    handleChange("popupConfigs", {
                                      id: shadow.id,
                                      config: "shadowPropsToShow",
                                      value: opts
                                    });
                                  }}
                                  isMulti
                                  isLoading={isShadowDefsLoading}
                                  isSearchable
                                  menuPortalTarget={document.body}
                                  classNames={reactSelectClassNames}
                                  className="ml-4"
                                />
                              </label>
                              {inputValues.popupConfigs[
                                shadow.id
                              ]?.shadowPropsToShow?.filter(
                                (prop) => prop.value.options?.length
                              )?.length ? (
                                <label className="text-sm whitespace-nowrap font-medium text-contentColorLight">
                                  Select colors for ENUM type params:
                                </label>
                              ) : null}
                              {inputValues.popupConfigs[
                                shadow.id
                              ]?.shadowPropsToShow?.map((prop, propInd) =>
                                !prop.value.options?.length ? null : (
                                  <div key={prop.label} className="ml-2">
                                    <h3 className="text-sm flex gap-2">
                                      {prop.value.name}
                                      <Badge
                                        size={"xs"}
                                        className="cursor-pointer"
                                        onClick={() => {
                                          const _shadowProps = [
                                            ...inputValues.popupConfigs[
                                              shadow.id
                                            ]?.shadowPropsToShow
                                          ];

                                          inputValues.popupConfigs[
                                            shadow.id
                                          ]?.shadowPropsToShow?.[
                                            propInd
                                          ].value.options.forEach((opt) => {
                                            _shadowProps[propInd] = {
                                              ..._shadowProps[propInd],
                                              enumBadgeColors: {
                                                ...(_shadowProps[propInd]
                                                  .enumBadgeColors || {}),
                                                [opt]:
                                                  badgeColors[
                                                    Math.floor(
                                                      Math.random() *
                                                        badgeColors.length
                                                    )
                                                  ]
                                              }
                                            };
                                          });

                                          handleChange("popupConfigs", {
                                            id: shadow.id,
                                            config: "shadowPropsToShow",
                                            value: _shadowProps
                                          });
                                        }}
                                      >
                                        Auto Pick
                                      </Badge>
                                    </h3>
                                    <div className="flex flex-row flex-wrap gap-1 pl-2">
                                      {prop.value.options.map((opt) => (
                                        <div key={opt}>
                                          <label className="text-sm font-medium text-contentColorLight">
                                            {opt}
                                          </label>
                                          <Field
                                            as="select"
                                            id={prop.value.name + "enum" + opt}
                                            name={
                                              prop.value.name + "enum" + opt
                                            }
                                            placeholder=""
                                            defaultValue={badgeOptions[0]}
                                            value={badgeOptions.filter(
                                              (badgeOpt) =>
                                                badgeOpt.value ===
                                                prop.enumBadgeColors?.[opt]
                                            )}
                                            component={ReactSelect}
                                            options={badgeOptions}
                                            menuPortalTarget={document.body}
                                            maxMenuHeight={100}
                                            className={`block min-w-[100px] rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                                              false
                                                ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                                                : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                                            }`}
                                            classNames={reactSelectClassNames}
                                            onChange={(e) => {
                                              const _shadowProps = [
                                                ...inputValues.popupConfigs[
                                                  shadow.id
                                                ]?.shadowPropsToShow
                                              ];
                                              _shadowProps[propInd] = {
                                                ..._shadowProps[propInd],
                                                enumBadgeColors: {
                                                  ...(_shadowProps[propInd]
                                                    .enumBadgeColors || {}),
                                                  [opt]: e.value
                                                }
                                              };

                                              handleChange("popupConfigs", {
                                                id: shadow.id,
                                                config: "shadowPropsToShow",
                                                value: _shadowProps
                                              });
                                            }}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )
                              )}
                              <label className="text-sm whitespace-nowrap flex flex-col gap-2  font-medium text-contentColorLight">
                                Custom Icon URL:
                                <input
                                  placeholder="https://www.example.com/sample-img.png"
                                  value={
                                    inputValues.popupConfigs[shadow.id]
                                      ?.iconURL
                                  }
                                  onChange={(e) => {
                                    handleChange("popupConfigs", {
                                      id: shadow.id,
                                      config: "iconURL",
                                      value: e.target.value
                                    });
                                  }}
                                  className="ml-4 text-contentColor font-normal block p-2 bg-background border border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
                                />
                              </label>
                            </div>
                          ) : null}
                        </div>
                      ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Form>
    </Formik>
  );
};
