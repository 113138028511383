interface IIndividualStatusTabProps {
    deploymentId: string;
}

const Rollback = (props: IIndividualStatusTabProps) => {
    return (
        <>
            Rollback
        </>
    )
}

export default Rollback;