import React, { useEffect, useState } from "react";
import DatapointDefinitionDetailsTabs, {
  DatapointDefinitionTabs
} from "./data-points-tabs.components";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import DatapointDefinitionDetails from "./tabs/data-point-definition-details.component";
import DatapointDefinitionVersions from "./tabs/data-point-definition-versions.component";
import DatapointDefinitionUsage from "./tabs/data-point-definition-usage.component";
import DatapointDefinitionRetention from "./tabs/data-point-definition-retention.component";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";
import DataPointDefinitionData from "./tabs/data-point-definition-data.component";
import ShowLoading from "@/app/shared/components/loading.component";

const DatapointDefinitionTabsRenderer: React.FC = () => {
  const location = useLocation();
  const [dataPointDef, setDataPointDef] = useState(null);
  const [datapointDefId] = useState(location.pathname.split("/")[3]);

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") as DatapointDefinitionTabs;

  const { isLoading } = useGetDataPointDefinitions(
    {
      ids: datapointDefId,
      fields: "data_point_proto,message_name,data_point_proto_descriptor"
    },
    (dataPoints) => {
      if (!dataPoints?.length) {
        toast.error(
          `Data Point definition with ID ${datapointDefId} not found.`
        );

        return;
      }

      const _dataPointDef = dataPoints[0];
      setDataPointDef(_dataPointDef);
    }
  );

  const [activeTab, setActiveTab] = useState<DatapointDefinitionTabs>(
    tab ?? DatapointDefinitionTabs.DETAILS
  );

  useEffect(() => {
    setSearchParams(
      { tab: activeTab ?? DatapointDefinitionTabs.DETAILS },
      {
        replace: true
      }
    );
  }, [activeTab, setSearchParams]);

  if (isLoading) {
    return <ShowLoading />;
  }

  return (
    <>
      <DatapointDefinitionDetailsTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === DatapointDefinitionTabs.DETAILS && dataPointDef ? (
        <DatapointDefinitionDetails dataPointDef={dataPointDef} />
      ) : activeTab === DatapointDefinitionTabs.VERSIONS && dataPointDef ? (
        <DatapointDefinitionVersions dataPointDef={dataPointDef} />
      ) : activeTab === DatapointDefinitionTabs.USAGE && dataPointDef ? (
        <DatapointDefinitionUsage dataPointDef={dataPointDef} />
      ) : activeTab === DatapointDefinitionTabs.RETENTION && dataPointDef ? (
        <DatapointDefinitionRetention dataPointDef={dataPointDef} />
      ) : activeTab === DatapointDefinitionTabs.DATA && dataPointDef ? (
        <DataPointDefinitionData dataPointDef={dataPointDef} />
      ) : null}
    </>
  );
};

export default DatapointDefinitionTabsRenderer;
