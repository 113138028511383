import { Badge } from "@tremor/react";

interface IColumParamLabelProps {
  paramName: string;
  dataSourceName: string;
  dataSource: "shadow" | "dataPoint";
}

const ColumnParamLabel: React.FC<IColumParamLabelProps> = ({
  paramName,
  dataSourceName,
  dataSource
}) => {
  if (dataSource === "shadow") {
    return (
      <div className="flex items-center gap-2">
        {paramName}
        <Badge tooltip="Shadow Param" color="yellow">
          {dataSourceName}
        </Badge>
      </div>
    );
  } else if (dataSource === "dataPoint") {
    return (
      <div className="flex items-center gap-2">
        {paramName}
        <Badge
          tooltip="Data Point Param"
          color="emerald"
          size="xs"
          className="!text-xs"
        >
          {dataSourceName}
        </Badge>
      </div>
    );
  }
};

export default ColumnParamLabel;
