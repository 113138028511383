import { useMemo } from "react";
import SettingsGenericTable from "../components/settings-generic-table.component";
import ApiKeyForm from "../components/settings-api-keys-form.component";
import useSettingsStore from "../../../store/settings/settings.store";
import dateService from "../../../services/date.service";
import { useGetApps } from "@app/shared/hooks/get/apps";

function APIKeys() {
  const [setShowGenerateAPIKeyForm] = useSettingsStore((state) => [
    state.setShowGenerateAPIKeyForm
  ]);

  const { data: projectApps } = useGetApps();

  const renderStatus = (status: string) => {
    const statusUI = {
      admin: (
        <span className="flex">
          <span className="bg-[#25a96a36] py-2 px-5 rounded text-[#25A96A] font-medium text-xs mr-6">
            ADMIN
          </span>
          {/* <button>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.33366 10.0002C8.33366 10.9206 9.07985 11.6668 10.0003 11.6668C10.9208 11.6668 11.667 10.9206 11.667 10.0002C11.667 9.07969 10.9208 8.3335 10.0003 8.3335C9.07985 8.3335 8.33366 9.07969 8.33366 10.0002Z" fill="black" fillOpacity="0.4" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.33366 16.6667C8.33366 17.5871 9.07985 18.3333 10.0003 18.3333C10.9208 18.3333 11.667 17.5871 11.667 16.6667C11.667 15.7462 10.9208 15 10.0003 15C9.07985 15 8.33366 15.7462 8.33366 16.6667Z" fill="black" fillOpacity="0.4" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.33366 3.33317C8.33366 4.25364 9.07985 4.99984 10.0003 4.99984C10.9208 4.99984 11.667 4.25364 11.667 3.33317C11.667 2.4127 10.9208 1.6665 10.0003 1.6665C9.07985 1.6665 8.33366 2.4127 8.33366 3.33317Z" fill="black" fillOpacity="0.4" />
                        </svg>
                    </button> */}
        </span>
      ),
      viewer: (
        <span className="flex">
          <span className="bg-[#5657591a] py-2 px-[17px] rounded text-[#565759] font-medium text-xs mr-6">
            VIEWER
          </span>
          {/* <button>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.33366 10.0002C8.33366 10.9206 9.07985 11.6668 10.0003 11.6668C10.9208 11.6668 11.667 10.9206 11.667 10.0002C11.667 9.07969 10.9208 8.3335 10.0003 8.3335C9.07985 8.3335 8.33366 9.07969 8.33366 10.0002Z" fill="black" fillOpacity="0.4" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.33366 16.6667C8.33366 17.5871 9.07985 18.3333 10.0003 18.3333C10.9208 18.3333 11.667 17.5871 11.667 16.6667C11.667 15.7462 10.9208 15 10.0003 15C9.07985 15 8.33366 15.7462 8.33366 16.6667Z" fill="black" fillOpacity="0.4" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.33366 3.33317C8.33366 4.25364 9.07985 4.99984 10.0003 4.99984C10.9208 4.99984 11.667 4.25364 11.667 3.33317C11.667 2.4127 10.9208 1.6665 10.0003 1.6665C9.07985 1.6665 8.33366 2.4127 8.33366 3.33317Z" fill="black" fillOpacity="0.4" />
                        </svg>
                    </button> */}
        </span>
      ),
      member: (
        <span className="flex">
          <span className="bg-[#166ff629] py-2 px-[17px] rounded text-[#1366E5] font-medium text-xs mr-6">
            MEMBER
          </span>
          {/* <button>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.33366 10.0002C8.33366 10.9206 9.07985 11.6668 10.0003 11.6668C10.9208 11.6668 11.667 10.9206 11.667 10.0002C11.667 9.07969 10.9208 8.3335 10.0003 8.3335C9.07985 8.3335 8.33366 9.07969 8.33366 10.0002Z" fill="black" fillOpacity="0.4" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.33366 16.6667C8.33366 17.5871 9.07985 18.3333 10.0003 18.3333C10.9208 18.3333 11.667 17.5871 11.667 16.6667C11.667 15.7462 10.9208 15 10.0003 15C9.07985 15 8.33366 15.7462 8.33366 16.6667Z" fill="black" fillOpacity="0.4" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.33366 3.33317C8.33366 4.25364 9.07985 4.99984 10.0003 4.99984C10.9208 4.99984 11.667 4.25364 11.667 3.33317C11.667 2.4127 10.9208 1.6665 10.0003 1.6665C9.07985 1.6665 8.33366 2.4127 8.33366 3.33317Z" fill="black" fillOpacity="0.4" />
                        </svg>
                    </button> */}
        </span>
      )
    };

    return statusUI[status.toLowerCase()];
  };

  const collaborators = useMemo(() => {
    const filterApps =
      projectApps?.map((app) => {
        return {
          app_name: app.AppName,
          action: app.Permissions[0].actions[0],
          joining_date: dateService.convertUTCToLocalDate(
            app?.CreatedAt || ""
          ),
          org_access: renderStatus(app.ProjectAccess)
        };
      }) || [];

    return filterApps;
  }, [projectApps]);

  return (
    <>
      <ApiKeyForm />
      <div>
        <button
          onClick={() => setShowGenerateAPIKeyForm(true)}
          className="inline-block px-2.5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
        >
          + Add API Key
        </button>

        <div className="flex flex-col mt-8 w-full h-[50vh] bg-background text-contentColor pb-6">
          <div className="-my-2 overflow-x-auto xl:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded-xl">
                {!!collaborators?.length && (
                  <SettingsGenericTable
                    column={Object.keys(collaborators[0])}
                    row={collaborators}
                    searchField={
                      collaborators.length
                        ? Object.keys(collaborators[0])[0]
                        : ""
                    }
                    searchTerm=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default APIKeys;
