import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IPanelDataResponse } from "@interfaces/dashboard.interface";

export const getDashboardPanelData = async (
  dashboardId: string,
  panelId: string,
  projectId: string,
  orgId: string,
  params: any,
  config: any
) => {
  const { data, ok } = await networkService.get<IPanelDataResponse>(
    `projects/${projectId}/dashboards/${dashboardId}/panels/${panelId}/data`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      },
      ...config
    }
  );

  if (ok) {
    return data.panelData;
  } else return null;
};

export const useGetDashboardPanelData = (
  dashboardId: string,
  panelId: string,
  refetch: boolean = true,
  params: any = {},
  config: any = {},
  successCb: ((data: any) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getDashboardPanelData",
      selectedProject.id,
      user.selectedOrg.id,
      dashboardId,
      panelId,
      config
    ],
    async () => {
      const dashboardPanelData = await getDashboardPanelData(
        dashboardId,
        panelId,
        selectedProject.id,
        user.selectedOrg.id,
        params,
        config
      );
      successCb && successCb(dashboardPanelData);

      return dashboardPanelData;
    },
    {
      enabled:
        !!selectedProject.id &&
        !!user.selectedOrg.id &&
        !!dashboardId &&
        !!panelId &&
        refetch,
      refetchInterval: 5000,
      keepPreviousData: true,
      retry: 5 // back-off delay is applied as default by react-query
    }
  );

  return res;
};
