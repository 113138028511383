import { memo } from "react";
import { Handle, Position } from "reactflow";
import NodeTemplate from "../node-template.component";
import RuleTriggerTypeSelector from "@app/rule-engine/rule-trigger-type-selector.component";
import useRuleEngineStore from "@store/rule-engine/rule-engine.store";
import MQTTTrigger from "./mqtt-trigger.component";
import HTTPTrigger from "./http-trigger/http-trigger.component";
import { Tooltip } from "react-tooltip";

const RuleTriggerTypeNode = ({ data, id, isConnectable }) => {
  const [rules, setRules] = useRuleEngineStore((state) => [
    state.rules,
    state.setRules
  ]);

  const rule = rules[data.name];

  return (
    <NodeTemplate hideDelete>
      <div className="flex justify-end">
        <div
          data-tooltip-id="trigger-node"
          data-tooltip-content="Trigger Type"
        >
          <RuleTriggerTypeSelector
            ruleTriggerType={rule.triggerType}
            setRuleTriggerType={(newVal) => {
              const newRules = { ...rules };
              newRules[data.name] = {
                ...newRules[data.name],
                triggerType: newVal
              };

              setRules(newRules);
            }}
          />
        </div>
      </div>
      {rule.triggerType === "MQTT" ? (
        <MQTTTrigger ruleName={rule.name} />
      ) : rule.triggerType === "HTTP" ? (
        <HTTPTrigger rule={rule} />
      ) : null}
      <Handle
        type="source"
        position={Position.Right}
        id="trigger-type-node-handle"
        isConnectable={true}
      />
      <Tooltip id="trigger-node" />
    </NodeTemplate>
  );
};

export default memo(RuleTriggerTypeNode);
