import { useQuery } from "react-query";

import {
  IDeployment,
  IDeploymentResponse
} from "@interfaces/shared.interface";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const getDeployments = async (projectId, orgId, params) => {
  const { data, ok } = await networkService.get<IDeploymentResponse>(
    `projects/${projectId}/ota_release_deployments`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useGetDeployments = (
  params: any = {},
  successCb: ((data: IDeployment[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getDeployments", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const deployments = await getDeployments(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );
      successCb && successCb(deployments);

      return deployments;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
