import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { useFleetAndDevicesStore } from "../../../store";
import { useMemo } from "react";
import { ArrowPathIcon, TrashIcon } from "@heroicons/react/24/outline";

interface IOTADeploymentHeaderProps {
  options?: boolean;
}

function OTADeploymentHeader(props: IOTADeploymentHeaderProps) {
  const { options } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const deploymentName = searchParams.get("name");

  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const path = useMemo(() => {
    if (
      location.pathname.includes(
        "/ota-updates/projects/deployment/create-deployment"
      )
    ) {
      return "Create new";
    }

    if (
      location.pathname.includes(
        "/ota-updates/projects/deployment/deployment-details"
      )
    ) {
      return deploymentName;
    }

    return "";
  }, [deploymentName, location.pathname]);

  return (
    <>
      <div className="bg-background border-b border-background-layer3 lg:px-8 sm:px-6 ">
        <div className="flex justify-between items-center py-5">
          <div className="flex flex-col">
            <div className="flex items-center mb-3">
              <h1 className="text-2xl font-medium text-contentColor mr-1.5">
                {selectedProject.project_name}
              </h1>
            </div>
            <div>
              {/* <p className="text-sm text-[#565759]">{`Deployments >  ${path ? path : ""}`}</p> */}

              <p className="text-sm text-contentColorLight">
                <Link to="/ota-updates/projects/deployment">{`Deployments >`}</Link>
                &nbsp;{`${path ? path : ""}`}
              </p>
            </div>
          </div>

          {(options || options === undefined) && (
            <ul className="flex justify-center items-center">
              <li className="ml-10 items-center cursor-pointer">
                <button className="ml-4 mx-1 text-sm flex text-contentColor justify-center items-center">
                  <ArrowPathIcon width={16} className="transform rotate-180" />

                  <span className="pl-2 text-sm">Refresh</span>
                </button>
              </li>
              {/* <li
                            className="ml-10 flex items-center cursor-pointer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.2"
                                stroke="currentColor"
                                className="w-5 h-5">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                            </svg>

                            <span className="pl-2 text-sm">Export</span>
                        </li> */}
              <li className="ml-10 flex items-center cursor-pointer">
                <TrashIcon width={16} />

                <span className="pl-2 text-sm">Delete</span>
              </li>
              <li className="ml-10 flex items-center cursor-pointer">
                <button
                  onClick={() =>
                    navigate(
                      "/ota-updates/projects/deployment/create-deployment"
                    )
                  }
                  className="max-w-xs py-3 px-3.5 pr-8 border border-solid border-primary rounded text-white bg-primary focus:outline-0 outline-0"
                >
                  + Create A Deployment
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default OTADeploymentHeader;
