import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuthStore, useFleetAndDevicesStore } from "../../../store";
import { FieldError } from "../../shared/components";
import * as yup from "yup";
import CreateBlueprintInfo from "./create-device-blueprint-info.component";
import { ShadowPolicy } from "@/app/fleet-and-devices";

// import DeviceCreationSteps from "../components/device-creation-steps.component";
// import SuggestionPanel from "../components/fad-suggestion-panel.component";

const validationSchema = yup.object({
  deviceBlueprintName: yup.string().required("Blueprint Name is required.")
});

function CreateDeviceBlueprint() {
  const navigate = useNavigate();

  const [createBlueprintStep, setCreateBlueprintStep] = useState(0);

  const handleSubmit = (inputFieldValues) => {
    // if (inputFieldValues.deviceName) {
    //   navigate("/fleet-and-devices/projects/apply-shadow/");
    // }
    setCreateBlueprintStep(1);
  };

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-7/12 pb-8">
          <div className="mt-7 mb-5 w-10/12">
            {/* <DeviceCreationSteps /> */}
          </div>

          {createBlueprintStep === 0 ? (
            <CreateBlueprintInfo onNext={handleSubmit} />
          ) : createBlueprintStep === 1 ? (
            <ShadowPolicy backPath="" />
          ) : null}
        </div>

        <div className="w-7/12">{/* <SuggestionPanel type="device" /> */}</div>
      </div>
    </>
  );
}

export default CreateDeviceBlueprint;
