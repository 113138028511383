import usePyScrtiptStore from "@/store/pyscript.store";
import { useEffect, useRef } from "react";

interface INanoPbFilesGenerator {}

const NanoPbFilesGenerator: React.FC<INanoPbFilesGenerator> = () => {
  const [setLoadingState] = usePyScrtiptStore((state) => [
    state.setLoadingState
  ]);

  const pyScriptWrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!pyScriptWrapperRef.current) return;

    function onPyScriptLoadError() {
      setLoadingState("error");
    }

    function onPyScriptLoadSuccess() {
      setLoadingState("success");
    }

    globalThis["onPyScriptLoadError"] = onPyScriptLoadError;
    globalThis["onPyScriptLoadSuccess"] = onPyScriptLoadSuccess;

    const pyScript = Object.assign(document.createElement("script"), {
      type: "py",
      src: `${process.env.PUBLIC_URL}/nanopb_generator/nanopb_generator_script.py`
    });

    pyScriptWrapperRef.current.append(pyScript);

    return () => {};
  }, []);

  return (
    <div
      ref={pyScriptWrapperRef}
      className="flex-col flex gap-4 items-center"
    ></div>
  );
};

export default NanoPbFilesGenerator;
