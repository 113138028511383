import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface ICertificate {
  id: string;
  cert: string;
  type: string;
  project_id: string;
  fleet_id: string;
  org_id: string;
  created_at: string;
}

interface ICertificateDetailsResponse {
  ok: number;
  data: { certificate: ICertificate };
}

export const getCertificateDetails = async (
  id: string,
  projectId: string,
  orgId: string,
  params
) => {
  const { data, ok } = await networkService.get<ICertificateDetailsResponse>(
    `projects/${projectId}/certificates/${id}`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.certificate;
  } else return null;
};

export const useGetCertificateDetails = (
  id: string,
  params: any = {},
  successCb: ((data: ICertificate) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);

  const res = useQuery(
    [
      "getCertificateDetails",
      selectedProject.id,
      user.selectedOrg.id,
      ...Object.keys(params).map((p) => `params-${p}-${params[p]}`)
    ],
    async () => {
      const certs = await getCertificateDetails(
        id,
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(certs);

      return certs;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id && !!id
    }
  );

  return res;
};
