import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { ICreateAPIKeyResponse } from "@interfaces/apps.interface";

interface IAppPermission {
  resource_type: string;
  actions: string[];
  resources: string[];
}

interface ICreateAppPayload {
  app_name: string;
  permissions: IAppPermission[];
  project_access: string;
}

export const createApp = async (
  projectId: string,
  orgId: string,
  payload: ICreateAppPayload
) => {
  const { data, ok } = await networkService.post<ICreateAPIKeyResponse>(
    `projects/${projectId}/apps/`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.projectApp;
  } else return null;
};

export const useCreateApp = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateAppPayload) =>
      await createApp(selectedProject.id, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getApps");
      }
    }
  );

  return res;
};
