function generateCertsH(
  device_cert: string,
  device_private_key: string,
  root_ca_cert: string,
  connection_settings: any
) {
  const CERTS_H_TEMPLATE = `#ifndef CERTS_H_
#define CERTS_H_

#include<Arduino.h>
#include <stdio.h>

#define DEVICE_NAME "${connection_settings.device_name}" 
#define ROOT_TOPIC "${connection_settings.root_topic}" 

static  char GOLAIN_CERT_CA[] PROGMEM = R"EOF(
${root_ca_cert.trim()}
)EOF";

// Device Certificate                                               //change this
static  char GOLAIN_DEVICE_CERT[] PROGMEM = R"KEY(
${device_cert.trim()}
)KEY";

// Device Private Key                                               //change this
static  char GOLAIN_PRIVATE_KEY[] PROGMEM = R"KEY(
${device_private_key.trim()}
)KEY";

#endif`;

  return CERTS_H_TEMPLATE;
}

export { generateCertsH };
