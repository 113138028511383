import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface IDeleteBluprintResponse {
  ok: number;
}

export const deleteBlueprint = async (
  projectId: string,
  orgId: string,
  blueprintId: string
) => {
  const { ok } = await networkService.delete<IDeleteBluprintResponse>(
    `projects/${projectId}/blueprints/${blueprintId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteBlueprint = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (blueprintId: string) =>
      await deleteBlueprint(
        selectedProject.id,
        user.selectedOrg.id,
        blueprintId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getBlueprints");
      }
    }
  );

  return res;
};
