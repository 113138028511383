import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";

interface IReleaseProps {
  device_types: string;
  release_name: string;
  target_fleets: string;
}

interface IRelease {
  id: string;
  project_id: string;
  org_id: string;
  created_at: string;
  props: IReleaseProps;
}

interface IOption {
  label: string;
  value: string;
}

interface ICreateDeploymentStepsData {
  deploymentName?: string;
  release?: IOption;
  targetFleets?: IOption[];
  scheduledTime?: Date;
  maxRetries?: string;
  retryInterval?: string;
}

interface IDeploymentProps {
  deployment_name: string;
  max_retries: string;
  retry_interval: string;
  target_fleets: string;
}
interface IDeployment {
  id: string;
  ota_release_id: string;
  initiated: boolean;
  scheduled_at: string;
  project_id: string;
  org_id: string;
  created_at: string;
  props: IDeploymentProps;
}

interface IOTAReleaseState {
  releaseList: IRelease[];
  activeRelease: IRelease | undefined;
  showCreateForm: boolean;
  showReleaseArtifactForm: boolean;
  showReleaseDetails: boolean;
  deploymentList: IDeployment[];
  createDeploymentStepsData: ICreateDeploymentStepsData;
  setReleaseList: (x: IRelease[]) => void;
  setActiveRelease: (x: IRelease) => void;
  setShowCreateForm: (x: boolean) => void;
  setShowReleaseArtifactForm: (x: boolean) => void;
  setShowReleaseDetails: (x: boolean) => void;
  setDeploymentList: (x: IDeployment[]) => void;
  setCreateDeploymentStepsData: (x: ICreateDeploymentStepsData) => void;
}

const useOTAReleaseStore = create<IOTAReleaseState>()(
  persist(
    devtools(
      (set) => ({
        releaseList: [],
        activeRelease: undefined,
        showCreateForm: false,
        showReleaseArtifactForm: false,
        showReleaseDetails: false,
        deploymentList: [],
        createDeploymentStepsData: {} as ICreateDeploymentStepsData,
        setReleaseList: (res) =>
          set(
            produce((state) => {
              state.releaseList = res;
            })
          ),
        setActiveRelease: (res) =>
          set(
            produce((state) => {
              state.activeRelease = res;
            })
          ),
        setShowCreateForm: (boolVal) =>
          set(
            produce((state) => {
              state.showCreateForm = boolVal;
            })
          ),
        setShowReleaseArtifactForm: (boolVal) =>
          set(
            produce((state) => {
              state.showReleaseArtifactForm = boolVal;
            })
          ),
        setShowReleaseDetails: (boolVal) =>
          set(
            produce((state) => {
              state.showReleaseDetails = boolVal;
            })
          ),
        setDeploymentList: (res) =>
          set(
            produce((state) => {
              state.deploymentList = res;
            })
          ),
        setCreateDeploymentStepsData: (data) =>
          set(
            produce((state) => {
              state.createDeploymentStepsData = { ...data };
            })
          )
      }),
      { name: "ota-release", serialize: { options: true } }
    ),
    {
      name: "ota-release", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useOTAReleaseStore;
