import React from "react";

interface ISwitch {
  color?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
}

const Switch: React.FC<ISwitch> = ({ className, checked, onChange }) => {
  return (
    <input
      className={`mr-2 mt-[0.3rem] h-5 w-9 appearance-none rounded-[1rem] bg-neutral-300 after:absolute after:z-[2] after:mt-[0.08rem] after:h-[1rem] after:w-[1rem] after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:mt-[0.08rem] checked:after:ml-[1.0625rem] checked:after:h-[1rem] checked:after:w-[1rem] checked:after:rounded-full checked:after:border-none  checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0  focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[1rem] focus:after:w-[1rem] focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary  dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary ${className}`}
      type="checkbox"
      role="switch"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      id="flexSwitchCheckDefault"
    />
  );
};

export default Switch;
