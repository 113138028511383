import { useNavigate } from "react-router-dom";
import { data } from "../../shared/utils/data-file.util";

const CreateNewDeploymentPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <main className="flex-1 flex flex-col items-center mx-8 mt-7 space-y-4  pt-9 lg:px-8 sm:px-6 text-contentColor bg-background rounded-md">
        <div className="flex flex-col flex-grow h-full justify-center items-center">
          <div className="mb-[38px] flex flex-col justify-center items-center">
            <h1 className="text-2xl text-center font-medium mb-2.5">
              {data.createDeploymentPage.heading}
            </h1>
            <p className="text-sm text-contentColorLight text-center w-[468px]">
              {data.createDeploymentPage.subHeading}
            </p>
          </div>
          <div className="text-center">
            <button
              onClick={() =>
                navigate("/ota-updates/projects/deployment/create-deployment")
              }
              className="inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
            >
              Create A Deployment
            </button>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateNewDeploymentPage;
