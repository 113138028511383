import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IUserContextResponse } from "@interfaces/user-contexts.interface";

export const getContextDefinitions = async (projectId, orgId, params) => {
  const { data, ok } = await networkService.get<IUserContextResponse>(
    `projects/${projectId}/user_context_definitions`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.userContexts;
  }
};

export const useGetUserContextDefinitions = (
  params: any = {},
  successCb: ((data: any[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);

  const res = useQuery(
    ["getContextDefinitions", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const contexts = await getContextDefinitions(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(contexts);

      return contexts;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
