import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore, useFleetAndDevicesStore } from "../../../store";
import SuggestionPanel from "../components/fad-suggestion-panel.component";
import FleetCreationSteps from "../components/fleet-creation-steps.component";

function ProvisioningPolicy() {
  const navigate = useNavigate();
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);

  const [activePanel, setActivePanel] = useState(0);

  const [fleetCreation] = useFleetAndDevicesStore((state) => [
    state.fleetCreation
  ]);

  useEffect(() => {
    if (!fleetCreation?.fleet_name?.trim()) {
      navigate("/fleet-and-devices/projects/about-fleet/", { replace: true });
      return;
    }
    updateAuthUser({ fleetCreationStatus: "1" });
  }, [fleetCreation?.fleet_name, navigate, updateAuthUser]);

  const handleClick = () => {
    navigate("/fleet-and-devices/projects/shadow-Policy/");
  };

  const PANELS = [
    {
      name: "Auto generate a new certificate",
      description: "We will provide you with new certificates",
      disabled: false
    },
    {
      name: "Use my certificate",
      description: "Coming soon - Contact us if you need this feature",
      disabled: true
    }
  ];

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12 pb-8">
          <div className="mt-7 mb-5 w-10/12">
            <FleetCreationSteps />
          </div>
          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Provisioning Policy
            </h1>

            <div className="w-10/12">
              {PANELS.map((panel, index) => (
                <div
                  key={index}
                  className={`
                                    w-full h-auto border border-background-layer3 rounded-lg px-5 py-3.5 my-2 cursor-pointer
                                    ${
                                      panel.disabled
                                        ? "opacity-70 pointer-events-none"
                                        : ""
                                    }
                                    ${
                                      index === activePanel
                                        ? "border-primary"
                                        : ""
                                    }
                                `}
                  onClick={() => setActivePanel(index)}
                >
                  <h1
                    className={`text-base text-left font-medium ${
                      index === activePanel ? "text-primary" : ""
                    }`}
                  >
                    {panel.name}
                  </h1>
                  <span className="text-sm text-left text-contentColorLight">
                    {panel.description}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="w-10/12  flex items-center mt-12 space-x-4">
            <Link
              to="/fleet-and-devices/projects/about-fleet/"
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
            >
              Back
            </Link>

            <button
              onClick={handleClick}
              className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
            >
              Next
            </button>
          </div>
        </div>

        <div className="w-7/12">
          <SuggestionPanel type="fleet" />
        </div>
      </div>
    </>
  );
}

export default ProvisioningPolicy;
