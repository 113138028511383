import {
  ChartBarIcon,
  Square2StackIcon,
  Squares2X2Icon
} from "@heroicons/react/24/outline";

interface IDeviceDetailsTabsProps {
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

function DeviceDetailsTabs(props: IDeviceDetailsTabsProps) {
  const { activeTab, setActiveTab } = props;

  return (
    <div className="bg-background border-background-layer3 mb-4 border-b">
      <div className=" md:grid md:gap-4 md:grid-cols-5 md:place-items-start">
        <button
          onClick={() => setActiveTab(0)}
          className={`${
            activeTab === 0
              ? "text-primaryLight border-b-2 border-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div
              className={`flex flex-row justify-between items-center ${
                activeTab === 0 ? "stroke-primaryLight" : "stroke-contentColor"
              }`}
            >
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 14.625C3.5 14.1277 3.69754 13.6508 4.04917 13.2992C4.40081 12.9475 4.87772 12.75 5.375 12.75H15.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.375 1.5H15.5V16.5H5.375C4.87772 16.5 4.40081 16.3025 4.04917 15.9508C3.69754 15.5992 3.5 15.1223 3.5 14.625V3.375C3.5 2.87772 3.69754 2.40081 4.04917 2.04917C4.40081 1.69754 4.87772 1.5 5.375 1.5V1.5Z"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span
                className={`${
                  activeTab === 0 && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Device Details
              </span>
            </div>
          }
        </button>

        <button
          onClick={() => setActiveTab(1)}
          className={`${
            activeTab === 1
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <Square2StackIcon width={18} />

              <span
                className={`${
                  activeTab === 1 && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Device Shadow
              </span>
            </div>
          }
        </button>

        <button
          onClick={() => setActiveTab(2)}
          className={`${
            activeTab === 2
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <ChartBarIcon width={18} />

              <span
                className={`${
                  activeTab === 2 && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Data-points
              </span>
            </div>
          }
        </button>

        <button
          onClick={() => setActiveTab(3)}
          className={`${
            activeTab === 3
              ? "border-b-2 border-primaryLight text-primaryLight"
              : ""
          } flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`}
        >
          {
            <div className="flex flex-row justify-between items-center">
              <Squares2X2Icon width={18} />

              <span
                className={`${
                  activeTab === 3 && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Dashboard
              </span>
            </div>
          }
        </button>

        {/* <button
                    onClick={() => setActiveTab(3)}

                    className={
                        `${activeTab === 3 ? "border-b-2 border-primaryLight text-primaryLight" : ""} flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`
                    }
                >
                    {
                        <div className="flex flex-row justify-between items-center">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 1.5C15.414 1.5 15.75 1.836 15.75 2.25V5.06775L14.25 6.56775V3H3.75V15H14.25V12.9315L15.75 11.4315V15.75C15.75 16.164 15.414 16.5 15 16.5H3C2.586 16.5 2.25 16.164 2.25 15.75V2.25C2.25 1.836 2.586 1.5 3 1.5H15ZM16.3335 6.606L17.394 7.6665L11.5605 13.5L10.4985 13.4985L10.5 12.4395L16.3335 6.606ZM9.75 9V10.5H6V9H9.75ZM12 6V7.5H6V6H12Z" fill={activeTab === 3 ? "#546CCC" : "#565759"} />
                            </svg>


                            <span className={`${activeTab === 3 && "text-primaryLight font-medium"} text-sm ml-2`}>Jobs</span>
                        </div>
                    }
                </button>

                <button
                    onClick={() => setActiveTab(4)}

                    className={
                        `${activeTab === 4 ? "border-b-2 border-primaryLight text-primaryLight" : ""} flex items-center px-2 py-5 -mb-px text-center text-contentColor lg:px-4 whitespace-nowrap`
                    }
                >
                    {
                        <div className="flex flex-row justify-between items-center">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 16.5C1.5 14.9087 2.13214 13.3826 3.25736 12.2574C4.38258 11.1321 5.9087 10.5 7.5 10.5C9.0913 10.5 10.6174 11.1321 11.7426 12.2574C12.8679 13.3826 13.5 14.9087 13.5 16.5H12C12 15.3065 11.5259 14.1619 10.682 13.318C9.83807 12.4741 8.69347 12 7.5 12C6.30653 12 5.16193 12.4741 4.31802 13.318C3.47411 14.1619 3 15.3065 3 16.5H1.5ZM7.5 9.75C5.01375 9.75 3 7.73625 3 5.25C3 2.76375 5.01375 0.75 7.5 0.75C9.98625 0.75 12 2.76375 12 5.25C12 7.73625 9.98625 9.75 7.5 9.75ZM7.5 8.25C9.1575 8.25 10.5 6.9075 10.5 5.25C10.5 3.5925 9.1575 2.25 7.5 2.25C5.8425 2.25 4.5 3.5925 4.5 5.25C4.5 6.9075 5.8425 8.25 7.5 8.25ZM13.713 11.0273C14.767 11.5019 15.6615 12.2709 16.2889 13.2418C16.9164 14.2126 17.2501 15.344 17.25 16.5H15.75C15.7502 15.633 15.4999 14.7844 15.0293 14.0562C14.5587 13.328 13.8878 12.7512 13.0972 12.3953L13.7123 11.0273H13.713ZM13.197 2.55975C13.9526 2.87122 14.5987 3.40015 15.0533 4.07942C15.5078 4.75869 15.7503 5.55768 15.75 6.375C15.7503 7.40425 15.3658 8.39642 14.6719 9.15662C13.978 9.91682 13.025 10.3901 12 10.4835V8.97375C12.5557 8.89416 13.0713 8.63851 13.471 8.24434C13.8707 7.85017 14.1335 7.33824 14.2209 6.7837C14.3082 6.22916 14.2155 5.66122 13.9563 5.16327C13.6971 4.66531 13.2851 4.26356 12.7808 4.017L13.197 2.55975Z" fill={activeTab === 4 ? "#546CCC" : "#565759"} />
                            </svg>


                            <span className={`${activeTab === 4 && "text-primaryLight font-medium"} text-sm ml-2`}>Collaborators</span>
                        </div>
                    }
                </button> */}
      </div>
    </div>
  );
}

export default DeviceDetailsTabs;
