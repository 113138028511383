type Props = { progress: number };

const ProgressBar: React.FC<Props> = ({ progress }) => {
  return (
    <div className="relative w-72 h-4">
      <div className="absolute top-0 left-0 w-full h-full bg-gray-200 rounded-full"></div>
      <div
        className="absolute top-0 left-0 w-full h-full bg-primary rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
      <div
        className={`absolute top-0 left-0 w-full h-full flex items-center justify-center ${
          progress < 50 ? "text-primary" : "text-gray-300"
        }`}
      >
        <span className="text-sm font-semibold">{progress}%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
