import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { ICreateDashbaordResponse } from "@interfaces/shared.interface";

export const createDashboard = async (
  projectId: string,
  orgId: string,
  payload
) => {
  const { data, ok } = await networkService.post<ICreateDashbaordResponse>(
    `projects/${projectId}/dashboards/`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.dashboardId;
  } else return null;
};

export const useCreateDashboard = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: { dashboard_name: string; dashboard_description: string }) =>
      await createDashboard(selectedProject.id, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDashboards");
      }
    }
  );

  return res;
};
