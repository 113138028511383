import React from "react";

interface FleetAndDevicesAccordionProps {
  accordionData: {
    label: string;
    value: string;
  }[];
}

const FleetAndDevicesAccordion: React.FC<FleetAndDevicesAccordionProps> = ({
  accordionData
}) => {
  const [openedAccordion, setOpenedAccordion] = React.useState(0);

  const handleAccordionClick = (id: number) => {
    setOpenedAccordion(id);
  };

  return (
    <ul className="accordion p-0 text-left list-none">
      {accordionData.map((obj, index) => (
        <li
          key={index}
          className="py-3 border-b border-solid border-background-layer3"
        >
          <h1
            className="w-full flex justify-between m-0 cursor-pointer font-medium text-sm ml-0.5 mb-1"
            onClick={() => handleAccordionClick(index)}
          >
            <span>{obj.label}</span>
            <span
              className={`transition-all duration-300 ease ${
                openedAccordion === index ? "hidden -rotate-180" : "rotate-0"
              }`}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.625 5.625L9 11.25L3.375 5.625"
                  stroke="#546CCC"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </h1>
          <div
            className={`inner overflow-hidden ${
              openedAccordion === index
                ? "max-h-40 transition-all duration-700 ease-in"
                : "max-h-0 transition-all duration-700 ease-out"
            } list-none`}
          >
            <p className="text-sm text-contentColorLight text-left ml-0.5 w-full">
              {obj.value}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FleetAndDevicesAccordion;
