import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

const HTTPQueryParamsEditor = ({ params, setParams }) => {
  return (
    <div className="flex flex-col gap-2">
      {params.map((param, ind) => {
        return (
          <div className="flex gap-2 items-center" key={ind}>
            <input
              placeholder="Key"
              className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
              value={param.key}
              onChange={(e) => {
                const _params = [...params];
                const _param = {
                  ..._params[ind],
                  key: e.target.value
                };
                _params[ind] = _param;
                setParams(_params);
              }}
            />
            <input
              placeholder="Value"
              className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
              value={param.value}
              onChange={(e) => {
                const _params = [...params];
                const _param = {
                  ..._params[ind],
                  value: e.target.value
                };
                _params[ind] = _param;
                setParams(_params);
              }}
            />
            <button
              className="bg-red-500 text-white p-1 rounded-sm"
              onClick={() => {
                const _params = [...params];
                _params.splice(ind, 1);
                setParams(_params);
              }}
            >
              <XMarkIcon width={16} />
            </button>
          </div>
        );
      })}
      <button
        disabled={params.some((param) => !param.key?.trim())}
        className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm disabled:opacity-50"
        onClick={() => {
          const _params = [...params];
          _params.push({ key: "", value: "" });
          setParams(_params);
        }}
      >
        Add Parameter
      </button>
    </div>
  );
};

export default HTTPQueryParamsEditor;
