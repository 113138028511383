import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const deleteFleet = async (
  projectId: string,
  orgId: string,
  fleetId: string
) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `projects/${projectId}/fleets/${fleetId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteFleet = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (fleetId: string) =>
      await deleteFleet(selectedProject.id, user.selectedOrg.id, fleetId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFleets");
      }
    }
  );

  return res;
};
