import { useQuery } from "react-query";

import { IGetMeta } from "@interfaces/shared.interface";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface IUserContextDefinitionData {
  //   device_id: string;
  //   fleet_id: string;
  //   timestamp: string;

  // other dynamic properties:
  [key: string]: any;
}

interface IDataPointDefinitionDataResponse {
  data: IUserContextDefinitionData[];
  meta: IGetMeta;
  ok: number;
}

export const getUserContextDefinitionData = async (
  contextId: string,
  projectId: string,
  orgId: string,
  params: any
) => {
  const { data, ok, meta } =
    await networkService.get<IDataPointDefinitionDataResponse>(
      `projects/${projectId}/user_context_definitions/${contextId}/data`,
      params,
      {
        headers: {
          "ORG-ID": orgId
        }
      }
    );

  if (ok) {
    return { data: data || [], meta };
  }
};

export const useGetUserContextDefinitionData = (
  contextId: string,
  params: any = {},
  successCb: ((data: IUserContextDefinitionData[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);

  const res = useQuery(
    [
      "getUserContextDefinitionData",
      contextId,
      selectedProject.id,
      user.selectedOrg.id,
      params
    ],
    async () => {
      const dpdData = await getUserContextDefinitionData(
        contextId,
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(dpdData.data);

      return dpdData;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
