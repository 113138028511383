import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import { IOption } from "../../interfaces";

interface ILandingPageLibrary {
  icon: string;
  title: string;
  description: string;
  link: string;
}

interface ILandingPageProducts {
  icon: string;
  title: string;
  description: string;
}
interface IContentStoreState {
  projectDetailsAccordions: IOption[];
  fleetDetailsAccordions: IOption[];
  deviceDetailsAccordions: IOption[];
  shadowAccordions: IOption[];
  deviceDatapointAccordions: IOption[];
  panelsAccordions: IOption[];
  landingPageLibrary: ILandingPageLibrary[];
  landingPageProducts: ILandingPageProducts[];
  setProjectDetailsAccordions: (x: IOption[]) => void;
  setFleetDetailsAccordions: (x: IOption[]) => void;
  setDeviceDetailsAccordions: (x: IOption[]) => void;
  setShadowAccordions: (x: IOption[]) => void;
  setDeviceDatapointAccordions: (x: IOption[]) => void;
  setPanelsAccordions: (x: IOption[]) => void;
  setLandingPageLibrary: (x: ILandingPageLibrary[]) => void;
  setLandingPageProducts: (x: ILandingPageProducts[]) => void;
}

const useContentStore = create<IContentStoreState>()(
  persist(
    devtools(
      (set) => ({
        projectDetailsAccordions: [],
        fleetDetailsAccordions: [],
        deviceDetailsAccordions: [],
        shadowAccordions: [],
        deviceDatapointAccordions: [],
        panelsAccordions: [],
        landingPageLibrary: [],
        landingPageProducts: [],
        setProjectDetailsAccordions: (options) =>
          set(
            produce((state) => {
              state.projectDetailsAccordions = options;
            })
          ),
        setFleetDetailsAccordions: (options) =>
          set(
            produce((state) => {
              state.fleetDetailsAccordions = options;
            })
          ),
        setDeviceDetailsAccordions: (options) =>
          set(
            produce((state) => {
              state.deviceDetailsAccordions = options;
            })
          ),
        setShadowAccordions: (options) =>
          set(
            produce((state) => {
              state.shadowAccordions = options;
            })
          ),
        setDeviceDatapointAccordions: (options) =>
          set(
            produce((state) => {
              state.deviceDatapointAccordions = options;
            })
          ),
        setPanelsAccordions: (options) =>
          set(
            produce((state) => {
              state.panelsAccordions = options;
            })
          ),
        setLandingPageLibrary: (data) =>
          set(
            produce((state) => {
              state.landingPageLibrary = data;
            })
          ),
        setLandingPageProducts: (data) =>
          set(
            produce((state) => {
              state.landingPageProducts = data;
            })
          )
      }),
      { name: "content-zero", serialize: { options: true } }
    ),
    {
      name: "content-zero", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useContentStore;
