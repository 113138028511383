import React from "react"
import { Outlet, useLocation } from "react-router-dom"
import { LogsAndActivitesProjects } from "."

const LogsAndActivitiesProvider: React.FC = () => {
  const location = useLocation();
  const flag =
    location.pathname === "/logs-and-activities/projects" ||
    location.pathname === "/logs-and-activities/projects/"

  return (
    <>
      {flag ? (
        <>
          <LogsAndActivitesProjects />
        </>
      ) : (
        <main className="flex-1 overflow-y-auto">
          <Outlet />
        </main>
      )}
    </>
  )
}

export default LogsAndActivitiesProvider
