import { useCallback, useEffect, useMemo, useState } from "react";
import SettingsGenericTable from "../components/settings-generic-table.component";
import dateService from "@services/date.service";
import { Button } from "@tremor/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  IOrgUser,
  useGetUsersInOrg
} from "@app/shared/hooks/get/users-in-org";
import { useQueryClient } from "react-query";
import { getUserPerms } from "@app/shared/hooks/get/user-perms";
import useAuthStore from "@store/auth.store";
import EditPerms, {
  IInputEntry,
  orgAccessOptions,
  orgPermissionOptions,
  permissionOptions,
  projAccessOptions
} from "../components/settings-org-edit-perms.component";
import { useFleetAndDevicesStore } from "@store/index";

function Members() {
  const [users, setUsers] = useState<IOrgUser[]>();
  const user = useAuthStore((state) => state.user);
  const selectedProject = useFleetAndDevicesStore(
    (state) => state.selectedProject
  );

  const [showEditPermForm, setShowEditPermForm] = useState(false);
  const [editUserPerms, setEditUserPerms] = useState<IInputEntry>();

  const queryClient = useQueryClient();

  useGetUsersInOrg({}, (users) => {
    if (users) {
      setUsers(users);
    } else {
      setUsers([]);
    }
  });

  useEffect(() => {
    if (!showEditPermForm) {
      setEditUserPerms(undefined);
    }
  }, [showEditPermForm]);

  const handleUserEditClick = useCallback(
    async (user_id, email) => {
      setShowEditPermForm(true);
      const d = await queryClient.fetchQuery({
        queryKey: ["getUserPerms"],
        queryFn: () =>
          getUserPerms(user_id, user.selectedOrg.id, {
            project_id: selectedProject.id
          })
      });

      let initialValues: IInputEntry = {
        user_id,
        email,
        permissions: [],
        orgPermissions: []
      };

      const org_access = orgAccessOptions.find(
        (op) => op.value.toLocaleLowerCase() === d.org_access.toLowerCase()
      );

      initialValues.org_access = org_access;

      d.permissions?.forEach((perm) => {
        if (perm.resource_type === "organizations") {
          const newActions = perm.actions
            .filter((act) => !["owner", "member", "admin"].includes(act))
            .map((act) => orgPermissionOptions.find((op) => op.value === act));
          initialValues.orgPermissions = newActions;
        } else if (perm.resource_type === "projects") {
          if (perm.actions === null) {
            initialValues.proj_access = { label: "Member", value: "member" };
            return;
          }
          const newActions = perm.actions
            .filter((act) => !["member", "admin"].includes(act))
            .map((act) => permissionOptions.find((op) => op.value === act));

          initialValues.permissions = newActions;

          const proj_access = perm.actions.find((act) =>
            ["member", "admin"].includes(act)
          );

          initialValues.proj_access = projAccessOptions.find(
            (op) => op.value === proj_access
          );
        }
      });

      setEditUserPerms(initialValues);
    },
    [queryClient, selectedProject.id, user.selectedOrg.id]
  );

  const renderStatus = (status: string) => {
    const statusUI = {
      owner: (
        <span className="flex">
          <span className="bg-[#8ca92536] py-2 px-5 rounded text-[#a99525] font-medium text-xs mr-6">
            OWNER
          </span>
        </span>
      ),
      admin: (
        <span className="flex">
          <span className="bg-[#25a96a36] py-2 px-5 rounded text-[#25A96A] font-medium text-xs mr-6">
            ADMIN
          </span>
        </span>
      ),
      viewer: (
        <span className="flex">
          <span className="bg-[#3659997d] py-2 px-[17px] rounded text-[#4281ff] font-medium text-xs mr-6">
            VIEWER
          </span>
        </span>
      ),
      member: (
        <span className="flex">
          <span className="bg-[#166ff629] py-2 px-[17px] rounded text-[#1366E5] font-medium text-xs mr-6">
            MEMBER
          </span>
        </span>
      )
    };

    return statusUI[status.toLowerCase()];
  };

  const collaborators = useMemo(() => {
    if (users?.length <= 0) return [];
    return (
      users?.map((user) => {
        return {
          email: user?.email,
          org_access: renderStatus(user?.permission ?? ""),
          Joined: dateService.convertUTCToLocalDate(user?.joined),
          last_active: dateService.convertUTCToLocalDate(user?.last_active_at),
          edit_permissions: (
            <Button
              className="!text-white"
              icon={PencilSquareIcon}
              onClick={() => handleUserEditClick(user.user_id, user.email)}
            >
              Edit
            </Button>
          )
        };
      }) || []
    );
  }, [handleUserEditClick, users]);

  return (
    <>
      <EditPerms
        open={showEditPermForm}
        setOpen={setShowEditPermForm}
        initialValues={editUserPerms}
      />

      <div className="flex flex-col mt-4 w-full max-h-[70vh] bg-background text-contentColor pb-6">
        <div className="overflow-y-auto">
          <div className="">
            <div className="overflow-auto">
              {!!collaborators?.length && (
                <SettingsGenericTable
                  column={Object.keys(collaborators[0])}
                  row={collaborators}
                  searchField={
                    collaborators.length
                      ? Object.keys(collaborators[0])[0]
                      : ""
                  }
                  searchTerm=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Members;
