import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import {
  ICreatePaneldResponse,
  IPanelCreatePayload
} from "@interfaces/dashboard.interface";

export const createDashboardPanel = async (
  projectId: string,
  orgId: string,
  dashboardId: string,
  payload: IPanelCreatePayload
) => {
  const { data, ok } = await networkService.post<ICreatePaneldResponse>(
    `projects/${projectId}/dashboards/${dashboardId}/panels`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.dashboardPanelId;
  } else return null;
};

export const useCreateDashboardPanel = (dashboardId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IPanelCreatePayload) =>
      await createDashboardPanel(
        selectedProject.id,
        user.selectedOrg.id,
        dashboardId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDashboardPanels");
      }
    }
  );

  return res;
};
