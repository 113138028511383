import React, { useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import dateService from "@services/date.service";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon
} from "@heroicons/react/24/outline";
import Pagination from "@/app/shared/components/pagination.component";
import { useGetConsumersInOrg } from "@/app/shared/hooks/get/consumer-users";

type Props = {};

interface IUserListRow {
  consumer_id: string;
  last_seen: string;
  created_at: string;
  issuer: string;
}

const UaUsersList = (props: Props) => {
  const { data: consumers, isLoading } = useGetConsumersInOrg();

  const columns = useMemo<ColumnDef<IUserListRow, any>[]>(
    () => [
      {
        id: "consumer_id",
        accessorKey: "consumer_id",
        header: "Consumer ID",
        cell: ({ getValue, row }) => {
          return getValue();
        },
        size: 30
      },
      {
        id: "issuer",
        accessorKey: "issuer",
        header: "Issuer",
        cell: ({ getValue }) => {
          return (
            <a
              href={getValue()}
              className="text-contentColorLight underline decoration-dashed	"
            >
              {getValue()}
            </a>
          );
        },
        size: 30
      },
      {
        id: "last_seen",
        accessorKey: "last_seen",
        header: "Last seen",
        sortingFn: (a, b, colId) =>
          dateService
            .convertDateStrToMoment(a.getValue(colId))
            .diff(dateService.convertDateStrToMoment(b.getValue(colId))),
        cell: ({ getValue }) => {
          return (
            <div className="text-contentColorLight">
              {dateService.convertUTCToLocalDate(getValue())}
            </div>
          );
        },
        size: 40
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        sortingFn: (a, b, colId) =>
          dateService
            .convertDateStrToMoment(a.getValue(colId))
            .diff(dateService.convertDateStrToMoment(b.getValue(colId))),
        cell: ({ getValue }) => {
          return (
            <div className="text-contentColorLight">
              {dateService.convertUTCToLocalDate(getValue())}
            </div>
          );
        },
        size: 40
      }
    ],
    []
  );

  const data = useMemo(
    () => (Array.isArray(consumers) ? consumers : []),
    [consumers]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  if (!isLoading && consumers?.length === 0) {
    return (
      <div className="w-full min-h-[60vh] text-contentColorLight flex justify-center items-center">
        No consumers yet.
      </div>
    );
  }

  return (
    <>
      <div className="mx-12 overflow-x-auto overflow-hidden">
        <table className="w-full h-full my-6 ">
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width: header.column.getSize()
                      }}
                      className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                : "",
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <ArrowUpIcon width={10} />,
                              desc: <ArrowDownIcon width={10} />
                            }[header.column.getIsSorted() as string] ??
                              (header.column.getCanSort() ? (
                                <ArrowsUpDownIcon width={10} />
                              ) : null)}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>{""}</div>
                          ) : null}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="rounded-md">
            {table.getRowModel().rows.map((row, ind) => {
              return (
                <tr
                  key={row.id}
                  className={`bg-background ${
                    ind === 0
                      ? "!rounded-t-md"
                      : ind === table.getTotalSize() - 1
                      ? "!rounded-b-md"
                      : ""
                  }`}
                >
                  {row.getVisibleCells().map((cell, cellInd) => {
                    return (
                      <td
                        key={cell.id}
                        className={`mx-2 whitespace-nowrap !bg-transparent text-sm text-center p-2 py-3 ${
                          ind === 0 && cellInd === 0
                            ? "rounded-tl-md"
                            : ind === table.getTotalSize() - 1 && cellInd === 0
                            ? "rounded-bl-md"
                            : ind === 0 && cellInd === columns.length - 1
                            ? "rounded-tr-md"
                            : ind === table.getTotalSize() - 1 &&
                              cellInd === columns.length - 1
                            ? "rounded-br-md"
                            : ""
                        }`}
                        style={{
                          width: cell.column.getSize()
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!isLoading ? <Pagination<IUserListRow> table={table} /> : null}
    </>
  );
};

export default React.memo(UaUsersList);
