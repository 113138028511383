import React, { RefObject, useEffect, useId } from "react";
import L, { LatLngExpression, Map, icon } from "leaflet";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents
} from "react-leaflet";

import factoryIcon from "@/assets/images/factory.svg";

import "leaflet/dist/leaflet.css";
import LocationMarker from "./dash-map-marker-component";
import LocationCluster from "./dash-map-cluster-component";
import { Button } from "@tremor/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useGetDashboards } from "@/app/shared/hooks/get/dashboards";
import { useDashboardStore } from "@/store";
import { useNavigate } from "react-router-dom";

const stations = [
  {
    device_id: "1",
    device_name: "Station 1",
    location: { lat: 17.38743482982873, lng: 78.59117577982774 },
    tanks: 30,
    totalVolume: 21,
    gasIn: 31,
    gasOut: 32
  },
  {
    device_id: "2",
    device_name: "Station 2",
    location: { lat: 19.16886271935784, lng: 73.00796148588537 },
    tanks: 30,
    totalVolume: 21,
    gasIn: 31,
    gasOut: 32
  },
  {
    device_id: "3",
    device_name: "Station 3",
    location: { lat: 12.936379109918258, lng: 77.57739752338267 },
    tanks: 30,
    totalVolume: 21,
    gasIn: 31,
    gasOut: 32
  }
  //   {
  //     device_id: "4",
  //     device_name: "Station 4",
  //     location: { lat: 77.57739752338267, lng: 12.936379109918258 },
  //     tanks: 30,
  //     totalVolume: 21,
  //     gasIn: 31,
  //     gasOut: 32
  //   }
];

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const StationsMapView = () => {
  const resizeObserverRef = React.useRef<ResizeObserver>(null);
  const id = useId();

  const navigate = useNavigate();

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  const { data: dash } = useGetDashboards();
  const [setSelectedDash, setLayouts, setPanels] = useDashboardStore(
    (state) => [state.setActiveDashboard, state.setLayouts, state.setPanels]
  );

  return (
    <div className="h-full w-full p-2 mb-2 -mt-[15px]">
      <MapContainer
        ref={(ref) => {
          if (!ref) return;

          const container = document.getElementById(id);
          if (!container) return;

          L.DomEvent.disableClickPropagation(
            container
          ).disableScrollPropagation(container);

          resizeObserverRef.current = new ResizeObserver(() => {
            if (!ref) {
              return;
            }
            if (ref.getContainer()) {
              ref?.invalidateSize({ pan: false });
            }
          });
          if (container) {
            resizeObserverRef.current.observe(container);
          }
        }}
        id={id}
        center={[12.936379109918258, 77.57739752338267]}
        zoom={20}
        zoomSnap={0.45}
        style={{
          width: "100%",
          height: "calc(100% - 0px)",
          marginTop: "6px",
          borderRadius: "6px",
          zIndex: 0
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {stations.map((station) => (
          //   <LocationMarker key={station.device_id} {...station} />
          <Marker
            icon={icon({
              iconUrl: factoryIcon,
              iconSize: new L.Point(60, 75)
            })}
            position={station.location}
            key={station.device_id}
          >
            <Popup>
              <div>
                <h2 className="text-center text-lg">{station.device_name}</h2>
                <div className="flex mt-2 gap-1">
                  <span className="font-bold">Tanks:</span>
                  <span>{station.tanks}</span>
                </div>
                <div className="flex mt-2 gap-1">
                  <span className="font-bold">Current Volume:</span>
                  <span>{station.totalVolume}L</span>
                </div>
                <div className="flex mt-2 gap-1">
                  <span className="font-bold">Gas IN:</span>
                  <span>{station.gasIn}L</span>
                </div>
                <div className="flex mt-2 gap-1">
                  <span className="font-bold">Gas Out:</span>
                  <span>{station.gasOut}L</span>
                </div>
              </div>
              <Button
                icon={ArrowRightIcon}
                iconPosition="right"
                size={"xs"}
                onClick={() => {
                  const dashboard = dash.find(
                    (d) => d.id === "3ca6eed2-705f-4c2d-91b2-bd94aad0b516"
                  );

                  setSelectedDash(dashboard);
                  setPanels([]);
                  setLayouts(dashboard.meta_data.layouts);

                  navigate("/dashboard/3ca6eed2-705f-4c2d-91b2-bd94aad0b516");
                }}
                className={"mt-3"}
              >
                View Details
              </Button>
            </Popup>
          </Marker>
        ))}
        {/* {clusters.map((cluster: ICluster) => (
          <LocationCluster key={cluster.cluster_id} {...cluster} />
        ))} */}
      </MapContainer>
    </div>
  );
};

const resizeMap = (mapRef: RefObject<Map>, id: string) => {};

export default React.memo(StationsMapView);
