export interface IArtifactProps {
  file_name: string;
  file_size: string;
  topic: string;
  type: string;
  version: string;
}

export interface IArtifact {
  id: string;
  ota_release_id: string;
  project_id: string;
  org_id: string;
  created_at: string;
  props: IArtifactProps;
}

export interface IReleaseProps {
  device_types: string;
  release_name: string;
  target_devices?: string;
  target_fleets: string;
}

export interface IRelease {
  id: string;
  project_id: string;
  org_id: string;
  created_at: string;
  props: IReleaseProps;
}

export interface IGetReleaseResponse {
  data: IRelease[];
  ok: number;
}

export interface IArtifactResponse {
  data: {
    ota_release_artifacts: IArtifact[];
  };
  ok: number;
}

export interface ICreateArtifactResponse {
  data: {
    ota_release_artifact_id: string;
  };
  ok: 1;
}

export interface IReleaseDetails {
  data: {
    artifacts: IArtifact[];
    ota_release: IRelease;
  };
  ok: number;
}

export enum OtaStatusType {
  Failure = "failure",
  Successful = "successful",
  InProgress = "in_progress",
  Pending = "pending",
  Retry = "retry"
}

export interface IPendingOTAUpdatesResponse {
  data: IPendingOTAUpdate[];
  ok: number;
}

export interface IPendingOTAUpdate {
  id: string;
  ota_release_id: string;
  ota_release_deployment_id: string;
  device_id: string;
  scheduled_at: string;
  initiated: boolean;
  attempt: number;
  status: OtaStatusType;
  created_at: string;
}
