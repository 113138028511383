import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import OTADeploymentHeader from "../components/ota-deployment-header.component";

const CreateDeployment = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname ===
        "/ota-updates/projects/deployment/create-deployment/" ||
      location.pathname ===
        "/ota-updates/projects/deployment/create-deployment"
    ) {
      navigate(
        "/ota-updates/projects/deployment/create-deployment/software-and-device",
        {
          replace: true
        }
      );
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <OTADeploymentHeader options={false} />
      <main className="flex-1 flex flex-col mx-8 mt-7 space-y-4 mr-28  lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
        <Outlet />
      </main>
    </>
  );
};

export default CreateDeployment;
