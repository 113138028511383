import { data } from "../../shared/utils/data-file.util";
import useOTAReleaseStore from "../../../store/ota/ota-release.store";

const CreateNewReleasePage = () => {
  const [setShowCreateForm] = useOTAReleaseStore((state) => [
    state.setShowCreateForm
  ]);

  return (
    <>
      <main className="flex-1 items-center mx-8 mt-7 space-y-4 overflow-y-auto pt-9 lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
        <div className="lg:h-full md:h-[65vh] flex justify-center items-center">
          <div>
            <div className="mb-[38px] flex flex-col justify-center items-center">
              <h1 className="text-2xl text-center font-medium mb-2.5">
                {data.createNewReleasePage.heading}
              </h1>
              <p className="text-xs text-contentColorLight text-center w-[468px]">
                {data.createNewReleasePage.subHeading}
              </p>
            </div>
            <div className="text-center">
              <button
                onClick={() => setShowCreateForm(true)}
                className="inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
              >
                Create New Release
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateNewReleasePage;
