import React, { useEffect, useRef, useState } from "react";

const REGEX = /({{.*?}})/g;

interface IVariableInput
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    "onChange"
  > {
  value: string;
  as?: "input";
  onChange: (x: string) => void;
  onChangeSync?: (x: string) => void;
  keyRegex?: RegExp;
}

export const VariableInput: React.FC<IVariableInput> = ({
  value = "",
  keyRegex = REGEX,
  as = "input",
  onChange,
  onChangeSync = null,
  ...props
}) => {
  const ref = useRef(null);
  const [finalVal, setFinalVal] = useState(value);

  useEffect(() => {
    setFinalVal(value);
  }, [value]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(finalVal);
    }, 100);

    return () => clearTimeout(timeout);
  }, [finalVal]);

  const syncScroll = (e) => {
    ref.current.scrollTop = e.target.scrollTop;
    ref.current.scrollLeft = e.target.scrollLeft;
  };
  return (
    <div className="input-container min-w-[250px] py-5 px-3 rounded-sm">
      {as === "input" ? (
        <input
          placeholder={props.placeholder ?? ""}
          name={props.name ?? ""}
          onChange={(e) => {
            setFinalVal(e.target.value);
            onChangeSync && onChangeSync(e.target.value);
          }}
          value={finalVal}
          onScroll={syncScroll}
          {...props}
          className={`nodrag bg-background mb-1 w-full h-full px-2 py-1 text-contentColor rounded-sm`}
        />
      ) : (
        <textarea
          rows={3}
          readOnly={false}
          placeholder={props.placeholder ?? ""}
          name={props.name ?? ""}
          onChange={(e) => {
            setFinalVal(e.target.value);
            onChangeSync && onChangeSync(e.target.value);
          }}
          value={finalVal}
          onScroll={syncScroll}
          {...props}
          className={`nodrag bg-background mb-1 w-full h-full px-2 py-1 text-contentColor rounded-sm`}
        />
      )}
      <div
        ref={ref}
        className="input-renderer rounded-sm px-2 py-1 overflow-x-auto bg-background border border-background-layer3"
      >
        {finalVal.split(keyRegex).map((word, i) => {
          if (word.match(keyRegex) !== null) {
            return (
              <span key={i} className="text-yellow-600 italic">
                {word}
              </span>
            );
          } else {
            return <span key={i}>{word}</span>;
          }
        })}
      </div>
    </div>
  );
};
