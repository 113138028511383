import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";

interface IPermission {
  resource_type: string;
  actions: string;
  resources: string[];
}

interface IInvites {
  id: string;
  token: string;
  email: string[];
  permissions: IPermission;
  times_used: number;
  limit: number;
  org_id: string;
  last_used_at: string;
  org_access: string;
  revoked: boolean;
  created_at: string;
}

interface ISettingsState {
  invites: IInvites[];
  showCreateInviteForm: boolean;
  showGenerateAPIKeyForm: boolean;
  setInvites: (x: IInvites[]) => void;
  setShowCreateInviteForm: (x: boolean) => void;
  setShowGenerateAPIKeyForm: (x: boolean) => void;
}

const useSettingsStore = create<ISettingsState>()(
  persist(
    devtools(
      (set) => ({
        invites: [],
        showCreateInviteForm: false,
        showGenerateAPIKeyForm: false,
        setInvites: (res) =>
          set(
            produce((state) => {
              state.invites = res;
            })
          ),
        setShowCreateInviteForm: (boolVal) =>
          set(
            produce((state) => {
              state.showCreateInviteForm = boolVal;
            })
          ),
        setShowGenerateAPIKeyForm: (boolVal) =>
          set(
            produce((state) => {
              state.showGenerateAPIKeyForm = boolVal;
            })
          )
      }),
      { name: "settings", serialize: { options: true } }
    ),
    {
      name: "settings", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useSettingsStore;
