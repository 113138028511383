import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { NotFound } from "../shared/components";
// import { AnalyticsProjects } from "."

import OrgNavigation from "./components/org-navigation.component";
import ProjectNavigation from "./components/project-navigation.component";
import SettingsCommonHeader from "./components/settings-common-header.component";

const SettingsProvider: React.FC = () => {
  const location = useLocation();

  const orgflag =
    location.pathname.includes(
      "/settings/organization-settings/org-details"
    ) ||
    location.pathname.includes("/settings/organization-settings/members") ||
    location.pathname.includes("/settings/organization-settings/invites");

  const projectFlag =
    location.pathname.includes("/settings/project-settings/project-details") ||
    location.pathname.includes("/settings/project-settings/api-keys");

  return (
    <>
      <SettingsCommonHeader
        title={orgflag ? "Org Settings" : "Project settings"}
      />
      {orgflag ? (
        <>
          <main className="flex-1 mx-8 mt-7 space-y-4 overflow-hidden lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            <OrgNavigation />
            <Outlet />
          </main>
        </>
      ) : projectFlag ? (
        <>
          <main className="flex-1 mx-8 mt-7 space-y-4 overflow-hidden lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            <ProjectNavigation />
            <Outlet />
          </main>
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default SettingsProvider;
