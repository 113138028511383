import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface IDeleteDeviceTagResponse {
  data: {
    disassociation_status: boolean;
  };
  ok: number;
}

export interface IDeleteDeviceTagPayload {
  tag_ids: string;
}

export const deleteDeviceTag = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  payload: IDeleteDeviceTagPayload
) => {
  const { data, ok } = await networkService.delete<IDeleteDeviceTagResponse>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/tags`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.disassociation_status;
  } else return null;
};

export const useDeleteDeviceTag = (fleetId: string, deviceId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IDeleteDeviceTagPayload) =>
      await deleteDeviceTag(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDeviceTags");
      }
    }
  );

  return res;
};
