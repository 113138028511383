const DefinitionsIcon = ({ isActive = false }) => {
  return (
    <svg
      className={
        isActive ? "stroke-contentColor" : "opacity-50 stroke-contentColor"
      }
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5217 7.26086V8.82608H6.47826V7.26086H1V13.5217H19V7.26086H13.5217V7.26086Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04688 7.26086H11.9599"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5625 5.69568H17.4321"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5625 4.13043H17.4321"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5625 2.56525H17.4321"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5625 1H17.4321"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DefinitionsIcon;
