import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { TextInput, TextInputProps } from "@tremor/react";

import React from "react";

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
} & TextInputProps & {
    type?: "text" | "url" | "email" | "password" | undefined;
    defaultValue?: string | undefined;
    value?: string | undefined;
    icon?:
      | React.JSXElementConstructor<any>
      | React.ElementType<any>
      | undefined;
    error?: boolean | undefined;
    errorMessage?: string | undefined;
    disabled?: boolean | undefined;
  } & React.RefAttributes<HTMLInputElement>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <TextInput
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
