import React from "react";
import { useFleetAndDevicesStore } from "../../../store";

function OTAReleaseHeader() {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  return (
    <>
      <div className="bg-background border-b border-background-layer3 lg:px-8 sm:px-6 ">
        <div className="flex justify-between items-center py-5">
          <div className="flex flex-col">
            <div className="flex items-center mb-3">
              <h1 className="text-2xl font-medium mr-1.5">
                {selectedProject.project_name}
              </h1>
            </div>
            <div>
              <p className="text-sm text-contentColorLight]">{`release > `}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OTAReleaseHeader;
