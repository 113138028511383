const defaultHeaders = [
  {
    label: "Accept",
    value: "Accept"
  },
  {
    label: "Accept-charset",
    value: "Accept-charset"
  },
  {
    label: "Accept-Encoding",
    value: "Accept-Encoding"
  },
  {
    label: "Accept-Language",
    value: "Accept-Language"
  },
  {
    label: "Accept-Ranges",
    value: "Accept-Ranges"
  },
  {
    label: "Access-Control-Allow-Credentials",
    value: "Access-Control-Allow-Credentials"
  },
  {
    label: "Access-Control-Allow-Headers",
    value: "Access-Control-Allow-Headers"
  },
  {
    label: "Access-Control-Allow-Methods",
    value: "Access-Control-Allow-Methods"
  },
  {
    label: "Access-Control-Allow-Origin",
    value: "Access-Control-Allow-Origin"
  },
  {
    label: "Access-Control-Expose-Headers",
    value: "Access-Control-Expose-Headers"
  },
  {
    label: "Access-Control-Max-Age",
    value: "Access-Control-Max-Age"
  },
  {
    label: "Access-Control-Request-Headers",
    value: "Access-Control-Request-Headers"
  },
  {
    label: "Access-Control-Request-Method",
    value: "Access-Control-Request-Method"
  },
  {
    label: "Age",
    value: "Age"
  },
  {
    label: "Alt-Svc",
    value: "Alt-Svc"
  },
  {
    label: "Authorization",
    value: "Authorization"
  },
  {
    label: "Cache-Control",
    value: "Cache-Control"
  },
  {
    label: "Clear-Site-Data",
    value: "Clear-Site-Data"
  },
  {
    label: "Connection",
    value: "Connection"
  },
  {
    label: "Content-Disposition",
    value: "Content-Disposition"
  },
  {
    label: "Content-Encoding",
    value: "Content-Encoding"
  },
  {
    label: "Content-Language",
    value: "Content-Language"
  },
  {
    label: "Content-Length",
    value: "Content-Length"
  },
  {
    label: "Content-Location",
    value: "Content-Location"
  },
  {
    label: "Content-Range",
    value: "Content-Range"
  },
  {
    label: "Content-Security-Policy",
    value: "Content-Security-Policy"
  },
  {
    label: "Content-Security-Policy-Report-Only",
    value: "Content-Security-Policy-Report-Only"
  },
  {
    label: "Content-Type",
    value: "Content-Type"
  },
  {
    label: "Cookie",
    value: "Cookie"
  },
  {
    label: "Cookie2",
    value: "Cookie2"
  },
  {
    label: "Cross-Origin-Resource-Policy",
    value: "Cross-Origin-Resource-Policy"
  },
  {
    label: "Date",
    value: "Date"
  },
  {
    label: "DNT",
    value: "DNT"
  },
  {
    label: "Early-Data",
    value: "Early-Data"
  },
  {
    label: "ETag",
    value: "ETag"
  },
  {
    label: "Expect",
    value: "Expect"
  },
  {
    label: "Expect-CT",
    value: "Expect-CT"
  },
  {
    label: "Expires",
    value: "Expires"
  },
  {
    label: "Feature-Policy",
    value: "Feature-Policy"
  },
  {
    label: "Forwarded",
    value: "Forwarded"
  },
  {
    label: "From",
    value: "From"
  },
  {
    label: "Host",
    value: "Host"
  },
  {
    label: "If-Match",
    value: "If-Match"
  },
  {
    label: "If-Modified-Since",
    value: "If-Modified-Since"
  },
  {
    label: "If-None-Match",
    value: "If-None-Match"
  },
  {
    label: "If-Range",
    value: "If-Range"
  },
  {
    label: "If-Unmodified-Since",
    value: "If-Unmodified-Since"
  },
  {
    label: "Keep-Alive",
    value: "Keep-Alive"
  },
  {
    label: "Large-Allocation",
    value: "Large-Allocation"
  },
  {
    label: "Last-Modified",
    value: "Last-Modified"
  },
  {
    label: "Link",
    value: "Link"
  },
  {
    label: "Location",
    value: "Location"
  },
  {
    label: "Origin",
    value: "Origin"
  },
  {
    label: "Pragma",
    value: "Pragma"
  },
  {
    label: "Proxy-Authenticate",
    value: "Proxy-Authenticate"
  },
  {
    label: "Proxy-Authorization",
    value: "Proxy-Authorization"
  },
  {
    label: "Public-Key-Pins",
    value: "Public-Key-Pins"
  },
  {
    label: "Public-Key-Pins-Report-Only",
    value: "Public-Key-Pins-Report-Only"
  },
  {
    label: "Range",
    value: "Range"
  },
  {
    label: "Referrer",
    value: "Referrer"
  },
  {
    label: "Referrer-Policy",
    value: "Referrer-Policy"
  },
  {
    label: "Retry-After",
    value: "Retry-After"
  },
  {
    label: "Save-Data",
    value: "Save-Data"
  },
  {
    label: "Sec-WebSocket-Accept",
    value: "Sec-WebSocket-Accept"
  },
  {
    label: "Server-Timing",
    value: "Server-Timing"
  },
  {
    label: "Set-Cookie",
    value: "Set-Cookie"
  },
  {
    label: "Set-Cookie2",
    value: "Set-Cookie2"
  },
  {
    label: "SourceMap",
    value: "SourceMap"
  },
  {
    label: "Strict-Transport-Security",
    value: "Strict-Transport-Security"
  },
  {
    label: "TE",
    value: "TE"
  },
  {
    label: "Timing-Allow-Origin",
    value: "Timing-Allow-Origin"
  },
  {
    label: "TK",
    value: "TK"
  },
  {
    label: "Trailer",
    value: "Trailer"
  },
  {
    label: "Transfer-Encoding",
    value: "Transfer-Encoding"
  },
  {
    label: "Upgrade-Insecure-Requests",
    value: "Upgrade-Insecure-Requests"
  },
  {
    label: "User-Agent",
    value: "User-Agent"
  },
  {
    label: "Vary",
    value: "Vary"
  },
  {
    label: "Via",
    value: "Via"
  },
  {
    label: "Warnings",
    value: "Warnings"
  },
  {
    label: "WWW-Authenticate",
    value: "WWW-Authenticate"
  },
  {
    label: "X-Content-Type-Options",
    value: "X-Content-Type-Options"
  },
  {
    label: "X-DNS-Prefetch-Control",
    value: "X-DNS-Prefetch-Control"
  },
  {
    label: "X-Forwarded-For",
    value: "X-Forwarded-For"
  },
  {
    label: "X-Forwarded-Host",
    value: "X-Forwarded-Host"
  },
  {
    label: "X-Forwarded-Proto",
    value: "X-Forwarded-Proto"
  },
  {
    label: "X-Frame-Options",
    value: "X-Frame-Options"
  },
  {
    label: "X-XSS-Protection",
    value: "X-XSS-Protection"
  }
];

export { defaultHeaders };
