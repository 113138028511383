import { IMetricGraph } from "@interfaces/index";
// import { BadgeDelta } from '@tremor/react';

/* 
    Component: Metric Graph [ Numeric Graph ]
    Props:  {
        dataPrev: data distibution for previous year [ Json Object ]
        dataCurr: data distibution for next/current year [ Json Object ]
        title : Title of graph [ panel name ]
        category : name of key from that represents label/header of data distribution  [ string ]
        datakey :  name of key from data that represents value of data distribution [ string ]
    }
    Author: Aaditya Kashid
*/

const MetricGraph: React.FC<IMetricGraph> = ({
  dataPrev,
  dataCurr,
  category,
  datakey,
  unit
}) => {
  const valueFormatter = (number: number) =>
    Intl.NumberFormat("en-US").format(number).toString();

  const resultantSum = dataCurr[datakey] - dataPrev[datakey];

  return (
    <>
      <div className="px-4 py-4 flex flex-col justify-center items-center">
        {/* <div className="w-full flex flex-row justify-between p-2 my-2">
                    <div className="flex flex-col">
                        <span className="text-md font-medium">{dataPrev[category]}</span>
                        <span className="text-xl font-extrabold">{valueFormatter(dataPrev[datakey])}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-md font-medium">{dataCurr[category]}</span>
                        <span className="text-xl font-extrabold">{valueFormatter(dataCurr[datakey])}</span>
                    </div>
                </div> */}
        <div className="my-8 flex items-center">
          {/* <BadgeDelta size="xl" text={valueFormatter(resultantSum)} deltaType={resultantSum > 0 ? "increase" : "decrease"}/> */}
          <h1 className="lg:text-[4rem] md:text-5xl font-bold">
            {valueFormatter(resultantSum)}
          </h1>
          <span className=" inline-block text-sm ml-2 align-bottom">
            KW/hr
          </span>
          <svg
            width="30"
            height="55"
            viewBox="0 0 30 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2"
            transform={resultantSum > 0 ? "rotate(0)" : "rotate(180)"}
          >
            <path
              d="M14.0371 43.6621L14.0371 6.66211"
              stroke={resultantSum > 0 ? "#00AC47" : "#F44868"}
              strokeWidth="3.71875"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.03711 15.6621L14.5371 5.66211L24.0371 15.6621"
              stroke={resultantSum > 0 ? "#00AC47" : "#F44868"}
              strokeWidth="3.71875"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default MetricGraph;
