export interface FieldInfo {
  line: number;
  name: string;
  type: "string" | "array" | "bytes";
  nanopbOption: string | null;
  fieldLimit?: number;
}

export function verifySemicolonEndingsInProto(proto: string) {
  const lines = proto.split(/\n/);

  for (let index = 0; index < lines.length; index++) {
    const line = lines[index];
    const semiColonIndex = line.indexOf(";");
    if (semiColonIndex > 0 && semiColonIndex !== line.length - 1) {
      return false;
    }
  }

  return true;
}

export function parseProtoFile(fileContents: string): FieldInfo[] {
  const lines = fileContents.split(/\n/);

  const fieldInfos: FieldInfo[] = [];
  lines.forEach((line, index) => {
    const stringMatch = line.match(/\bstring\s+(\w+)\s*=/);
    if (stringMatch) {
      fieldInfos.push({
        line: index,
        name: stringMatch[1],
        type: "string",
        nanopbOption: null
      });
    }

    const arrayMatch = line.match(/\brepeated\s+\w+\s+(\w+)\s*=/);
    if (arrayMatch) {
      fieldInfos.push({
        line: index,
        name: arrayMatch[1],
        type: "array",
        nanopbOption: null
      });
    }

    const bytesMatch = line.match(/\bbytes\s+(\w+)\s*=/);
    if (bytesMatch) {
      fieldInfos.push({
        line: index,
        name: bytesMatch[1],
        type: "bytes",
        nanopbOption: null
      });
    }
  });

  return fieldInfos;
}

export function getUserInputForField(field: FieldInfo): FieldInfo {
  switch (field.type) {
    case "string":
      field.nanopbOption = `max_length=${field.fieldLimit}`;
      break;

    case "array":
      field.nanopbOption = `max_count=${field.fieldLimit}`;
      break;

    case "bytes":
      field.nanopbOption = `max_size=${field.fieldLimit}`;
      break;
  }
  return field;
}

export function updateProtoFile(
  fileContents: string,
  fieldInfos: FieldInfo[]
): string {
  let lines = fileContents.split(/\n/);

  fieldInfos.forEach((field) => {
    if (field.nanopbOption) {
      let lineToUpdate = lines[field.line];
      if (lineToUpdate.indexOf("]") > 0) {
        lineToUpdate = lineToUpdate.replace(
          /];$/,
          `,(nanopb).${field.nanopbOption}];`
        );
      } else {
        lineToUpdate = lineToUpdate.replace(
          /;$/,
          ` [(nanopb).${field.nanopbOption}];`
        );
      }
      lines[field.line] = lineToUpdate;
    }
  });

  return lines.join("\n");
}
