import React, { useState } from "react";
import PlayGround from "./playground.component";
import useRuleEngineStore from "@store/rule-engine/rule-engine.store";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface IRuleEngine {}

const RuleEngine: React.FC<IRuleEngine> = () => {
  const [editAction, setEditAction] = useState({
    id: "",
    name: "",
    description: "",
    actionSequenceNodeId: ""
  });

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const id = params["id"];

  const [
    rules,
    actionData, // actionSequenceNodeId -> actionId -> actionData
    responseData, // HTTPRresponseNode.id -> HTTPResponse
    setActionData,
    setResponseData
  ] = useRuleEngineStore((state) => [
    state.rules,
    state.actionData,
    state.responseData,
    state.setActionData,
    state.setResponseData
  ]);

  let rule = Object.values(rules).find((r) => r.id === id);

  if (id === "new-rule") {
    const ruleName = location.state?.ruleName;
    rule = rules[ruleName];
  }

  const setActionDataForCurrentEditor = (funcOrData, id = null) => {
    if (!id) {
      setActionData((currActionData) => ({
        ...currActionData,
        [editAction.actionSequenceNodeId]: {
          ...currActionData[editAction.actionSequenceNodeId],
          [editAction.id]:
            typeof funcOrData === "function"
              ? funcOrData(
                  currActionData[editAction.actionSequenceNodeId][
                    editAction.id
                  ] || {}
                )
              : funcOrData
        }
      }));
    } else {
      setActionData((currActionData) => ({
        ...currActionData,
        [editAction.actionSequenceNodeId]: {
          ...currActionData[editAction.actionSequenceNodeId],
          [id]:
            typeof funcOrData === "function"
              ? funcOrData(
                  currActionData[editAction.actionSequenceNodeId][id] || {}
                )
              : funcOrData
        }
      }));
    }
  };

  if (!rule) {
    if (id === "new-rule") {
      navigate("/rule-engine");
      return null;
    } else {
      return (
        <div className="flex justify-center min-h-screen items-center">
          Could not find the rule with id "{id}"
        </div>
      );
    }
  }

  const { name, description, version, triggerType } = rule;

  return (
    <main className="min-h-screen">
      <PlayGround
        setEditAction={setEditAction}
        title={name}
        ruleDetails={{ name, description, triggerType, version }}
        actionData={actionData}
        fetchedRuleData={rules[name].ruleData}
        // for setting the inputValues
        setActionData={setActionData}
        responseData={responseData}
        setResponseData={setResponseData}
      />

      {/* ACTION EDITOR PLAYGROUND */}
      {editAction.name ? (
        <PlayGround
          setEditAction={setEditAction}
          title={editAction.name}
          actionEditor
          actionData={
            actionData[editAction.actionSequenceNodeId][editAction.id] || {}
          }
          setActionData={setActionDataForCurrentEditor}
        />
      ) : null}
    </main>
  );
};

export default RuleEngine;
