import axios from "axios";
import useContentStore from "../../../store/content/content.store";
import appConstants from "../config"

export interface IDetailsResponse {
    values: string[][];
};

export function useGoogleSheetApiHook() {
    const [
        setProjectDetailsAccordions,
        setFleetDetailsAccordions,
        setDeviceDetailsAccordions,
        setShadowAccordions,
        setDeviceDatapointAccordions,
        setLandingPageLibrary,
        setLandingPageProducts,
        setPanelsAccordions
    ] = useContentStore((state) => [
        state.setProjectDetailsAccordions,
        state.setFleetDetailsAccordions,
        state.setDeviceDetailsAccordions,
        state.setShadowAccordions,
        state.setDeviceDatapointAccordions,
        state.setLandingPageLibrary,
        state.setLandingPageProducts,
        state.setPanelsAccordions,
    ]);

    const fetchProjectDetailsAccordionData = async () => {
        const response = await axios.get<IDetailsResponse>(
            `${appConstants.urls.googleSheetsBaseUrl}/${process.env.REACT_APP_GOOGLE_SHEET_ID}/values/Projects!A:E`,
            { params: { key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY } },
        );

        if (response?.data?.values?.length) {
            const values = response.data.values;
            const convertedToOptions = values.map((option: string[]) => { return { label: option[0], value: option[1] } });

            if (convertedToOptions.length) {
                setProjectDetailsAccordions(convertedToOptions);
            }
        }
    }

    const fetchFleetDetailsAccordionData = async () => {

        const response = await axios.get<IDetailsResponse>(
            `${appConstants.urls.googleSheetsBaseUrl}/${process.env.REACT_APP_GOOGLE_SHEET_ID}/values/Fleets!A:E`,
            { params: { key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY } },
        );

        if (response?.data?.values?.length) {
            const values = response.data.values;
            const convertedToOptions = values.map((option: string[]) => { return { label: option[0], value: option[1] } });

            if (convertedToOptions.length) {
                setFleetDetailsAccordions(convertedToOptions);
            }
        }
    }

    const fetchDeviceDetailsAccordionData = async () => {

        const response = await axios.get<IDetailsResponse>(
            `${appConstants.urls.googleSheetsBaseUrl}/${process.env.REACT_APP_GOOGLE_SHEET_ID}/values/Device!A:E`,
            { params: { key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY } },
        );

        if (response?.data?.values?.length) {
            const values = response.data.values;
            const convertedToOptions = values.map((option: string[]) => { return { label: option[0], value: option[1] } });

            if (convertedToOptions.length) {
                setDeviceDetailsAccordions(convertedToOptions);
            }
        }
    }

    const fetchShadowsAccordionData = async () => {

        const response = await axios.get<IDetailsResponse>(
            `${appConstants.urls.googleSheetsBaseUrl}/${process.env.REACT_APP_GOOGLE_SHEET_ID}/values/Shadows!A:E`,
            { params: { key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY } },
        );

        if (response?.data?.values?.length) {
            const values = response.data.values;
            const convertedToOptions = values.map((option: string[]) => { return { label: option[0], value: option[1] } });

            if (convertedToOptions.length) {
                setShadowAccordions(convertedToOptions);
            }
        }
    }

    const fetchDeviceDatapointsAccordionData = async () => {

        const response = await axios.get<IDetailsResponse>(
            `${appConstants.urls.googleSheetsBaseUrl}/${process.env.REACT_APP_GOOGLE_SHEET_ID}/values/DataPoints!A:E`,
            { params: { key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY } },
        );

        if (response?.data?.values?.length) {
            const values = response.data.values;
            const convertedToOptions = values.map((option: string[]) => { return { label: option[0], value: option[1] } });

            if (convertedToOptions.length) {
                setDeviceDatapointAccordions(convertedToOptions);
            }
        }
    }

    const fetchPanelsAccordionData = async () => {

        const response = await axios.get<IDetailsResponse>(
            `${appConstants.urls.googleSheetsBaseUrl}/${process.env.REACT_APP_GOOGLE_SHEET_ID}/values/Panels!A:E`,
            { params: { key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY } },
        );

        if (response?.data?.values?.length) {
            const values = response.data.values;
            const convertedToOptions = values.map((option: string[]) => { return { label: option[0], value: option[1] } });

            if (convertedToOptions.length) {
                setPanelsAccordions(convertedToOptions);
            }
        }
    }

    const fetchLandingDetails = async () => {
        const responseClientLibrary = await axios.get<IDetailsResponse>(
            `${appConstants.urls.googleSheetsBaseUrl}/${process.env.REACT_APP_GOOGLE_SHEET_ID}/values/Landing!A:D`,
            { params: { key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY } },
        );

        if (responseClientLibrary?.data?.values?.length) {
            const values = responseClientLibrary.data.values;
            const keys: string[] = values[0];
            const convertedToOptions = [];

            values.forEach((option: string[], index) => {
                const obj = {};

                if (index) {
                    option.forEach((val, index) => {
                        obj[keys[index]] = val;
                    })

                    convertedToOptions.push(obj);
                }
            });

            if (convertedToOptions.length) {
                setLandingPageLibrary(convertedToOptions);
            }
        }

        const responseExpoProducts = await axios.get<IDetailsResponse>(
            `${appConstants.urls.googleSheetsBaseUrl}/${process.env.REACT_APP_GOOGLE_SHEET_ID}/values/Landing!G:I`,
            { params: { key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY } },
        );

        if (responseExpoProducts?.data?.values?.length) {
            const values = responseExpoProducts.data.values;
            const keys: string[] = values[0];
            const convertedToOptions = [];

            values.forEach((option: string[], index) => {
                const obj = {};

                if (index) {
                    option.forEach((val, index) => {
                        obj[keys[index]] = val;
                    })

                    convertedToOptions.push(obj);
                }
            });

            if (convertedToOptions.length) {
                setLandingPageProducts(convertedToOptions);
            }
        }
    }

    const fetchAllContent = async () => {
        await fetchProjectDetailsAccordionData();
    }

    return {
        fetchPanelsAccordionData,
        fetchFleetDetailsAccordionData,
        fetchDeviceDetailsAccordionData,
        fetchProjectDetailsAccordionData,
        fetchShadowsAccordionData,
        fetchDeviceDatapointsAccordionData,
        fetchLandingDetails,
        fetchAllContent,
    }
}

