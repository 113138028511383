import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon
} from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import { useState } from "react";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <>
      {!sidebarOpen ? (
        <Button
          icon={ChevronDoubleRightIcon}
          tooltip="Add Nodes"
          variant="light"
          className="absolute top-24 left-4 z-10 !text-contentColorLight hover:text-contentColorLight hover:bg-background-layer3 p-2 rounded-md bg-background-layer2 transition-colors duration-200"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        />
      ) : null}
      <aside
        className={
          "border-r border-background-layer3 text-sm py-12 px-4 bg-background z-10 mt-20 text-contentColor h-full w-80 lg:w-72 2xl:w-64 fixed inset-y-0 left-0 transition duration-200 transform " +
          (sidebarOpen
            ? "translate-x-0 ease-in"
            : "-translate-x-full ease-out")
        }
      >
        <Button
          icon={ChevronDoubleLeftIcon}
          variant="light"
          color="gray"
          className="absolute top-2 right-2 text-contentColorLight hover:text-contentColorLight hover:bg-background-layer3 p-2 rounded-md bg-background-layer2 transition-colors duration-200"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        />
        <h1 className="text-2xl font-medium mb-3">Nodes</h1>
        <hr className="border-background-layer3 mb-4" />
        <div className="description mb-4">
          Drag these nodes to the playground to start building your rule!
        </div>
        <div
          className="dndnode border-2 border-background-layer3 "
          onDragStart={(event) => onDragStart(event, "actionSequenceNode")}
          draggable
        >
          Action Sequence Node
        </div>
        <div
          className="dndnode border-2 border-background-layer3"
          onDragStart={(event) => onDragStart(event, "httpResponseNode")}
          draggable
        >
          HTTP Response Node
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
