import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { ITrigger } from "@/interfaces/triggers.interface";

interface IUpdateTriggerResponse {
  data: {
    trigger: ITrigger;
  };
  ok: number;
}

export interface IUpdateTriggerPayload {
  trigger_name: string;
  trigger_description: string;
  trigger_type: string;
  definition: string;
  active: boolean;
  rule_id?: string;
}

export const updateTrigger = async (
  projectId: string,
  orgId: string,
  payload: IUpdateTriggerPayload,
  triggerId: string
) => {
  const { data, ok } = await networkService.patch<IUpdateTriggerResponse>(
    `projects/${projectId}/triggers/${triggerId}`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.trigger;
  } else return null;
};

export const useUpdateTrigger = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({ data, id }: { data: IUpdateTriggerPayload; id: string }) =>
      await updateTrigger(selectedProject.id, user.selectedOrg.id, data, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getTriggers");
      }
    }
  );

  return res;
};
