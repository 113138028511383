import { useQuery } from "react-query";

import { IGetMeta } from "@interfaces/shared.interface";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface IDataPointDefinitionData {
  device_id: string;
  fleet_id: string;
  timestamp: string;

  // other dynamic properties:
  [key: string]: any;
}

interface IDataPointDefinitionDataResponse {
  data: IDataPointDefinitionData[];
  meta: IGetMeta;
  ok: number;
}

export const getDataPointDefinitionsData = async (
  dataPointId: string,
  projectId: string,
  orgId: string,
  params: any
) => {
  const { data, ok, meta } =
    await networkService.get<IDataPointDefinitionDataResponse>(
      `projects/${projectId}/data_point_definitions/${dataPointId}/data`,
      params,
      {
        headers: {
          "ORG-ID": orgId
        }
      }
    );

  if (ok) {
    return { data, meta };
  }
};

export const useGetDataPointDefinitionsData = (
  dataPointId: string,
  params: any = {},
  successCb: ((data: IDataPointDefinitionData[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);

  const res = useQuery(
    [
      "getDataPointDefinitionsData",
      dataPointId,
      selectedProject.id,
      user.selectedOrg.id,
      params
    ],
    async () => {
      const dpdData = await getDataPointDefinitionsData(
        dataPointId,
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(dpdData.data);

      return dpdData;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
