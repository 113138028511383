import { useEffect } from "react";
// import { BarChart, LineChart, NumericInfoGraph, PieChart } from "../dashboard/components/graphs";
// import { chartdata, data } from "../dashboard/sample-data";

import { useGoogleSheetApiHook } from "../shared/hooks/google-sheet-api.hook";
import useContentStore from "../../store/content/content.store";
import { useFleetAndDevicesStore } from "../../store";

const HomeLandingPage: React.FC = () => {
  const { fetchLandingDetails } = useGoogleSheetApiHook();

  // const wrapperRef = useRef(null);

  const [landingPageLibrary, landingPageProducts] = useContentStore(
    (state) => [state.landingPageLibrary, state.landingPageProducts]
  );

  const [projectDetails] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  useEffect(() => {
    if (!landingPageLibrary.length || !landingPageProducts.length) {
      fetchLandingDetails();
    }
  }, [landingPageLibrary, landingPageProducts, fetchLandingDetails]);

  // useEffect(()=> {
  //     wrapperRef.current.style.zoom = "75%";
  // }, []);

  const renderLibraryCard = (
    key: number,
    icon: string,
    title: string,
    description: string,
    link: string
  ) => {
    if (link === "") {
      return (
        <div
          key={key}
          className="flex flex-col justify-start max-w-[22rem] bg-background p-4 rounded-md"
        >
          <div className="flex items-center">
            <img src={icon} alt={icon} className="h-[90%]" />
            <h1 className="font-bold text-sm">{title}</h1>
          </div>
          <div className="text-[#828282] text-xs my-3">{description}</div>
        </div>
      );
    }
    return (
      <div
        key={key}
        className="flex flex-col justify-start w-full min-w-[14rem] bg-background p-4 rounded-md"
      >
        <div className="flex items-center">
          <img src={icon} alt={icon} className="h-[90%]" />
          <h1 className="font-bold text-sm text-contentColor">{title}</h1>
        </div>
        <div className="text-contentColorLight text-xs my-3">
          {description}
        </div>
        <div className="flex items-center">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.92201 12.4354C3.72201 12.3021 3.55001 12.1321 3.34867 11.8914C3.24417 11.7635 3.14061 11.6349 3.03801 11.5054C2.72934 11.1221 2.53467 10.9454 2.33334 10.8728C2.16687 10.813 2.03096 10.6896 1.95551 10.5296C1.88006 10.3696 1.87124 10.1862 1.93101 10.0198C1.99077 9.8533 2.11421 9.71739 2.27418 9.64193C2.43415 9.56648 2.61754 9.55767 2.78401 9.61743C3.28534 9.79743 3.62467 10.1074 4.08201 10.6761C4.01934 10.5981 
                            4.30867 10.9608 4.37067 11.0354C4.49734 11.1868 4.59067 11.2788 4.66401 11.3274C4.80001 11.4188 5.05534 11.4581 5.43067 11.4208C5.44601 11.1661 5.49334 10.9188 5.56534 10.6908C3.58667 10.2068 2.46667 8.93076 2.46667 6.42676C2.46667 5.6001 2.71334 4.8561 3.17201 4.2321C3.02667 3.6361 3.04867 2.91543 3.37334 2.1041C3.41019 2.01232 3.46699 1.92989 3.53963 1.86278C3.61227 1.79567 3.69894 1.74557 3.79334 1.7161C3.84734 1.7001 3.87801 1.69276 3.93201 1.68476C4.46734 1.60276 5.22334 1.7981 6.20867 2.41543C6.79587 2.27815 7.39698 2.20925 8.00001 2.2101C8.60801 2.2101 9.21201 2.27943 9.78934 2.41543C10.774 1.79343 11.5313 1.5981 12.0707 1.68476C12.1273 1.69343 12.1753 1.70476 12.216 1.7181C12.3086 1.74861 12.3933 1.79905 12.4642 1.86586C12.5352 1.93266 12.5906 2.01421 
                            12.6267 2.10476C12.9513 2.91543 12.9733 3.6361 12.828 4.23143C13.2887 4.85543 13.5333 5.59476 13.5333 6.42676C13.5333 8.93143 12.4173 10.2034 10.4387 10.6881C10.522 10.9648 10.5653 11.2741 10.5653 11.6081C10.5654 12.2117 10.5627 12.8152 10.5573 13.4188C10.7071 13.4514 10.841 13.5347 10.9365 13.6545C11.032 13.7743 11.0834 13.9234 11.0819 14.0766C11.0804 14.2298 11.0262 14.3779 10.9283 14.4958C10.8305 14.6137 10.695 14.6943 10.5447 14.7241C9.78534 14.8761 9.22267 14.3694 9.22267 13.7074L9.22401 13.4101L9.22734 12.9401C9.23067 12.4681 9.23201 12.0481 9.23201 11.6081C9.23201 11.1434 9.11001 10.8401 8.94867 10.7014C8.50801 10.3214 8.73134 9.5981 9.30867 9.53343C11.2867 9.31143 12.2 8.54543 12.2 6.42676C12.2 5.7901 11.992 5.2641 11.5913 4.8241C11.5069 4.73159 
                            11.4503 4.61718 11.4279 4.49397C11.4055 4.37076 11.4182 4.24373 11.4647 4.12743C11.5753 3.85143 11.6227 3.48943 11.5287 3.05143L11.522 3.05343C11.1947 3.1461 10.782 3.34676 10.2833 3.6861C10.2029 3.74067 10.1116 3.777 10.0156 3.79254C9.9197 3.80809 9.82154 3.80248 9.72801 3.7761C9.16527 3.62029 8.58391 3.54201 8.00001 3.54343C7.40667 3.54343 6.81867 3.62276 6.27201 3.77676C6.17882 3.80293 6.08105 
                            3.8085 5.9855 3.79307C5.88994 3.77765 5.79889 3.7416 5.71867 3.68743C5.21734 3.34943 4.80267 3.14943 4.47334 3.0561C4.37734 3.49143 4.42467 3.8521 4.53467 4.12743C4.58121 4.24367 4.59408 4.37066 4.57179 4.49387C4.54951 4.61708 4.49298 4.73152 4.40867 4.8241C4.01067 5.2601 3.80001 5.7961 3.80001 6.42676C3.80001 8.54143 4.71401 9.3121 6.68134 9.53343C7.25801 9.5981 7.48201 10.3181 7.04401 10.6988C6.91601 10.8108 6.75801 11.1868 6.75801 11.6081V13.7081C6.75801 14.3654 6.20134 14.8581 5.45134 14.7268C5.29918 14.7 5.16103 14.6213 5.06048 14.504C4.95994 14.3867 4.90325 14.2382 4.9001 14.0837C4.89695 13.9293 4.94754 13.7785 5.04322 13.6572C5.1389 13.536 5.27373 13.4517 5.42467 13.4188V12.7588C4.81801 12.7994 4.31667 12.7001 3.92201 12.4354Z"
              fill="#546CCC"
            />
          </svg>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <span className="text-primary font-medium text-xs ml-2">
              Save GitHub
            </span>
          </a>
        </div>
      </div>
    );
  };

  // const renderProductCard = (key: number, icon: string, title: string, description: string) => {
  //     return (
  //         <div key={key} className="flex flex-col justify-start w-[22rem] bg-white ml-4 p-4 mb-4 rounded-md">
  //             <div className="flex items-center">
  //                 <img src={icon} alt={icon} className="h-[90%]" />
  //                 <h1 className="ml-2 font-bold text-base">{title}</h1>
  //             </div>
  //             <div className="text-[#828282] text-xs my-3">{description}</div>
  //         </div>
  //     )
  // }

  return (
    <main className="w-full h-auto py-8 overflow-y-auto lg:px-8 sm:px-6">
      {/* <div ref={wrapperRef} className="w-full flex flex-wrap">
                <div className='w-max ml-4'>
                    <div className="relative w-[24rem] h-[24rem] flex flex-col bg-white my-5 rounded-md">
                        <BarChart
                            title="Database"
                            chartdata={chartdata}
                            categories={["Item A", "Item B", "Item C"]}
                            colors={["yellow", "blue", "green"]}
                            datakey="temp" yMin={500} yMax={5000}
                        />
                    </div>
                </div>
                <div className='w-max  ml-4'>
                    <div className="relative w-[24rem] h-[24rem] flex flex-col bg-white my-5 rounded-md">
                        <LineChart
                            title="Auth"
                            chartdata={chartdata}
                            categories={["Item A", "Item B", "Item C"]}
                            colors={["yellow", "blue", "green"]}
                            datakey="temp" yMin={500} yMax={5000}
                        />
                    </div>
                </div>
                <div className='w-max  ml-4'>
                    <div className="relative w-[24rem] h-[24rem] flex flex-col bg-white my-5 rounded-md">
                        <PieChart
                            title="Storage"
                            data={data}
                            category="Item A"
                            colors={["yellow", "blue", "green"]}
                            datakey="hosts"
                            variant="donut"
                        />
                    </div>
                </div>
                <div className='w-max  ml-4'>
                    <div className="relative w-[24rem] h-[24rem] flex flex-col bg-white my-5 rounded-md">
                        <NumericInfoGraph title="Orders Placed" info={34200} />
                    </div>
                </div>
            </div> */}
      <div className="mb-7">
        <h1 className="font-bold text-4xl mb-6">
          {projectDetails.project_name}
        </h1>
        <h1 className="font-normal text-xl mb-6">
          {" "}
          Welcome to your new project !
        </h1>
        <p className="font-normal text-base">
          If you're new to this, a good way to get started would be our
          documentation
        </p>
        <p className="font-normal text-base mb-6">
          Here are some SDKs to help you integrate and build with Golain:
        </p>

        <button
          onClick={() =>
            window.open(
              "https://dev.docs.golain.io/",
              "_blank" // <- This is what makes it open in a new window.
            )
          }
          className="mr-2 px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
        >
          Docs
        </button>
      </div>
      <div className="w-full my-3 mt-5">
        <h1 className="font-medium text-lg mb-2">Client Libraries</h1>
        <div className="my-4 gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {landingPageLibrary.map((lib, index) =>
            renderLibraryCard(
              index,
              lib.icon,
              lib.title,
              lib.description,
              lib.link
            )
          )}
        </div>
      </div>

      {/* <div className="w-full my-3 mt-5">
                <h1 className="font-medium text-lg mb-2">Explore Products</h1>
                <div className="flex flex-row my-4 flex-wrap">
                    {
                        landingPageProducts.map((prod, index) => (renderProductCard(index, prod.icon, prod.title, prod.description)))
                    }
                </div>
            </div> */}
    </main>
  );
};

export default HomeLandingPage;
