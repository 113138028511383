import { Tooltip } from "react-tooltip";
import {
  IContext,
  TContextType,
  contextFieldRequiredMap
} from "./rule-engine.helper";
import TransitionedMenu from "../shared/components/transitioned-men.component";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";

/* Tooltip for creating a new context */
export function AddFetchedContextTooltip(
  fetchedContexts: IContext[],
  newContextType: string,
  setNewContextType,
  setFetchedContexts
) {
  return (
    <Tooltip
      id={"fetched-context-add-tooltip"}
      place="bottom"
      clickable
      className="nodrag"
    >
      {!fetchedContexts?.some((c) => c.error) ? (
        <>
          <div>Select the type of fetched context you want to add.</div>

          <div className="flex gap-2 justify-center mt-2">
            <TransitionedMenu
              noHeightLimit
              buttonComponent={
                <div
                  className={`flex px-2 py-1 rounded-sm uppercase text-contentColor items-center bg-background-layer3 `}
                >
                  {newContextType}
                  <ChevronDownIcon width={16} className="ml-2" />
                </div>
              }
            >
              {Object.keys(contextFieldRequiredMap).map((type) => (
                <Menu.Item key={"fetched-context-type-" + type}>
                  {({ active }) => (
                    <button
                      className={`w-full flex gap-1 items-center uppercase ${
                        active && "bg-background-layer2"
                      } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
                      onClick={() => setNewContextType(type)}
                    >
                      {type}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </TransitionedMenu>
            <button
              className="bg-green-500 text-white px-2 py-1 rounded-sm"
              onClick={() => {
                setFetchedContexts((prev) => [
                  ...prev,
                  {
                    key: "",
                    type: newContextType as TContextType,
                    device_id: "",
                    fleet_id: "",
                    user_id: ""
                  }
                ]);
              }}
            >
              Add
            </button>
          </div>
        </>
      ) : (
        <>
          Fetched Context of type
          <span className="text-yellow-500 mx-1">
            {fetchedContexts[fetchedContexts.length - 1]?.type}
          </span>
          requires: <br />
          {contextFieldRequiredMap[
            fetchedContexts[fetchedContexts.length - 1]?.type
          ]
            .filter((field) => {
              return (
                fetchedContexts[fetchedContexts.length - 1]?.[field].trim() ===
                ""
              );
            })
            .map((field) => (
              <span className="text-blue-500 mr-1">{field}, </span>
            ))}
          to be filled
        </>
      )}
    </Tooltip>
  );
}
