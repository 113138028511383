import { useEffect, useMemo, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuthStore, useFleetAndDevicesStore } from "../../../store";
import { FieldError } from "../../shared/components";
import DeviceCreationSteps from "../components/device-creation-steps.component";
import SuggestionPanel from "../components/fad-suggestion-panel.component";
import ReactSelectCreatable from "react-select/creatable";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { TagIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Badge, Button } from "@tremor/react";
import { useGetTags } from "@/app/shared/hooks/get/tags";
import { IOption } from "@/interfaces";
import { useCreateTag } from "@/app/shared/hooks/post/create-tag";
import { toast } from "react-toastify";

function AboutDevice() {
  const navigate = useNavigate();
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [deviceCreation, setDeviceCreation] = useFleetAndDevicesStore(
    (state) => [state.deviceCreation, state.setDeviceCreation]
  );

  const [inputFieldValues, setInputFieldValues] = useState({ deviceName: "" });
  const [inputFieldErrors, setInputFieldErrors] = useState({ deviceName: "" });

  const [showAddTags, setShowAddTags] = useState(
    !!deviceCreation.tags?.length
  );
  const [selectedTags, setSelectedTags] = useState<IOption[]>(
    deviceCreation.tags
  );

  const { data: tags, isLoading: tagsLoading } = useGetTags();
  const createTagMutation = useCreateTag();

  useEffect(() => {
    updateAuthUser({ deviceCreationStatus: "0" });
  }, [updateAuthUser]);

  useEffect(() => {
    if (deviceCreation?.device_name) {
      setInputFieldValues({
        deviceName: deviceCreation.device_name
      });
    }
  }, [deviceCreation]);

  const tagsOptions: IOption[] = useMemo(() => {
    return !tags
      ? []
      : tags.map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  const handleTagCreate = (tagName: string) => {
    createTagMutation.mutate(
      { tag_name: tagName },
      {
        onSuccess: (tagId) => {
          setSelectedTags([...selectedTags, { value: tagId, label: tagName }]);
          toast.success(`Created tag '${tagName}' successfully!`);
        }
      }
    );
  };

  const handleSubmit = () => {
    if (!inputFieldValues.deviceName.trim()) {
      return setInputFieldErrors({
        ...inputFieldErrors,
        deviceName: "Please enter device name."
      });
    } else if (
      inputFieldValues.deviceName.trim() &&
      inputFieldErrors.deviceName
    ) {
      setInputFieldErrors({ ...inputFieldErrors, deviceName: "" });
    }

    if (inputFieldValues.deviceName) {
      setDeviceCreation({
        device_name: inputFieldValues.deviceName,
        tags: showAddTags ? selectedTags : []
      });
      navigate("/fleet-and-devices/projects/apply-shadow/");
    }
  };

  const handleFieldChange = (e: any, id: string) => {
    const payload = { ...inputFieldValues };
    payload[id] = e.target.value;
    setInputFieldValues({ ...payload });
  };

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12 pb-8">
          <div className="mt-7 mb-5 w-10/12">
            <DeviceCreationSteps />
          </div>

          <Formik initialValues={{ deviceName: "" }} onSubmit={handleSubmit}>
            <Form className="flex flex-col flex-1">
              <div>
                <h1 className="text-lg text-left font-medium mb-2.5">
                  About Device
                </h1>

                <div className="flex-1">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Device Name
                    </label>

                    <Field
                      type="text"
                      id="deviceName"
                      name="deviceName"
                      placeholder="Device Name"
                      value={inputFieldValues.deviceName}
                      onChange={(e) => handleFieldChange(e, "deviceName")}
                      className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />
                    {inputFieldErrors.deviceName ? (
                      <FieldError message={inputFieldErrors.deviceName} />
                    ) : null}
                  </div>

                  <div className="form-group mb-5 flex justify-between w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Activate on creation{" "}
                    </label>
                    <div>
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-background-layer3  after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#0AB63B]"></div>
                        <span className="ml-3 text-sm font-medium">
                          Active
                        </span>
                      </label>
                    </div>
                  </div>
                  {!showAddTags ? (
                    <Badge
                      color="fuchsia"
                      size="xs"
                      onClick={() => setShowAddTags(true)}
                      className="cursor-pointer"
                    >
                      <span className="flex gap-2">
                        <TagIcon width={16} />
                        Add Tags
                      </span>
                    </Badge>
                  ) : (
                    <div className="w-10/12">
                      <div className="flex mb-3 justify-between">
                        <span className="text-sm block font-medium text-contentColorLight">
                          Tags
                        </span>
                        <Button
                          icon={TrashIcon}
                          variant="light"
                          color="red"
                          onClick={() => setShowAddTags(false)}
                        />
                      </div>
                      <ReactSelectCreatable
                        isMulti
                        value={selectedTags}
                        onChange={(opts: IOption[]) => {
                          setSelectedTags(opts);
                        }}
                        isLoading={createTagMutation.isLoading || tagsLoading}
                        options={tagsOptions}
                        onCreateOption={handleTagCreate}
                        menuPortalTarget={document.body}
                        classNames={reactSelectClassNames}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="">
                <button
                  type="submit"
                  className="w-44 inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  Next
                </button>
              </div>
            </Form>
          </Formik>
        </div>

        <div className="w-7/12">
          <SuggestionPanel type="device" />
        </div>
      </div>
    </>
  );
}

export default AboutDevice;
