import { useState, useEffect, useMemo, BaseSyntheticEvent } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import useCreateHeatmapStore from "@store/dashboard/create-heatmap.store";

import dateService from "@services/date.service";
import { useDebounce } from "@/app/shared/utils/helper.util";

interface IHeatmapError {
  param: boolean;
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm";

const aggregationParams = [
  { value: "avg", label: "Mean" },
  { value: "max", label: "Max" },
  { value: "min", label: "Min" },
  { value: "sum", label: "Sum" },
  { value: "last", label: "Last" },
  { value: "first", label: "First" },
  { value: "count", label: "Count" }
];

export const HeatMapForm = ({
  params,
  onChangeHandler,
  enableCreatebutton
}) => {
  const [inputValues, setInputValues] = useCreateHeatmapStore((state) => [
    state.inputValues,
    state.setInputValues
  ]);

  const [minColor, setMinColor] = useState(inputValues.minColor);
  const [maxColor, setMaxColor] = useState(inputValues.maxColor);

  const minColorDebounced = useDebounce(minColor, 250);
  const maxColorDebounced = useDebounce(maxColor, 250);

  const [error, setErrors] = useState<IHeatmapError>({ param: true });

  const [selectOptions, setSelectOptions] = useState(params);

  useEffect(() => {
    setSelectOptions(params);
  }, [params]);

  const initialValues = useMemo(
    () => ({
      "heatmap-parameter": ""
    }),
    []
  );

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (key: string, value: any) => {
    let newInputs = { ...inputValues };
    newInputs[key] = value;

    if (key === "param") {
      setErrors({ ...error, param: false });
    }

    if (key === "timePeriod") {
      newInputs.startDate = dateService
        .parseRelativeDate(value.start)
        .format(DATE_FORMAT);
      newInputs.endDate = dateService
        .parseRelativeDate(value.end)
        .format(DATE_FORMAT);
    }

    setInputValues(newInputs);
  };

  useEffect(() => {
    onChangeHandler([inputValues]);
  }, [inputValues, onChangeHandler]);

  useEffect(() => {
    if (minColorDebounced) {
      handleChange("minColor", minColorDebounced);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minColorDebounced]);

  useEffect(() => {
    if (maxColorDebounced) {
      handleChange("maxColor", maxColorDebounced);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxColorDebounced]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <div className="mb-2">
          <div className="w-10/12 my-2">
            <label className="text-sm font-medium text-gray-600">
              Aggregation Mode
            </label>
            <Field
              as="select"
              id={"heatmap-aggregation-mode"}
              name={"heatmap-aggregation-mode"}
              placeholder="Aggregation Mode"
              defaultValue={aggregationParams[0]}
              value={aggregationParams.filter(
                (opt) => opt.value === inputValues.aggregationMode
              )}
              component={Select}
              options={aggregationParams}
              styles={{}}
              className={`block w-10/12 2xl:w-4/12  mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                error["heatmap-params"]
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
              }`}
              onChange={(e) => handleChange("aggregationMode", e.value)}
            />
          </div>

          {/* NUMBER OF ROWS */}
          <div className="mb-2 w-6/12">
            <label className="text-sm font-medium text-gray-600">
              Number of rows
            </label>
            <Field
              as="input"
              type="number"
              id={"heatmap-rows-Count"}
              name={"heatmap-rows-Count"}
              placeholder="7"
              min={1}
              max={100}
              value={inputValues.rowsCount}
              className={`block w-10/12 lg:w-6/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                error["heatmap-params"]
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
              }`}
              onChange={(e: BaseSyntheticEvent) =>
                handleChange("rowsCount", parseInt(e.target.value))
              }
            />
          </div>
          {/* COLOURS */}
          <div className="flex flex-row">
            {/* COLOR MIN */}
            <div className="w-4/12">
              <label className="text-sm font-medium text-gray-600">
                Min Colour
              </label>
              <Field
                as={"input"}
                type="color"
                id={"heatmap-min-color-picker"}
                name={"heatmap-min-color-picker"}
                placeholder="#FFF"
                value={inputValues.minColor}
                options={selectOptions}
                styles={{}}
                className={`block w-6/12 mt-0 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                  error["heatmap-params"]
                    ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                    : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                }`}
                onChange={(e: BaseSyntheticEvent) =>
                  setMinColor(e.target.value)
                }
              />
            </div>
            {/* COLOR MAX */}
            <div className="w-4/12">
              <label className="text-sm font-medium text-gray-600">
                Max Colour
              </label>
              <Field
                as={"input"}
                type="color"
                id={"heatmap-max-color-picker"}
                name={"heatmap-max-color-picker"}
                placeholder="#FFF"
                value={inputValues.maxColor}
                styles={{}}
                className={`block w-6/12 mt-0 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                  error["heatmap-params"]
                    ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                    : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                }`}
                onChange={(e: BaseSyntheticEvent) =>
                  setMaxColor(e.target.value)
                }
              />
            </div>
          </div>
          <div className="mt-2">
            <label className="text-sm font-medium text-gray-600">
              Box Roundness (%)
            </label>
            <Field
              as={"input"}
              type="range"
              min={0}
              max={50}
              step={10}
              id={"heatmap-box-roundness"}
              name={"heatmap-box-roundness"}
              value={inputValues.boxRoundness}
              options={selectOptions}
              styles={{}}
              className={`block w-6/12 mt-0 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                error["heatmap-params"]
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
              }`}
              onChange={(e: BaseSyntheticEvent) =>
                handleChange("boxRoundness", e.target.value)
              }
            />
          </div>
          <div className="mt-3 flex gap-2 items-center">
            <label className="text-sm font-medium text-gray-600">
              Show Values in cell
            </label>
            <div>
              <label className="inline-flex relative items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={inputValues.showValues}
                  onChange={(e) =>
                    handleChange("showValues", e.target.checked)
                  }
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#0AB63B]"></div>
              </label>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
