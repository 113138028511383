import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { ICreateArtifactResponse } from "@interfaces/ota.interface";

export const createArtifact = async (
  projectId: string,
  orgId: string,
  releaseId: string,
  payload: FormData
) => {
  const { data, ok } = await networkService.post<ICreateArtifactResponse>(
    `projects/${projectId}/ota_releases/${releaseId}/artifacts`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.ota_release_artifact_id;
  } else return null;
};

export const useCreateArtifact = (releaseId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: FormData) =>
      await createArtifact(
        selectedProject.id,
        user.selectedOrg.id,
        releaseId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getArtifacts");
      }
    }
  );

  return res;
};
