import { IOption } from "@/interfaces";
import {
  ITriggerDefinition,
  ITriggerFormState,
  THTTPTriggerAuthType,
  TParamType
} from "@/interfaces/triggers.interface";

export const authTypes: IOption[] = [
  {
    label: "Platform User",
    value: "BearerTokenPlatform"
  },
  {
    label: "Consumer",
    value: "BearerTokenConsumer"
  },
  {
    label: "API Key",
    value: "ApiKey"
  }
];

export const paramTypes: IOption[] = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "bool", label: "Bool" },
  { value: "struct", label: "Struct" }
  // { value: "array", label: "Array" }
];

const getFormattedDefaultValue = (
  defaultValue: any,
  paramType: TParamType
) => {
  switch (paramType) {
    case "string":
      return defaultValue;
    case "number":
      if (defaultValue === "") {
        return 0;
      }
      return defaultValue;
    case "bool":
      if (defaultValue === "") {
        return true;
      }
      return defaultValue;

    default:
      return defaultValue;
  }
};

const serializeHTTPTrigger = (
  values: ITriggerFormState
): ITriggerDefinition => {
  const triggerDefinition: ITriggerDefinition = {
    allowed_methods: values.httpMethods,
    auth_type: values.authMode.value as THTTPTriggerAuthType,
    headers: {
      optional: values.headers
        .filter((header) => !header.required)
        .reduce((acc, cur) => {
          acc[cur.key] = cur.defaultValue;
          return acc;
        }, {}),
      required: values.headers
        .filter((header) => header.required)
        .reduce((acc, cur) => {
          acc[cur.key] = "";
          return acc;
        }, {})
    }
  };

  if (values.path?.length) {
    triggerDefinition.path_params = values.path.reduce((acc, cur: string) => {
      let newPath = acc;

      //   if (cur.startsWith(":")) {
      //     newPath += "([a-zA-Z0-9-]+)";
      //   } else {
      //     newPath += cur;
      //   }

      newPath += cur;

      newPath += "/";

      return newPath;
    }, "/");
  }

  if (values.queryParams?.length) {
    triggerDefinition.query_params = values.queryParams.map((qParam) => ({
      param_name: qParam.name,
      param_type: qParam.type.value as TParamType,
      default: qParam.required
        ? undefined
        : getFormattedDefaultValue(
            qParam.defaultValue,
            qParam.type.value as TParamType
          )
    }));
  }

  if (
    values.bodyParams?.length &&
    values.httpMethods?.filter((m) => m !== "GET").length
  ) {
    triggerDefinition.required_body_params = values.bodyParams
      .filter((bParam) => bParam.required)
      .map((bParam) => ({
        param_name: bParam.name,
        param_type: bParam.type.value as TParamType,
        default: undefined
      }));
    triggerDefinition.optional_body_params = values.bodyParams
      .filter((bParam) => !bParam.required)
      .map((bParam) => ({
        param_name: bParam.name,
        param_type: bParam.type.value as TParamType,
        default: getFormattedDefaultValue(
          bParam.defaultValue,
          bParam.type.value as TParamType
        )
      }));
  }

  return triggerDefinition;
};

export const deserializeHTTPTrigger = (
  triggerName: string,
  definition: ITriggerDefinition
): ITriggerFormState => {
  const pathParams = definition.path_params
    ? definition.path_params.split("/").filter(Boolean)
    : [];
  const headers = Object.entries({
    ...definition.headers.required,
    ...definition.headers.optional
  }).map(([key, defaultValue]) => ({
    key,
    defaultValue,
    required: definition.headers.required.hasOwnProperty(key)
  }));

  const queryParams = (definition.query_params || []).map((qParam) => ({
    name: qParam.param_name,
    type: {
      value: qParam.param_type,
      label: getLabelForParamType(qParam.param_type)
    },
    defaultValue: qParam.default || "",
    required: true // Assuming all query params are required
  }));

  // const bodyParams = [
  //   ...(definition.required_body_params || []).map((bParam) => ({
  //     name: bParam.param_name,
  //     type: {
  //       value: bParam.param_type,
  //       label: getLabelForParamType(bParam.param_type)
  //     },
  //     defaultValue: "",
  //     required: true
  //   })),
  //   ...(definition.optional_body_params || []).map((bParam) => ({
  //     name: bParam.param_name,
  //     type: {
  //       value: bParam.param_type,
  //       label: getLabelForParamType(bParam.param_type)
  //     },
  //     defaultValue: bParam.default || "",
  //     required: false
  //   }))
  // ];

  const bodyParams = [];

  const httpMethods = definition.allowed_methods;

  return {
    name: triggerName,
    authMode: {
      value: definition.auth_type,
      label: getLabelForAuthType(definition.auth_type)
    },
    path: pathParams,
    headers,
    queryParams,
    bodyParams,
    httpMethods
  };
};

// Helper functions to map enums to labels
const getLabelForAuthType = (authType: THTTPTriggerAuthType): string => {
  return authTypes.find((opt) => opt.value === authType).label;
};

const getLabelForParamType = (paramType: TParamType): string => {
  return paramTypes.find((opt) => opt.value === paramType).label;
};

export { serializeHTTPTrigger };
