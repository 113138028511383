import React, { useState, useMemo } from "react";
import { ILog } from "@interfaces/index";

import dateService from "@services/date.service";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  Bars3Icon,
  ChevronDownIcon,
  ClipboardDocumentListIcon
} from "@heroicons/react/24/outline";
import { Badge, BadgeProps } from "@tremor/react";
import { copyToClipboard, isObject } from "@utils/helper.util";

import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import Filter from "@/app/shared/components/tanstack-table-filter.component";
import { Tooltip } from "react-tooltip";

interface ILogsChildComponentProps {
  logs: ILog[];
}

export const LevelBadgeColorMap: {
  [key in ILog["level"]]: BadgeProps["color"];
} = {
  warning: "yellow",
  error: "red",
  info: "indigo",
  debug: "slate"
};

const initialFilteringColumnState = {
  resource_id: false,
  service: false,
  function: false,
  tag: false,
  level: false,
  time: false
};

const LogsChildComponent: React.FC<ILogsChildComponentProps> = ({ logs }) => {
  const [filteringColumns, setFilteringColumns] = useState<
    Record<string, boolean>
  >(initialFilteringColumnState);

  const columns = useMemo<ColumnDef<ILog, any>[]>(
    () => [
      {
        header: "Resource ID",
        accessorKey: "resource_id",
        size: 250,
        enableSorting: false,
        filterFn: "includesString",
        cell: ({ row, getValue }) => {
          return (
            <div className="flex flex-row items-center gap-2">
              {
                <ChevronDownIcon
                  width={14}
                  className={`cursor-pointer transition-transform min-w-[14px] transform ${
                    row.getIsExpanded() ? "rotate-180" : "rotate-0"
                  }`}
                  onClick={() => row.toggleExpanded()}
                />
              }
              <span>{getValue()}</span>
              <button
                type="button"
                onClick={() => copyToClipboard(getValue())}
                className="block "
              >
                <ClipboardDocumentListIcon width={14} />
              </button>
            </div>
          );
        }
      },
      {
        header: "Service",
        accessorKey: "service",
        size: 70,
        filterFn: "includesString",
        cell: ({ getValue }) => {
          return (
            <div className="flex flex-row items-center gap-2">
              <Badge size="xs" color="indigo" className="!text-xs">
                {getValue()}
              </Badge>
            </div>
          );
        }
      },
      {
        header: "Function",
        accessorKey: "function",
        size: 100,
        filterFn: "includesString",
        cell: ({ getValue }) => {
          return (
            <div className="flex flex-row items-center flex-wrap gap-2">
              <Badge size="xs" color="indigo" className="!text-xs">
                {getValue()}
              </Badge>
            </div>
          );
        }
      },
      {
        header: "Tag",
        accessorKey: "tag",
        size: 100,
        maxSize: 100,
        filterFn: "includesString",
        cell: ({ getValue }) => {
          return (
            <div className="flex flex-row items-center flex-wrap gap-2">
              <Badge
                size="xs"
                key={getValue()}
                color="indigo"
                className="!text-xs"
              >
                {getValue()}
              </Badge>
            </div>
          );
        }
      },
      {
        header: "Level",
        accessorKey: "level",
        size: 100,
        maxSize: 100,
        filterFn: "includesString",
        cell: ({ getValue }) => {
          return (
            <div className="flex flex-row items-center flex-wrap gap-2">
              <Badge
                size="xs"
                key={getValue()}
                color={LevelBadgeColorMap[getValue().toLowerCase()]}
                className="!text-xs"
              >
                {getValue()}
              </Badge>
            </div>
          );
        }
      },
      {
        header: "Time",
        accessorKey: "time",
        size: 100,
        filterFn: "includesString",
        sortingFn: sortingFns.datetime,
        cell: (info) => {
          return (
            <div className="flex flex-row items-center gap-2">
              <span>
                {dateService.convertUTCToLocalDate(
                  info.getValue(),
                  true,
                  true
                )}
              </span>
            </div>
          );
        }
      }
    ],
    []
  );

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data: logs,
    columns,
    state: {
      expanded
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  return (
    <div className="h-full w-full logs-table">
      <table className="w-full h-full">
        <thead className="bg-gray-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: header.column.getSize()
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          className={`flex gap-2 items-center justify-center`}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <ArrowUpIcon
                                width={16}
                                className="min-w-[16px] cursor-pointer"
                                onClick={header.column.getToggleSortingHandler()}
                              />
                            ),
                            desc: (
                              <ArrowDownIcon
                                width={16}
                                className="min-w-[16px] cursor-pointer"
                                onClick={header.column.getToggleSortingHandler()}
                              />
                            )
                          }[header.column.getIsSorted() as string] || null}
                          {header.column.getCanSort() &&
                          !header.column.getIsSorted() ? (
                            <ArrowsUpDownIcon
                              width={16}
                              className="min-w-[16px] cursor-pointer"
                              onClick={header.column.getToggleSortingHandler()}
                            />
                          ) : null}
                          {header.column.getCanFilter() ? (
                            <>
                              <div
                                className="cursor-pointer"
                                data-tooltip-id={
                                  "log-table-filter-" + header.column.id
                                }
                                onClick={() =>
                                  setFilteringColumns((prev) => {
                                    return {
                                      ...prev,
                                      [header.column.id]: true
                                    };
                                  })
                                }
                              >
                                <Bars3Icon
                                  width={16}
                                  className="min-w-[16px]"
                                />
                              </div>
                              <Tooltip
                                isOpen={true}
                                id={"log-table-filter-" + header.column.id}
                                style={{
                                  zIndex: 30,
                                  display: filteringColumns[header.column.id]
                                    ? undefined
                                    : "none"
                                }}
                                openOnClick
                                variant="light"
                                border={"1px solid black"}
                                clickable
                                render={(props) => {
                                  return (
                                    <Filter
                                      column={header.column}
                                      table={table}
                                      filteringColumns={filteringColumns}
                                      setFilteringColumns={setFilteringColumns}
                                    />
                                  );
                                }}
                              />
                            </>
                          ) : null}
                        </div>
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <>
                <tr key={row.id} className="bg-white">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="mx-2 text-xs"
                        style={{
                          width: cell.column.getSize()
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {row.getIsExpanded() ? (
                  <tr>
                    <td
                      colSpan={row.getVisibleCells().length}
                      className="bg-gray-100"
                    >
                      <div className="w-full flex flex-col gap-2">
                        <DetailCellRenderer message={row.original.message} />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const generateJSXRecursively = (
  data: any,
  paddingLeft: string,
  maxDepth = 10
) => {
  if (maxDepth === 0) return <div>Max depth reached</div>;

  if (data === null || data === undefined) return null;

  return Object.keys(data).map((key) => {
    if (Array.isArray(data[key])) {
      return (
        <div className="flex flex-col gap-2" key={key}>
          <Badge color="indigo">
            {key} ({data[key].length})
          </Badge>
          <div
            className={`pl-${paddingLeft} flex flex-col gap-2 justify-center flex-wrap`}
          >
            {data[key].map((item, index) => {
              if (!item)
                return (
                  <div key={`${key}-${index}`}>
                    <span className="text-gray-500">null</span>
                  </div>
                );
              if (Array.isArray(item) || isObject(item)) {
                return generateJSXRecursively(item, paddingLeft, maxDepth--);
              }
              return (
                <div
                  className="flex flex-row gap-2 items-center"
                  key={`${key}-${index}`}
                >
                  <span className="">{item.toString()}</span>
                  {/* copy to clip board button */}
                  <button
                    type="button"
                    onClick={() => copyToClipboard(item.toString())}
                    className="block "
                  >
                    <ClipboardDocumentListIcon width={14} />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (isObject(data[key])) {
      return (
        <div className="flex flex-col gap-2" key={key}>
          <Badge color="indigo">
            {key} ({Object.keys(data[key]).length})
          </Badge>
          <div
            className={`pl-${paddingLeft} flex flex-col gap-2 justify-center flex-wrap`}
          >
            {generateJSXRecursively(data[key], paddingLeft, maxDepth--)}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row gap-2 items-center" key={key}>
          <Badge color="sky">
            {key} ({data[key] === null ? "null" : typeof data[key]})
          </Badge>
          <div className="flex gap-2 items-center">
            <span className="">{data[key]?.toString() ?? "null"}</span>
            {/* copy to clip board button */}
            <button
              type="button"
              onClick={() => copyToClipboard(data[key]?.toString())}
              className="block "
            >
              <ClipboardDocumentListIcon width={14} />
            </button>
          </div>
        </div>
      );
    }
  });
};

const DetailCellRenderer = ({ message }) => {
  const data = JSON.parse(message || "{}");

  return (
    <div className="p-3 max-h-[200px] max-w-full overflow-scroll">
      <span className="block mb-2">More Details:</span>
      <hr />
      {/* recursively go inside the data object, add padding-left when you go inside a level. */}
      <div className="flex flex-col gap-2 pl-2 mt-3">
        {data && data.log && Object.keys(data.log).length ? (
          generateJSXRecursively(data.log, "10")
        ) : (
          <div className="flex w-full h-32 text-gray-500 justify-center items-center">
            No details available
          </div>
        )}
      </div>
    </div>
  );
};

export default LogsChildComponent;
