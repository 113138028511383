import { useEffect, useState } from "react";
import { Form, Formik, Field } from "formik";
import { FieldError } from "../../shared/components";
import DeploymentSuggestionPanel from "../components/ota-create-deployment-suggestion-panel.component";
import DeploymentCreationSteps from "../components/ota-deployment-creation-steps.component";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../../store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useOTAReleaseStore from "../../../store/ota/ota-release.store";

const SetRolloutSchedule = () => {
  const navigate = useNavigate();

  const [setCreateDeploymentStepsData, createDeploymentStepsData] =
    useOTAReleaseStore((state) => [
      state.setCreateDeploymentStepsData,
      state.createDeploymentStepsData
    ]);

  const [inputFieldValues, setInputFieldValues] = useState({
    scheduledTime: createDeploymentStepsData.scheduledTime || new Date(),
    maxRetries: createDeploymentStepsData.maxRetries || "",
    retryInterval: createDeploymentStepsData.retryInterval || ""
  });
  const [inputFieldErrors, setInputFieldErrors] = useState({
    scheduledTime: "",
    maxRetries: "",
    retryInterval: ""
  });

  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);

  useEffect(() => {
    updateAuthUser({ deploymentCreationStatus: "1" });
  }, [updateAuthUser]);

  const validate = () => {
    const errors = { ...inputFieldErrors };

    // Max retries validation
    if (!inputFieldValues.maxRetries) {
      errors.maxRetries = "Max retries is required.";
    } else if (inputFieldValues.maxRetries && errors.maxRetries) {
      errors.maxRetries = "";
    }

    // retry interval validation
    if (!inputFieldValues.retryInterval) {
      errors.retryInterval = "Retry interval is required.";
    } else if (inputFieldValues.retryInterval && errors.retryInterval) {
      errors.retryInterval = "";
    }

    if (errors.maxRetries || errors.retryInterval) {
      setInputFieldErrors({ ...errors });
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validate()) return;

    setCreateDeploymentStepsData({
      ...createDeploymentStepsData,
      ...inputFieldValues
    });

    navigate(
      "/ota-updates/projects/deployment/create-deployment/deploy-details"
    );
  };

  const handleFieldChange = (e: any, id: string) => {
    const payload = { ...inputFieldValues };

    if (id === "scheduledTime") {
      payload[id] = e;
    } else {
      payload[id] = `${e.target.value}`;
    }

    setInputFieldValues({ ...payload });
  };

  return (
    <>
      <>
        <div className="flex w-full h-full">
          <div className="w-5/12  pb-8">
            <div className="mt-7 mb-5 w-10/12">
              <DeploymentCreationSteps />
            </div>
            <Formik
              initialValues={{ release: "", maxRetries: "" }}
              onSubmit={handleSubmit}
            >
              <Form className="flex flex-col flex-1">
                <div>
                  <h1 className="text-lg text-left font-medium mb-2.5">
                    Select target software and device
                  </h1>

                  <div className="flex-1">
                    <div className="mb-5">
                      <label className="text-sm font-medium text-gray-600">
                        Schedule Time
                      </label>

                      <DatePicker
                        className="block w-10/12 p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                        onChange={(e) => handleFieldChange(e, "scheduledTime")}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        selected={inputFieldValues.scheduledTime}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                      {inputFieldErrors.scheduledTime ? (
                        <FieldError message={inputFieldErrors.scheduledTime} />
                      ) : null}
                    </div>

                    <div className="mb-5">
                      <label className="flex font-medium text-sm mb-2">
                        Max Retries
                      </label>

                      <Field
                        type="text"
                        id="maxRetries"
                        name="maxRetries"
                        placeholder="Max Retries"
                        value={inputFieldValues.maxRetries}
                        onChange={(e) => handleFieldChange(e, "maxRetries")}
                        className="block w-10/12 p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                      />
                      {inputFieldErrors.maxRetries ? (
                        <FieldError message={inputFieldErrors.maxRetries} />
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="mb-5">
                      <label className="flex font-medium text-sm mb-2">
                        Retry Interval
                      </label>

                      <Field
                        type="text"
                        id="retryInterval"
                        name="retryInterval"
                        placeholder="Retry Interval (mins)"
                        value={inputFieldValues.retryInterval}
                        onChange={(e) => handleFieldChange(e, "retryInterval")}
                        className="block w-10/12 p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                      />
                      {inputFieldErrors.retryInterval ? (
                        <FieldError message={inputFieldErrors.maxRetries} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="w-10/12  flex items-center mt-12 space-x-4">
                  <Link
                    to="/ota-updates/projects/deployment/create-deployment/software-and-device"
                    className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
                  >
                    Back
                  </Link>

                  <button
                    type="submit"
                    className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                  >
                    Next
                  </button>
                </div>
              </Form>
            </Formik>
          </div>

          <div className="w-7/12">
            <DeploymentSuggestionPanel />
          </div>
        </div>
      </>
    </>
  );
};

export default SetRolloutSchedule;
