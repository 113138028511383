import { useEffect, useMemo, useState } from "react";
import useRuleEngineStore, {
  MQTTDefDirection
} from "@store/rule-engine/rule-engine.store";
import Select from "react-select";
import {
  isObject,
  reactSelectClassNames,
  shadowFieldsGenerator
} from "@app/shared/utils/helper.util";
import { IOption } from "@interfaces/shared.interface";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import { useGetFleets } from "@/app/shared/hooks/get/fleets";
import { useGetDataPointDefinitions } from "@/app/shared/hooks/get";
import { Button } from "@tremor/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const dataKindOptions: IOption[] = [
  { label: "Device Shadow", value: "device-shadow" },
  { label: "Device Data", value: "device-data" }
];

const directionOptions: IOption[] = [
  { label: "Any", value: "any" },
  { label: "Ingress", value: "ingress" },
  { label: "Egress", value: "egress" }
];

const filterByOptions: IOption[] = [
  { label: "Devices", value: "devices" },
  { label: "Fleets", value: "fleets" }
];

const MQTTTrigger = ({ ruleName }) => {
  const [rules, ruleData, localRulesData, setRules, setLocalRuleData] =
    useRuleEngineStore((state) => [
      state.rules,
      state.ruleData,
      state.localRulesData,
      state.setRules,
      state.setLocalRuleData
    ]);

  const rule = rules[ruleName];

  const [dataKind, setDataKind] = useState(
    dataKindOptions.find(
      (dOpt) => dOpt.value === (rule.definition?.data_kind ?? "device-shadow")
    )
  );

  const [direction, setDirection] = useState(
    directionOptions.find(
      (dOpt) => dOpt.value === (rule.definition?.direction ?? "any")
    )
  );

  const [lastFetchedPage, setLastFetchedPage] = useState(1);
  const [selectedDevices, setSelectedDevices] = useState<IOption[]>(undefined);
  const [filterBy] = useState<IOption>(filterByOptions[0]);
  const [selectedFleet, setSelectedFleet] = useState<IOption>();
  const [selectedDpd, setSelectedDpd] = useState<IOption>();

  const { data: devicesResponse, isLoading: isDevicesLoading } = useGetDevices(
    { page: lastFetchedPage }
  );
  const {
    data: alreadySelectedDevicesResponse,
    isLoading: isAlreadySelectedDevicesLoading
  } = useGetDevices({
    page: lastFetchedPage,
    device_id:
      selectedDevices === undefined ? rule.definition?.devices?.join(",") : ""
  });
  const { data: fleets } = useGetFleets({
    fields: "slug"
  });
  const { data: dpd } = useGetDataPointDefinitions({
    fields: "data_point_proto_structure"
  });

  const deviceOptions: Record<string, IOption> = useMemo(
    () =>
      devicesResponse?.devices?.reduce((acc, cur) => {
        acc[cur.id] = {
          label: cur.device_name,
          value: cur.id
        };
        return acc;
      }, {}) || {},
    [devicesResponse]
  );

  const fleetOptions = useMemo(
    () => fleets?.map((f) => ({ label: f.fleet_name, value: f.slug })) || [],
    [fleets]
  );

  const dpdOptions = useMemo(
    () => dpd?.map((_dpd) => ({ label: _dpd.name, value: _dpd.name })) || [],
    [dpd]
  );

  useEffect(() => {
    setRules((rules) => {
      const _rules = { ...rules };

      _rules[ruleName] = {
        ..._rules[ruleName],
        definition: {
          ..._rules[ruleName].definition,
          direction: direction.value as MQTTDefDirection,
          data_kind: dataKind.value,
          data_point_name: selectedDpd?.value
        }
      };

      return _rules;
    });

    return () => {};
  }, [
    direction,
    ruleName,
    setRules,
    dataKind.value,
    selectedDpd?.value,
    selectedFleet?.value,
    selectedDevices,
    filterBy.value
  ]);

  useEffect(() => {
    if (
      rule.definition?.devices?.length &&
      alreadySelectedDevicesResponse?.devices?.length
    ) {
      setSelectedDevices(
        alreadySelectedDevicesResponse?.devices?.map((dev) => ({
          label: dev.device_name,
          value: dev.id
        }))
      );
    }

    return () => {};
  }, [alreadySelectedDevicesResponse, rule.definition?.devices?.length]);

  useEffect(() => {
    if (dpdOptions?.length && rule.definition?.data_point_name) {
      setSelectedDpd(
        dpdOptions.find(
          (_dpd) => _dpd.value === rule.definition?.data_point_name
        )
      );
    }

    return () => {};
  }, [dpdOptions, rule.definition?.data_point_name]);

  useEffect(() => {
    if (fleetOptions?.length && rule.definition?.slug) {
      setSelectedFleet(
        fleetOptions.find((opt) => opt.value === rule.definition?.slug)
      );
    }

    return () => {};
  }, [fleetOptions, rule.definition?.slug]);

  const onDataPointChange = (opt: IOption) => {
    setSelectedDpd(opt);

    const _dpd = dpd.find((dataPoint) => dataPoint.name === opt.value);

    const fields = shadowFieldsGenerator(_dpd.data_point_proto_structure);

    const inputs = [...ruleData.inputs];
    const validTypes = ["string", "bool", "number"];
    fields.forEach((field) => {
      if (validTypes.includes(field.structure) || isObject(field.structure)) {
        if (!inputs.find((i) => i.key === field.name)) {
          inputs.push({
            key: field.name,
            type: isObject(field.structure) ? "struct" : field.structure
          });
        }
      } else {
        console.log("Unknown data point field type: " + field.structure);
      }
    });

    if (localRulesData && localRulesData[ruleName]) {
      setLocalRuleData(ruleName, {
        ...localRulesData[ruleName],
        ruleData: { ...localRulesData[ruleName].ruleData, inputs }
      });
    } else {
      setRules((rules) => {
        const _rules = { ...rules };

        _rules[ruleName] = {
          ..._rules[ruleName],
          ruleData: {
            ..._rules[ruleName].ruleData,
            inputs
          }
        };

        return _rules;
      });
    }
  };
  return (
    <>
      <hr className="border-background-layer3 mt-2" />

      <div className="flex flex-col mt-2 nodrag">
        <label className="text-base font-medium text-contentColor">
          Data Kind
        </label>
        <Select
          placeholder="Data Kind"
          isSearchable={false}
          options={dataKindOptions}
          value={dataKind}
          onChange={(val: IOption) => setDataKind(val)}
          classNames={reactSelectClassNames}
        />
      </div>

      {dataKind.value === "device-shadow" ? (
        <div className="flex flex-col mt-2 nodrag">
          <label className="text-base font-medium text-contentColor">
            Direction
          </label>
          <Select
            placeholder="Direction"
            isSearchable={false}
            options={directionOptions}
            value={direction}
            onChange={(val: IOption) => setDirection(val)}
            classNames={reactSelectClassNames}
          />
        </div>
      ) : null}

      {dataKind.value === "device-data" ? (
        <div className="flex flex-col mt-2 nodrag">
          <label className="text-base font-medium text-contentColor">
            Data Point Definition
          </label>
          <Select
            placeholder="Data Point Definition"
            isSearchable={false}
            options={dpdOptions}
            value={selectedDpd}
            onChange={onDataPointChange}
            classNames={reactSelectClassNames}
            menuPortalTarget={
              document.getElementsByClassName(
                "reactflow-wrapper"
              )[0] as HTMLElement
            }
            menuPosition="fixed"
          />
        </div>
      ) : null}

      {/* {dataKind.value === "device-data" ? (
    <div className="flex flex-col mt-2 nodrag">
      <label className="text-base font-medium text-contentColor">
        Filter By
      </label>
      <Select
        placeholder="Filter By"
        isSearchable={false}
        options={filterByOptions}
        value={filterBy}
        onChange={(val: IOption) => setFilterBy(val)}
        classNames={reactSelectClassNames}
      />
    </div>
  ) : null} */}

      {dataKind.value === "device-data" && filterBy.value === "fleets" ? (
        <div className="flex flex-col mt-2 nodrag">
          <label className="text-base font-medium text-contentColor">
            Fleet
          </label>
          <Select
            placeholder="Fleet"
            isSearchable={false}
            options={fleetOptions}
            value={selectedFleet}
            onChange={(val: IOption) => setSelectedFleet(val)}
            classNames={reactSelectClassNames}
          />
        </div>
      ) : null}
      {dataKind.value === "device-shadow" || filterBy.value === "devices" ? (
        <div className="flex flex-col mt-2 nodrag">
          <label className="text-base font-medium text-contentColor">
            Devices
          </label>
          <div className="flex gap-2">
            <Select
              placeholder="Devices"
              isSearchable={true}
              isLoading={isDevicesLoading || isAlreadySelectedDevicesLoading}
              isDisabled={isDevicesLoading || isAlreadySelectedDevicesLoading}
              options={Object.values(deviceOptions)}
              value={selectedDevices}
              onChange={(val: IOption[]) => {
                setRules((rules) => {
                  const _rules = { ...rules };

                  if (filterBy.value === "devices") {
                    _rules[ruleName] = {
                      ..._rules[ruleName],
                      definition: {
                        ..._rules[ruleName].definition,
                        devices: val?.map((d) => d.value)
                      }
                    };
                  } else {
                    _rules[ruleName] = {
                      ..._rules[ruleName],
                      definition: {
                        ..._rules[ruleName].definition,
                        slug: selectedFleet?.value
                      }
                    };
                  }

                  return _rules;
                });

                setSelectedDevices(val);
              }}
              className="min-w-[150px] max-w-sm"
              classNames={reactSelectClassNames}
              isMulti
            />
            <Button
              disabled={lastFetchedPage >= devicesResponse?.meta?.totalPages}
              variant="light"
              loading={isDevicesLoading}
              tooltip="Load more devices"
              icon={ArrowPathIcon}
              onClick={() => {
                setLastFetchedPage((prev) => prev + 1);
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MQTTTrigger;
