import React, { useMemo } from "react";
import { FieldInfo } from "@/app/shared/utils/proto-helper.util";
import { Button } from "@tremor/react";
import { Field, Form, Formik } from "formik";
import { NumberInput } from "@tremor/react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import Pagination from "@/app/shared/components/pagination.component";

interface Props {
  fieldInfos: FieldInfo[];
  onSubmit: (values: Record<string, number>) => void;
}

const typeToObj = {
  string: { nanoPbOption: "characters", color: "text-orange-500" },
  array: { nanoPbOption: "items", color: "text-blue-500" },
  bytes: { nanoPbOption: "bytes", color: "text-yellow-500" }
};
const ProtoLimitsForm: React.FC<Props> = ({ fieldInfos, onSubmit }) => {
  const columns = useMemo<ColumnDef<FieldInfo, any>[]>(
    () => [
      {
        id: "line",
        accessorKey: "line",
        header: "Line",
        size: 10,
        maxSize: 10,
        cell: ({ getValue, row }) => (
          <span className="">{row.original.line}</span>
        )
      },
      {
        id: "name",
        accessorKey: "name",
        header: "Field",
        size: 60,
        maxSize: 60,
        cell: ({ getValue, row }) => (
          <span className="font-bold">{getValue()}</span>
        )
      },
      {
        id: "type",
        accessorKey: "type",
        header: "Type",
        size: 80,
        cell: ({ getValue, row }) => (
          <span className={typeToObj[getValue()].color}>{getValue()}</span>
        )
      },
      {
        id: "limit",
        accessorKey: "limit",
        header: "Limit",
        cell: ({ getValue, row }) => {
          return (
            <div className="flex justify-center">
              <Field
                id={`${row.original.name}-${row.original.type}-${row.original.line}`}
                name={`${row.original.name}-${row.original.type}-${row.original.line}`}
                type={"number"}
                component={({ field, form, ...props }) => (
                  <NumberInput
                    {...field}
                    {...props}
                    value={field.value}
                    onValueChange={(val: number) =>
                      form.setFieldValue(
                        `${row.original.name}-${row.original.type}-${row.original.line}`,
                        val
                      )
                    }
                  />
                )}
                defaultValue={10}
                min={0}
                className="w-20 h-8"
              />
            </div>
          );
        },
        size: 80
      },
      {
        id: "suffix",
        accessorKey: "type",
        header: "",
        size: 80,
        cell: ({ getValue }) => typeToObj[getValue()].nanoPbOption
      }
    ],
    []
  );

  const table = useReactTable({
    data: fieldInfos,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });
  return (
    <div>
      <Formik
        initialValues={fieldInfos.reduce((acc, cur) => {
          acc[`${cur.name}-${cur.type}-${cur.line}`] = 10;
          return acc;
        }, {})}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        <Form className="flex flex-col">
          <div className="max-h-[55vh] overflow-y-auto">
            <table className="w-full h-full my-6">
              <thead className="">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            width: `${header.getSize()}px`
                          }}
                          className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler()
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </div>
                              {header.column.getCanFilter() ? (
                                <div>{""}</div>
                              ) : null}
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <>
                      <tr
                        key={row.id}
                        className="bg-background-layer1 border-b border-background-layer3"
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className={`mx-2 text-sm text-center p-2 py-3`}
                              style={{
                                width: cell.column.getSize(),
                                maxWidth: cell.column.getSize()
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            {fieldInfos.length > 10 ? (
              <Pagination<FieldInfo> table={table} />
            ) : null}
          </div>
          <Button type="submit" className="ml-auto">
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default ProtoLimitsForm;
