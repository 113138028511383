import { useMutation, useQueryClient } from "react-query";
import networkService from "@services/network.service";
import { ICreateOrg } from "@interfaces/shared.interface";

export interface ICreateOrgPayload {
  org_name: string;
  type: string;
  website_link?: string;
  email: string;
  firstname_user: string;
  lastname_user: string;
}

export const createOrg = async (payload: ICreateOrgPayload) => {
  const { data, ok } = await networkService.post<ICreateOrg>(
    `organizations`,
    payload
  );

  if (ok) {
    return data.orgId;
  } else return null;
};

export const useCreateOrg = () => {
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateOrgPayload) => await createOrg(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getOrganizations");
      }
    }
  );

  return res;
};
