import { VariableInput } from "@/app/rule-engine/variable-input.component";
import {
  ArrowPathRoundedSquareIcon,
  ExclamationTriangleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import React, { useState } from "react";

export interface IFCMMessage {
  token: string;
  topic: string;
  data: Array<{ key: string; value: string }>;
  title: string;
  body: string;
}

interface IFCMMessageEditorProps {
  message: IFCMMessage;
  setMessage: (x: IFCMMessage | ((x: IFCMMessage) => IFCMMessage)) => void;
}

const FCMMessageEditor: React.FC<IFCMMessageEditorProps> = ({
  message,
  setMessage
}) => {
  const [currentSelected, setCurrentSelected] = useState<"token" | "topic">(
    "token"
  );
  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex flex-row gap-1 content-stretch w-full nodrag mt-2">
        <VariableInput
          type="text"
          placeholder={currentSelected === "token" ? "Token" : "Topic"}
          value={message[currentSelected]}
          className="nodrag bg-background w-full px-2 py-1 text-sm text-contentColor border-background-layer3 rounded-sm border-2"
          onChange={(val: string) =>
            setMessage({ ...message, [currentSelected]: val })
          }
        />

        <Button
          icon={ArrowPathRoundedSquareIcon}
          onClick={() => {
            setMessage({ ...message, [currentSelected]: "" });
            setCurrentSelected(
              currentSelected === "token" ? "topic" : "token"
            );
          }}
          tooltip={currentSelected === "token" ? "Use Topic" : "Use Token"}
          variant="secondary"
          size="xs"
        />
      </div>
      {!message[currentSelected].trim().length ? (
        <p className="text-red-500 flex gap-1 text-xs">
          <ExclamationTriangleIcon width={14} />{" "}
          {currentSelected === "token" ? "Token" : "Topic"} is required.
        </p>
      ) : null}
      <div className="flex flex-col gap-1 w-full nodrag mt-2">
        <label className="flex flex-col gap-1">
          Title
          <VariableInput
            type="text"
            placeholder={"Send hello world text"}
            value={message.title}
            onChange={(val: string) => setMessage({ ...message, title: val })}
          />
          {!message.title.trim().length ? (
            <p className="text-red-500 flex gap-1 text-xs">
              <ExclamationTriangleIcon width={14} /> Title is required.
            </p>
          ) : null}
        </label>
      </div>
      <div className="flex flex-col gap-1 w-full nodrag mt-2">
        <label className="flex flex-col gap-1">
          Body
          <VariableInput
            as="input"
            type="text"
            placeholder={"Hello World!"}
            value={message.body}
            onChange={(val: string) => setMessage({ ...message, body: val })}
          />
          {!message.body.trim().length ? (
            <p className="text-red-500 flex gap-1 text-xs">
              <ExclamationTriangleIcon width={14} /> Body is required.
            </p>
          ) : null}
        </label>
      </div>
      <div className="flex flex-col gap-1 w-full nodrag mt-2">
        <h3 className="text-base">Data:</h3>
        {message.data?.map((header, ind) => {
          return (
            <div className="flex gap-2 items-center" key={ind}>
              <input
                placeholder="Key"
                className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
                value={header.key}
                onChange={(e) => {
                  const _data = [...message.data];
                  const _dataItem = {
                    ...message.data[ind],
                    key: e.target.value
                  };
                  _data[ind] = _dataItem;
                  setMessage({ ...message, data: _data });
                }}
              />
              <input
                placeholder="Value"
                className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
                value={header.value}
                onChange={(e) => {
                  const _data = [...message.data];
                  const _dataItem = {
                    ...message.data[ind],
                    value: e.target.value
                  };
                  _data[ind] = _dataItem;
                  setMessage({ ...message, data: _data });
                }}
              />
              <button
                className="bg-red-500 text-white p-1 rounded-sm"
                onClick={() => {
                  const _data = [...message.data];
                  _data.splice(ind, 1);
                  setMessage({ ...message, data: _data });
                }}
              >
                <XMarkIcon width={16} />
              </button>
            </div>
          );
        })}
        <button
          disabled={message.data?.some((header) => !header.key?.trim())}
          className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm disabled:opacity-50"
          onClick={() => {
            const _data = [...message.data];
            _data.push({ key: "", value: "" });
            setMessage({ ...message, data: _data });
          }}
        >
          Add Data
        </button>
      </div>
    </div>
  );
};

export default FCMMessageEditor;
