import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IGaugeDefination,
  ILineDefination,
  IPieDefinations
} from "../../interfaces";
import { useDashboardStore, useFleetAndDevicesStore } from "../../store";
import DashboardUtilsHeader from "./components/dash-filter-header.component";
import PanelWrapper from "./dash-wrapper-panel.component";
import CarouselWrapper from "./dash-carousel-warpper.component";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { CoreProps, Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { GRID_COLS, generateLayout } from "./dash.helper";
import { useGetDashboardPanels } from "@app/shared/hooks/get/dashboard-panels";
import ShowLoading from "@app/shared/components/loading.component";
import ShowError from "@app/shared/components/error.component";
import { Badge, Color } from "@tremor/react";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useGetDevicePanels } from "../shared/hooks/get/device-panels";
import { TDashPanel } from "@/store/dashboard/dashboard.store";
import { IServerQuery } from "../query-builder/query-builder.helper";
import { DASH_PANEL_TYPE } from "@/interfaces/dashboard-panel.interface";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface IDashboardDetails {
  deviceId?: string;
  onAddPanel?: () => void;
  dashboardConfigs?: {
    hideAddPanel?: boolean;
    hideDelete?: boolean;
    hideEditLayout?: boolean;
  };
}

const Details: React.FC<IDashboardDetails> = ({
  deviceId,
  onAddPanel,
  dashboardConfigs
}) => {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const panelErrors = useRef({});
  const errorToastTimer = useRef(null);

  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const [editDashboard, savedLayouts] = useDashboardStore((state) => [
    state.editingLayout,
    state.layouts
  ]);

  const [
    dashboards,
    panels,
    carousels,
    activeDashboard,
    setPanels,
    setCarousels,
    clearCreatePanelState,
    zoomLevel
  ] = useDashboardStore((state) => [
    state.dashboards,
    state.panels,
    state.carousels,
    state.activeDashboard,
    state.setPanels,
    state.setCarousels,
    state.clearCreatePanelState,
    state.zoomLevel
  ]);

  const sortPanels = (obj: any) => {
    const sortedObj = {};
    const rowKeys = Object.keys(obj);

    if (rowKeys.length) {
      rowKeys.forEach((row) => {
        const sortedPanels = obj[row].sort(
          (a, b) => a.definition.panel_position - b.definition.panel_position
        );

        sortedObj[row] = sortedPanels;
      });
    }
    return { ...sortedObj };
  };

  // if rendered in project level dashbaord, fetch panels from that dashboard
  const { isLoading: isDashPanelsLoading, error } = useGetDashboardPanels(
    deviceId ? "" : activeDashboard?.id,
    {},
    (dashboardPanels) => {
      if (deviceId) return;
      if (dashboardPanels?.length) {
        let carouselObj = {};
        let panelsArr = [];
        dashboardPanels.forEach((panel) => {
          if (panel.definition.arrangement) {
            if (panel.definition.arrangement.carousel in carouselObj) {
              carouselObj[panel.definition.arrangement.carousel].push(panel);
            } else {
              carouselObj[panel.definition.arrangement.carousel] = [panel];
            }
          } else {
            panelsArr.push(panel);
          }
        });
        if (!isEmpty(carouselObj)) {
          setCarousels(sortPanels(carouselObj));
        } else {
          setCarousels({});
        }
        if (panelsArr.length) {
          const newState = {
            currentBreakpoint: "lg",
            compactType: "vertical",
            resizeHandles: ["se"],
            mounted: true,
            // generate layouts according to the current breakpoint,
            // and merge them with the saved layouts
            layouts: {
              ...Object.keys(GRID_COLS).reduce((acc, cur) => {
                acc[cur] = generateLayout(panelsArr, GRID_COLS[cur], ["se"]);
                return acc;
              }, {}),
              ...savedLayouts
            }
          };
          setLayoutState(newState);
          setPanels(panelsArr);
        }
      } else {
        setPanels([]);
      }
    }
  );

  // if rendered inside a device, fetch device panels
  const { isLoading: isDevicePanelsLoading } = useGetDevicePanels(
    selectedFleet.id,
    deviceId,
    {},
    (blueprintPanels) => {
      if (!blueprintPanels) setPanels([]);
      const panelsArr: TDashPanel[] = [];

      // convert blueprint panels to standard panels
      blueprintPanels.forEach((bp) => {
        const panel: TDashPanel = {
          id: "",
          blueprint_id: bp.id,
          title: bp.definition.title,
          panel_description: bp.name,
          panel_type: bp.definition.type,
          definition: JSON.parse(bp.definition.panel_definition),
          data_config: JSON.parse(bp.definition.data_config) as IServerQuery,
          dashboard_id: "",
          project_id: bp.project_id,
          org_id: "",
          created_at: bp.created_at
        };
        panelsArr.push(panel);
      });

      setPanels(panelsArr);
    }
  );

  const { data: fleets } = useGetFleets();

  useEffect(() => {
    if (activeDashboard.id) {
      clearCreatePanelState();
    }
  }, [activeDashboard.id, clearCreatePanelState]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.style.zoom = `${zoomLevel}%`;
  }, [zoomLevel]);

  useEffect(() => {
    let timer = errorToastTimer.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const renderCarouselPanels = useCallback(() => {
    const carouselKeys = Object.keys(carousels);
    return carouselKeys.map((carousel) => (
      <CarouselWrapper title={carousel} panels={carousels[carousel]} />
    ));
  }, [carousels]);

  const handleAddPanel = () => {
    if (deviceId) {
      onAddPanel && onAddPanel();
      return;
    }

    if (fleets.length > 0) {
      navigate("/dashboard/new-panel-type");
    } else {
      toast.error(
        "Please add at least one Fleet before creating a new panel!"
      );
    }
  };

  const handlePanelError = (
    panelId: string,
    panelTitle: string,
    error: string
  ) => {
    panelErrors.current[panelId] = { error, panelTitle };

    // batch the errors into one toast to avoid spamming the user with toasts
    if (Object.keys(panelErrors.current).length === 1) {
      errorToastTimer.current = setTimeout(() => {
        if (Object.keys(panelErrors.current).length)
          toast.error(
            <div>
              <span className="text-sm font-bold mb-1 block">
                There were some errors fetching the data of following panels:{" "}
              </span>
              <div className="flex flex-col">
                {Object.keys(panelErrors.current).map((panelId) => (
                  <div className="flex items-center" key={panelId}>
                    <span className="text-xs font-medium whitespace-nowrap  ">
                      {panelErrors.current[panelId].panelTitle}:
                    </span>
                    <span className="text-xs ml-2">
                      {panelErrors.current[panelId].error}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          );
        panelErrors.current = {};
        clearTimeout(errorToastTimer.current);
      }, 500);
    }
  };

  const [layoutState, setLayoutState] = useState({
    currentBreakpoint: "lg",
    compactType: "vertical",
    resizeHandles: ["se"],
    mounted: false,
    layouts: {
      ...Object.keys(GRID_COLS).reduce((acc, cur) => {
        acc[cur] = generateLayout(panels, GRID_COLS[cur], ["se"]);
        return acc;
      }, {}),
      ...savedLayouts
    }
  });

  const onBreakpointChange: (Breakpoint) => void = (breakpoint) => {
    setLayoutState((curState) => ({
      ...curState,
      currentBreakpoint: breakpoint
    }));
  };

  const memoizedPanels = useMemo(() => {
    if (!layoutState.layouts[layoutState.currentBreakpoint]?.length) return;
    const normalPanels = panels.map(
      (panel: ILineDefination | IPieDefinations | IGaugeDefination) => (
        <div
          key={panel.id || panel.blueprint_id}
          data-grid={layoutState.layouts[layoutState.currentBreakpoint].find(
            (el) => el.i === panel.id || panel.blueprint_id
          )}
          className={`${editDashboard ? "select-none" : ""} ${
            !editDashboard && "hide-resize"
          }`}
        >
          <PanelWrapper
            panel={panel}
            deviceId={deviceId}
            key={panel.id || panel.blueprint_id}
            handlePanelError={handlePanelError}
          />
        </div>
      )
    );

    const carouselPanels =
      renderCarouselPanels()?.map((element, ind) => (
        <div
          key={"carousel" + ind}
          data-grid={layoutState.layouts[layoutState.currentBreakpoint].find(
            (el) => el.i === "carousel" + ind
          )}
          className={`${editDashboard ? "select-none" : ""} ${
            !editDashboard && "hide-resize"
          }`}
        >
          {element}
        </div>
      )) || [];

    return [...normalPanels, ...carouselPanels];
  }, [
    layoutState.layouts,
    layoutState.currentBreakpoint,
    panels,
    renderCarouselPanels,
    editDashboard,
    deviceId
  ]);

  // need to do this because for some reason RGL calls the onLayoutChange callback with
  // a layout object that arranges all the panels into a single column
  useEffect(
    () => {
      setLayoutState((curState) => ({
        ...curState,
        layouts: {
          ...Object.keys(GRID_COLS).reduce((acc, cur) => {
            acc[cur] = generateLayout(panels, GRID_COLS[cur], ["se"]);
            return acc;
          }, {}),
          ...savedLayouts
        }
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDashboard.id]
  );
  if (isDashPanelsLoading || isDevicePanelsLoading) {
    return <ShowLoading />;
  }

  if (error) {
    return <ShowError />;
  }

  if (!deviceId && !dashboards.length) {
    return null;
  }

  return (
    <main className="w-full h-auto py-4 overflow-y-auto lg:px-6 sm:px-4">
      <DashboardUtilsHeader
        hideAddPanel={dashboardConfigs?.hideAddPanel}
        hideDelete={dashboardConfigs?.hideDelete}
        hideEditLayout={dashboardConfigs?.hideEditLayout}
        addPanel={handleAddPanel}
        layouts={layoutState.layouts}
        setLayouts={setLayoutState}
        panels={panels}
      />
      <div ref={wrapperRef} className="w-full py-4">
        {/* {!isEmpty(carousels) && renderCarouselPanels()} */}
        <div className="flex flex-wrap gap-4 w-full h-full">
          {memoizedPanels?.length ? (
            <ResponsiveGridLayout
              className="layout w-full"
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={GRID_COLS}
              rowHeight={260}
              width={1200}
              isDraggable={editDashboard}
              isResizable={editDashboard}
              layouts={layoutState.layouts}
              onBreakpointChange={onBreakpointChange}
              measureBeforeMount={false}
              useCSSTransforms={layoutState.mounted}
              compactType={layoutState.compactType as CoreProps["compactType"]}
              preventCollision={!layoutState.compactType}
              onLayoutChange={(layout, layouts) => {
                if (!layout.length) return;

                // save the current layout to all the breakpoints, let the library handle the arrangement
                const newLayouts = Object.keys(GRID_COLS).reduce(
                  (acc, cur) => {
                    if (!layout.length) return acc;
                    acc[cur] = layout;
                    return acc;
                  },
                  {}
                );
                if (Object.keys(newLayouts).length) {
                  setLayoutState((curState) => ({
                    ...curState,
                    layouts: newLayouts
                  }));
                }
              }}
            >
              {memoizedPanels}
            </ResponsiveGridLayout>
          ) : !dashboardConfigs?.hideAddPanel ? (
            <div className="w-full h-[60vh] flex items-center justify-center">
              <div
                onClick={handleAddPanel}
                className="h-[300px] gap-2 cursor-pointer w-[300px] text-primary flex items-center justify-center border-dashed border-2 border-primary rounded-lg"
              >
                <PlusIcon width={20} /> Create a new panel!
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Tooltip
        id="panel-error-tooltip"
        className="border border-red-500"
        style={{
          zIndex: 100000,
          backgroundColor: "#F87171",
          color: "#fff"
        }}
      />
      <Tooltip
        id={`tooltip-badge-hover`}
        className="z-20 transform"
        variant="light"
        border={"1px solid black"}
        render={({ activeAnchor }) => {
          const labels = activeAnchor
            ?.getAttribute("data-tooltip-labels")
            ?.split(",");
          const values = activeAnchor
            ?.getAttribute("data-tooltip-values")
            ?.split(",");
          const colorsProp = activeAnchor?.getAttribute("data-tooltip-colors");
          const colors = colorsProp ? JSON.parse(colorsProp) : {};

          return (
            <div className="flex flex-col gap-1">
              {labels?.map((label, i) => (
                <Badge
                  size="xs"
                  key={i}
                  color={colors[label] as Color}
                  className={`text-xs`}
                >
                  <span style={{ fontSize: "0.2rem !important" }}>
                    {label}: {values[i]}
                  </span>
                </Badge>
              ))}
            </div>
          );
        }}
      />
    </main>
  );
};

export default Details;
