import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { ICarousel } from "../../../interfaces";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "@tremor/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

const Carousel: React.FC<ICarousel> = ({
  header,
  childPerPage,
  childCount,
  children
}) => {
  const sliderRef = useRef<Slider>();
  const [page, setPage] = useState(0);

  const maxPages = Math.ceil(childCount / childPerPage);
  const liArray = new Array(maxPages).fill(0);

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: childPerPage,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    adaptiveHeight: true
  };

  const handlePage = (pgType: "NEXT" | "PREV") => {
    if (pgType === "NEXT") {
      if (page < maxPages - 1) {
        setPage(page + 1);
        sliderRef?.current?.slickNext();
      } else {
        setPage(0);
        sliderRef?.current?.slickGoTo(0);
      }
    } else {
      if (page > 0) {
        setPage(page - 1);
        sliderRef?.current?.slickPrev();
      } else {
        setPage(maxPages - 1);
        sliderRef.current?.slickGoTo(maxPages - 1);
      }
    }
  };

  return (
    <div className="w-full h-full my-2 py-2">
      <div className="w-full flex flex-row items-center justify-between">
        <h4 className="text-lg font-medium">{header}</h4>
        <div className="flex flex-row items-center justify-start">
          <Button
            // disabled={page === 0}
            icon={ArrowLeftIcon}
            className="text-contentColorLight"
            variant="light"
            onClick={() => handlePage("PREV")}
          />
          <ul className="flex flex-row">
            {liArray.map((l, index) => (
              <li
                key={index}
                className={`block mx-[5px] w-[9px] h-[9px] rounded-lg transition-colors ${
                  page === index ? "w-[20px] bg-primary" : "bg-[#D9D9D9]"
                }`}
              ></li>
            ))}
          </ul>
          <Button
            // disabled={page === childCount - 1}
            icon={ArrowRightIcon}
            className="text-contentColorLight"
            variant="light"
            onClick={() => handlePage("NEXT")}
          />
        </div>
      </div>
      <Slider ref={sliderRef} adaptiveHeight className="h-full" {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default Carousel;
