import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import {
  IPendingOTAUpdate,
  IPendingOTAUpdatesResponse
} from "@interfaces/ota.interface";

export const getPendingReleaseUpdates = async (
  deploymentId,
  projectId,
  orgId,
  params
) => {
  const { data, ok } = await networkService.get<IPendingOTAUpdatesResponse>(
    `projects/${projectId}/ota_release_deployments/${deploymentId}/pending-ota-updates`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useGetPendingReleaseUpdates = (
  deploymentId: string,
  params: any = {},
  successCb: ((data: IPendingOTAUpdate[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getPendingReleaseUpdates",
      deploymentId,
      selectedProject.id,
      user.selectedOrg.id,
      params
    ],
    async () => {
      const pendingUpdates = await getPendingReleaseUpdates(
        deploymentId,
        selectedProject.id,
        user.selectedOrg.id,
        params
      );
      successCb && successCb(pendingUpdates);

      return pendingUpdates;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id && !!deploymentId
    }
  );

  return res;
};
