import React, { useId, useState } from "react";
import { TOrderBy, ORDER_BY_OPTIONS, Order } from "@interfaces/query-builder";
import { Button } from "@tremor/react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import ReactSelect from "react-select";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";

interface OrderByClauseProps {
  orderBy: TOrderBy;
  selectedDPDStructure: any;
  onChangeHandler: (orderBy: TOrderBy) => void;
}

const OrderByClause: React.FC<OrderByClauseProps> = ({
  orderBy,
  selectedDPDStructure,
  onChangeHandler
}) => {
  const [hoveredOrder, setHoveredOrder] = useState(null);

  const randomId = useId();

  const onHover = (ind) => {
    setHoveredOrder(ind);
  };

  const onMouseLeave = () => {
    setHoveredOrder(null);
  };

  return (
    <div className="flex flex-col gap-4 border bg-background-layer1 border-background-layer3 p-4 mb-4 ml-12">
      {orderBy.map((order, ind) => (
        <div
          onMouseEnter={() => onHover(ind)}
          onMouseLeave={() => onMouseLeave()}
          className="flex gap-2 relative justify-center pr-8"
        >
          <label>
            Column:
            <input
              type="text"
              name={`${randomId}-order-by-${ind}`}
              list={`${randomId}-order-by`}
              value={order.parameter}
              placeholder="Column Name"
              onChange={(e) => {
                const newOrderBy = [...orderBy];
                newOrderBy[ind] = {
                  ...newOrderBy[ind],
                  parameter: e.target.value.trim()
                };
                onChangeHandler(newOrderBy);
              }}
              className="text-sm block border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
            />
          </label>
          <label>
            Order:
            <ReactSelect
              options={Object.values(ORDER_BY_OPTIONS)}
              value={ORDER_BY_OPTIONS[order.order]}
              placeholder="Column Name"
              onChange={(e: { label: string; value: Order }) => {
                const newOrderBy = [...orderBy];
                newOrderBy[ind] = {
                  ...newOrderBy[ind],
                  order: e.value
                };
                onChangeHandler(newOrderBy);
              }}
              className="text-xs border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
              classNames={reactSelectClassNames}
            />
          </label>
          {hoveredOrder === ind ? (
            <Button
              type="button"
              icon={TrashIcon}
              color="red"
              variant="light"
              className="absolute right-1 bottom-3"
              onClick={() => {
                const newOrderBy = [...orderBy];
                newOrderBy.splice(ind, 1);
                onChangeHandler(newOrderBy);
              }}
            />
          ) : null}
        </div>
      ))}
      {selectedDPDStructure ? (
        <datalist id={`${randomId}-order-by`}>
          {[
            ...Object.keys(selectedDPDStructure),
            ...["timestamp", "device_id", "fleet_id"]
          ].map((param) => (
            <option key={param}>{param}</option>
          ))}
        </datalist>
      ) : null}
      <Button
        icon={PlusIcon}
        disabled={orderBy.some((order) => !order.parameter)}
        size="xs"
        className="h-min self-end mb-1"
        onClick={() => {
          onChangeHandler([...orderBy, { order: Order.ASC, parameter: "" }]);
        }}
      />
    </div>
  );
};

export default OrderByClause;
