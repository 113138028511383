import { IAlarmsResponse } from '../../interfaces';

export const chartdata = [
    {
        temp: "14.00",
        "Item A": 1074,
        "Item B": 1130,
        "Item C": 989,
    },
    {
        temp: "15.00",
        "Item A": 1993,
        "Item B": 4593,
        "Item C": 3193,
    },
    {
        temp: "16.00",
        "Item A": 3209,
        "Item B": 2609,
        "Item C": 3709,
    },
    {
        temp: "17.00",
        "Item A": 3898,
        "Item B": 4598,
        "Item C": 4198,
    },
    {
        temp: "18.00",
        "Item A": 4830,
        "Item B": 3790,
        "Item C": 4500,
    },
    {
        temp: "19.00",
        "Item A": 3030,
        "Item B": 2790,
        "Item C": 3500,
    },
    {
        temp: "20.00",
        "Item A": 1730,
        "Item B": 3190,
        "Item C": 2500,
    },
];

export const data = [
    {
        hosts: "Returning",
        "Item A": 4830,
    },
    {
        hosts: "Inactive",
        "Item A": 3030,
    },
    {
        hosts: "New",
        "Item A": 2730,
    },
]

export const metricData = [
    {
        year: 2019,
        amount: 4830,
    },
    {
        year: 2020,
        amount: 39030,
    },
]

export const alarmsResponse: IAlarmsResponse = {
    data: {
        alarms: [
            {
                name: "G1 Fan",
                status: true,
            },
            {
                name: "DIP 1",
                status: true,
            },
            {
                name: "DIP 2",
                status: false,
            },
            {
                name: "DIP 3",
                status: false,
            },
            {
                name: "DIP 4",
                status: true,
            },
            {
                name: "DIP 5",
                status: true,
            },
            {
                name: "DIP 6",
                status: false,
            },
        ]
    }
}