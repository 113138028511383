import React from "react";
import { useLocation } from "react-router-dom";
import { useServiceProvider } from "../../shared/hooks";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface IPanelCreationStep {
  name: string;
  status: string;
}

const PANELCREATION_PROGRESS: Array<IPanelCreationStep> = [
  { name: "Type", status: "upcoming" },
  { name: "Form", status: "upcoming" }
];

export default function FleetCreationSteps() {
  const serviceProvider = useServiceProvider(),
    { pathname } = useLocation(),
    [progress, setProgress] = React.useState<Array<IPanelCreationStep>>([]);

  React.useEffect(() => {
    if (!pathname || !serviceProvider) {
      return;
    }

    if (pathname === "/dashboard/") {
      setProgress([]);
      return;
    }

    const panelCreationStatus = serviceProvider?.panelCreationStatus,
      progressStatus = parseInt(
        panelCreationStatus ? panelCreationStatus : "0"
      );

    setProgress(
      PANELCREATION_PROGRESS.map((step, index) => {
        return index === progressStatus
          ? { ...step, status: "current" }
          : index > progressStatus
          ? { ...step, status: "upcoming" }
          : { ...step, status: "complete" };
      })
    );
  }, [pathname, serviceProvider]);

  if (!progress.length) {
    return null;
  }

  return (
    <nav aria-label="Progress">
      <ol className="flex items-center">
        {progress.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== progress.length - 1 ? "pr-8 sm:pr-20 w-full" : "",
              "relative"
            )}
          >
            {step.status === "complete" ? (
              <React.Fragment>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-[#0AB63B]" />
                </div>
                <div className="relative flex items-center justify-center w-8 h-8 rounded-full bg-green-primary">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" fill="#0AB63B" />
                    <path
                      d="M21.3337 12L14.0003 19.3333L10.667 16"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="sr-only">{step.name}</span>
                </div>
              </React.Fragment>
            ) : step.status === "current" ? (
              <React.Fragment>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div
                  className="relative flex items-center justify-center w-8 h-8 bg-background-layer0.5 border rounded-full border-primary"
                  aria-current="step"
                >
                  <span
                    className="w-4 h-4 rounded-full bg-primaryLight"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div className="relative flex items-center justify-center w-8 h-8 bg-background-layer0.5 border border-gray-300 rounded-full group hover:border-gray-400">
                  <span
                    className="w-4 h-4 bg-transparent rounded-full group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </React.Fragment>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
