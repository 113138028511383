import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface ICreateDeploymentPayload {
  ota_release_id: string;
  scheduled_at: Date;
  props: {
    deployment_name: string;
    max_retries: string;
    retry_interval: string;
    target_fleets: string;
  };
}

export const createReleaseDeployment = async (
  projectId: string,
  orgId: string,
  payload: ICreateDeploymentPayload
) => {
  const { data, ok } = await networkService.post<{ data: {}; ok: number }>(
    `projects/${projectId}/ota_release_deployments`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useCreateReleaseDeployment = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateDeploymentPayload) =>
      await createReleaseDeployment(
        selectedProject.id,
        user.selectedOrg.id,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDeployments");
      }
    }
  );

  return res;
};
