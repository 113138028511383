import { ActionType } from "@app/rule-engine/rule-engine.helper";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { useQuery } from "react-query";

export interface IAction {
  action_type: ActionType;
  additional_params: Record<string, string>;
  created_at: string;
  definition: any;
  deleted_at: null | string;
  description: string;
  fetched_context: any[];
  id: string;
  name: string;
  org_id: string;
  project_id: string;
  version: number;
}

interface IActionsResponse {
  ok: number;
  data: {
    actions: IAction[];
  };
}

export const getActions = async (projectId: string, orgId: string, params) => {
  const { data, ok } = await networkService.get<IActionsResponse>(
    `projects/${projectId}/actions/`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.actions;
  } else return null;
};

export const useGetActions = (
  params: any = {},
  successCb: ((data: IAction[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getActions", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const actions = await getActions(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(actions);

      return actions;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
