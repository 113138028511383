import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";
import { IPermission } from "@interfaces/apps.interface";

export interface IOrgUser {
  external_id: string;
  email: string;
  permission: string;
  invite_id: string | null;
  last_active_at: string;
  joined: string;
}

export interface IUserPerms {
  org_access: string;
  permissions: IPermission[];
}

interface IUserPermsResponse {
  ok: number;
  data: IUserPerms;
}

export const getUserPerms = async (user_id: string, orgId: string, params) => {
  const { data, ok } = await networkService.get<IUserPermsResponse>(
    `organizations/users/${user_id}`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useGetUserPerms = (
  user_id: string,
  params: any = {},
  successCb: ((data: IUserPerms) => void) | null = null
) => {
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getUserPerms", user_id, params],
    async () => {
      const users = await getUserPerms(user_id, user.selectedOrg.id, params);

      successCb && successCb(users);

      return users;
    },
    {
      enabled: !!user.selectedOrg.id && !!user_id
    }
  );

  return res;
};
