import { StepType } from "@reactour/tour";
import { WalkthroughItem } from "../components/walkthrough";
import ELEMENT_IDS from "./walkthrough-elements-ids.util";
import { Button } from "@tremor/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

interface IOverviewCallbacks {
  openSidebar: () => void;
  closeSidebar: () => void;
  setShownOverviewTour: () => void;
  endTour: () => void;
}

const overviewStep: (x: IOverviewCallbacks) => StepType[] = (
  callbacks: IOverviewCallbacks
) => [
  {
    selector: "#" + ELEMENT_IDS.COLLABORATORS_ICON,
    content: (
      <WalkthroughItem
        title="Invite Collaborators!"
        content="Invite your team to collaborate on your project."
        img="https://bacrialm.sirv.com/walkthrough/collaborators.png"
      />
    ),
    stepInteraction: false
  },
  {
    selector: "#" + ELEMENT_IDS.SIDEBAR_OPEN,
    content: (
      <WalkthroughItem
        title="Open Sidebar"
        content="All of the goodies of Golain are in this sidebar!"
      />
    ),
    stepInteraction: false
  },
  {
    selector: "#" + ELEMENT_IDS.SIDEBAR_DEVICES,
    content: (
      <WalkthroughItem
        title="Create a Device!"
        content="Create a device to start sending data to Golain."
        img="https://bacrialm.sirv.com/walkthrough/create-device.png"
      />
    ),
    action: () => {
      callbacks.openSidebar();
    },
    actionAfter: () => {
      callbacks.setShownOverviewTour();
    },
    stepInteraction: false
  },
  {
    selector: "#" + ELEMENT_IDS.SIDEBAR_DASHBOARD,
    content: (
      <WalkthroughItem
        title="Dashboard"
        content="Choose from a variety of panels to visualize your data."
        // TODO: Add img
      />
    ),
    action: () => {
      callbacks.openSidebar();
    },
    actionAfter: () => {
      callbacks.setShownOverviewTour();
    },
    stepInteraction: false
  },
  {
    selector: "#" + ELEMENT_IDS.SIDEBAR_LOGS,
    content: (
      <WalkthroughItem
        title="Logs"
        content="View your device's logs and debug any errors!"
        // TODO: Add img
      />
    ),
    action: () => {
      callbacks.openSidebar();
    },
    actionAfter: () => {
      callbacks.setShownOverviewTour();
    },
    stepInteraction: false
  },
  {
    selector: "#" + ELEMENT_IDS.SIDEBAR_OTA,
    content: (
      <WalkthroughItem
        title="Over-the-Air Updates!"
        content="Update your device's firmware over-the-air."
        // TODO: Add img
      />
    ),
    action: () => {
      callbacks.openSidebar();
    },
    actionAfter: () => {
      callbacks.setShownOverviewTour();
    },
    stepInteraction: false
  },
  {
    selector: "#" + ELEMENT_IDS.SIDEBAR_CONSUMER,
    action: () => {
      callbacks.openSidebar();
    },
    actionAfter: () => {
      callbacks.setShownOverviewTour();
    },
    content: (
      <WalkthroughItem
        title="Consumer Authentication"
        content={
          <div className="flex flex-col items-end">
            Authenticate and manage your end users on Golain!
            <Button
              size="xs"
              icon={ArrowRightIcon}
              iconPosition="right"
              className="self-end mt-1"
              onClick={() => {
                callbacks.closeSidebar();
                callbacks.endTour();
              }}
            >
              Start Exploring
            </Button>
          </div>
        }
        // TODO: Add img
      />
    ),
    stepInteraction: false
  }
];

export { overviewStep as steps };
