import { Disclosure } from "@headlessui/react";
import {
  CheckCircleIcon,
  ChevronUpIcon,
  EllipsisHorizontalCircleIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { Badge, Button } from "@tremor/react";
import { ErrorMessage, Field, FieldArray } from "formik";
import React, { FunctionComponent } from "react";
import ReactSelect from "react-select";
import { IOption } from "@/interfaces";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { FieldError } from "@/app/shared/components";
import ParamDefaultValue from "./http-trigger-param-default.component";
import { paramTypes } from "../http-trigger.helper";

interface HTTPTriggerParamProps {
  fieldName: "bodyParams" | "queryParams";
  values: any;
  heading: string;
}

const HTTPTriggerParam: FunctionComponent<HTTPTriggerParamProps> = React.memo(
  ({ fieldName, values, heading }) => {
    return (
      <Disclosure defaultOpen>
        {({ open }) => (
          <FieldArray
            name={fieldName}
            render={(arrayHelpers) => (
              <div>
                <Disclosure.Button
                  className={
                    "flex w-full justify-between rounded-lg bg-background-layer1 px-4 py-2 text-left text-sm font-medium hover:bg-background-layer2 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75"
                  }
                >
                  <div className="flex gap-2">
                    <h3 className="block text-base font-medium text-contentColor">
                      {heading}
                    </h3>
                    <Button
                      type="button"
                      variant="secondary"
                      size="xs"
                      className="!text-xs rounded-md !px-2 py-0.5"
                      icon={PlusIcon}
                      onClick={(e) => {
                        if (open) {
                          e.stopPropagation();
                        }
                        arrayHelpers.insert(values[fieldName]?.length ?? 0, {
                          name: "",
                          type: {
                            value: "string",
                            label: "String"
                          },
                          defaultValue: "",
                          required: true
                        });
                      }}
                    >
                      Add
                    </Button>
                    <Badge color={"blue"}>
                      {values[fieldName]?.length || 0}
                    </Badge>
                  </div>
                  <ChevronUpIcon
                    width={20}
                    className={`transition-transform duration-100 ${
                      open ? "rotate-0" : "rotate-180"
                    }`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <div className="flex mt-2 flex-col gap-4">
                    {values[fieldName]?.map((_, index: number) => (
                      <div key={index} className="flex flex-col relative">
                        <div className="flex justify-between">
                          <div className="flex items-center w-full">
                            <Field
                              name={`${fieldName}.${index}.name`}
                              placeholder="Param Name"
                              className={`block  px-3 py-2 border rounded-r-none bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                            />

                            <Field
                              component={({ field, form, ...props }) => (
                                <ReactSelect
                                  {...field}
                                  {...form}
                                  value={values[fieldName][index].type}
                                  onChange={(val: IOption) => {
                                    form.setFieldValue(
                                      `${fieldName}.${index}.defaultValue`,
                                      val.value === "struct" ? "{}" : ""
                                    );
                                    form.setFieldValue(
                                      `${fieldName}.${index}.type`,
                                      val
                                    );
                                  }}
                                  className="block w-full bg-background text-contentColor !rounded-none focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                                  classNames={{
                                    ...reactSelectClassNames,
                                    control: () =>
                                      "!bg-background !text-contentColor !border-l-0 !border-background-layer3 !rounded-none focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm"
                                  }}
                                  placeholder="Select data type."
                                  options={paramTypes}
                                />
                              )}
                              id={`${fieldName}.${index}.type.value`}
                              name={`${fieldName}.${index}.type.value`}

                              // menuPortalTarget={document.body}
                            />
                          </div>
                          <div className="flex items-center">
                            <Field
                              name={`${fieldName}.${index}.required`}
                              component={({ field, form, ...props }) => (
                                <Button
                                  onClick={() => {
                                    form.setFieldValue(
                                      `${fieldName}.${index}.required`,
                                      !values[fieldName][index].required
                                    );
                                    form.setFieldValue(
                                      `${fieldName}.${index}.defaultValue`,
                                      values[fieldName][index].type.value ===
                                        "struct"
                                        ? "{}"
                                        : ""
                                    );
                                  }}
                                  color={
                                    values[fieldName][index].required
                                      ? "green"
                                      : undefined
                                  }
                                  variant="secondary"
                                  className={`block px-3 py-2 border border-l-0  !rounded-r-none !m-0  !rounded-l-none bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                                  icon={
                                    values[fieldName][index].required
                                      ? CheckCircleIcon
                                      : EllipsisHorizontalCircleIcon
                                  }
                                  tooltip={
                                    values[fieldName][index].required
                                      ? "Make Optional"
                                      : "Make Required"
                                  }
                                />
                              )}
                            />
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              variant="secondary"
                              color="red"
                              className={`block px-3 py-2 border border-l-0 !m-0  !rounded-l-none bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                              icon={TrashIcon}
                            />
                          </div>
                        </div>
                        <ErrorMessage name={`${fieldName}.${index}.name`}>
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                        {!values[fieldName][index].required ? (
                          <div className="pl-8 mt-2">
                            {/* <ArrowCurved className="absolute stroke-contentColorLighter left-2 -rotate-[160deg] top-10" /> */}
                            <ParamDefaultValue
                              type={values[fieldName][index].type.value}
                              name={`${fieldName}.${index}.defaultValue`}
                              id={`${fieldName}.${index}.defaultValue`}
                            />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </Disclosure.Panel>
              </div>
            )}
          />
        )}
      </Disclosure>
    );
  }
);

export default HTTPTriggerParam;
