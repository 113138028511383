import { Link } from "react-router-dom";

function DeviceCreationSuccess() {
    return (
        <div className="lg:h-full md:h-[65vh] flex justify-center items-center">
            <div>
                <div className="mb-[38px]">
                    <h1 className="text-2xl text-center font-medium mb-2.5">
                        Congratulations!
                    </h1>
                    <h1 className="text-2xl text-center font-medium mb-2.5">
                        Your Device is created successfully
                    </h1>
                </div>
                <div className="text-center">
                    <Link
                        to="/fleet-and-devices/projects"
                        className="w-48 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
                        Back to devices
                    </Link>

                    <Link
                        to="/fleet-and-devices/projects/about-device/"
                        className="ml-5 w-48 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
                        Create new device
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default DeviceCreationSuccess;
