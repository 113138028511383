import React, { useEffect, useState } from "react";
import MethodsMenu from "../../http-response-node/methods-menu.component";
import HTTPHeadersEditor from "./http-headers-editor.component";
import Editor from "@monaco-editor/react";
import Modal from "@app/shared/components/modal.component";
import ActionTypeDropdown from "../action-type-dropdown.component";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { VariableInput } from "@app/rule-engine/variable-input.component";
import HTTPQueryParamsEditor from "./http-query-params-editor.component";
import { Button } from "@tremor/react";

type ActiveTab = "headers" | "body" | "query-params";

interface IParam {
  key: string;
  value: string;
}

const HTTPActionDefinitionEditor = ({
  actionDefinition,
  setActionDefinition,
  setActionType,
  actionTypes,
  actionType
}) => {
  const [httpUrl, setURL] = useState(actionDefinition.url ?? "");
  const [method, setMethod] = useState(actionDefinition.method ?? "get");
  const [headers, setHeaders] = useState(actionDefinition.headers ?? []);
  const [params, setParams] = useState<IParam[]>(
    actionDefinition.params ?? []
  );
  const [body, setBody] = useState(
    actionDefinition.body
      ? JSON.stringify(actionDefinition.body, null, 4)
      : "{}"
  );

  const [activeTab, setActiveTab] = useState<ActiveTab>("headers");
  const [jsonEditorModalOpen, setJsonEditorModalOpen] = useState(false);
  const [modalJSON, setModalJSON] = useState("{}");

  useEffect(() => {
    const hasErrors = false;

    setActionDefinition((prev) => ({
      ...prev,
      url: httpUrl,
      method,
      headers,
      params,
      hasErrors
    }));
  }, [httpUrl, method, headers, setActionDefinition, params]);

  useEffect(() => {
    setActiveTab("headers");

    return () => {};
  }, [method]);

  return (
    <>
      <div className="bg-background-layer1 w-full h-full p-4 rounded-md border border-background-layer3">
        <div className="flex justify-between">
          <ActionTypeDropdown
            setActionType={setActionType}
            actionTypes={actionTypes}
            setActionDefinition={setActionDefinition}
            buttonComponent={
              <div className="flex gap-2 bg-background-layer3 rounded-sm px-2 py-1 items-center">
                <span className="uppercase">{actionType}</span>
                <ChevronDownIcon width={16} />
              </div>
            }
          />
          <div className="flex gap-2">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(true)}
            >
              JSON
            </button>
            <MethodsMenu method={method} setMethod={setMethod} />
          </div>
        </div>
        {/* URL */}
        <div className="flex flex-col gap-1 nodrag mt-2">
          <VariableInput
            type="url"
            placeholder="https://example.com"
            value={httpUrl}
            className="nodrag bg-background w-full px-2 py-1 text-sm text-contentColor border-background-layer3 rounded-sm border-2"
            onChange={(val) => setURL(val)}
          />
          {/* {!validURL(httpUrl) ? (
            <span className="text-red-500">Enter a valid URL.</span>
          ) : null} */}
        </div>

        <div className="flex items-center justify-between nodrag border-b border-background-layer3 mt-2">
          {/* tabs */}
          <div className="flex gap-2  mt-2">
            <button
              className={`${
                activeTab === "headers"
                  ? "border-b-2 border-primaryLight"
                  : "bg-background-layer1"
              } px-2 py-1 rounded-sm text-contentColor`}
              onClick={() => setActiveTab("headers")}
            >
              Headers
            </button>
            {method !== "get" ? (
              <button
                className={`${
                  activeTab === "body"
                    ? "border-b-2 border-primaryLight"
                    : "bg-background-layer1"
                } px-2 py-1 rounded-sm text-contentColor`}
                onClick={() => setActiveTab("body")}
              >
                Body
              </button>
            ) : (
              <button
                className={`${
                  activeTab === "query-params"
                    ? "border-b-2 border-primaryLight"
                    : "bg-background-layer1"
                } px-2 py-1 rounded-sm text-contentColor`}
                onClick={() => setActiveTab("query-params")}
              >
                Query Params
              </button>
            )}
          </div>
          {activeTab === "body" ? (
            <Button
              variant="light"
              className="font-mono"
              tooltip="Format"
              onClick={() => {
                setBody(JSON.stringify(JSON.parse(body), null, 4));
              }}
            >
              {"{}"}
            </Button>
          ) : null}
        </div>

        {activeTab === "headers" ? (
          <div className="flex gap-2 nodrag mt-2">
            <HTTPHeadersEditor headers={headers} setHeaders={setHeaders} />
          </div>
        ) : activeTab === "query-params" ? (
          <div className="flex gap-2 nodrag mt-2">
            <HTTPQueryParamsEditor params={params} setParams={setParams} />
          </div>
        ) : activeTab === "body" ? (
          <div className="flex gap-2 nodrag mt-2">
            <Editor
              height="200px"
              width="100%"
              theme="vs-dark"
              language="json"
              value={body}
              onChange={(val) => {
                setBody(val);
                try {
                  setActionDefinition({
                    ...actionDefinition,
                    body: JSON.parse(val)
                  });
                } catch {
                  // pass
                }
              }}
              options={{
                readOnly: false,
                minimap: {
                  enabled: false
                },
                lineNumbers: "off"
              }}
            />
          </div>
        ) : null}
      </div>
      <Modal
        open={jsonEditorModalOpen}
        setOpen={setJsonEditorModalOpen}
        title="JSON Editor"
        className="w-full max-w-2xl"
        disableClickOutside
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set HTTP Action JSON</h1>
          <p>
            All your changes will be overwritten using this JSON editor.
            <br />
          </p>
          <Editor
            height="500px"
            width="100%"
            theme="vs-dark"
            language="json"
            value={modalJSON}
            onChange={(val) => {
              try {
                setModalJSON(val);
              } catch (err) {}
            }}
            options={{
              readOnly: false,
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => {
                let _modalJSON;
                try {
                  _modalJSON = JSON.parse(modalJSON);
                } catch {
                  return;
                }
                const method = _modalJSON["method"].toLowerCase();
                const url = _modalJSON["url"];
                const headers = _modalJSON["headers"];
                const body = _modalJSON["body"];

                if (method) {
                  const validMethods = [
                    "get",
                    "post",
                    "put",
                    "delete",
                    "patch"
                  ];
                  if (validMethods.includes(method)) {
                    setMethod(method);
                  }
                }
                if (url) {
                  setURL(url);
                } else {
                  setURL("");
                }
                if (headers) {
                  setHeaders(
                    Object.keys(headers).map((key) => ({
                      key,
                      value: headers[key]
                    }))
                  );
                } else {
                  setHeaders([]);
                }
                if (body) {
                  setBody(body);
                } else {
                  setBody("");
                }

                setJsonEditorModalOpen(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HTTPActionDefinitionEditor;
