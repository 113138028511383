import { useMemo } from "react";
import SettingsGenericTable from "../components/settings-generic-table.component";
import useSettingsStore from "@store/settings/settings.store";
import CreateInvite from "../components/settings-org-create-invite-form.component";
import dateService from "@services/date.service";
import { useGetInvites } from "@app/shared/hooks/get/invites";
import { Button } from "@tremor/react";
import { TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { useDeleteInvite } from "@app/shared/hooks/delete/delete-invite";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";

function Invites() {
  const [invites, setInvites, setShowCreateInviteForm] = useSettingsStore(
    (state) => [state.invites, state.setInvites, state.setShowCreateInviteForm]
  );

  useGetInvites({}, (invites) => {
    if (invites) {
      setInvites(invites);
    } else {
      setInvites([]);
    }
  });

  const deleteInviteMutation = useDeleteInvite();
  const { openConfirmDeleteModal } = useConfirmDelete();

  const renderStatus = (status: string) => {
    const statusUI = {
      admin: (
        <span className="flex">
          <span className="bg-[#25a96a36] py-2 px-5 rounded text-[#25A96A] font-medium text-xs mr-6">
            ADMIN
          </span>
        </span>
      ),
      viewer: (
        <span className="flex">
          <span className="bg-[#3659997d] py-2 px-[17px] rounded text-[#4281ff] font-medium text-xs mr-6">
            VIEWER
          </span>
        </span>
      ),
      member: (
        <span className="flex">
          <span className="bg-[#166ff629] py-2 px-[17px] rounded text-[#1366E5] font-medium text-xs mr-6">
            MEMBER
          </span>
        </span>
      )
    };

    return statusUI[status.toLowerCase()];
  };

  const collaborators = useMemo(() => {
    if (invites.length <= 0) return [];
    return invites.map((invite) => {
      const collaborator = invite?.email ? invite?.email[0] : "N/A";
      return {
        collaborator,
        created_at: dateService.convertUTCToLocalDate(invite?.created_at),
        org_access: renderStatus(invite?.org_access),
        times_used: invite?.times_used,
        revoke: (
          <Button
            variant="light"
            icon={TrashIcon}
            color="red"
            disabled={invite.times_used >= invite.limit}
            tooltip={
              invite.times_used >= invite.limit
                ? "Invite token already exhausted"
                : "Revoke Access"
            }
            onClick={() => {
              openConfirmDeleteModal(() => {
                deleteInviteMutation.mutate(invite.id);
              }, "Revoke Access to this invite token?");
            }}
          />
        )
      };
    });
  }, [deleteInviteMutation, invites, openConfirmDeleteModal]);

  return (
    <>
      <CreateInvite />
      <div>
        {/* <button
          onClick={() => setShowCreateInviteForm(true)}
          className="inline-block px-2.5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
        >
          + Invite Colleague
        </button> */}

        <div className="flex flex-col mt-4 w-full max-h-[70vh] bg-background text-contentColor pb-6">
          {/* <div className="flex rounded-sm justify-between bg-background-layer1 items-center px-4 py-2">
            <h2 className="text-lg font-medium">Organization Members</h2>
            <Button className="uppercase !text-white">New Group</Button>
          </div> */}
          <div className="flex justify-end">
            <Button
              icon={UserPlusIcon}
              onClick={() => setShowCreateInviteForm(true)}
              className="uppercase mb-4 !text-white"
            >
              Invite collaborator
            </Button>
          </div>
          <div className="overflow-y-auto">
            <div className="">
              <div className="overflow-hidden">
                {!!collaborators?.length && (
                  <SettingsGenericTable
                    column={Object.keys(collaborators[0])}
                    row={collaborators}
                    searchField={
                      collaborators.length
                        ? Object.keys(collaborators[0])[0]
                        : ""
                    }
                    searchTerm=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Invites;
