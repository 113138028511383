import { scaleLinear } from 'd3-scale';

/* 
    Functions to run calculations to find points/positions, colors, angles in SVG drawings
    Author: Aaditya Kashid
*/


/* 
    Function: findPoint
    Description: to find point (x,y) with given angle on a circle
    Arguments: {
        cx: center x 
        cy: center y 
        rad: radius of circle 
        cornerGrad: cornor position from steps | arc angle at circle
        angleCalc: true if cornerGrad is already caculated angle.
    }
*/
const findPoint = (cx: number, cy: number, rad: number, cornerGrad: number, angleCalc?: boolean) =>{
    var cornerRad = angleCalc ? cornerGrad : cornerGrad * Math.PI / 180 ;
    var nx = Math.cos(cornerRad)*rad + cx;
    var ny = Math.sin(cornerRad)*rad + cy;

    return { x: nx, y: ny};
}

/* 
    Function: getGaugeLinesCoords
    Description: to calculate array of line's position & color over the arc of circle with given arguments
    Arguments: {
        cnt: count of lines to draw over the arc  
        x: starting x position 
        y: starting y position  
        r: radius of arc  
        lineHt: line height (starting from radius)  
        minColorAngle: angle where min distribution ends  [ to determine color of line ]
        maxColorAngle:  angle where max distribution ends  [ to determine color of line ]  
    }
*/
const getGaugeLinesCoords = (cnt: number, x: number, y: number, r: number, lineHt: number, minColorAngle: number, maxColorAngle: number) => {
    const points = [];
    const corner = 180/cnt;
    for (let i = 1; i < cnt; i++) {
        var ptOne = findPoint(x, y, r + lineHt, -corner * i);
        var ptTwo = findPoint(x, y, r, -corner * i);
        const angle = ((-corner * i) * Math.PI / 180);       
        var color = angle >= minColorAngle && angle <= maxColorAngle ? "rgba(22, 111, 246, 0.62)" : "#FFF";
        points.push({ptOne, ptTwo, color});
    }
    return points;
}

/* 
    Function: getGaugeDistCoords
    Description: calculate position & color over the arc of circle to plot text(value labels) inside SVG
    Arguments: {
        cnt: count of texts to draw over the arc  
        x: starting x position 
        y: starting y position  
        r: radius of arc  
        minColorAngle: angle where min distribution ends  [ to determine color of text ]
        maxColorAngle:  angle where max distribution ends  [ to determine color of text ]  
    }
*/
const getGaugeDistCoords = (cnt: number, x: number, y: number, r: number, minColorAngle: number, maxColorAngle: number, min: number, max: number) => {
    const points = [];
    const values = [];
    const corner = 180/cnt;
    let tempVal = min;
    const step = ( max - min ) / cnt;
    for (let i = 1; i < cnt; i++) {
        const angle = ((-corner * i) * Math.PI / 180);
        var color = angle >= minColorAngle && angle <= maxColorAngle ? "rgba(22, 111, 246, 0.62)" : "#FFF";
        tempVal += step;
        points.push({...findPoint(x, y, r, -corner * i), color, value: Math.ceil(tempVal)});
        values.push(tempVal.toFixed(0))
    }
    return {points, values: values.reverse()};
}

/* 
    Function: getGaugeLineCoord
    Description: to calculate array of line's position over the arc of circle with given arguments
    Arguments: {
        angle: Angle over the arc where line position needs be  
        x: starting x position 
        y: starting y position  
        r: radius of arc  
        lineHt: line height (starting from radius)  
    }
*/
const getGaugeLineCoord = (angle: number, x: number, y: number, r: number, lineHt: number) => {
    var ptOne = findPoint(x, y, r + lineHt, angle, true);
    var ptTwo = findPoint(x, y, r, angle, true);
    return {ptOne, ptTwo};
}

/* 
    Function: getMinLinearAngle
    Description: to map values from one domain into other [ e.g. map number to color ]
    Arguments: {
        minP: min value in domain A  
        maxP: max value in domain A 
        minA: min value in domain B  
        maxA: max value in domain B 
        value: resultant value that needs to be mapped into other ( ranged between domain minP to maxP ) 
    }
*/
const getMinLinearAngle = (minP: number, maxP: number, minA: number, maxA: number, value: number) => {
    const percentScale = scaleLinear()
        .domain([minP, maxP])
        .range([0, 1])
        .clamp(true)

    const angleScale = scaleLinear()
        .domain([0, 1])
        .range([minA, maxA])
        .clamp(true);

    return angleScale(percentScale(value));
}

/* 
    Function: getGradientSteps
    Description: returns array of colors given the start and end colors and the steps in gradiant 
    Arguments: {
        steps: stops in gradient  
        startColor: stat color in gradient 
        endColor: end color in gradient  
    }
*/
const getGradientSteps = (steps: number, startColor: string, endColor: string) => {
    const colorScale = scaleLinear<string>()
    .domain([0, 1])
    .range([startColor, endColor]);

    return(colorScale.ticks(steps).map((colorVal) => colorScale(colorVal)));
}

/* 
    Function: getEndAngle
    Description: to map number from domain into radians angle
    Arguments: {
        dMin: min value in domain  
        dMax: max value in domain 
        rMin: min value in radians 
        rMax: max value in radians
        value: resultant value that needs to be mapped into other ( ranged between domain dMin to dMax ) 
    }
*/
const getEndAngle = (dMin: number, dMax: number, rMin: number, rMax: number, value: number) => {
    const angleScale = scaleLinear()
        .domain([dMin, dMax])
        .range([rMin, rMax])
        .clamp(true);

    return angleScale(value);
}

/* 
    Function: getRectMappedY
    Description: to map number from domain into SVG rect's bounds
    Arguments: {
        minP: min value in domain A  
        maxP: max value in domain A 
        rMin: min value in radians 
        rMax: max value in radians
        value: resultant value that needs to be mapped into other ( ranged between domain minP to maxP ) 
    }
*/
const getRectMappedY = (minP: number, maxP: number, rMin: number, rMax: number, value: number) => {
    const percentScale = scaleLinear()
        .domain([minP, maxP])
        .range([0, 1])
        .clamp(true)

    const rectScale = scaleLinear()
        .domain([0, 1])
        .range([rMin, rMax])
        .clamp(true)

    return rectScale(percentScale(value));
}

/* 
    Function: getTempLineYCoords
    Description: to calcuate Line's Y coordinate & color bounding inside SVG rect 
    Arguments: {
        cnt: count of lines to draw inside rect  
        rMin: min Y coord of rect
        rMax: max Y coord of rect 
        minY: Y value of resultant minimun distribution in chart
        maxY: Y value of resultant maximum distribution in chart
    }
*/
const getTempLineYCoords = (cnt: number, rMin: number, rMax: number,  minY: number, maxY: number, min: number, max: number) => {
    const points = [];
    const values = [];
    const segment = (rMax + rMin) / cnt;
    let tempVal = min;
    const step = ( max - min ) / cnt;
    
    for (let i = 1; i < cnt-1; i++) {
        const y = rMin + (segment * i);        
        var color = y <= minY && y <= maxY ? "rgba(22, 111, 246, 0.62)" : "#FFF";
        tempVal += step;
        points.push({y, color, value: (tempVal).toFixed(0)});
        values.push(tempVal.toFixed(2))
    }
    return {points: points.reverse(), values};
}

export {
    getGaugeLinesCoords, 
    getGaugeDistCoords,
    getGaugeLineCoord,

    getMinLinearAngle,
    getGradientSteps,
    getEndAngle,

    getRectMappedY,
    getTempLineYCoords
}