import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface IUpdateActionResponse {
  ok;
}

export const updateAction = async (
  projectId: string,
  orgId: string,
  actionId: string,
  payload: any
) => {
  const { ok } = await networkService.patch<IUpdateActionResponse>(
    `projects/${projectId}/actions/${actionId}`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUpdateAction = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({ data, actionId }: { data: any; actionId: string }) =>
      await updateAction(
        selectedProject.id,
        user.selectedOrg.id,
        actionId,
        data
      ),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries("getApps");
      }
    }
  );

  return res;
};
