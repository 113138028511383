import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function ProjectsDropdownMenu({
  projects,
  selectedProject,
  handleProjectSelection
}) {
  return (
    <Menu as="div" className="relative flex text-left">
      <Menu.Button
        className={`px-3 py-1 flex justify-center text-sm items-center hover:bg-background-layer1 hover:text-contentColor rounded-md text-contentColor focus:ring-1 focus:ring-offset-1 focus:ring-offset-background-layer2 focus:ring-background-layer2`}
      >
        {selectedProject.id ? selectedProject.project_name : "Select Project"}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute top-7 w-fit z-10 mt-2 origin-top-right rounded-md bg-background-layer1 shadow-lg ring-1 ring-background-layer2 ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {projects &&
              projects.map((project) => (
                <Menu.Item key={project.id}>
                  <button
                    onClick={() => handleProjectSelection(project.id)}
                    className={`w-full flex gap-1 items-center text-left whitespace-nowrap px-4 py-2 text-sm ${
                      project.id === selectedProject.id
                        ? "bg-background-layer3 text-contentColor"
                        : "text-contentColor hover:bg-background-layer2"
                    }`}
                  >
                    {project.project_name}
                  </button>
                </Menu.Item>
              ))}
            <Menu.Item>
              <Link
                to="fleet-and-devices/projects/project-details"
                className={`flex gap-2 whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer2`}
              >
                <PlusIcon width={14} />
                <span className="whitespace-nowrap text-sm">
                  Create Project
                </span>
              </Link>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ProjectsDropdownMenu;
