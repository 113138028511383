import { useState } from "react";
import { object, string } from "yup";
import { AddOverlayLayout } from "@app/shared/components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FieldError } from "@app/shared/components";
import useOTAReleaseStore from "@store/ota/ota-release.store";
import { useCreateArtifact } from "@app/shared/hooks/post/create-artifact";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IReleaseArtifactFormProps {
  //   fetchKeys: () => Promise<void>;
}

const releaseArtifactFormSchema = object().shape({
  version: string().required("Please mention version."),
  type: string().required("Please enter type."),
  topic: string().required("Please enter topic.")
});

const ReleaseArtifactForm: React.FC<IReleaseArtifactFormProps> = () => {
  const [activeRelease, showReleaseArtifactForm, setShowReleaseArtifactForm] =
    useOTAReleaseStore((state) => [
      state.activeRelease,
      state.showReleaseArtifactForm,
      state.setShowReleaseArtifactForm
    ]);

  const [artifactFile, setArtifactFile] = useState<File>(undefined);
  const [errorMsgs, setErrorMsgs] = useState({
    artifact: ""
  });

  const createArtifactMutation = useCreateArtifact(activeRelease?.id);

  const handleSubmit = async (
    values: {
      version: string;
      type: string;
      topic: string;
    },
    { resetForm }
  ) => {
    if (artifactFile) {
      setErrorMsgs({ ...errorMsgs, artifact: "" });
    } else {
      setErrorMsgs({ ...errorMsgs, artifact: "Please upload artifact file." });
      return;
    }

    const formData = new FormData();
    formData.append("artifact", artifactFile);
    formData.append("props", JSON.stringify(values));

    createArtifactMutation.mutate(formData, {
      onSuccess: () => {
        setShowReleaseArtifactForm(false);
        resetForm();
        setArtifactFile(undefined);
      }
    });
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;

    if (fileMb > 2) {
      return setErrorMsgs({
        ...errorMsgs,
        artifact: "Please upload file less than 2MB"
      });
    }

    if (errorMsgs.artifact) {
      setErrorMsgs({ ...errorMsgs, artifact: "" });
    }

    setArtifactFile(file);
  };

  return (
    <AddOverlayLayout
      title="Create New Release"
      overlayOpen={showReleaseArtifactForm}
      setOverlayOpen={setShowReleaseArtifactForm}
    >
      <div className="flex flex-col items-center">
        <Formik
          initialValues={{
            version: "",
            type: "",
            topic: ""
          }}
          validationSchema={releaseArtifactFormSchema}
          onSubmit={handleSubmit}
        >
          <Form className="w-full flex flex-col flex-1 mt-7">
            <div className="mb-5">
              <label className="text-sm font-medium text-contentColorLight">
                Version
              </label>

              <Field
                type="text"
                id="version"
                name="version"
                placeholder="Version"
                className="block w-full p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              />
              <ErrorMessage name="version">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>

            <div className="mb-5">
              <label className="text-sm font-medium text-contentColorLight">
                Type
              </label>

              <Field
                type="text"
                id="type"
                name="type"
                placeholder="Type"
                className="block w-full p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              />
              <ErrorMessage name="type">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>

            <div className="mb-5">
              <label className="text-sm font-medium text-contentColorLight">
                Topic
              </label>

              <Field
                type="text"
                id="topic"
                name="topic"
                placeholder="Topic"
                className="block w-full p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              />
              <ErrorMessage name="topic">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>

            <div className="mb-5">
              {!!artifactFile ? (
                <div>
                  <label className="text-sm font-medium text-contentColorLight">
                    Selected File
                  </label>

                  <div className="px-3 py-2 my-2.5 flex justify-between items-center bg-background-layer1 border border-background-layer3 border-solid rounded text-contentColor text-sm">
                    <h1 className="font-bold">{artifactFile.name}</h1>
                    <button
                      type="button"
                      className="ml-2.5"
                      onClick={() => setArtifactFile(undefined)}
                    >
                      <XMarkIcon width={16} color="red" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center w-full">
                  <label
                    htmlFor="artifact"
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-background-layer3 border-dashed rounded-lg cursor-pointer bg-background-layer1"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 mb-3 text-contentColorLight"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-contentColor">
                        <span className="font-semibold">
                          Click to upload artifact
                        </span>{" "}
                        or Drag & Drop
                      </p>
                      <p className="text-xs text-contentColor">(MAX. 2 Mb)</p>
                    </div>
                    <input
                      type="file"
                      id="artifact"
                      name="artifact"
                      className="hidden"
                      onChange={handleFile}
                    />
                    {errorMsgs.artifact ? (
                      <FieldError message={errorMsgs.artifact} />
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="w-full inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
              >
                Create
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </AddOverlayLayout>
  );
};

export default ReleaseArtifactForm;
