import { TrashIcon } from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import React from "react";
import { useReactFlow, useNodeId } from "reactflow";
import { NodeType } from "../node-types.helper";
import useRuleEngineStore from "@/store/rule-engine/rule-engine.store";

const NodeTemplate = ({ children, hideDelete = false, ...props }) => {
  const [setActionData, setResponseData] = useRuleEngineStore((state) => [
    state.setActionData,
    state.setResponseData
  ]);
  const reactFlow = useReactFlow();
  const nodeId = useNodeId();

  const onNodeDelete = () => {
    const curNode = reactFlow.getNode(nodeId);

    if (curNode.type === NodeType.actionSequenceNode) {
      setActionData((actData) => {
        const newActData = { ...actData };
        delete newActData[nodeId];

        return newActData;
      });
    } else if (curNode.type === NodeType.httpResponseNode) {
      setResponseData((setResponseData) => {
        const newResData = { ...setResponseData };
        delete newResData[nodeId];

        return newResData;
      });
    }

    reactFlow.setNodes((nodes) => nodes.filter((n) => n.id !== nodeId));
    reactFlow.setEdges((edges) =>
      edges.filter((edge) => edge.target !== nodeId)
    );
  };

  return (
    <div
      {...props}
      className="p-3 relative text-sm border-2 border-background-layer3 rounded-md bg-background-layer0.5 text-contentColor"
    >
      {!hideDelete ? (
        <Button
          icon={TrashIcon}
          variant="light"
          color="red"
          className="absolute right-1 top-1"
          onClick={onNodeDelete}
        />
      ) : null}
      {children}
    </div>
  );
};

export default NodeTemplate;
