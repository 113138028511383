import { ClockIcon } from "@heroicons/react/24/outline";
import React from "react";

export enum CertificateInfoTabs {
  DETAILS = "details"
}

const iconsMap = {
  [CertificateInfoTabs.DETAILS]: (activeTab) => (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 14.625C3.5 14.1277 3.69754 13.6508 4.04917 13.2992C4.40081 12.9475 4.87772 12.75 5.375 12.75H15.5"
        stroke={
          activeTab === CertificateInfoTabs.DETAILS ? "#546CCC" : "#565759"
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.375 1.5H15.5V16.5H5.375C4.87772 16.5 4.40081 16.3025 4.04917 15.9508C3.69754 15.5992 3.5 15.1223 3.5 14.625V3.375C3.5 2.87772 3.69754 2.40081 4.04917 2.04917C4.40081 1.69754 4.87772 1.5 5.375 1.5V1.5Z"
        stroke={
          activeTab === CertificateInfoTabs.DETAILS ? "#546CCC" : "#565759"
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};

interface IFadCertificateInfoTabs {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const FADCertificateInfoTabs: React.FC<IFadCertificateInfoTabs> = ({
  activeTab,
  setActiveTab
}) => {
  return (
    <div className="bg-background border-b border-background-layer3">
      <div className=" md:grid md:gap-4 md:grid-cols-5 ">
        {(
          Object.keys(CertificateInfoTabs) as Array<
            keyof typeof CertificateInfoTabs
          >
        ).map((key) => (
          <button
            onClick={() => setActiveTab(CertificateInfoTabs[key])}
            className={`${
              activeTab === CertificateInfoTabs[key]
                ? "text-primary border-b-2 border-primary"
                : ""
            } flex items-center px-2 py-5 justify-center -mb-px text-center text-contentColorLight lg:px-4 whitespace-nowrap`}
          >
            {
              <div className="flex flex-row justify-between items-center">
                {iconsMap[CertificateInfoTabs[key]](activeTab)}

                <span
                  className={`${
                    activeTab === CertificateInfoTabs[key] &&
                    "text-primborder-primary font-medium"
                  } text-sm ml-2`}
                >
                  {CertificateInfoTabs[key].toUpperCase()}
                </span>
              </div>
            }
          </button>
        ))}
      </div>
    </div>
  );
};

export default FADCertificateInfoTabs;
