import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { ITrigger } from "@/interfaces/triggers.interface";

interface ICreateTriggerResponse {
  data: {
    trigger_id: ITrigger;
  };
  ok: number;
}

export interface ICreateTriggerPayload {
  trigger_name: string;
  trigger_description: string;
  trigger_type: string;
  definition: string;
  active: boolean;
  rule_id?: string;
}

export const createTrigger = async (
  projectId: string,
  orgId: string,
  payload: ICreateTriggerPayload
) => {
  const { data, ok } = await networkService.post<ICreateTriggerResponse>(
    `projects/${projectId}/triggers/`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.trigger_id;
  } else return null;
};

export const useCreateTrigger = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateTriggerPayload) =>
      await createTrigger(selectedProject.id, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getTriggers");
      }
    }
  );

  return res;
};
