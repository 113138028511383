import React, { useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import dateService from "@services/date.service";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon
} from "@heroicons/react/24/outline";
import Pagination from "@/app/shared/components/pagination.component";
import { useGetDataPointDefinitionsData } from "@/app/shared/hooks/get/data-point-definition-data";
import { Link } from "react-router-dom";
import { useFleetAndDevicesStore } from "@/store";
import { useGetFleets } from "@/app/shared/hooks/get/fleets";
import LoadingSpin from "@/app/shared/components/loading-spin.component";
import { isObject } from "@/app/shared/utils/helper.util";
import { Button, DateRangePicker, DateRangePickerValue } from "@tremor/react";
import Modal from "@/app/shared/components/modal.component";

interface IDatapointDefinitionDataProps {
  dataPointDef: any;
}

interface IDataPointDefDataRow {
  device_id: string;
  fleet_id: string;
  timestamp: string;

  // dynamic keys:
  [key: string]: string;
}

const DataPointDefinitionData: React.FC<IDatapointDefinitionDataProps> = ({
  dataPointDef
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rowSelection, setRowSelection] = useState({});
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [value, setValue] = useState<DateRangePickerValue>({
    from: null,
    to: null,
    selectValue: null
  });

  const [setSelectedFleet] = useFleetAndDevicesStore((state) => [
    state.setSelectedFleet
  ]);

  const { data: fleets, isLoading: isLoadingFleets } = useGetFleets();

  const { data: dpdData, isLoading: isLoadingData } =
    useGetDataPointDefinitionsData(dataPointDef.id, {
      page,
      limit: pageSize
    });

  const columns = useMemo<ColumnDef<IDataPointDefDataRow, any>[]>(() => {
    const defaultCols: ColumnDef<IDataPointDefDataRow, any>[] = [
      {
        id: "device_id",
        accessorKey: "device_id",
        header: "Device",
        cell: ({ getValue, row }) => {
          return getValue();
        },
        size: 30
      },
      {
        id: "fleet_id",
        accessorKey: "fleet_id",
        header: "Fleet",
        cell: ({ getValue }) => {
          const fleet = fleets?.find((f) => f.id === getValue());

          return (
            <Link
              to={"/fleet-and-devices/projects"}
              className="underline"
              onClick={() => {
                if (fleet) {
                  setSelectedFleet(fleet);
                }
              }}
            >
              {isLoadingFleets || !fleet ? <LoadingSpin /> : fleet.fleet_name}
            </Link>
          );
        },
        size: 100,
        minSize: 100
      },
      {
        id: "timestamp",
        accessorKey: "timestamp",
        header: "Timestamp",
        sortingFn: (a, b, colId) =>
          dateService
            .convertDateStrToMoment(a.getValue(colId))
            .diff(dateService.convertDateStrToMoment(b.getValue(colId))),
        cell: ({ getValue }) => {
          return (
            <div className="text-contentColorLight">
              {dateService.convertUTCToLocalDate(getValue())}
            </div>
          );
        },
        size: 40,
        minSize: 100
      }
    ];

    if (dpdData?.data?.length) {
      Object.keys(dpdData?.data[0]).forEach((key) => {
        if (defaultCols.some((col) => col.id === key)) return;
        defaultCols.push({
          id: key,
          accessorKey: key,
          header: key,
          cell: ({ getValue }) => {
            const data = getValue();
            if (isObject(data)) {
              return JSON.stringify(data, null, 4);
            } else {
              return getValue()?.toString();
            }
          },
          size: 30,
          minSize: 100
        });
      });
    }

    return defaultCols;
  }, [dpdData, fleets, isLoadingFleets, setSelectedFleet]);

  const data = useMemo(
    () => (Array.isArray(dpdData?.data) ? dpdData?.data : []),
    [dpdData?.data]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      pagination: {
        pageIndex: dpdData?.meta?.currentPage ?? 0,
        pageSize: dpdData?.meta?.itemsPerPage ?? 0
      }
    },
    manualPagination: true,
    pageCount: dpdData?.meta?.totalPages ?? 0,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  if (!isLoadingData && dpdData?.data?.length === 0) {
    return (
      <div className="w-full min-h-[60vh] text-contentColorLight flex justify-center items-center">
        No data in data point.
      </div>
    );
  }

  return (
    <>
      {/* <div className="flex justify-end mt-4">
        <DateRangePicker
          className="max-w-md mr-2"
          value={value}
          onValueChange={(v) => {
            if (!v.selectValue) {
              setValue({ ...v, selectValue: null });
            } else {
              setValue(v);
            }
          }}
          selectPlaceholder="Select Range"
          color="rose"
          maxDate={new Date()}
        ></DateRangePicker>
        <Button
          disabled={!value.from}
          onClick={() => setShowDownloadModal(true)}
        >
          Download Data
        </Button>
      </div> */}
      <div className="mx-12 overflow-x-auto overflow-hidden">
        <table className="w-full h-full my-6 ">
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width: header.column.getSize(),
                        minWidth: header.column.getSize()
                      }}
                      className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                : "",
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <ArrowUpIcon width={10} />,
                              desc: <ArrowDownIcon width={10} />
                            }[header.column.getIsSorted() as string] ??
                              (header.column.getCanSort() ? (
                                <ArrowsUpDownIcon width={10} />
                              ) : null)}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>{""}</div>
                          ) : null}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="rounded-md">
            {table.getRowModel().rows.map((row, ind) => {
              return (
                <tr
                  key={row.id}
                  className={`bg-background ${
                    ind === 0
                      ? "!rounded-t-md"
                      : ind === table.getTotalSize() - 1
                      ? "!rounded-b-md"
                      : ""
                  }`}
                >
                  {row.getVisibleCells().map((cell, cellInd) => {
                    return (
                      <td
                        key={cell.id}
                        className={`mx-2 whitespace-nowrap !bg-transparent text-sm text-center p-2 py-3 ${
                          ind === 0 && cellInd === 0
                            ? "rounded-tl-md"
                            : ind === table.getTotalSize() - 1 && cellInd === 0
                            ? "rounded-bl-md"
                            : ind === 0 && cellInd === columns.length - 1
                            ? "rounded-tr-md"
                            : ind === table.getTotalSize() - 1 &&
                              cellInd === columns.length - 1
                            ? "rounded-br-md"
                            : ""
                        }`}
                        style={{
                          width: cell.column.getSize()
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!isLoadingData ? (
        <Pagination<IDataPointDefDataRow>
          table={table}
          setPage={setPage}
          setPageSize={setPageSize}
          meta={dpdData?.meta}
        />
      ) : null}
      <Modal
        open={showDownloadModal}
        setOpen={setShowDownloadModal}
        title="Download Data"
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Download Data</h1>
          <p>
            Your download is being prepared.
            <br />
            We will send you an email to download the data in 15-20 mins!
          </p>

          <div className="flex gap-4 justify-end">
            <Button color="green" onClick={() => setShowDownloadModal(false)}>
              Awesome!
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(DataPointDefinitionData);
