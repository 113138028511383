import React, { useEffect, useState } from "react";
import FCMMessageEditor, { IFCMMessage } from "./fcm-message-editor.component";
import Editor from "@monaco-editor/react";
import Modal from "@app/shared/components/modal.component";
import ActionTypeDropdown from "../action-type-dropdown.component";
import {
  ChevronDownIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";
import { VariableInput } from "@app/rule-engine/variable-input.component";
import FCMEnvironmentsEditor, {
  IEnvironments
} from "./fcm-environments-editor.component";
import { Button } from "@tremor/react";
import { ErrorMessage } from "formik";

type ActiveTab = "message" | "environments";

const FCMActionDefinitionEditor = ({
  actionDefinition,
  setActionDefinition,
  setActionType,
  actionTypes,
  actionType
}) => {
  const [credVariable, setCredVariable] = useState(
    actionDefinition.credentials ?? ""
  );
  const [environments, setEnvironments] = useState<IEnvironments>(
    actionDefinition.environments ?? []
  );

  const [fcmMessage, setFcmMessage] = useState<IFCMMessage>(
    actionDefinition.fcm_message ??
      ({
        body: "",
        data: [],
        title: "",
        token: "",
        topic: ""
      } as IFCMMessage)
  );

  const [activeTab, setActiveTab] = useState<ActiveTab>("message");
  const [jsonEditorModalOpen, setJsonEditorModalOpen] = useState(false);
  const [modalJSON, setModalJSON] = useState("{}");

  useEffect(() => {
    let hasErrors = false;

    const requiredVars = [credVariable, fcmMessage.title, fcmMessage.body];

    if (
      requiredVars.some((val) => !val.trim()) ||
      (!fcmMessage.token.trim() && !fcmMessage.token.trim())
    ) {
      hasErrors = true;
    }

    setActionDefinition((prev) => ({
      credentials: credVariable,
      environments,
      fcm_message: fcmMessage,
      hasErrors
    }));
  }, [credVariable, environments, fcmMessage]);

  return (
    <>
      <div className="bg-background-layer1 w-full h-full p-4 rounded-md border border-background-layer3">
        <div className="flex justify-between">
          <ActionTypeDropdown
            setActionType={setActionType}
            actionTypes={actionTypes}
            setActionDefinition={setActionDefinition}
            buttonComponent={
              <div className="flex gap-2 bg-background-layer3 rounded-sm px-2 py-1 items-center">
                <span className="uppercase">{actionType}</span>
                <ChevronDownIcon width={16} />
              </div>
            }
          />
          {/* <div className="flex gap-2">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(true)}
            >
              JSON
            </button>
            <MethodsMenu method={method} setMethod={setMethod} />
          </div> */}
        </div>
        {/* URL */}
        <div className="flex flex-col gap-1 nodrag mt-2">
          <VariableInput
            type="text"
            name="credentials"
            placeholder="Credentials Variable"
            value={credVariable}
            className="nodrag bg-background w-full px-2 py-1 text-sm text-contentColor border-background-layer3 rounded-sm border-2"
            onChange={(val) => setCredVariable(val)}
          />
          {!credVariable.trim().length ? (
            <p className="text-red-500 flex gap-1 text-xs">
              <ExclamationTriangleIcon width={14} /> Credentials Variable is
              required.
            </p>
          ) : null}
        </div>

        <div className="flex items-center justify-between nodrag border-b border-background-layer3 mt-2">
          {/* tabs */}
          <div className="flex gap-2  mt-2">
            <button
              className={`${
                activeTab === "message"
                  ? "border-b-2 border-primaryLight"
                  : "bg-background-layer1"
              } px-2 py-1 rounded-sm text-contentColor`}
              onClick={() => setActiveTab("message")}
            >
              FCM Message
            </button>

            <button
              className={`${
                activeTab === "environments"
                  ? "border-b-2 border-primaryLight"
                  : "bg-background-layer1"
              } px-2 py-1 rounded-sm text-contentColor`}
              onClick={() => setActiveTab("environments")}
            >
              Environments
            </button>
          </div>
        </div>

        {activeTab === "message" ? (
          <div className="flex gap-2 nodrag mt-2">
            <FCMMessageEditor
              message={fcmMessage}
              setMessage={setFcmMessage}
            />
          </div>
        ) : activeTab === "environments" ? (
          <div className="flex gap-2 nodrag mt-2">
            <FCMEnvironmentsEditor
              environments={environments}
              setEnvironments={setEnvironments}
            />
          </div>
        ) : null}
      </div>
      <Modal
        open={jsonEditorModalOpen}
        setOpen={setJsonEditorModalOpen}
        title="JSON Editor"
        className="w-full max-w-2xl"
        disableClickOutside
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set HTTP Action JSON</h1>
          <p>
            All your changes will be overwritten using this JSON editor.
            <br />
          </p>
          <Editor
            height="500px"
            width="100%"
            theme="vs-dark"
            language="json"
            value={modalJSON}
            onChange={(val) => {
              try {
                setModalJSON(val);
              } catch (err) {}
            }}
            options={{
              readOnly: false,
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => {
                let _modalJSON;
                try {
                  _modalJSON = JSON.parse(modalJSON);
                } catch {
                  return;
                }
                const method = _modalJSON["method"].toLowerCase();
                const url = _modalJSON["url"];
                const headers = _modalJSON["headers"];
                const body = _modalJSON["body"];

                setJsonEditorModalOpen(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FCMActionDefinitionEditor;
