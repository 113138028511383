import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useReactFlow
} from "reactflow";

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  const reactFlow = useReactFlow();

  const onEdgeClick = (evt) => {
    evt.stopPropagation();
    reactFlow.setEdges((edges) => edges.filter((e) => e.id !== id));
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all"
          }}
          className="nodrag nopan"
        >
          <Button
            variant="light"
            icon={() => <XMarkIcon width={10} />}
            className="rounded-full !text-contentColor w-6 h-6 bg-background-layer1 border border-background-layer3 cursor-pointer"
            onClick={(event) => onEdgeClick(event)}
          />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
