import { ErrorMessage, Field, Form, Formik } from "formik";
import { FieldError } from "@app/shared/components";
import * as yup from "yup";

interface CreateBlueprintInfoProps {
  onNext: (values: any) => void;
}

const validationSchema = yup.object({
  deviceBlueprintName: yup.string().required("Blueprint Name is required.")
});

const CreateBlueprintInfo: React.FC<CreateBlueprintInfoProps> = ({
  onNext
}) => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ deviceBlueprintName: "" }}
      onSubmit={onNext}
    >
      <Form className="flex flex-col flex-1">
        <div>
          <h1 className="text-lg text-left font-medium mb-2.5">
            Create Device Blueprint
          </h1>

          <div className="mb-5">
            <label className="text-sm font-medium text-contentColorLight">
              Blueprint Name*
            </label>

            <Field
              type="text"
              id="deviceBlueprintName"
              name="deviceBlueprintName"
              placeholder="Blueprint Name"
              className="block w-6/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
            />
            <ErrorMessage name="deviceBlueprintName">
              {(msg) => <FieldError message={msg} />}
            </ErrorMessage>
          </div>

          <label className="text-sm font-medium text-contentColorLight">
            Device Name (Optional)
          </label>
          <div className="flex justify-between gap-4">
            <div className="mb-5">
              <Field
                type="text"
                id="deviceNamePrefix"
                name="deviceNamePrefix"
                placeholder="Prefix"
                className="block p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
              />
            </div>
            <div className="mb-5 mt-auto flex-grow max-w-sm">
              <Field
                type="text"
                readonly
                value=""
                disabled
                placeholder="Auto Generated ID"
                className="block w-full p-3 mt-auto border-background-layer3 bg-background-layer1 rounded-md sm:text-sm"
              />
            </div>
            <div className="mb-5">
              <Field
                type="text"
                id="deviceNameSuffix"
                name="deviceNameSuffix"
                placeholder="Suffix"
                className="block p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="">
          <button
            type="submit"
            className="w-44 inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
          >
            Next
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default CreateBlueprintInfo;
