import { useQuery } from "react-query";

import {} from "@interfaces/shared.interface";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IGetReleaseResponse, IRelease } from "@interfaces/ota.interface";

export const getReleases = async (projectId, orgId, params) => {
  const { data, ok } = await networkService.get<IGetReleaseResponse>(
    `projects/${projectId}/ota_releases`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useGetReleases = (
  params: any = {},
  successCb: ((data: IRelease[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getReleases", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const releases = await getReleases(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );
      successCb && successCb(releases);

      return releases;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
