import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import {
  IDashbaordsResponse,
  IDashboard
} from "@interfaces/dashboard.interface";

export const getDashboards = async (
  projectId: string,
  orgId: string,
  params
) => {
  const { data, ok } = await networkService.get<IDashbaordsResponse>(
    `projects/${projectId}/dashboards/`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.dashboards;
  } else return null;
};

export const useGetDashboards = (
  params: any = {},
  successCb: ((data: IDashboard[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getDashboards", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const dashboards = await getDashboards(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(dashboards);

      return dashboards;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
