import React from 'react';
import RenderDeployment from './ota-render-deployment.component';

const Projects: React.FC = () => {
    return (
        <>
            <RenderDeployment/>
        </>
    )
}

export default Projects;
