import { useMutation, useQueryClient } from "react-query";
import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";
import { ICreateProjectResponse } from "@interfaces/shared.interface";

interface ICreateProjectPayload {
  project_name: string;
  region: string;
  hosting_platform: string;
}

export const createProject = async (
  orgId: string,
  payload: ICreateProjectPayload
) => {
  const { data, ok } = await networkService.post<ICreateProjectResponse>(
    "projects",
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.projectId;
  } else return null;
};

export const useCreateProject = () => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateProjectPayload) =>
      await createProject(user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getProjects");
      }
    }
  );

  return res;
};
