import { useCallback, useMemo } from "react";
import useOTAReleaseStore from "../../../store/ota/ota-release.store";
import GenericTable from "../../shared/components/generic-table/table.component";
import ReleaseDetails from "../components/ota-release-details.component";
import { data } from "../../shared/utils/data-file.util";
import { useGetArtifacts } from "@app/shared/hooks/get/artifacts";
import { useDeleteArtifact } from "@app/shared/hooks/delete/delete-artifact";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";

const RenderArtifacts = () => {
  const [
    activeRelease,
    setShowReleaseArtifactForm,
    showReleaseDetails,
    setShowReleaseDetails
  ] = useOTAReleaseStore((state) => [
    state.activeRelease,
    state.setShowReleaseArtifactForm,
    state.showReleaseDetails,
    state.setShowReleaseDetails
  ]);

  const { openConfirmDeleteModal } = useConfirmDelete();

  const { data: artifactsList } = useGetArtifacts(activeRelease.id);

  const deleteArtifactMutation = useDeleteArtifact();

  const deleteArtifact = useCallback(
    (artifactId) => {
      openConfirmDeleteModal(
        () =>
          deleteArtifactMutation.mutate({
            releaseId: activeRelease.id,
            artifactId
          }),
        "Are you sure you want to delete this artifact?"
      );
    },
    [activeRelease?.id, deleteArtifactMutation, openConfirmDeleteModal]
  );

  const options = useMemo(() => {
    return (
      artifactsList?.map((artifact) => {
        return {
          ...artifact.props,
          delete: (
            <button
              className="pl-2"
              onClick={() => deleteArtifact(artifact.id)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4H3.33333H14"
                  stroke="#E21B17"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.33337 4.00016V2.66683C5.33337 2.31321 5.47385 1.97407 5.7239 1.72402C5.97395 1.47397 6.31309 1.3335 6.66671 1.3335H9.33337C9.687 1.3335 10.0261 1.47397 10.2762 1.72402C10.5262 1.97407 10.6667 2.31321 10.6667 2.66683V4.00016M12.6667 4.00016V13.3335C12.6667 13.6871 12.5262 14.0263 12.2762 14.2763C12.0261 14.5264 11.687 14.6668 11.3334 14.6668H4.66671C4.31309 14.6668 3.97395 14.5264 3.7239 14.2763C3.47385 14.0263 3.33337 13.6871 3.33337 13.3335V4.00016H12.6667Z"
                  stroke="#E21B17"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.66663 7.3335V11.3335"
                  stroke="#E21B17"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.33337 7.3335V11.3335"
                  stroke="#E21B17"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )
        };
      }) || []
    );
  }, [artifactsList, deleteArtifact]);

  return (
    <>
      {showReleaseDetails && <ReleaseDetails />}
      <div className="h-[54vh]">
        <div className="flex justify-between items-center mb-2.5">
          <h1 className="text-lg text-left font-medium">
            Artifacts in this release
          </h1>
          <h1
            className="font-medium text-sm underline text-primary cursor-pointer"
            onClick={() => setShowReleaseDetails(true)}
          >
            View Release Details
          </h1>
        </div>
        {!!options.length ? (
          <div className="h-[90%] overflow-auto">
            <GenericTable
              column={Object.keys(options[0])}
              row={options}
              searchField={options.length ? Object.keys(options[0])[0] : ""}
              searchTerm=""
            />
          </div>
        ) : (
          <div className="mb-2.5">
            <p className="w-1/2 opacity-50 text-sm">
              {data.createNewArtifactSection.subHeading}
            </p>
          </div>
        )}
      </div>
      <div>
        <button
          onClick={() => setShowReleaseArtifactForm(true)}
          className="inline-block px-16 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
        >
          Create artifact with this release
        </button>
      </div>
    </>
  );
};

export default RenderArtifacts;
