import {
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  PencilSquareIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { usePanelError } from "../../../shared/utils/dash-panel-error.context";
import {
  useAuthStore,
  useDashboardStore,
  useFleetAndDevicesStore
} from "@store/index";
import {
  ICreatePanelState,
  initialVals
} from "@store/dashboard/dashboard.store";
import networkService from "@services/network.service";
import { toast } from "react-toastify";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";
import { Badge, Button } from "@tremor/react";
import TransitionedMenu from "@/app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import useCreateHeatmapStore from "@/store/dashboard/create-heatmap.store";
import {
  DASH_PANEL_TYPE,
  barOrientationOptions
} from "@/interfaces/dashboard-panel.interface";
import { useMemo } from "react";

const TIME_COLS = ["bucket", "time", "timestamp"];

interface IHeaderProps {
  panel?: any;
  disableZoom?: boolean;
}
const GraphHeader: React.FC<IHeaderProps> = ({
  panel,
  disableZoom = false
}) => {
  const { panelError } = usePanelError();
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);

  const navigate = useNavigate();

  const [
    panels,
    activeDashboard,
    setPanels,
    setBlueprintPanel,
    setCreateBlueprintKind,
    setCreateBlueprintModalOpen
  ] = useDashboardStore((state) => [
    state.panels,
    state.activeDashboard,
    state.setPanels,
    state.setBlueprintPanel,
    state.setCreateBlueprintKind,
    state.setCreateBlueprintModalOpen
  ]);

  // get stores for diff panel types for edit config:
  const [setHeatmapInputValues] = useCreateHeatmapStore((state) => [
    state.setInputValues
  ]);

  const [setCreatePanelAppearance, setInputValues, setPanelName] =
    useDashboardStore((state) => [
      state.setCreatePanelAppearance,
      state.setInputValues,
      state.setPanelName
    ]);

  const { openConfirmDeleteModal } = useConfirmDelete();

  const isBlueprintPanel = useMemo(
    () => !!panel.blueprint_id,
    [panel.blueprint_id]
  );

  const deletePanel = async () => {
    const res = await networkService.delete<any>(
      `projects/${selectedProject.id}/dashboards/${activeDashboard.id}/panels/${panel.id}`,
      {},
      {
        headers: {
          "ORG-ID": user.selectedOrg.id
        }
      }
    );

    if (res.ok) {
      setPanels(panels.filter((p) => p.id !== panel.id));
      toast.success("Panel deleted successfully");
    }
  };

  const onEditClick = () => {
    setPanelName(panel.title);
    const newInputValues: ICreatePanelState["inputValues"] = {
      ...initialVals
    };

    switch (panel.panel_type) {
      case DASH_PANEL_TYPE.HEATMAP:
        newInputValues.selectedParams = panel.data_config["select"]
          .filter((col) => !TIME_COLS.includes(col.alias))
          .map((col) => ({
            value: col.param,
            label: col.param,
            data: null
          }));

        setHeatmapInputValues({
          ...panel.definition.options
        });

        setCreatePanelAppearance({ ...(panel.definition.options ?? {}) });

        break;

      case DASH_PANEL_TYPE.BAR_CHART:
        newInputValues.selectedParams = panel.data_config["select"]
          .filter((col) => !TIME_COLS.includes(col.alias))
          .map((col) => ({
            value: col.param,
            label: col.param,
            data: null
          }));

        const barChartInitialState = {
          ...(panel.definition.options ?? {}),
          orientation: barOrientationOptions.find(
            (opt) => opt.value === panel.definition.options.orientation
          )
        };

        setCreatePanelAppearance({ ...barChartInitialState });

        break;

      case DASH_PANEL_TYPE.LINE_CHART:
        newInputValues.selectedParams = panel.data_config["select"]
          .filter((col) => !TIME_COLS.includes(col.param))
          .map((col) => ({
            value: col.param,
            label: col.param,
            data: null
          }));

        setCreatePanelAppearance({ ...(panel.definition.options ?? {}) });

        break;

      case DASH_PANEL_TYPE.GAUGE:
      case DASH_PANEL_TYPE.PIE_CHART:
      case DASH_PANEL_TYPE.NUMERIC:
        newInputValues.selectedParams = [
          {
            label: panel.data_config["select"][0].param,
            value: panel.data_config["select"][0].param,
            data: null
          }
        ];

        setCreatePanelAppearance({ ...(panel.definition.options ?? {}) });

        break;
      case DASH_PANEL_TYPE.GENERIC:
        setCreatePanelAppearance({
          columnState: panel.definition.columnState
        });

        break;

      default:
        setInputValues({
          ...initialVals,
          ...panel.definition.options
        });
        break;
    }

    // set time bucket:
    panel.data_config["select"].forEach((param) => {
      if (
        param.expression?.pattern === "time_bucket('{timeBucket}', timestamp)"
      ) {
        const [timeBucket, timeBucketUnit] =
          param.expression?.values["timeBucket"].split(" ");
        newInputValues.timeBucket = parseInt(timeBucket);
        newInputValues.timeBucketUnit = timeBucketUnit;
      }
    });

    setInputValues(newInputValues);

    navigate("/dashboard/edit-panel-config/", {
      state: { editPanel: true, panel }
    });
  };

  const onCreateBlueprintClick = () => {
    setBlueprintPanel(panel);
    setCreateBlueprintKind("PANEL");
    setCreateBlueprintModalOpen(true);
  };

  const onDeleteClick = () => {
    openConfirmDeleteModal(
      deletePanel,
      "Are you sure you want to this delete panel?"
    );
  };

  return (
    <div className="flex flex-row justify-between relative z-10 items-center px-4 py-2">
      <span className="flex gap-2 items-center">
        <h5 className="text-lg">{panel.title}</h5>
        {panelError && (
          <a
            href="/"
            onClick={(e) => e.preventDefault()}
            data-tooltip-id="panel-error-tooltip"
            data-tooltip-content={panelError}
          >
            <ExclamationCircleIcon width={20} color="red" />
          </a>
        )}
        {isBlueprintPanel ? (
          <Badge
            className="cursor-pointer"
            onClick={() =>
              navigate(`/blueprints/panels/${panel.blueprint_id}`)
            }
          >
            blueprint
          </Badge>
        ) : null}
      </span>

      {!isBlueprintPanel ? (
        <TransitionedMenu
          className={"relative flex text-left border-background-layer3 mt-1"}
          menuPosition="right"
          buttonComponent={
            <Button icon={EllipsisVerticalIcon} variant="light" />
          }
        >
          {panel.panel_type !== DASH_PANEL_TYPE.GEO_MAP &&
          !isBlueprintPanel ? (
            <Menu.Item>
              <Button
                icon={PencilSquareIcon}
                onClick={onEditClick}
                variant="light"
                className="px-4 my-1 text-contentColor hover:text-contentColorLight"
                size="xs"
              >
                Edit
              </Button>
            </Menu.Item>
          ) : null}
          {!isBlueprintPanel ? (
            <Menu.Item>
              <Button
                icon={DocumentDuplicateIcon}
                onClick={onCreateBlueprintClick}
                variant="light"
                className="px-4 my-1 text-contentColor hover:text-contentColorLight"
                size="xs"
              >
                Create Blueprint
              </Button>
            </Menu.Item>
          ) : null}
          <Menu.Item>
            <Button
              icon={TrashIcon}
              onClick={onDeleteClick}
              color="red"
              variant="light"
              className="px-4 my-1"
              size="xs"
            >
              Delete
            </Button>
          </Menu.Item>
        </TransitionedMenu>
      ) : null}
      {/* <button onClick={onDeleteClick} className="z-10">
        <TrashIcon className="h-4 w-4 text-red-400 hover:text-red-500" />
      </button> */}
    </div>
  );
};

export default GraphHeader;
