import { Link } from "react-router-dom";
import { useAuthStore } from "../../../store";
import { data } from "../../shared/utils/data-file.util";

function CreateProject() {
  const [user] = useAuthStore((state) => [state.user]);

  return (
    <div className=" flex flex-grow justify-center items-center">
      <div>
        <div className="text-base text-primary mb-7 text-center">
          <span className="font-bold text-primaryLight">Create Project</span>{" "}
          <span className="font-bold text-base mx-0.5 text-contentColorLight">
            {" "}
            &#62;{" "}
          </span>
          Create fleets{" "}
          <span className="font-bold text-base mx-0.5 text-contentColorLight">
            {" "}
            &#62;{" "}
          </span>
          Add Devices{" "}
          <span className="font-bold text-base mx-0.5 text-contentColorLight">
            {" "}
            &#62;{" "}
          </span>
          Analyse & Manage{" "}
          <span className="font-bold text-base mx-0.5 text-contentColorLight">
            {" "}
            &#62;{" "}
          </span>
          Repeat
        </div>
        <div className="mb-[38px] flex flex-col justify-center items-center">
          <h1 className="text-2xl text-center font-medium mb-2.5">
            This is your new organisation : {user.selectedOrg.org_name}.
          </h1>
          <p className="text-sm text-contentColorLight text-center w-[468px]">
            {data.createProject.subHeading[0]}
          </p>
          <p className="text-sm text-contentColorLight text-center w-[468px]">
            {data.createProject.subHeading[1]}
          </p>
        </div>
        <div className="text-center">
          <Link
            to="/fleet-and-devices/projects/project-details"
            className="inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
          >
            Create New Project
          </Link>
          <div className="flex mt-4 text-center justify-center items-center">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14ZM8.75 8H11.75V9.5H7.25V4.25H8.75V8Z"
                  fill="#565759"
                />
              </svg>
            </span>
            <span className="ml-1.5 text-xs">In just 3 mins</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateProject;
