import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const deleteDevice = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string
) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteDeviceMutation = (fleetId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (deviceId: string) =>
      await deleteDevice(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getFleetDevices");
      }
    }
  );

  return res;
};
