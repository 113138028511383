import { Link, Navigate, Outlet, useLocation } from "react-router-dom";

type Props = {};

const subPathRenderToURl = {
  Shadows: "shadows",
  "Data Points": "data-points",
  Contexts: "contexts"
};

const Definitions = (props: Props) => {
  const location = useLocation();

  const pathSplits = location.pathname.split("/");

  if (pathSplits.length < 3) {
    return <Navigate to={"/definitions/shadows"} replace />;
  } else if (pathSplits.length === 3 && pathSplits[2].trim() === "") {
    return <Navigate to={"/definitions/shadows"} replace />;
  }

  const subPathFromURL = location.pathname.split("/")[2]?.toLowerCase();

  const subPathToRender =
    subPathFromURL === "shadows"
      ? "Shadows"
      : subPathFromURL === "contexts"
      ? "Contexts"
      : "Data Points";

  return (
    <>
      <div className="bg-background border-b border-background-layer3 lg:px-8 sm:px-6 ">
        <div className="relative flex flex-col justify-bet"></div>
        <div className="flex flex-col justify-between items-start pt-4 w-full">
          <h4 className="text-xl font-semibold text-contentColor">
            {"Definitions"}
          </h4>
        </div>
        <div className="mt-4 mb-2 flex items-center gap-1">
          <div className="text-sm text-contentColorLight">{`Defintions >`}</div>
          <Link
            to={`/definitions/${subPathRenderToURl[subPathToRender]}`}
            className="text-sm text-contentColorLight hover:underline"
          >
            {subPathToRender}
          </Link>
        </div>
      </div>

      <main className="rounded-md bg-background text-contentColor m-4 p-4">
        <Outlet />
      </main>
    </>
  );
};

export default Definitions;
