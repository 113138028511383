import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { OnboardingStep } from "../../../interfaces";
import { useServiceProvider } from "../hooks";
import { getAuthRedirect } from "../utils/helper.util";
import OnboardingSlider from "./onboarding-slider.component";
import OnboardingSteps from "./onboarding-steps.component";

import golainLogo from "../../../assets/images/golain_text_logo.svg";
// import clientLogo from '../../../assets/images/golain_logo.svg';
// import clientLogo from '../../../assets/images/golain_logo.svg';
import { data } from "../utils/data-file.util";
import { useGetOrganizations } from "../hooks/get/organizations";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";

const LayoutOnboarding: React.FC = () => {
  const navigate = useNavigate();
  const serviceProvider = useServiceProvider();
  const { data: orgs } = useGetOrganizations();

  if (!serviceProvider) {
    return null;
  } else if (
    serviceProvider.onboardingStatus === OnboardingStep.Organization
  ) {
    // if onboarding is not completed yet, redirect to the onboarding screens
    const redirectUri = getAuthRedirect(serviceProvider.onboardingStatus);
    return <Navigate to={redirectUri} />;
  }

  return (
    <div className="overflow-hidden lg:h-screen lg:flex font-dm-sans">
      <section className="overflow-y-auto lg:w-2/5">
        <div className="flex flex-col w-full max-w-2xl min-h-screen px-8 py-12 mx-auto xl:px-16 md:px-10">
          <div className="relative w-full flex items-center space-x-4 mb-9">
            {orgs?.length > 0 ? (
              <Button
                icon={() => <XMarkIcon width={24} />}
                onClick={() => navigate("/home")}
                variant="light"
                tooltip="Home"
                className="absolute right-0 top-0"
              />
            ) : null}
            {/* <div className="rounded-xl">
              <img width={50} src={clientLogo} alt="clientLogo" />
            </div> */}

            <div className="w-full flex justify-center items-center">
              {/* <p className="text-xs text-gray-500">Powered by</p> */}
              <div>
                <img
                  className="w-[300px] mr-2"
                  src={golainLogo}
                  alt="clientLogo"
                />
              </div>
            </div>
          </div>
          <OnboardingSteps></OnboardingSteps>
          <Outlet />
        </div>
      </section>

      <OnboardingSlider data={data.onboarding.orgDetails.right} />
    </div>
  );
};

export default LayoutOnboarding;
