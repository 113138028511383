import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import useShadowStore from "../../../store/shadow/shadow.store";
import { copyToClipboard, pasteFromClipboard } from "../utils/helper.util";
import Modal from "./modal.component";
import useThemeStore from "@store/theme.store";
import {
  ArrowsPointingInIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  ClipboardIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon
} from "@heroicons/react/24/outline";

const OverlayDatapointEditorModal = () => {
  const [datapoint, setDatapoint, maximizeDatapoint, setMaximizeDatapoint] =
    useShadowStore((state) => [
      state.datapoint,
      state.setDatapoint,
      state.maximizeDatapoint,
      state.setMaximizeDatapoint
    ]);
  const [customDatapoint, setCustomDatapoint] = useState("");
  const [theme] = useThemeStore((state) => [state.theme]);

  useEffect(() => {
    if (datapoint) {
      setCustomDatapoint(datapoint);
    }
  }, [datapoint]);

  const handleMinimizeShadow = () => {
    setDatapoint(customDatapoint);
    setMaximizeDatapoint({ state: false, readOnly: undefined });
  };

  const handleShadowEditor = (value: string) => {
    setCustomDatapoint(value);
  };

  const saveChanges = () => {
    setDatapoint(customDatapoint);
    setMaximizeDatapoint({ state: false, readOnly: undefined });
  };

  const downloadFile = () => {
    const filename = "unnamed_datapoint";

    const url = window.URL.createObjectURL(
      new Blob([customDatapoint], { type: "text/plain" })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}.proto`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Modal
      open={maximizeDatapoint.state}
      setOpen={(val: boolean) =>
        setMaximizeDatapoint({
          state: val,
          readOnly: maximizeDatapoint.readOnly
        })
      }
    >
      <div className="relative px-[26px] py-[18px] bg-background rounded-lg border border-solid border-background-layer3 text-contentColor">
        <div className="flex justify-between items-center ">
          <h1 className="font-medium text-lg">Data Point Definition</h1>
          <ul className="flex justify-center items-center">
            <li className="ml-10 items-center cursor-pointer">
              <button
                onClick={() => copyToClipboard(customDatapoint)}
                className="ml-4 mx-1 text-sm flex justify-center items-center"
              >
                <ClipboardDocumentIcon width={20} />

                <span className="pl-2 text-sm font-medium">Copy</span>
              </button>
            </li>

            {!!maximizeDatapoint.readOnly === false && (
              <li className="ml-10 items-center cursor-pointer">
                <button
                  onClick={() =>
                    pasteFromClipboard().then((res: string) =>
                      setCustomDatapoint(res)
                    )
                  }
                  className="ml-4 mx-1 text-sm flex justify-center items-center"
                >
                  <ClipboardDocumentCheckIcon width={20} />

                  <span className="pl-2 text-sm font-medium">Paste</span>
                </button>
              </li>
            )}

            {!!maximizeDatapoint.readOnly === false && (
              <li className="ml-10 items-center cursor-pointer">
                <button className="ml-4 mx-1 text-sm flex justify-center items-center">
                  <CloudArrowUpIcon width={20} />

                  <span className="pl-2 text-sm font-medium">Upload</span>
                </button>
              </li>
            )}

            <li className="ml-10 items-center cursor-pointer">
              <button
                onClick={downloadFile}
                className="ml-4 mx-1 text-sm flex justify-center items-center"
              >
                <CloudArrowDownIcon width={20} />

                <span className="pl-2 text-sm font-medium">Download</span>
              </button>
            </li>

            <li className="ml-10 flex items-center cursor-pointer">
              <button
                onClick={handleMinimizeShadow}
                className="p-2.5 bg-background-layer1 rounded"
              >
                <ArrowsPointingInIcon
                  width={20}
                  className="text-primaryLight"
                />
              </button>
            </li>
          </ul>
        </div>

        <div className="mt-8">
          <Editor
            height="60vh"
            language="cpp"
            value={customDatapoint}
            onChange={handleShadowEditor}
            theme={theme === "golain" || theme === "none" ? "vs" : "vs-dark"}
            options={{
              readOnly: !!maximizeDatapoint.readOnly,
              lineNumbers: "off",
              minimap: { enabled: false },
              scrollbar: { vertical: "hidden" },
              overviewRulerBorder: false,
              overviewRulerLanes: 0,
              folding: false,
              matchBrackets: "never",
              theme: theme === "golain" || theme === "none" ? "vs" : "vs-dark"
            }}
          />
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={saveChanges}
            className="flex px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
          >
            Done
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OverlayDatapointEditorModal;
