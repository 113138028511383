import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IDevice, IDeviceResponse } from "@interfaces/fad.interface";
import { ITag } from "@/interfaces/tags.interface";

interface ITagsResponse {
  ok: number;
  data: {
    tags: ITag[];
  };
}

export const getDevicesTags = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  params: any
) => {
  const { data, ok } = await networkService.get<ITagsResponse>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/tags`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.tags;
  } else return null;
};

export const useGetDeviceTags = (
  fleetId: string,
  deviceId: string,
  params: any = {},
  successCb: ((data: ITag[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getDeviceTags", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const tags = await getDevicesTags(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        params
      );

      successCb && successCb(tags);

      return tags;
    },
    {
      enabled: params?.device_id
        ? !!selectedProject.id && !!user.selectedOrg.id && !!params?.device_id
        : !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
