export const GRID_COLS = {
  xxl: 5,
  xl: 4,
  lg: 4,
  md: 3,
  sm: 2,
  xs: 1,
  xxs: 1
};

export function generateLayout(panels, maxCols: number, resizeHandles) {
  const layout = [];

  let y = 0;

  const mapTypes = ["GEO_MAP", "MAP_LOCATION"];
  let x = 0;
  panels.forEach((panel) => {
    const isMapType =
      panel.panel_type === "GENERIC"
        ? mapTypes.includes(panel.definition.panel_type)
        : mapTypes.includes(panel.panel_type);

    let curW = 1,
      curH = 1;
    if (x + curW > maxCols) {
      x = 0;
      y++;
    }

    layout.push({
      i: panel.id,
      x: x,
      y: y,
      w: curW,
      h: curH,
      minW: 1,
      minH: 1,
      // maxW: maxCols,
      static: false,
      isResizable: true,
      resizeHandles: resizeHandles
    });
    x += curW;

    if (x > maxCols) {
      x = 0;
      y++;
    }
  });

  return layout;
}
