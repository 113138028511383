import { IInsights } from "../../../interfaces";
import { AddOverlayLayout } from "../../shared/components";

interface InsightsInterface {
  insights: IInsights;
  insightOpen: boolean;
  setInsightOpen: (value: boolean) => void;
}

const Insights: React.FC<InsightsInterface> = ({
  insights,
  insightOpen,
  setInsightOpen
}) => {
  return (
    <AddOverlayLayout
      title="Insights of last 24 hours"
      overlayOpen={insightOpen}
      setOverlayOpen={setInsightOpen}
    >
      <ul className="flex flex-col items-center">
        <li className="relative mb-5">
          <div className="w-full h-0.5 rounded bg-[#ECC705]"></div>
          <div className="flex p-4 bg-[#FFFBE6] rounded">
            <div className="flex justify-between pt-1">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.85953 2.57347L1.21286 12.0001C1.09644 12.2018 1.03484 12.4303 1.03418 12.6631C1.03353 12.896 1.09385 13.1249 1.20914 13.3272C1.32443 13.5294 1.49068 13.698 1.69133 13.816C1.89199 13.9341 2.12006 13.9976 2.35286 14.0001H13.6462C13.879 13.9976 14.1071 13.9341 14.3077 13.816C14.5084 13.698 14.6746 13.5294 14.7899 13.3272C14.9052 13.1249 14.9655 12.896 14.9649 12.6631C14.9642 12.4303 14.9026 12.2018 14.7862 12.0001L9.13953 2.57347C9.02068 2.37754 8.85334 2.21555 8.65366 2.10313C8.45397 1.9907 8.22868 1.93164 7.99953 1.93164C7.77037 1.93164 7.54508 1.9907 7.3454 2.10313C7.14571 2.21555 6.97837 2.37754 6.85953 2.57347V2.57347Z" stroke="#ECC705" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 6V8.66667" stroke="#ECC705" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 11.3333H8.00583" stroke="#ECC705" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div className="ml-4">
              <h1 className="text-base font-normal mb-2">Warning</h1>
              <p className="text-sm opacity-70 text-black">
                Warning logs that originated from the device.
              </p>
            </div>
            <div className="ml-7">
              <h1 className="text-base font-bold">{insights?.warning || 0}</h1>
            </div>
          </div>
        </li>

        <li className="relative mb-5">
          <div className="w-full h-0.5 rounded bg-[#E21B17]"></div>
          <div className="flex p-4 bg-[#FDE9E8] rounded">
            <div className="flex justify-between pt-1">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.85953 2.57347L1.21286 12.0001C1.09644 12.2018 1.03484 12.4303 1.03418 12.6631C1.03353 12.896 1.09385 13.1249 1.20914 13.3272C1.32443 13.5294 1.49068 13.698 1.69133 13.816C1.89199 13.9341 2.12006 13.9976 2.35286 14.0001H13.6462C13.879 13.9976 14.1071 13.9341 14.3077 13.816C14.5084 13.698 14.6746 13.5294 14.7899 13.3272C14.9052 13.1249 14.9655 12.896 14.9649 12.6631C14.9642 12.4303 14.9026 12.2018 14.7862 12.0001L9.13953 2.57347C9.02068 2.37754 8.85334 2.21555 8.65366 2.10313C8.45397 1.9907 8.22868 1.93164 7.99953 1.93164C7.77037 1.93164 7.54508 1.9907 7.3454 2.10313C7.14571 2.21555 6.97837 2.37754 6.85953 2.57347V2.57347Z" stroke="#E21B17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 6V8.66667" stroke="#E21B17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 11.3333H8.00583" stroke="#E21B17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

            </div>
            <div className="ml-4">
              <h1 className="text-base font-normal mb-2">Error</h1>
              <p className="text-sm opacity-70 text-black">
                Errors that occured on the device. These are ERROR and FATAL logs - available based on persistent logging settings on device.
              </p>
            </div>
            <div className="ml-7">
              <h1 className="text-base font-bold">{insights?.error || 0}</h1>
            </div>
          </div>
        </li>

        <li className="relative mb-5">
          <div className="w-full h-0.5 rounded bg-[#00AC47]"></div>
          <div className="flex p-4 bg-[#E6F7ED] rounded">
            <div className="flex justify-between pt-1">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_184_15836)">
                  <path d="M8.00016 10.0001C10.5775 10.0001 12.6668 7.91074 12.6668 5.33341C12.6668 2.75609 10.5775 0.666748 8.00016 0.666748C5.42283 0.666748 3.3335 2.75609 3.3335 5.33341C3.3335 7.91074 5.42283 10.0001 8.00016 10.0001Z" stroke="#00AC47" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.47317 9.26008L4.6665 15.3334L7.99984 13.3334L11.3332 15.3334L10.5265 9.25342" stroke="#00AC47" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_184_15836">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </div>
            <div className="ml-4">
              <h1 className="text-base font-normal mb-2">Success</h1>
              <p className="text-sm opacity-70 text-black">
                These are INFO and DEBUG logs. Available based on the log level set on the device.
              </p>
            </div>
            <div className="ml-7">
              <h1 className="text-base font-bold">{insights?.success || 0}</h1>
            </div>
          </div>
        </li>


      </ul>
    </AddOverlayLayout>
  );
};

export default Insights;
