import React, { useEffect, useMemo, useState } from "react";
import {
  IContext,
  IInput,
  extractVariableFromContext
} from "./rule-engine.helper";
import {
  CheckCircleIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import { Button } from "@tremor/react";

const AdditionalDataSiddebar = ({ actionData }) => {
  const [inputsUsed, setInputsUsed] = useState({});
  const [contextsUsed, setContextsUsed] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    if (
      actionData.inputs === undefined ||
      actionData.fetchedContexts === undefined
    ) {
      return;
    }
    const _tempActionInputMap = actionData.inputs?.reduce((acc, curr) => {
      acc[curr.key] = 0;
      return acc;
    }, {});

    const _tempActionContextstMap = actionData.fetchedContexts?.reduce(
      (acc, curr) => {
        acc[curr.key] = 0;
        return acc;
      },
      {}
    );

    actionData.fetchedContexts?.forEach((context: IContext) => {
      const variable = extractVariableFromContext(context);
      let foundInInputs = false;
      actionData.inputs?.forEach((input: IInput) => {
        if (variable.value && variable.value === input.key) {
          _tempActionInputMap[input.key]++;
          foundInInputs = true;
        }
      });

      if (!foundInInputs) {
        if (variable.value && variable.value in _tempActionContextstMap) {
          _tempActionContextstMap[variable.value]++;
        }
      }
    });

    setInputsUsed(_tempActionInputMap);
    setContextsUsed(_tempActionContextstMap);
  }, [actionData]);

  const { data: shadowDefs } = useGetShadowDefinitions({
    fields: "shadow_proto_structure"
  });

  return (
    <>
      {!sidebarOpen ? (
        <Button
          icon={ChevronDoubleLeftIcon}
          tooltip="Available Data"
          variant="light"
          className="fixed top-24 right-8 z-10 !text-contentColorLight hover:text-contentColorLight hover:bg-background-layer3 p-2 rounded-md bg-background-layer2 transition-colors duration-200"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        />
      ) : null}
      <aside
        className={`border-l right-0 ml-auto flex-grow border-background-layer3 text-sm py-12 px-4 bg-background z-10 mt-20 text-contentColor h-full w-80 lg:w-72 2xl:w-64 fixed inset-y-0 left-0 transition duration-200 transform 
          ${
            sidebarOpen ? "translate-x-0 ease-in" : "translate-x-full ease-out"
          }`}
      >
        <Button
          icon={ChevronDoubleRightIcon}
          variant="light"
          color="gray"
          className="absolute top-2 left-2 text-contentColorLight hover:text-contentColorLight hover:bg-background-layer3 p-2 rounded-md bg-background-layer2 transition-colors duration-200"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        />
        <h1 className="text-2xl font-medium mb-3">Available Data</h1>
        <hr className="border-background-layer3 mb-4" />

        <div className="description mb-4">
          Any inputs, fetched context's data will be visible here
        </div>
        {actionData.inputs?.length ? (
          <div className="text-xs">
            <h3 className="font-semibold text-base mb-2">Inputs:</h3>
            <div className="flex flex-col gap-2">
              {actionData.inputs.map((input: IInput, ind: number) => {
                return (
                  <div
                    key={ind}
                    className="flex justify-between gap-2 p-2 border border-green-500 rounded-md"
                  >
                    <div className="flex gap-2">
                      <span className="font-semibold max-w-xs">
                        {input.key}
                      </span>
                      <span className="font-semibold">:</span>
                      <span>{input.type}</span>
                    </div>
                    <div>
                      {inputsUsed[input.key] !== undefined &&
                      inputsUsed[input.key] > 0 ? (
                        <CheckCircleIcon color="green" width={20} />
                      ) : (
                        <ExclamationTriangleIcon
                          className="text-yellow-500"
                          width={20}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {actionData.fetchedContexts?.length ? (
          <div className="text-xs mt-2">
            <h3 className="font-semibold text-base mb-2">Contexts:</h3>
            <div className="flex flex-col gap-2">
              {actionData.fetchedContexts.map(
                (context: IContext, ind: number) => {
                  return (
                    <div
                      key={context.key + ind}
                      className="flex gap-2 flex-col p-2 border border-yellow-500 rounded-md"
                    >
                      <div className="flex justify-between">
                        <div className="flex gap-2">
                          <span className="font-semibold max-w-xs">
                            {context.key}
                          </span>
                          <span className="font-semibold">:</span>
                          <span>{context.type}</span>
                        </div>
                        <div>
                          {contextsUsed[context.key] !== undefined &&
                          contextsUsed[context.key] > 0 ? (
                            <CheckCircleIcon color="green" width={20} />
                          ) : (
                            <ExclamationTriangleIcon
                              className="text-yellow-500 "
                              width={20}
                            />
                          )}
                        </div>
                      </div>
                      {context.type === "device-shadow" ? (
                        <FetchedContextData
                          shadowDefs={shadowDefs}
                          context={context}
                        />
                      ) : null}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        ) : null}
      </aside>
    </>
  );
};

export default AdditionalDataSiddebar;

const FetchedContextData = ({ context, shadowDefs }) => {
  const selectedShadowDef = useMemo(
    () => shadowDefs?.find((s) => s.id === context.shadow_definition_id),
    [context.shadow_definition_id, shadowDefs]
  );

  if (!selectedShadowDef) return null;

  return (
    <>
      <h3 className="font-bold">Shadow Structure:</h3>
      <pre className="max-h-[10rem] overflow-auto">
        {JSON.stringify(
          simplifyStructure(selectedShadowDef.shadow_proto_structure),
          null,
          4
        )}
      </pre>
    </>
  );
};

interface SimplifiedStructure {
  [key: string]: string | SimplifiedStructure;
}
function simplifyStructure(originalStructure: any): SimplifiedStructure {
  const simplified: SimplifiedStructure = {};

  for (const key in originalStructure) {
    if (originalStructure.hasOwnProperty(key)) {
      const structure = originalStructure[key];

      if (typeof structure === "object" && !Array.isArray(structure)) {
        // Recursively simplify nested structures
        const simplifiedNested = simplifyStructure(structure);

        // Only include the nested structure if it's not empty
        if (Object.keys(simplifiedNested).length > 0) {
          simplified[key] = simplifiedNested.structure || simplifiedNested;
        } else {
          simplified[key] = structure.structure || structure;
        }
      } else if (
        key !== "cardinality" &&
        key !== "name" &&
        key !== "options" &&
        key !== "structure"
      ) {
        // Omit specified keys
        simplified[key] = structure;
      }
    }
  }

  return simplified;
}
