import { useState } from "react";
import { Field, Form, Formik } from "formik";

interface IShowDashboardCreate {
  showDashCreateOpen: boolean;
  setShowDashCreateOpen: (x: boolean) => void;
  handleCreate: (name: string, description: string) => void;
}

const ShowDashCreate: React.FC<IShowDashboardCreate> = ({
  showDashCreateOpen,
  setShowDashCreateOpen,
  handleCreate
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [focusName, setFocusName] = useState(false);
  const [focusDesc, setFocusDesc] = useState(false);

  const handleSubmit = () => {
    if (name.length && description.length) {
      setName("");
      setDescription("");
      handleCreate(name, description);
    }
  };

  return (
    <div className="text-gray-800 bg-gray-100 font-dm-sans">
      <div
        onClick={() => setShowDashCreateOpen(false)}
        className={
          "fixed inset-0 z-40 transition-opacity bg-black opacity-50 " +
          (showDashCreateOpen ? "block" : "hidden")
        }
      ></div>

      <div
        className={
          "fixed inset-y-0 right-0 z-50 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-full sm:w-[28rem] " +
          (showDashCreateOpen
            ? "translate-x-0 ease-in"
            : "translate-x-full ease-out")
        }
      >
        <div className="p-6">
          <Formik
            initialValues={{ name: "", description: "" }}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col flex-1">
              <h1 className="text-3xl text-left font-medium mb-5">
                Create a new dashboard
              </h1>
              <div className="flex-1">
                <div className="mb-5">
                  <label className="text-sm font-medium text-gray-600">
                    Dashboard Name
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={name}
                    className={`block w-full p-3 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                      !name.length && focusName
                        ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                        : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                    }`}
                    onChange={(e) => setName(e.target.value)}
                    onFocus={() => setFocusName(true)}
                    onBlur={() => setFocusName(false)}
                  />
                </div>
                <div className="mb-5">
                  <label className="text-sm font-medium text-gray-600">
                    DashboardDescription
                  </label>
                  <Field
                    as="textarea"
                    rows="4"
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={description}
                    className={`block w-full p-3 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                      !description.length && focusDesc
                        ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                        : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                    }`}
                    onChange={(e) => setDescription(e.target.value)}
                    onFocus={() => setFocusDesc(true)}
                    onBlur={() => setFocusDesc(false)}
                  />
                </div>
              </div>

              <div className="w-full flex items-center mt-4 space-x-4">
                <button
                  className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
                  onClick={() => setShowDashCreateOpen(false)}
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  className={`w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80 ${
                    (!name.length || !description.length) &&
                    "bg-gray-500 cursor-not-allowed"
                  }`}
                  type="submit"
                  disabled={!name.length || !description.length}
                >
                  {" "}
                  Create{" "}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ShowDashCreate;
