import React, { useState, useEffect } from 'react';
import Editor from '@monaco-editor/react';
import useShadowStore from '../../../store/shadow/shadow.store';
import { copyToClipboard, pasteFromClipboard } from '../utils/helper.util';

const defaultShadow = `/*syntax = "proto3";\n` +
    `message Shadow {\n` +
    `\tint32 red = 1;\n` +
    `\tint32 green = 2;\n` +
    `\tint32 blue = 3;\n` +
    `\tbool on = 4;\n` +
    `}*/`

const OverlayShadowEditorModal = () => {
    const [shadow, setShadow, maximizeShadow, setMaximizeShadow] = useShadowStore((state) => [state.shadow, state.setShadow, state.maximizeShadow, state.setMaximizeShadow]);
    const [customShadow, setCustomShadow] = useState(defaultShadow);

    useEffect(() => {
        if (shadow) {
            setCustomShadow(shadow);
        }
    }, [shadow]);

    const handleMinimizeShadow = () => {
        setShadow(customShadow);
        setMaximizeShadow({ state: false, readOnly: undefined });
    }

    const handleShadowEditor = (value: string) => {
        setCustomShadow(value);
    }

    const saveChanges = () => {
        setShadow(customShadow);
        setMaximizeShadow({ state: false, readOnly: undefined });
    }

    const downloadFile = () => {
        const filename = "unnamed";

        const url = window.URL.createObjectURL(new Blob([customShadow], { type: "text/plain" }));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute('download', `${filename}.proto`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <>
            {maximizeShadow.state ? (
                <div className="fixed w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
                    <div className="top-0 left-0 right-0 bottom-0 w-full h-screen bg-[#1010108c] backdrop-blur-[5px]"></div>
                    <div className="fixed w-full h-full flex items-center justify-center">

                        <div className="relative w-7/12 px-[26px] py-[18px] bg-[#F7F6F3] rounded-lg border border-solid border-[#D4D4D4]">
                            <div className="flex justify-between items-center ">
                                <h1 className="font-medium text-lg">Shadow Definition</h1>
                                <ul className="flex justify-center items-center">
                                    <li className="ml-10 items-center cursor-pointer">
                                        <button
                                            onClick={() => copyToClipboard(customShadow)}
                                            className="ml-4 mx-1 text-sm flex justify-center items-center">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.25 4.5V2.25C5.25 2.05109 5.32902 1.86032 5.46967 1.71967C5.61032 1.57902 5.80109 1.5 6 1.5H15C15.1989 1.5 15.3897 1.57902 15.5303 1.71967C15.671 1.86032 15.75 2.05109 15.75 2.25V12.75C15.75 12.9489 15.671 13.1397 15.5303 13.2803C15.3897 13.421 15.1989 13.5 15 13.5H12.75V15.75C12.75 16.164 12.4125 16.5 11.9948 16.5H3.00525C2.90635 16.5006 2.8083 16.4816 2.71674 16.4442C2.62519 16.4068 2.54192 16.3517 2.47174 16.282C2.40156 16.2123 2.34584 16.1294 2.30779 16.0381C2.26974 15.9468 2.2501 15.8489 2.25 15.75L2.25225 5.25C2.25225 4.836 2.58975 4.5 3.00675 4.5H5.25ZM3.7515 6L3.75 15H11.25V6H3.7515ZM6.75 4.5H12.75V12H14.25V3H6.75V4.5ZM5.25 8.25H9.75V9.75H5.25V8.25ZM5.25 11.25H9.75V12.75H5.25V11.25Z" fill="black" />
                                            </svg>

                                            <span className="pl-2 text-sm font-medium">Copy</span>
                                        </button>
                                    </li>

                                    {!!maximizeShadow.readOnly === false && <li className="ml-10 items-center cursor-pointer">
                                        <button
                                            onClick={() => pasteFromClipboard().then(res => setCustomShadow(res))}
                                            className="ml-4 mx-1 text-sm flex justify-center items-center">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.75 2.244V15.756C15.7484 15.953 15.6694 16.1414 15.5301 16.2806C15.3907 16.4198 15.2022 16.4986 15.0052 16.5H2.99475C2.79736 16.5 2.60804 16.4216 2.4684 16.2821C2.32875 16.1426 2.2502 15.9534 2.25 15.756V2.244C2.25157 2.04704 2.33056 1.85859 2.46991 1.71938C2.60926 1.58018 2.79778 1.50137 2.99475 1.5H15.0052C15.4163 1.5 15.75 1.833 15.75 2.244ZM14.25 3H3.75V15H14.25V3ZM8.46975 9.84075L11.652 6.65925L12.7125 7.71975L8.46975 11.9625L5.55225 9.045L6.6135 7.9845L8.46975 9.84075Z" fill="black" />
                                            </svg>


                                            <span className="pl-2 text-sm font-medium">Paste</span>
                                        </button>
                                    </li>}

                                    {!!maximizeShadow.readOnly === false && <li className="ml-10 items-center cursor-pointer">
                                        <button
                                            className="ml-4 mx-1 text-sm flex justify-center items-center">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.25 14.25H15.75V15.75H2.25V14.25ZM9.75 4.371V12.75H8.25V4.371L3.69675 8.925L2.63625 7.8645L9 1.5L15.3638 7.86375L14.3032 8.92425L9.75 4.3725V4.371Z" fill="black" />
                                            </svg>

                                            <span className="pl-2 text-sm font-medium">Upload</span>
                                        </button>
                                    </li>}

                                    <li className="ml-10 items-center cursor-pointer">
                                        <button
                                            onClick={downloadFile}
                                            className="ml-4 mx-1 text-sm flex justify-center items-center">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.25 14.25H15.75V15.75H2.25V14.25ZM9.75 9.879L14.3032 5.325L15.3638 6.3855L9 12.75L2.63625 6.38625L3.69675 5.325L8.25 9.8775V1.5H9.75V9.879Z" fill="black" />
                                            </svg>


                                            <span className="pl-2 text-sm font-medium">Download</span>
                                        </button>
                                    </li>

                                    <li className="ml-10 flex items-center cursor-pointer">
                                        <button onClick={handleMinimizeShadow} className="p-2.5 bg-white rounded">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 7H22V9H16V3H18V7ZM8 9H2V7H6V3H8V9ZM18 17V21H16V15H22V17H18ZM8 15V21H6V17H2V15H8Z" fill="#546CCC" />
                                            </svg>
                                        </button>
                                    </li>
                                </ul>
                            </div>

                            <div className="mt-8">
                                <Editor
                                    height="60vh"
                                    language="cpp"
                                    value={customShadow}
                                    onChange={handleShadowEditor}
                                    options={
                                        {
                                            lineNumbers: 'off',
                                            minimap: { enabled: false },
                                            scrollbar: { vertical: "hidden" },
                                            overviewRulerBorder: false,
                                            overviewRulerLanes: 0,
                                            folding: false,
                                            matchBrackets: "never",
                                        }
                                    }
                                />
                            </div>

                            <div className="flex justify-end mt-6">
                                <button
                                    onClick={saveChanges}
                                    className="flex px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                                >
                                    Done
                                </button>
                            </div>
                        </div>

                    </div>
                </div>) : ""}
        </>
    );
}

export default OverlayShadowEditorModal;