import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function HelpDropdownMenu() {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={`ml-6 w-10 h-10 flex justify-center items-center bg-background-layer1 rounded-full`}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18ZM9 16.2C10.9096 16.2 12.7409 15.4414 14.0912 14.0912C15.4414 12.7409 16.2 10.9096 16.2 9C16.2 7.09044 15.4414 5.25909 14.0912 3.90883C12.7409 2.55857 10.9096 1.8 9 1.8C7.09044 1.8 5.25909 2.55857 3.90883 3.90883C2.55857 5.25909 1.8 7.09044 1.8 9C1.8 10.9096 2.55857 12.7409 3.90883 14.0912C5.25909 15.4414 7.09044 16.2 9 16.2ZM8.1 11.7H9.9V13.5H8.1V11.7ZM9.9 10.2195V10.8H8.1V9.45C8.1 9.2113 8.19482 8.98239 8.3636 8.8136C8.53239 8.64482 8.7613 8.55 9 8.55C9.25567 8.54998 9.50608 8.47737 9.72209 8.3406C9.9381 8.20383 10.1108 8.00853 10.2202 7.77742C10.3295 7.54632 10.371 7.28892 10.3398 7.03516C10.3086 6.7814 10.206 6.54173 10.0438 6.34402C9.88174 6.14631 9.66682 5.99871 9.4241 5.91837C9.18138 5.83804 8.92083 5.82828 8.67278 5.89023C8.42473 5.95219 8.19937 6.08331 8.02293 6.26833C7.84649 6.45336 7.72621 6.68469 7.6761 6.9354L5.9103 6.5817C6.01977 6.03458 6.27256 5.52633 6.64283 5.10893C7.0131 4.69152 7.48757 4.37992 8.01773 4.20599C8.54789 4.03205 9.11474 4.00202 9.66031 4.11895C10.2059 4.23589 10.7106 4.4956 11.1229 4.87154C11.5352 5.24748 11.8403 5.72617 12.007 6.25866C12.1737 6.79115 12.196 7.35835 12.0716 7.90228C11.9472 8.4462 11.6806 8.94735 11.2991 9.35448C10.9175 9.76161 10.4347 10.0601 9.9 10.2195Z"
              fill="#546CCC"
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                  href="https://dev.docs.golain.io/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Documentation
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                  href="mailto:support@golain.io"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Contact us
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default HelpDropdownMenu;
