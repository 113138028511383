import React, { useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import dateService from "@services/date.service";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  PlusIcon
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { DebouncedInput } from "@app/shared/components/deboundced-input.component";
import { reactTableFuzzyFilter } from "@app/shared/utils/helper.util";
import Pagination from "@app/shared/components/pagination.component";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import { Button } from "@tremor/react";

interface IShadowDefRow {
  id: string;
  name: string;
  created: string;
  version: string;
  usedBy: React.ReactNode;
}

type Props = {};

const ShadowDefinitionsAll = (props: Props) => {
  const [shadows, setShadowDefs] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const navigate = useNavigate();

  useGetShadowDefinitions(
    { fields: "shadow_proto_structure" },
    (shadowDefs) => {
      let _shadowDefs = shadowDefs ?? [];
      _shadowDefs = _shadowDefs.map((sd) => ({
        ...sd,
        created_at_local: dateService.convertUTCToLocalDate(sd.created_at)
      }));

      setShadowDefs(_shadowDefs);
    }
  );

  const columns = useMemo<ColumnDef<IShadowDefRow, any>[]>(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: "Name",
        cell: ({ getValue, row }) => {
          return (
            <Link
              to={`${row.original.id}?tab=details`}
              className="hover:underline"
            >
              {getValue()}
            </Link>
          );
        },
        size: 50
      },
      {
        id: "created_at",
        accessorKey: "created_at_local",
        header: "Created At",
        cell: ({ getValue }) => {
          return <div className="text-gray-500">{getValue()}</div>;
        },
        size: 30
      },
      {
        id: "version",
        accessorKey: "version",
        header: "Version",
        cell: ({ getValue }) => {
          return <div className="text-gray-500">{getValue()}</div>;
        },
        size: 30
      },
      {
        id: "usedBy",
        header: "Used By",
        cell: ({ row }) => {
          return (
            <div
              className="text-gray-500 flex gap-2 justify-center cursor-pointer"
              onClick={() => {
                navigate(`${row.original.id}?tab=usage`);
              }}
            >
              <span>Used By</span>
              <ChevronRightIcon width={14} className="text-primary" />
            </div>
          );
        },
        size: 30
      }
    ],
    [navigate]
  );

  const table = useReactTable({
    data: shadows,
    columns,
    state: {
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: reactTableFuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="max-w-sm">
          <DebouncedInput
            value={globalFilter ?? ""}
            icon={MagnifyingGlassIcon}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-background-layer3 bg-background text-contentColor"
            placeholder="Search all columns..."
          />
        </div>
        <Button
          onClick={() =>
            navigate("/fleet-and-devices/projects/shadow-Policy/", {
              state: "create-shadow-definition"
            })
          }
          icon={PlusIcon}
          className="bg-primary !text-white flex-shrink hover:bg-primaryLight"
        >
          Create Shadow Definition
        </Button>
      </div>
      <hr className="border-background-layer3" />
      <div className="max-h-[55vh] overflow-y-scroll">
        {/* table */}
        <table className="w-full h-full my-6">
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width: header.column.getSize()
                      }}
                      className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                : "",
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <ArrowUpIcon width={10} />,
                              desc: <ArrowDownIcon width={10} />
                            }[header.column.getIsSorted() as string] ??
                              (header.column.getCanSort() ? (
                                <ArrowsUpDownIcon width={10} />
                              ) : null)}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>{""}</div>
                          ) : null}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <>
                  <tr
                    key={row.id}
                    className="bg-background-layer1 border-b border-background-layer3"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="mx-2 text-sm text-center p-2 py-3"
                          style={{
                            width: cell.column.getSize()
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
        <Pagination<IShadowDefRow> table={table} />
      </div>
    </div>
  );
};

export default ShadowDefinitionsAll;
