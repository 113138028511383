import { useState, useEffect, useMemo, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { isEmpty } from "lodash";
import { Button } from "@tremor/react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { IOption } from "@/interfaces";

interface ILineInput {
  linesParams: IOption;
  label: string;
  color: IOption;
  labelName: string;
  colorName: string;
  paramName: string;
}

interface ILineError {
  linesParams: boolean;
  label: boolean;
  color: boolean;
}

export const LineChartForm = ({
  onChangeHandler,
  enableCreatebutton,
  params,
  initialData = null
}) => {
  const [linesInputs, setLinesInputs] = useState<ILineInput[]>(
    initialData ?? []
  );
  const [error, setErrors] = useState<ILineError[]>(
    initialData
      ? initialData.map((d) => ({
          linesParams: false,
          label: false,
          color: false
        }))
      : []
  );

  const [selectOptions, setSelectOptions] = useState(params);

  useEffect(() => {
    setSelectOptions(params);
  }, [params]);

  const initialValues = useMemo(
    () =>
      linesInputs.map((line) => {
        return {
          [line.labelName]: "",
          [line.colorName]: "",
          [line.paramName]: ""
        };
      }),
    [linesInputs]
  );

  const lineColorOptions = [
    { value: "zinc", label: "zinc" },
    { value: "stone", label: "stone" },
    { value: "red", label: "red" },
    { value: "orange", label: "orange" },
    { value: "amber", label: "amber" },
    { value: "yellow", label: "yellow" },
    { value: "lime", label: "lime" },
    { value: "green", label: "green" },
    { value: "emerald", label: "emerald" },
    { value: "teal", label: "teal" },
    { value: "cyan", label: "cyan" },
    { value: "sky", label: "sky" },
    { value: "blue", label: "blue" },
    { value: "indigo", label: "indigo" },
    { value: "violet", label: "violet" },
    { value: "purple", label: "purple" },
    { value: "fuchsia", label: "fuchsia" },
    { value: "pink", label: "pink" },
    { value: "rose", label: "rose" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleAddLine = (e: any) => {
    e.preventDefault();
    if (linesInputs.length < params.length) {
      const newEntry = linesInputs.length;
      setErrors((errors) => [
        ...errors,
        { linesParams: false, label: false, color: false }
      ]);
      setLinesInputs((inputs) => [
        ...inputs,
        {
          linesParams: null,
          label: "",
          color: null,
          labelName: `lineLabel_${newEntry}`,
          colorName: `lineColor_${newEntry}`,
          paramName: `lineParam_${newEntry}`
        }
      ]);
    }
  };

  const handleChange = (idx: number, key: string, value: any) => {
    let newInputs = [...linesInputs];
    newInputs[idx][key] = value;
    setLinesInputs(newInputs);
  };

  const isValid = useCallback(() => {
    let errVal = true;
    let errors = [...error];

    if (linesInputs.length < 1) {
      return true;
    }

    linesInputs.forEach((line, lineInd) => {
      if (!line.label.length) {
        errors[lineInd].label = true;
        errVal = false;
      } else errors[lineInd].label = false;

      if (!line.color?.value?.length) {
        errors[lineInd].color = true;
        errVal = false;
      } else errors[lineInd].color = false;

      if (isEmpty(line.linesParams ?? {})) {
        errors[lineInd].linesParams = true;
        errVal = false;
      } else errors[lineInd].linesParams = false;
    });

    setErrors(errors);

    return errVal;
  }, [error, linesInputs]);

  useEffect(() => {
    if (linesInputs.length) {
      if (isValid()) {
        onChangeHandler(linesInputs);
        enableCreatebutton(false);
      } else {
        enableCreatebutton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linesInputs]);

  const getBorderStyle = (error: boolean) => {
    return {
      control: (base) =>
        error
          ? {
              ...base,
              borderColor: "red",
              boxShadow: "none",
              padding: "4px",
              ":hover": { borderColor: "red" },
              ":focus": { borderColor: "red" }
            }
          : { ...base, padding: "4px" }
    };
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        {linesInputs.map((ele, index) => (
          <div key={ele.labelName} className="mb-2 relative">
            <Button
              type="button"
              onClick={() => {
                const newLineInputs = [...linesInputs];
                newLineInputs.splice(index, 1);
                setLinesInputs(newLineInputs);
              }}
              variant="light"
              icon={TrashIcon}
              color="red"
              className="absolute right-2 top-2"
            />
            <div className="flex flex-row">
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Line Parameter
                </label>
                <Field
                  as="select"
                  id={linesInputs[index].paramName}
                  name={linesInputs[index].paramName}
                  value={linesInputs[index].linesParams}
                  placeholder="Line Parameter"
                  component={Select}
                  options={selectOptions}
                  styles={getBorderStyle(error[index]["linesParams"])}
                  className={`block w-10/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                    error[index]["lineParams"]
                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                  }`}
                  onChange={(e) => {
                    handleChange(index, "linesParams", e);
                  }}
                  maxMenuHeight={180}
                />
              </div>
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Label
                </label>
                <Field
                  type="text"
                  id={linesInputs[index].labelName}
                  name={linesInputs[index].labelName}
                  placeholder="Line Label"
                  autoComplete="off"
                  value={linesInputs[index].label}
                  onChange={(e) =>
                    handleChange(index, "label", e.target.value)
                  }
                  className={`block w-10/12 p-3 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                    error[index]["label"]
                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                  }`}
                />
              </div>
            </div>
            <div className="flex flex-row my-2 border-b-2">
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Line Color
                </label>
                <Field
                  as="select"
                  id={linesInputs[index].colorName}
                  name={linesInputs[index].colorName}
                  value={linesInputs[index].color}
                  className={`block w-10/12 py-3 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                    error[index]["color"]
                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                  }`}
                  placeholder="Line Color"
                  component={Select}
                  options={lineColorOptions}
                  styles={getBorderStyle(error[index]["color"])}
                  onChange={(e) => handleChange(index, "color", e)}
                  maxMenuHeight={180}
                />
              </div>
            </div>
          </div>
        ))}
        <Button
          disabled={linesInputs.length >= params.length}
          tooltip={
            linesInputs.length >= params.length
              ? "Select a few paramaters first."
              : ""
          }
          className={`text-sm bg-primary hover:bg-primaryLight border-b border-transparent my-2 ${
            linesInputs.length < params.length
              ? "cursor-pointer hover:border-primary"
              : "cursor-not-allowed "
          }`}
          onClick={handleAddLine}
        >
          <span className="flex gap-2">
            <PlusIcon width={16} /> Add Line{" "}
          </span>
        </Button>
      </Form>
    </Formik>
  );
};
