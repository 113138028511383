import {
  InformationCircleIcon,
  PlusIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import { ErrorMessage, Field, FieldArray } from "formik";
import { FunctionComponent, memo, useRef } from "react";
import { Tooltip } from "react-tooltip";

interface HTTPTriggerPathProps {
  values: any;
  errors: any;
  touched: any;
}

const HTTPTriggerPath: FunctionComponent<HTTPTriggerPathProps> = memo(
  ({ values, errors, touched }) => {
    const pathScrollerDiv = useRef<HTMLDivElement>();
    return (
      <>
        <label
          htmlFor="path"
          className="flex gap-1 items-center text-sm font-medium text-contentColor"
        >
          Path{" "}
          <span
            data-tooltip-id="path-element-tooltip"
            data-tooltip-html={`
            Construct a path that will be hit to execute this trigger. <br />
            You can add a colon (:) infront of a path which will be <br />
            treated as a variable (of UUID type).
            `}
          >
            <InformationCircleIcon width={16} className="text-primary" />
          </span>
        </label>
        <FieldArray
          name="path"
          render={(arrayHelpers) => (
            <div className="flex gap-1 items-center">
              <div
                ref={pathScrollerDiv}
                className="max-w-full flex overflow-x-auto gap-2 pb-2"
              >
                {values.path?.map((pathEl: string, index: number) => (
                  <div>
                    <div className="flex justify-between pr-2">
                      <span>
                        <ErrorMessage name={`path.${index}`}>
                          {(msg) => (
                            <span
                              data-tooltip-id="path-element-tooltip"
                              data-tooltip-content={msg}
                            >
                              <InformationCircleIcon color="red" width={16} />
                            </span>
                          )}
                        </ErrorMessage>
                      </span>
                      <Button
                        type="button"
                        variant="light"
                        icon={XMarkIcon}
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                        color="red"
                      />
                    </div>
                    <div className="flex items-center gap-1">
                      <Field
                        type="text"
                        id={`path.${index}`}
                        name={`path.${index}`}
                        autoComplete="off"
                        className={`!text-xs font-mono py-0.5 px-1 max-w-[90px] ${
                          values?.path?.[index].startsWith(":")
                            ? "text-yellow-500"
                            : "text-contentColor"
                        } bg-background ${
                          touched?.path?.[index] && errors.path?.[index]
                            ? "border-red-500 focus:border-red-500"
                            : "border-background-layer3 focus:border-primaryLight"
                        } rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary `}
                        placeholder=""
                      />
                      <span className="text-base font-medium">/</span>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                icon={PlusIcon}
                type="button"
                size="xs"
                variant="secondary"
                onClick={() => {
                  arrayHelpers.insert(values.path?.length ?? 0, "");
                  setTimeout(() =>
                    pathScrollerDiv.current?.scrollTo({
                      left: pathScrollerDiv.current?.scrollWidth
                    })
                  );
                }}
                className="!text-xs rounded-md !px-1 py-0.5 mt-auto mb-2.5"
              />
            </div>
          )}
        />

        <Tooltip id="path-element-tooltip" />
      </>
    );
  }
);

export default HTTPTriggerPath;
