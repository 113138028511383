import { useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import dateService from "@services/date.service";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  PlusIcon
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { DebouncedInput } from "@app/shared/components/deboundced-input.component";
import { reactTableFuzzyFilter } from "@app/shared/utils/helper.util";
import Pagination from "@app/shared/components/pagination.component";
import { useGetUserContextDefinitions } from "@app/shared/hooks/get/context-definitions";
import { Button } from "@tremor/react";

interface IUserContextDefRow {
  id: string;
  name: string;
  created: string;
  version: string;
}

type Props = {};

const ContextsAll = (props: Props) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [contexts, setContexts] = useState([]);
  const navigate = useNavigate();

  useGetUserContextDefinitions({}, (contexts) => {
    const _contexts = contexts?.map((context) => ({
      ...context,
      version: "Latest",
      created_at_local: dateService.convertUTCToLocalDate(context.created_at)
    }));

    setContexts(_contexts || []);
  });

  const columns = useMemo<ColumnDef<IUserContextDefRow, any>[]>(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: "Context",
        cell: ({ getValue, row }) => {
          return (
            <Link
              to={`${row.original.id}?tab=details`}
              className="hover:underline"
            >
              {getValue()}
            </Link>
          );
        },
        size: 50
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        sortingFn: (a, b, colId) =>
          dateService
            .convertDateStrToMoment(a.getValue(colId))
            .diff(dateService.convertDateStrToMoment(b.getValue(colId))),
        cell: ({ getValue }) => {
          return (
            <div className="text-contentColorLight">
              {dateService.convertUTCToLocalDate(getValue())}
            </div>
          );
        },
        size: 30
      },
      {
        id: "version",
        accessorKey: "version",
        header: "Version",
        cell: ({ getValue }) => {
          return <div className="text-contentColorLight">{getValue()}</div>;
        },
        size: 30
      }
    ],
    []
  );

  const table = useReactTable({
    data: contexts,
    columns,
    state: {
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: reactTableFuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  return (
    <div>
      <div className="my-6 flex justify-between">
        <div className="max-w-sm">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-background-layer3 bg-background text-contentColor"
            placeholder="Search all columns..."
          />
        </div>
        <Button
          onClick={() => navigate("create/")}
          icon={PlusIcon}
          className="bg-primary !text-white flex-shrink hover:bg-primaryLight"
        >
          Create User Context
        </Button>
      </div>
      <hr className="border-background-layer3" />
      <div className="max-h-[55vh] overflow-y-scroll">
        {/* table */}
        <table className="w-full h-full my-6">
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width: header.column.getSize()
                      }}
                      className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                : "",
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <ArrowUpIcon width={10} />,
                              desc: <ArrowDownIcon width={10} />
                            }[header.column.getIsSorted() as string] ??
                              (header.column.getCanSort() ? (
                                <ArrowsUpDownIcon width={10} />
                              ) : null)}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>{""}</div>
                          ) : null}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <>
                  <tr
                    key={row.id}
                    className="bg-background-layer1 border-b border-background-layer3"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="mx-2 text-sm text-center p-2 py-3"
                          style={{
                            width: cell.column.getSize()
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
        <Pagination<IUserContextDefRow> table={table} />
      </div>
    </div>
  );
};

export default ContextsAll;
