import OTAAccordion from './ota-accordion.component';

function DeploymentSuggestionPanel() {

    return (
        <div className="ml-8 mt-7 lg:h-[90%] md:h-full bg-[#EEF1FA] rounded relative">
            <div className="pl-5 pt-7 mb-7 relative">
                <div className="flex items-center">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_366_23875)">
                            <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#546CCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 12V9" stroke="#546CCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 6H9.00583" stroke="#546CCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_366_23875">
                                <rect width="18" height="18" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <h1 className="font-medium text-sm ml-3.5 text-primary">How to get started?</h1>
                </div>
            </div>

            <div className="mt-5 mx-[51px] relative">
                <OTAAccordion accordionData={[]} />
            </div>

            <div className="mt-5 mx-[51px] bg-white  pl-4 pt-2 pb-3 rounded">
                <h1 className="font-medium text-sm ml-0.5 mb-3">Have Questions?</h1>
                <div className="flex">
                    <h1 className="font-medium text-sm mr-6 text-primary"><a href='https://dev.docs.golain.io/docs/faqs/' target="_blank" rel="noreferrer">FAQs</a></h1>
                    <span className="border-r-2 my-0.5 border-[#BAC8FF]"></span>
                    <h1 className="font-medium text-sm ml-6 text-primary"><a href='mailto:support@golain.io'>Contact Us</a></h1>
                </div>
            </div>

        </div>
    );
}

export default DeploymentSuggestionPanel;
