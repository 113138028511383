import { useState } from "react";
import Select from "react-select";
import { AddOverlayLayout } from "@app/shared/components";
import { Formik, Field, Form } from "formik";
import { FieldError } from "@app/shared/components";
import useSettingsStore from "@store/settings/settings.store";
import { IOption } from "@interfaces/index";
import { copyToClipboard } from "@app/shared/utils/helper.util";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import { useQueryClient } from "react-query";
import { useCreateApp } from "@app/shared/hooks/post/create-app";
import { useGetDevices } from "@app/shared/hooks/get/devices";

interface IApiKeysFormProps {}

interface IInputEntry {
  appName: string;
  access: IOption;
  resourceType: IOption;
  resources: IOption[];
  action: IOption;
}

interface IInputError {
  appName: string;
  access: string;
  resourceType: string;
  resources: string;
  action: string;
}

const accessOptions: IOption[] = [
  { label: "ADMIN", value: "ADMIN" },
  { label: "MEMBER", value: "MEMBER" },
  { label: "VIEWER", value: "VIEWER" }
];

const resourceTypeOptions: IOption[] = [
  { label: "Devices", value: "devices" },
  { label: "Fleets", value: "fleets" }
];

const actionOptions: IOption[] = [
  { label: "can_read", value: "can_read" },
  { label: "can_write", value: "can_write" },
  { label: "can_create", value: "can_create" },
  { label: "can_delete", value: "can_delete" }
];

const ApiKeyForm: React.FC<IApiKeysFormProps> = () => {
  const [showGenerateAPIKeyForm, setShowGenerateAPIKeyForm] = useSettingsStore(
    (state) => [state.showGenerateAPIKeyForm, state.setShowGenerateAPIKeyForm]
  );
  const queryClient = useQueryClient();

  const [resourcesOptions, setResourcesOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [appKey, setAppKey] = useState<string>("");
  const [inputFieldValues, setInputFieldValues] = useState<IInputEntry>({
    appName: "",
    access: {} as IOption,
    resourceType: {} as IOption,
    resources: [],
    action: {} as IOption
  });
  const [errorMsgs, setErrorMsgs] = useState<IInputError>({
    appName: "",
    access: "",
    resourceType: "",
    resources: "",
    action: ""
  });

  const handleInput = (event: any, fieldId: string) => {
    const payload = { ...inputFieldValues };
    const errors = { ...errorMsgs };

    const selector = {
      access: true,
      resources: true,
      action: true
    };

    if (selector[fieldId]) {
      payload[fieldId] = event;

      if (errors[fieldId]) {
        errors[fieldId] = "";
        setErrorMsgs(errors);
      }
    } else {
      const value = event.target.value;

      if (!value.trim()) {
        const eMsg = { ...errorMsgs };
        eMsg[fieldId] = "App name is required";
        setErrorMsgs(eMsg);
      } else if (errorMsgs[fieldId]) {
        const eMsg = { ...errorMsgs };
        eMsg[fieldId] = "";
        setErrorMsgs(eMsg);
      }

      payload[fieldId] = value;
    }

    setInputFieldValues(payload);
  };

  useGetFleets({}, (fleets) => {
    if (fleets?.length) {
      const options = fleets.map((fleet) => {
        return { label: fleet.fleet_name, value: fleet.id };
      });

      setResourcesOptions([...options]);
      if (errorMsgs.resourceType)
        setErrorMsgs({ ...errorMsgs, resourceType: "" });
    } else {
      setErrorMsgs({
        ...errorMsgs,
        resourceType: "No fleets available, create one in fleets & devices"
      });
      setResourcesOptions([]);
    }
  });

  useGetDevices({}, (devices) => {
    if (devices?.length) {
      const options = devices.map((device) => {
        return { label: device.device_name, value: device.id };
      });

      setResourcesOptions([...options]);
      if (errorMsgs.resourceType)
        setErrorMsgs({ ...errorMsgs, resourceType: "" });
    } else {
      setErrorMsgs({
        ...errorMsgs,
        resourceType: "No devices available, create one in fleets & devices"
      });
      setResourcesOptions([]);
    }
  });

  const handleResourceType = async (e: IOption) => {
    if (inputFieldValues.resourceType?.value !== e.value) {
      setInputFieldValues({ ...inputFieldValues, resourceType: e });
    }

    if (
      inputFieldValues.resourceType?.value !== "fleets" &&
      e.value === "fleets"
    ) {
      queryClient.invalidateQueries("getFleets");
    } else if (
      inputFieldValues.resourceType?.value !== "devices" &&
      e.value === "devices"
    ) {
      queryClient.invalidateQueries("getDevices");
    }
  };

  const validateForm = () => {
    if (
      errorMsgs.access ||
      errorMsgs.appName ||
      errorMsgs.resourceType ||
      errorMsgs.resources ||
      errorMsgs.action
    ) {
      return false;
    }

    const errors = { ...errorMsgs };

    if (!inputFieldValues.appName.trim()) {
      errors.appName = "App name is required";
    } else {
      errors.appName = "";
    }

    if (!inputFieldValues.access?.value) {
      errors.access = "Select access";
    } else {
      errors.access = "";
    }

    if (!inputFieldValues.resourceType?.value) {
      errors.resourceType = "Select resource type";
    } else {
      errors.resourceType = "";
    }

    if (!inputFieldValues.resources?.length) {
      errors.resources = "Select resources";
    } else {
      errors.resources = "";
    }

    if (!inputFieldValues.action?.value) {
      errors.action = "Select action";
    } else {
      errors.action = "";
    }

    if (
      errors.access ||
      errors.appName ||
      errors.resourceType ||
      errors.resources ||
      errors.action
    ) {
      setErrorMsgs({ ...errors });
      return false;
    }

    return true;
  };

  const createApiKeyMutation = useCreateApp();

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const resourceIds = inputFieldValues.resources.map((res) => res.value);
    const payload = {
      app_name: inputFieldValues.appName,
      permissions: [
        {
          resource_type: inputFieldValues.resourceType.value,
          resources: resourceIds,
          actions: [inputFieldValues.action.value]
        }
      ],
      project_access: inputFieldValues.access.value
    };

    createApiKeyMutation.mutate(payload, {
      onSuccess: (projectApp) => {
        if (projectApp) {
          setAppKey(projectApp.api_key);

          // Clear fields
          setInputFieldValues({} as IInputEntry);
          setErrorMsgs({} as IInputError);
          setResourcesOptions([]);
        }
      }
    });
  };

  function copyTo(id: string) {
    let c = document.getElementById(id);
    copyToClipboard(c["value"]);
  }

  const closeAndClear = () => {
    setAppKey("");
    setShowGenerateAPIKeyForm(false);
  };

  return (
    <AddOverlayLayout
      title="Generate API key"
      overlayOpen={showGenerateAPIKeyForm}
      setOverlayOpen={closeAndClear}
    >
      <div className="flex flex-col items-center">
        {showGenerateAPIKeyForm && (
          <Formik
            initialValues={{
              appName: ""
            }}
            onSubmit={handleSubmit}
          >
            <Form className="w-full flex flex-col flex-1 mt-7">
              {appKey ? (
                <div className="w-full flex flex-col flex-1 mt-7">
                  <div className="mb-5">
                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="appKey"
                        name="appKey"
                        value={appKey}
                        placeholder="Org Name"
                        className="block w-10/12 p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("appKey")}
                          className="block ml-3.5 bg-[#166ff629] p-3 rounded"
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                              fill="#546CCC"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <FieldError
                      message={
                        "Copy the key before closing, You cannot access it again."
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="w-full flex flex-col flex-1 mt-7">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-[#B7B9C1]">
                      App Name
                    </label>

                    <Field
                      type="text"
                      id="appName"
                      name="appName"
                      placeholder="App Name "
                      value={inputFieldValues.appName}
                      onChange={(e: any) => handleInput(e, "appName")}
                      className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                    />
                    {errorMsgs.appName ? (
                      <FieldError message={errorMsgs.appName} />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-[#B7B9C1]">
                      Project Access
                    </label>
                    <Select
                      placeholder="Select"
                      isSearchable={false}
                      options={accessOptions}
                      value={inputFieldValues.access}
                      onChange={(e) => handleInput(e, "access")}
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                    {errorMsgs.access ? (
                      <FieldError message={errorMsgs.access} />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-[#B7B9C1]">
                      Resources Type
                    </label>

                    <Select
                      placeholder="Select Type"
                      options={resourceTypeOptions}
                      value={inputFieldValues.resourceType}
                      onChange={handleResourceType}
                      isClearable={false}
                      isSearchable={false}
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                    {errorMsgs.resourceType ? (
                      <FieldError message={errorMsgs.resourceType} />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-[#B7B9C1]">
                      Add Resources
                    </label>
                    <Select
                      isDisabled={!resourcesOptions.length}
                      placeholder="Select"
                      isSearchable={false}
                      options={resourcesOptions}
                      value={inputFieldValues.resources}
                      onChange={(e) => handleInput(e, "resources")}
                      isClearable={true}
                      isMulti
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-[#B7B9C1]">
                      Action
                    </label>
                    <Select
                      placeholder="Select"
                      isSearchable={false}
                      options={actionOptions}
                      value={inputFieldValues.action}
                      onChange={(e) => handleInput(e, "action")}
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                    {errorMsgs.action ? (
                      <FieldError message={errorMsgs.action} />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="w-full border-b border-black"></div>
                </div>
              )}

              <div>
                {appKey ? (
                  <button
                    type="button"
                    onClick={closeAndClear}
                    className="w-full inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="w-full inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                  >
                    Create
                  </button>
                )}
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </AddOverlayLayout>
  );
};

export default ApiKeyForm;
