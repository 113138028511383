import React, { useState } from "react";
import { IInsights } from "../../../interfaces";
import { useFleetAndDevicesStore } from "../../../store";
import Insights from "./insights-component";

interface ILogsAndActivitiesHeaderProps {
  insights: IInsights;
}

function LogsAndActivitiesHeader(props: ILogsAndActivitiesHeaderProps) {
  const { insights } = props;
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [insightsOpen, setInsightsOpen] = useState(false);

  return (
    <>
      <Insights
        insights={insights}
        insightOpen={insightsOpen}
        setInsightOpen={setInsightsOpen}
      />
      <div className="bg-background border-b border-background-layer3 lg:px-8 sm:px-6 ">
        <div className="flex justify-between items-center py-5">
          <div className="flex flex-col">
            <div className="flex items-center mb-3">
              <h1 className="text-2xl font-medium mr-1.5">
                {selectedProject.project_name}
              </h1>
              <svg
                className="mt-0.5"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.30023 7.90832C7.6362 7.90832 7.90856 7.63596 7.90856 7.29998C7.90856 6.96401 7.6362 6.69165 7.30023 6.69165C6.96425 6.69165 6.69189 6.96401 6.69189 7.29998C6.69189 7.63596 6.96425 7.90832 7.30023 7.90832Z"
                  stroke="#B3B3B3"
                  strokeWidth="1.36875"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.30023 3.65002C7.6362 3.65002 7.90856 3.37766 7.90856 3.04168C7.90856 2.70571 7.6362 2.43335 7.30023 2.43335C6.96425 2.43335 6.69189 2.70571 6.69189 3.04168C6.69189 3.37766 6.96425 3.65002 7.30023 3.65002Z"
                  stroke="#B3B3B3"
                  strokeWidth="1.36875"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.30023 12.1666C7.6362 12.1666 7.90856 11.8943 7.90856 11.5583C7.90856 11.2223 7.6362 10.95 7.30023 10.95C6.96425 10.95 6.69189 11.2223 6.69189 11.5583C6.69189 11.8943 6.96425 12.1666 7.30023 12.1666Z"
                  stroke="#B3B3B3"
                  strokeWidth="1.36875"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div>
              <p className="text-sm text-contentColorLight">{`All logs `}</p>
            </div>
          </div>
          <ul className="flex justify-center items-center">
            <li className="ml-10 flex items-center cursor-pointer">
              <button
                onClick={() => setInsightsOpen(true)}
                className="flex justify-center items-center px-4 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 11.25C3 11.25 3.75 10.5 6 10.5C8.25 10.5 9.75 12 12 12C14.25 12 15 11.25 15 11.25V2.25C15 2.25 14.25 3 12 3C9.75 3 8.25 1.5 6 1.5C3.75 1.5 3 2.25 3 2.25V11.25Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 16.5V11.25"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <h1 className="ml-3">Insights</h1>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default LogsAndActivitiesHeader;
