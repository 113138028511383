import React, { useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import { useFleetAndDevicesStore, useDashboardStore } from "../../store";
import { IDashboard } from "../../interfaces";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShowDashCreate from "./components/dash-create-modal";
import ShowError from "../shared/components/error.component";
import {
  ChevronDownIcon,
  DocumentDuplicateIcon,
  PlusIcon
} from "@heroicons/react/24/outline";
import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import { useGetDashboards } from "@app/shared/hooks/get/dashboards";
import ShowLoading from "@app/shared/components/loading.component";
import { useCreateDashboard } from "@app/shared/hooks/post/create-dashboard";
import { Button } from "@tremor/react";
import DashPanelCreateBlueprintModal from "./components/dash-panel-create-blueprint-modal.component";

const AllDashboards: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const newDashboard = useRef("");
  const [showDashCreateOpen, setShowDashCreateOpen] = useState(false);

  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const [
    dashboards,
    activeDashboard,
    setCreateBlueprintKind,
    setCreateBlueprintModalOpen,
    setLayouts,
    setActiveDashboard,
    setDashboards,
    setPanels,
    setCarousels
  ] = useDashboardStore((state) => [
    state.dashboards,
    state.activeDashboard,
    state.setCreateBlueprintKind,
    state.setCreateBlueprintModalOpen,
    state.setLayouts,
    state.setActiveDashboard,
    state.setDashboards,
    state.setPanels,
    state.setCarousels
  ]);

  const { isLoading, error } = useGetDashboards({}, (dashboards) => {
    if (dashboards?.length) {
      setDashboards(dashboards);

      if (newDashboard.current.length) {
        const dashboard = dashboards.find(
          (d) => d.id === newDashboard.current
        );
        setActiveDashboard(dashboard);
        navigate(`/dashboard/${dashboard.id}`);
        newDashboard.current = "";
      } else {
        // get dashboard id from url
        const dashboardId = location.pathname.split("/")[2];
        const dashboard = dashboards.find((d) => d.id === dashboardId);

        if (dashboard) {
          if (dashboard.meta_data?.layouts) {
            setLayouts(dashboard.meta_data.layouts);
          }
          setActiveDashboard(dashboard);
        } else {
          setActiveDashboard(dashboards[0]);
          if (dashboards[0].meta_data?.layouts) {
            setLayouts(dashboards[0].meta_data.layouts);
          }
          navigate(`/dashboard/${dashboards[0].id}`, { replace: true });
        }
      }
    } else {
      setPanels([]);
      setCarousels({});
      setDashboards([]);
      setActiveDashboard({} as IDashboard);
      navigate("/dashboard/", { replace: true });
    }
  });

  const newDashboardMutation = useCreateDashboard();

  const handleCreateNewTab = (name: string, description: string) => {
    newDashboardMutation.mutate(
      {
        dashboard_name: name,
        dashboard_description: description
      },
      {
        onSuccess: (dashboardId) => {
          if (dashboardId) {
            newDashboard.current = dashboardId;
          } else {
            newDashboard.current = "";
          }
          setShowDashCreateOpen(false);
        }
      }
    );
  };

  const handleTabChange = (dashbaord: IDashboard) => {
    if (dashbaord.id === activeDashboard.id) {
      return;
    }

    setPanels([]);
    setCarousels({});

    if (dashbaord.meta_data?.layouts) {
      setLayouts(dashbaord.meta_data.layouts);
    } else {
      setLayouts({});
    }

    setActiveDashboard(dashbaord);
    navigate(`/dashboard/${dashbaord.id}`);
  };

  const renderTabHeader = (tab: IDashboard, index: number) => {
    return (
      <div
        className={`relative flex flex-row items-center !w-full text-sm font-medium ${
          tab.id === activeDashboard.id
            ? "text-primaryLight"
            : "text-contentColor"
        }`}
      >
        <h4>{tab.dashboard_name}</h4>
        {tab.id === activeDashboard.id && (
          <ChevronDownIcon className="w-4 h-4 ml-1 mt-0 text-primary" />
        )}
      </div>
    );
  };

  const renderTabList = (width) => {
    return dashboards.map((dashbaord, index) => (
      <div
        key={index}
        className={`relative mr-4 px-2 pb-1 mt-2 ${width} cursor-pointer border-b-2  ${
          activeDashboard.id === dashbaord.id
            ? "border-primaryLight"
            : "border-transparent"
        } `}
        onClick={() => handleTabChange(dashbaord)}
      >
        {renderTabHeader(dashbaord, index)}
      </div>
    ));
  };

  const renderCreateButton = () => {
    return (
      <Button
        icon={PlusIcon}
        variant="light"
        className="px-2 mt-2 pb-1 w-max text-sm font-medium border-b-2 border-transparent hover:border-primary"
        onClick={() => setShowDashCreateOpen(true)}
      >
        New Dashboard
      </Button>
    );
  };

  const renderCreateBlueprintButton = () => {
    return (
      <Button
        variant="light"
        icon={DocumentDuplicateIcon}
        onClick={() => {
          setCreateBlueprintKind("DASHBOARD");
          setCreateBlueprintModalOpen(true);
        }}
        className="mr-4 px-2 mt-2 pb-1 w-max text-sm font-medium border-b-2 border-transparent hover:border-primary"
      >
        Create Blueprint
      </Button>
    );
  };
  if (error) {
    return <ShowError />;
  }

  if (isLoading) {
    return <ShowLoading />;
  }

  return (
    <>
      <ShowDashCreate
        showDashCreateOpen={showDashCreateOpen}
        setShowDashCreateOpen={setShowDashCreateOpen}
        handleCreate={handleCreateNewTab}
      />

      <div className="bg-background border-b lg:px-8 sm:px-6 border-b-background-layer2">
        <div className="relative flex flex-col justify-bet">
          <div className="flex flex-col justify-between items-start pt-4 w-full">
            <h4 className="text-xl font-semibold text-contentColor">
              {selectedProject.project_name}
            </h4>
          </div>
          <div className="relative flex flex-row justify-start items-center mt-1 w-full">
            {error ? (
              <ShowError />
            ) : dashboards.length > 6 ? (
              <React.Fragment>
                <div className="relative w-10/12 mb-2 justify-start">
                  <TransitionedMenu
                    buttonComponent={
                      <div className="pb-1 -mb-4 cursor-pointer border-b-2  border-primary">
                        {renderTabHeader(activeDashboard, 0)}
                      </div>
                    }
                  >
                    {dashboards.map((dashbaord) => (
                      <Menu.Item key={dashbaord.id}>
                        <div
                          key={dashbaord.id}
                          className={`relative pl-3 text-sm py-2 pr-10 cursor-pointer hover:bg-gray-100 ${
                            activeDashboard.id === dashbaord.id
                              ? "bg-gray-100"
                              : ""
                          } `}
                          onClick={() => handleTabChange(dashbaord)}
                        >
                          {dashbaord.dashboard_name}
                        </div>
                      </Menu.Item>
                    ))}
                  </TransitionedMenu>
                </div>
                <div className="flex relative mr-10">
                  {/* {renderCreateBlueprintButton()} */}
                  {renderCreateButton()}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="flex flex-row justify-start items-center">
                  {renderTabList("w-30")}
                </div>
                <div className="w-max flex items-center gap-2">
                  {/* {renderCreateBlueprintButton()} */}
                  {renderCreateButton()}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <DashPanelCreateBlueprintModal dashboard={activeDashboard} />
      {/* Outlet for all routes!*/}
      <Outlet />
    </>
  );
};

export default AllDashboards;
