import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

export type IEnvironments = Array<string>;

interface IFCMEnvironmentsEditor {
  environments: IEnvironments;
  setEnvironments: (x: IEnvironments | ((x: IEnvironments) => void)) => void;
}

const FCMEnvironmentsEditor: React.FC<IFCMEnvironmentsEditor> = ({
  environments,
  setEnvironments
}) => {
  return (
    <div className="flex flex-col w-full gap-2">
      {environments.map((env, ind) => {
        return (
          <div className="flex gap-2 items-center" key={ind}>
            <input
              placeholder="Environment"
              className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
              value={env}
              onChange={(e) => {
                const _environments = [...environments];

                _environments[ind] = e.target.value;
                setEnvironments(_environments);
              }}
            />
            <button
              className="bg-red-500 text-white p-1 rounded-sm"
              onClick={() => {
                const _environments = [...environments];
                _environments.splice(ind, 1);
                setEnvironments(_environments);
              }}
            >
              <XMarkIcon width={16} />
            </button>
          </div>
        );
      })}
      <button
        disabled={environments.some((env) => !env?.trim())}
        className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm disabled:opacity-50"
        onClick={() => {
          const _environments = [...environments];
          _environments.push("");
          setEnvironments(_environments);
        }}
      >
        Add Environment
      </button>
    </div>
  );
};

export default FCMEnvironmentsEditor;
