import { XMarkIcon } from "@heroicons/react/24/outline";

const InputsItem = ({ input, setInputs, ind, inputs }) => (
  <div className="flex flex-col gap-2">
    <div
      key={ind}
      className="flex gap-2 p-2 bg-background-layer1 rounded-sm items-center text-xs border border-background-layer3"
    >
      <input
        placeholder="Key"
        className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
        onChange={(e) => {
          setInputs((prev) => {
            const newInputs = [...prev];
            const newInput = {
              ...newInputs[ind],
              key: e.target.value
            };
            newInputs[ind] = newInput;
            return newInputs;
          });
        }}
        value={input.key}
      />
      <select
        className="nodrag bg-background text-xs w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
        onChange={(e) => {
          setInputs((prev) => {
            const newInputs = [...prev];
            const newInput = {
              ...newInputs[ind],
              type: e.target.value
            };
            newInputs[ind] = newInput;
            return newInputs;
          });
        }}
        value={input.type}
      >
        <option value="string">String </option>
        <option value="number">Number </option>
        <option value="bool">Boolean</option>
        <option value="struct">Struct</option>
        {/* <option value="gen">Gen</option> */}
      </select>
      {input.deletable === undefined || input.deletable ? (
        <XMarkIcon
          width={16}
          className="text-red-500 min-w-[16px] cursor-pointer"
          onClick={() => {
            setInputs((prev) => {
              const newInputs = [...prev];
              newInputs.splice(ind, 1);
              return newInputs;
            });
          }}
        />
      ) : null}
    </div>
    {inputs.slice(0, ind).some((inp) => inp.key === input.key) ? (
      <span className="text-red-500">Inputs should have unique names</span>
    ) : null}
  </div>
);

export default InputsItem;
