import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { IOption } from "@/interfaces";
import { Badge, Button } from "@tremor/react";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext
} from "formik";
import ReactSelect from "react-select";
import {
  CheckCircleIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  EllipsisHorizontalCircleIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { defaultHeaders } from "../header-list";
import { Disclosure } from "@headlessui/react";
import * as Yup from "yup";
import { FieldError } from "@/app/shared/components";
import {
  ICreateTriggerPayload,
  useCreateTrigger
} from "@/app/shared/hooks/post/create-trigger";
import useRuleEngineStore, {
  IRule
} from "@/store/rule-engine/rule-engine.store";
import {
  ITriggerFormState,
  THTTPMethod
} from "@/interfaces/triggers.interface";
import { useEffect } from "react";
import HTTPTriggerPath from "./http-trigger-path.component";
import HTTPTriggerParam from "./http-trigger-param.component";
import { useUpdateTrigger } from "@/app/shared/hooks/patch/update-triggers";
import { authTypes, serializeHTTPTrigger } from "../http-trigger.helper";
import { toast } from "react-toastify";

interface IHTTPTriggerNewProps {
  rule: IRule;
}

const httpMethods = ["GET", "POST", "PUT", "PATCH", "DELETE"] as THTTPMethod[];

const initialFormValues: ITriggerFormState = {
  name: "",
  authMode: null,
  path: [],
  headers: [
    {
      key: "",
      defaultValue: "",
      required: true
    }
  ],
  queryParams: [
    {
      name: "",
      type: { value: "string", label: "String" },
      defaultValue: "",
      required: true
    }
  ],
  bodyParams: [],
  httpMethods: ["GET"]
};

const HTTPTriggerSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  path: Yup.array().of(
    Yup.string()
      .matches(/^:*[\w-]+$/g, "Path element not valid")
      .required("Path element cannot be empty")
  ),
  authMode: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .nullable()
    .required("Select an Authentication Mode"),
  headers: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required("Input a header name"),
      defaultValue: Yup.string().notRequired(),
      required: Yup.bool().required()
    })
  ),
  queryParams: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Parameter Name is required."),
      type: Yup.object().required(),
      defaultValue: Yup.string().notRequired(),
      required: Yup.bool().required()
    })
  ),
  bodyParams: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Parameter Name is required."),
      type: Yup.object().required(),
      defaultValue: Yup.string().notRequired(),
      required: Yup.bool().required()
    })
  ),
  httpMethods: Yup.array()
    .of(Yup.string())
    .min(1, "Select at least one HTTP Method.")
});

const HTTPTriggerNew: React.FC<IHTTPTriggerNewProps> = ({ rule }) => {
  const [triggerFormState, setRules] = useRuleEngineStore((state) => [
    state.triggerData?.[rule.name],
    state.setRules
  ]);

  const createTriggerMutation = useCreateTrigger();
  const updateTriggerMutation = useUpdateTrigger();

  const onSubmit = (values: ITriggerFormState) => {
    const triggerDefinition = serializeHTTPTrigger(values);

    const payload: ICreateTriggerPayload = {
      trigger_name: values.name,
      trigger_description: "",
      trigger_type: "HTTP",
      definition: JSON.stringify(triggerDefinition),
      active: true
      // rule_id: rule.id
    };

    if (rule.triggerId) {
      updateTriggerMutation.mutate(
        { data: payload, id: rule.triggerId },
        {
          onSuccess: (trigger) => {
            setRules((rules) => ({
              ...rules,
              [rule.name]: {
                ...rules[rule.name],
                triggerId: trigger.id
              }
            }));
            toast.success("Updated trigger successfully!");
          },
          onError: console.error
        }
      );
    } else {
      createTriggerMutation.mutate(payload, {
        onSuccess: (trigger) => {
          setRules((rules) => ({
            ...rules,
            [rule.name]: {
              ...rules[rule.name],
              triggerId: trigger.id
            }
          }));
          toast.success("Created trigger successfully!");
        },
        onError: console.error
      });
    }
  };

  return (
    <Formik
      validationSchema={HTTPTriggerSchema}
      initialValues={triggerFormState ?? initialFormValues}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched }) => (
        <Form className="mb-4 nodrag">
          <SaveFormData rule={rule} />
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-contentColor"
            >
              Name*
            </label>
            <Field
              type="text"
              id="name"
              name="name"
              autoComplete="off"
              className="mt-1 block w-full bg-background border-background-layer3 text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
              placeholder="Provide a name that uniquely identifies this trigger."
            />
            <ErrorMessage name="name">
              {(msg) => <FieldError message={msg} />}
            </ErrorMessage>
          </div>
          <div className="mb-4">
            <HTTPTriggerPath
              values={values}
              errors={errors}
              touched={touched}
            />
          </div>

          <div className="mb-4">
            <h3 className="text-md flex items-center font-semibold mb-2">
              Advanced Configuration{" "}
              <Cog6ToothIcon width={16} className="ml-1" />
            </h3>
            <div className="border-t border-gray-200 pt-4">
              <div className="mb-4">
                <label
                  htmlFor="authMode"
                  className="block text-base font-medium text-contentColor"
                >
                  Authentication Mode*
                  <Field
                    id="authMode"
                    name="authMode"
                    component={({ field, form, ...props }) => (
                      <ReactSelect
                        {...field}
                        {...form}
                        id={"authMode"}
                        name={"authMode"}
                        value={values.authMode}
                        onChange={(val: IOption) => {
                          form.setFieldValue(`authMode`, val);
                        }}
                        placeholder="Pick a mode to authenticate users."
                        options={authTypes}
                        className="block w-full bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                        classNames={{
                          ...reactSelectClassNames,
                          control: () =>
                            "!bg-background !text-contentColor placeholder:!text-contentColorLight !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm"
                        }}
                      />
                    )}
                  />
                  <ErrorMessage name="authMode">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </label>
              </div>

              {/* Headers: */}
              <div className="mb-4">
                <Disclosure defaultOpen>
                  {({ open }) => (
                    <FieldArray
                      name={"headers"}
                      render={(arrayHelpers) => (
                        <div>
                          <Disclosure.Button
                            className={
                              "flex w-full justify-between rounded-lg bg-background-layer1 hover:bg-background-layer2 px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75"
                            }
                          >
                            <div className="flex gap-2">
                              <h3 className="block text-base font-medium text-contentColor">
                                Headers
                              </h3>
                              <Button
                                type="button"
                                variant="secondary"
                                size="xs"
                                className="!text-xs rounded-md !px-2 py-0.5"
                                icon={PlusIcon}
                                onClick={(e) => {
                                  if (open) {
                                    e.stopPropagation();
                                  }
                                  arrayHelpers.insert(
                                    values.headers?.length ?? 0,
                                    {
                                      key: "",
                                      defaultValue: "",
                                      required: true
                                    }
                                  );
                                }}
                              >
                                Add
                              </Button>
                              <Badge color={"blue"}>
                                {values.headers?.length || 0}
                              </Badge>
                            </div>
                            <ChevronUpIcon
                              width={20}
                              className={`transition-transform duration-100 ${
                                open ? "rotate-0" : "rotate-180"
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            <div className="flex mt-2 flex-col gap-2">
                              {values.headers?.map((header, index: number) => (
                                <>
                                  <div className="flex justify-between">
                                    <div className="flex items-center w-full">
                                      <datalist id="default-headers">
                                        {defaultHeaders.map((option) => (
                                          <option value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </datalist>
                                      <Field
                                        name={`headers.${index}.key`}
                                        placeholder="Header"
                                        className={`block  px-3 py-2 border rounded-r-none bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                                        list={"default-headers"}
                                      />

                                      <Field
                                        name={`headers.${index}.defaultValue`}
                                        disabled={
                                          values.headers[index].required
                                        }
                                        placeholder={
                                          values.headers[index].required
                                            ? "---"
                                            : "Set default value."
                                        }
                                        className={`block disabled:placeholder:text-contentColorLighter  px-3 py-2 w-10/12 border border-l-0 rounded-l-none rounded-r-none bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                                      />
                                    </div>
                                    <div className="flex items-center">
                                      <Field
                                        type="button"
                                        name={`headers.${index}.required`}
                                        component={({
                                          field,
                                          form,
                                          ...props
                                        }) => (
                                          <Button
                                            onClick={() => {
                                              form.setFieldValue(
                                                `headers.${index}.required`,
                                                !values.headers[index].required
                                              );
                                              form.setFieldValue(
                                                `headers.${index}.defaultValue`,
                                                ""
                                              );
                                            }}
                                            color={
                                              values.headers[index].required
                                                ? "green"
                                                : undefined
                                            }
                                            variant="secondary"
                                            className={`block px-3 py-2 border border-l-0  !rounded-r-none !m-0  !rounded-l-none bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                                            icon={
                                              values.headers[index].required
                                                ? CheckCircleIcon
                                                : EllipsisHorizontalCircleIcon
                                            }
                                            tooltip={
                                              values.headers[index].required
                                                ? "Make Optional"
                                                : "Make Required"
                                            }
                                          />
                                        )}
                                        className={`block h-full px-3 py-2 border border-x-0 rounded-l-none rounded-r-none bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                                      />
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        variant="secondary"
                                        color="red"
                                        className={`block px-3 py-2 border border-l-0 !m-0  !rounded-l-none bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                                        icon={TrashIcon}
                                      />
                                    </div>
                                  </div>
                                  <ErrorMessage name={`headers.${index}.key`}>
                                    {(msg) => <FieldError message={msg} />}
                                  </ErrorMessage>
                                </>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </div>
                      )}
                    />
                  )}
                </Disclosure>
              </div>

              {/* Query Params: */}
              <div className="mb-4">
                <HTTPTriggerParam
                  fieldName="queryParams"
                  heading="Query Parameters"
                  values={values}
                />
              </div>

              {/* Body Params: */}
              {/* Any other method than GET selected */}
              {values.httpMethods?.filter((m) => m !== "GET").length ? (
                <div className="mb-4">
                  <HTTPTriggerParam
                    fieldName="bodyParams"
                    heading="Body Parameters"
                    values={values}
                  />
                </div>
              ) : null}
              <div className="mb-4 border-t border-background-layer3 pt-2">
                <h3 className="block text-base font-medium text-contentColor">
                  HTTP Methods*
                </h3>
                <div className="mt-2 flex justify-start space-x-2">
                  {httpMethods.map((method) => (
                    <label
                      htmlFor={"httpMethod-" + method}
                      className={`inline-flex items-center ${
                        !values.httpMethods?.includes(method)
                          ? "bg-background-layer2"
                          : "bg-primaryLight !text-white"
                      } rounded-full px-3 py-1`}
                    >
                      <Field
                        type="checkbox"
                        name="httpMethods"
                        id={"httpMethod-" + method}
                        value={method}
                        className="form-checkbox rounded-full"
                      />
                      <span className="ml-2">{method}</span>
                    </label>
                  ))}
                </div>
                <ErrorMessage name="httpMethods">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>
            </div>
          </div>
          <div className="flex w-full mt-4 justify-end">
            <Button
              loading={
                createTriggerMutation.isLoading ||
                updateTriggerMutation.isLoading
              }
              type="submit"
              className="self-end !text-white"
            >
              {rule.triggerId ? "Update" : "Create"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const SaveFormData = ({ rule }) => {
  const formik = useFormikContext();

  const setTriggerFormState = useRuleEngineStore(
    (state) => state.setTriggerData
  );

  useEffect(() => {
    setTriggerFormState(rule.name, formik.values as ITriggerFormState);

    return () => {};
  }, [formik.values, rule.name, setTriggerFormState]);

  return null;
};

export default HTTPTriggerNew;
