import { NavLink } from "react-router-dom";

function ProjectNavigation() {
  return (
    <div className="px-4 pt-6 bg-background border-background-layer3 border-b lg:px-8 sm:px-6 ">
      <div className=" md:grid md:gap-4 md:grid-cols-5 md:place-items-start">
        <NavLink
          to="/settings/project-settings/project-details"
          className={({ isActive }) =>
            `${
              isActive ? "text-primary border-b-2 border-primaryLight" : ""
            } flex items-center px-2 py-5 -mb-px text-center text-contentColorLight lg:px-4 whitespace-nowrap`
          }
        >
          {({ isActive }) => (
            <div className="flex flex-row justify-between items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1254_21407)">
                  <path
                    d="M15 1.5C15.414 1.5 15.75 1.836 15.75 2.25V5.06775L14.25 6.56775V3H3.75V15H14.25V12.9315L15.75 11.4315V15.75C15.75 16.164 15.414 16.5 15 16.5H3C2.586 16.5 2.25 16.164 2.25 15.75V2.25C2.25 1.836 2.586 1.5 3 1.5H15ZM16.3335 6.606L17.394 7.6665L11.5605 13.5L10.4985 13.4985L10.5 12.4395L16.3335 6.606V6.606ZM9.75 9V10.5H6V9H9.75ZM12 6V7.5H6V6H12Z"
                    fill={isActive ? "#546CCC" : "#565759"}
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1254_21407">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span
                className={`${
                  isActive && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                Project Details
              </span>
            </div>
          )}
        </NavLink>

        <NavLink
          to="/settings/project-settings/api-keys"
          className={({ isActive }) =>
            `${
              isActive ? "text-primary border-b-2 border-primaryLight" : ""
            } flex items-center px-2 py-5 -mb-px text-center text-contentColorLight lg:px-4 whitespace-nowrap`
          }
        >
          {({ isActive }) => (
            <div className="flex flex-row justify-between items-center">
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1293_17217)">
                  <path
                    d="M8.56847 8.87113L14.4552 2.98438L15.5157 4.04488L14.4552 5.10612L16.3107 6.96162L15.2502 8.02287L13.394 6.16663L12.3335 7.22712L13.9242 8.81787L12.8637 9.87912L11.273 8.28763L9.62897 9.93162C10.1393 10.7071 10.344 11.6443 10.2034 12.562C10.0628 13.4796 9.587 14.3126 8.86792 14.8997C8.14884 15.4869 7.23758 15.7866 6.31035 15.7409C5.38312 15.6951 4.50578 15.3072 3.84797 14.6521C3.18874 13.9954 2.79724 13.117 2.74956 12.1876C2.70188 11.2583 3.00142 10.3445 3.58998 9.62369C4.17853 8.90291 5.01405 8.42671 5.93414 8.28762C6.85423 8.14854 7.79317 8.35651 8.56847 8.87113ZM8.09072 13.5909C8.30921 13.3842 8.48405 13.1359 8.6049 12.8605C8.72576 12.5851 8.79019 12.2883 8.79437 11.9875C8.79855 11.6868 8.7424 11.3883 8.62925 11.1097C8.5161 10.8311 8.34823 10.5779 8.13558 10.3653C7.92292 10.1526 7.66979 9.98475 7.39115 9.87159C7.11251 9.75844 6.81401 9.70229 6.5133 9.70648C6.21259 9.71066 5.91577 9.77508 5.64038 9.89594C5.36499 10.0168 5.11663 10.1916 4.90997 10.4101C4.50011 10.8345 4.27332 11.4028 4.27845 11.9928C4.28358 12.5827 4.52021 13.147 4.93738 13.5642C5.35454 13.9814 5.91887 14.218 6.50881 14.2231C7.09876 14.2283 7.66711 14.0015 8.09147 13.5916L8.09072 13.5909Z"
                    fill={isActive ? "#546CCC" : "#565759"}
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1293_17217">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <span
                className={`${
                  isActive && "text-primaryLight font-medium"
                } text-sm ml-2`}
              >
                API keys
              </span>
            </div>
          )}
        </NavLink>
      </div>
    </div>
  );
}

export default ProjectNavigation;
