import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Badge, BadgeDelta } from "@tremor/react";
import dateService from "@services/date.service";
import { parseAggregatedEnumsStringToJSON } from "@app/shared/utils/helper.util";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import { formatNumber } from "@/app/shared/utils/dashboard.utils";
import { useDashboardStore } from "@/store";
import { Link, useNavigate } from "react-router-dom";
import { useGetDashboards } from "@/app/shared/hooks/get/dashboards";

/* 
    Component: Table
    Props:  {
        title : Title of Table [ panel name ]
        data : data for table
          
    }
    Author: Yash Kandalkar
*/

interface ITable {
  title: string;
  data?: any;
  panelColumnDefs?: any;
  showSampleTable?: boolean;
  onChangeHandler?: (data: any) => void;
  zoom?: number;
}

const sampleDataColumnsDef = [
  {
    field: "name",
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true
    },
    sortable: true
  },
  {
    field: "Fleet",
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true
    },
    sortable: true
  },
  {
    field: "status",
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true
    },
    sortable: true,
    cellRenderer: (params) => {
      return <Badge color="emerald">{params.value}</Badge>;
    }
  },
  {
    field: "timestamp",
    filter: "agDateColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true
    },
    sortable: true,
    cellDataType: "date",
    valueFormatter: (params) => {
      return dateService.convertUTCToLocalDate(params.value);
    }
  }
];

const sampleData = [
  {
    name: "ESP32",
    Fleet: "Bikes",
    status: "active",
    timestamp: "2021-09-01T00:00:00.000Z"
  },
  {
    name: "ESP32-1",
    Fleet: "Bikes",
    status: "active",
    timestamp: "2021-09-01T00:00:00.000Z"
  },
  {
    name: "ESP32-2",
    Fleet: "Bikes",
    status: "active",
    timestamp: "2021-09-01T00:00:00.000Z"
  },
  {
    name: "ESP32-3",
    Fleet: "Bikes",
    status: "active",
    timestamp: "2021-09-01T00:00:00.000Z"
  },
  {
    name: "ESP32-4",
    Fleet: "Bikes",
    status: "active",
    timestamp: "2021-09-01T00:00:00.000Z"
  },
  {
    name: "ESP32-5",
    Fleet: "Bikes",
    status: "active",
    timestamp: "2021-09-01T00:00:00.000Z"
  }
];

const TableComponent: React.FC<ITable> = ({
  data,
  panelColumnDefs,
  showSampleTable,
  onChangeHandler
}) => {
  const gridRef = useRef(null);

  const navigate = useNavigate();

  const [rowData] = useState(sampleData);
  const [sampleDataColumns] = useState(sampleDataColumnsDef);
  const [columnDefs, setColumnDefs] = useState(null);

  const [{ inputValues }, createPanelAppearance, setCreatePanelAppearance] =
    useDashboardStore((state) => [
      state.createPanelState,
      state.createPanelAppearance,
      state.setCreatePanelAppearance
    ]);

  const { columns, columnState } = createPanelAppearance;

  const uniqueDeviceIds = useMemo(() => {
    const deviceIds = new Set();
    data?.forEach((row) => {
      if (row.hasOwnProperty("device_id")) {
        deviceIds.add(row["device_id"]);
      }
    });

    return [...deviceIds].join(",");
  }, [data]);

  const { data: devicesResponse } = useGetDevices({
    device_id: uniqueDeviceIds
  });

  const { data: dash } = useGetDashboards();
  const [setSelectedDash, setLayouts, setPanels, setCarousels] =
    useDashboardStore((state) => [
      state.setActiveDashboard,
      state.setLayouts,
      state.setPanels,
      state.setCarousels
    ]);

  useEffect(() => {
    let newColDefs = [];

    if (showSampleTable) {
      let tempColDefs =
        columns?.map((column) => {
          return {
            field: column.parameter.name,
            headerName: column.label,
            filter: "agTextColumnFilter",
            filterParams: {
              buttons: ["apply", "reset"],
              closeOnApply: true
            },
            sortable: true
          };
        }) ?? [];
      newColDefs = [...newColDefs, ...tempColDefs];
      if (columns?.length) {
        // push device name column to the beginning
        newColDefs.unshift({
          field: "device_id",
          headerName: "Device Name",
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["apply", "reset"],
            closeOnApply: true
          },
          sortable: true
        });
        newColDefs.push({
          field: "bucket",
          headerName: "timestamp",
          filter: "agDateColumnFilter",
          filterParams: {
            buttons: ["apply", "reset"],
            closeOnApply: true
          },
          sortable: true
        });
        if (columnDefs === null) {
          setColumnDefs(newColDefs);
        }
        gridRef.current?.api?.setColumnDefs(newColDefs);
      }
    } else {
      // when rendered inside dashboard panel
      let newDefs = panelColumnDefs.map((column) => {
        return {
          field: column.colId,
          headerName: column.headerName,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["apply", "reset"],
            closeOnApply: true
          },
          sortable: true,
          autoRowHeight: true,
          cellRenderer: (params) => {
            if (column.colId === "device_id") {
              return (
                <Link
                  to={`/fleet-and-devices/projects/device-details?code=${params.value}`}
                  className="text-blue-500 cursor-pointer"
                >
                  {
                    devicesResponse?.devices?.find(
                      (device) => device.id === params.value
                    )?.device_name
                  }
                </Link>
              );
            }

            if (column.colId === "station") {
              return (
                <Link
                  to="/dashboard/3ca6eed2-705f-4c2d-91b2-bd94aad0b516"
                  onClick={() => {
                    const dashboard = dash.find(
                      (d) => d.id === "3ca6eed2-705f-4c2d-91b2-bd94aad0b516"
                    );

                    setSelectedDash(dashboard);
                    setPanels([]);
                    setCarousels({});
                    setLayouts(dashboard.meta_data.layouts);
                    navigate(
                      "/dashboard/3ca6eed2-705f-4c2d-91b2-bd94aad0b516"
                    );
                    // window.location.href =
                    //   "/dashboard/3ca6eed2-705f-4c2d-91b2-bd94aad0b516";
                  }}
                  className="text-blue-500 cursor-pointer"
                >
                  {params.value}
                </Link>
              );
            }

            if (["tank_in", "last_in", "gasIn"].includes(column.colId)) {
              return (
                <BadgeDelta
                  className="mt-2"
                  deltaType="increase"
                  isIncreasePositive={true}
                >
                  {formatNumber(params.value ?? 0)}
                </BadgeDelta>
              );
            }
            if (["tank_out", "last_out", "gasOut"].includes(column.colId)) {
              return (
                <BadgeDelta className="mt-2" deltaType="decrease">
                  {formatNumber(params.value ?? 0)}
                </BadgeDelta>
              );
            }

            if (column.colId === "bucket") {
              return dateService.convertUTCToLocalDate(params.value);
            }

            // check if float type
            if (
              params.value &&
              !isNaN(params.value) &&
              params.value.toString().indexOf(".") !== -1
            ) {
              return formatNumber(params.value);
            }

            if (
              params.value &&
              typeof params.value === "string" &&
              params.value.startsWith("(") &&
              params.value.endsWith(")")
            ) {
              const enumsJson = parseAggregatedEnumsStringToJSON(params.value);
              const freq = enumsJson?.counts;
              const labels = enumsJson?.datums.splice(1);

              return (
                <>
                  <div
                    data-tooltip-id={`tooltip-badge-hover`}
                    data-tooltip-labels={labels.join(",")}
                    data-tooltip-values={freq.join(",")}
                    data-tooltip-colors={
                      column.enumBadgeColours
                        ? JSON.stringify(
                            labels.reduce(
                              (acc, label, i) => ({
                                ...acc,
                                [label]: column.enumBadgeColours[label]
                              }),
                              {}
                            )
                          )
                        : JSON.stringify(
                            labels.reduce(
                              (acc, label, i) => ({
                                ...acc,
                                [label]: "gray"
                              }),
                              {}
                            )
                          )
                    }
                    className="flex flex-wrap gap-1 items-center relative h-full overflow-visible"
                  >
                    {labels.map((label, i) => (
                      <Badge
                        size="xs"
                        key={label + i}
                        color={
                          column.enumBadgeColours
                            ? column.enumBadgeColours[label]
                            : "gray"
                        }
                        className={`text-xs absolute !opacity-90 !bg-opacity-90`}
                        style={{
                          top: `${i * 0.25}rem`,
                          left: `${i * 0.25}rem`,
                          border: "1px solid #ccc"
                        }}
                      >
                        <span
                          className="text-white"
                          style={{ fontSize: "0.2rem !important" }}
                        >
                          {label}: {freq[i]}
                        </span>
                      </Badge>
                    ))}
                  </div>
                </>
              );
            }

            return params.value;
          }
        };
      });
      newColDefs = [...newDefs];
      setColumnDefs(newColDefs);
      gridRef.current?.api?.setColumnDefs(newColDefs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnState,
    columns,
    showSampleTable,
    devicesResponse,
    panelColumnDefs
  ]);

  const generateRandomData = useCallback(
    (numOfRows) => {
      const data = [];
      for (let i = 0; i < numOfRows; i++) {
        const row = {};
        row["device_id"] = `Example Device-${i + 1}`;
        row["bucket"] = dateService.convertUTCToLocalDate(
          dateService.getCurrentDate()
        );
        columns?.forEach((column) => {
          row[column.parameter.name] = Math.floor(Math.random() * 100);
        });
        data.push(row);
      }
      return data;
    },
    [columns]
  );

  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true
    };
  }, []);

  const onColumnStateChange = useCallback(() => {
    const columnApi = gridRef.current?.columnApi;

    if (!columnApi) return;
    const _columnState = columnApi
      .getColumnState()
      .filter((column) =>
        columns?.find(
          (col) =>
            col.parameter.name === column.colId ||
            column.colId === "device_id" ||
            column.colId === "bucket"
        )
      )
      .map((column) => ({
        ...column,
        headerName:
          column.colId === "device_id"
            ? "Device Name"
            : column.colId === "bucket"
            ? "Timestamp"
            : columns?.find((col) => col.parameter.name === column.colId)
                ?.label,
        aggregationMode:
          column.colId === "Device Name"
            ? null
            : column.colId === "bucket"
            ? null
            : columns?.find((col) => col.parameter.name === column.colId)
                ?.aggregationMode,
        enumBadgeColours: columns?.find(
          (col) => col.parameter.name === column.colId
        )?.parameter?.enumBadgeColours
      }));
    setCreatePanelAppearance((prev) => ({
      ...prev,
      columnState: _columnState
    }));
  }, [columns, setCreatePanelAppearance]);

  useEffect(() => {
    onChangeHandler &&
      onChangeHandler([
        { ...inputValues, ...createPanelAppearance, columnState }
      ]);
  }, [columnState, createPanelAppearance, inputValues, onChangeHandler]);

  useEffect(() => {
    onColumnStateChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  return (
    <>
      <div className="relative flex flex-col h-[96%] w-full">
        <div
          onMouseDown={(e) => {
            // to prevent react-grid-layout from dragging
            e.preventDefault();
            e.stopPropagation();
          }}
          className={`${
            showSampleTable ? "p-4" : "p-2"
          } text-sm flex flex-col w-full overflow-hidden flex-grow`}
        >
          {showSampleTable ? (
            <div
              className="ag-theme-alpine ag-theme-custom"
              style={{ height: 400, width: "100%`" }}
            >
              {((columns?.length && columnDefs !== null) || true) && (
                <AgGridReact
                  ref={gridRef}
                  rowData={
                    columns?.length
                      ? generateRandomData(
                          inputValues.filters?.devices?.length || 10
                        )
                      : rowData
                  }
                  columnDefs={columns?.length ? columnDefs : sampleDataColumns}
                  defaultColDef={defaultColDef}
                  suppressRowHoverHighlight={true}
                  maintainColumnOrder={true}
                  onColumnMoved={onColumnStateChange}
                  suppressDragLeaveHidesColumns={true}
                />
              )}
            </div>
          ) : (
            <div
              className="ag-theme-alpine ag-theme-custom"
              style={{ height: "100%", width: "100%" }}
            >
              {columnDefs !== null && (
                <AgGridReact
                  ref={gridRef}
                  rowData={data}
                  columnDefs={columnDefs}
                  rowHeight={50}
                  onColumnMoved={onColumnStateChange}
                  maintainColumnOrder={true}
                  defaultColDef={defaultColDef}
                  suppressRowHoverHighlight={true}
                  suppressDragLeaveHidesColumns={true}
                ></AgGridReact>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TableComponent;
