import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Formik, Form } from "formik";
import ColumnItem, { IColumn } from "./table-form-column-item.component";
import { Badge } from "@tremor/react";
import { PlusIcon, SparklesIcon } from "@heroicons/react/24/outline";
import ColumnParamLabel from "./table-form-column-param-label.component";
import { isObject } from "@utils/helper.util";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import { useDashboardStore } from "@/store";

interface ITableError {
  dataSource: boolean;
  columns: true;
}

export const TableForm = ({ onChangeHandler, enableCreatebutton }) => {
  const [allParams, setAllParams] = useState<any[]>([]);
  const [selectedColumnToEdit, setSelectedColumnToEdit] = useState(null);

  const [{ inputValues }, createPanelAppearance, setCreatePanelAppearance] =
    useDashboardStore((state) => [
      state.createPanelState,
      state.createPanelAppearance,

      state.setCreatePanelAppearance
    ]);

  const setColumns = (cols: IColumn[]) =>
    setCreatePanelAppearance((prev) => ({ ...prev, columns: cols }));

  const [error, setErrors] = useState<ITableError>({
    dataSource: true,
    columns: true
  });

  const initialValues = useMemo(
    () => ({
      "table-parameter": ""
    }),
    []
  );

  const { data: dataPointDefs } = useGetDataPointDefinitions({
    fields: "data_point_proto_structure"
  });

  const { data: shadowDefs } = useGetShadowDefinitions();

  useEffect(() => {
    const params = [];

    if (inputValues.dataPoints.length && dataPointDefs?.length) {
      inputValues.dataPoints.forEach((dp) => {
        const dataPoint = dataPointDefs.find((d) => d.id === dp.value);
        Object.keys(dataPoint.data_point_proto_structure).forEach(
          (paramKey) => {
            const param = dataPoint.data_point_proto_structure[paramKey];

            if (
              createPanelAppearance.columns?.find(
                (c) => c.parameter.value === dataPoint.id + "/" + param.id
              )
            ) {
              return;
            }

            if (isObject(param.structure)) {
              return;
            }

            params.push({
              value: dataPoint.name + "/" + paramKey,
              label: (
                <ColumnParamLabel
                  paramName={paramKey}
                  dataSourceName={dataPoint.name}
                  dataSource={"dataPoint"}
                />
              ),
              dataSourceName: dataPoint.name,
              dataSource: "dataPoint",
              name: paramKey,
              type: param.structure,
              enumBadgeColours: param.options?.reduce((acc, curr) => {
                acc[curr] = "gray";
                return acc;
              }, {})
            });
          }
        );
      });
    }

    if (inputValues.shadow?.id) {
      Object.keys(inputValues.shadow.shadow_proto_structure).forEach(
        (paramKey) => {
          params.push({
            value: inputValues.shadow.name + "/" + paramKey,
            label: (
              <ColumnParamLabel
                paramName={paramKey}
                dataSourceName={inputValues.shadow.name}
                dataSource={"shadow"}
              />
            ),
            dataSourceName: inputValues.shadow.name,
            dataSource: "shadow",
            name: paramKey
          });
        }
      );
    }

    setAllParams(params);

    return () => {};
  }, [
    createPanelAppearance.columns,
    dataPointDefs,
    inputValues.dataPoints,
    inputValues.shadow,
    shadowDefs
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (createPanelAppearance.columns) {
      let newCols = createPanelAppearance.columns?.filter(
        (c) => c.parameter.dataSource !== "dataPoint"
      );

      setCreatePanelAppearance((prev) => ({ ...prev, columns: newCols }));
    }
  }, [inputValues.dataPoints]);

  useEffect(() => {
    if (createPanelAppearance.columns) {
      let newCols = createPanelAppearance.columns?.filter(
        (c) => c.parameter.dataSource !== "shadow"
      );
      setCreatePanelAppearance((prev) => ({ ...prev, columns: newCols }));
    }
  }, [inputValues.shadow]);

  // this ref makes sure we only run the effect once as
  // it needs to run once when we are navigating here from an edit panel action
  const setColsOnce = useRef(false);

  useEffect(() => {
    if (
      !setColsOnce.current &&
      allParams.length &&
      createPanelAppearance.columnState?.length
    ) {
      const colsFromParams = createPanelAppearance.columnState?.filter(
        (col) => !["device_id", "bucket"].includes(col.colId)
      );

      // a sanity check, to only update columns when we need to.
      if (
        !createPanelAppearance.columns ||
        colsFromParams.length > createPanelAppearance.columns?.length
      ) {
        const cols = colsFromParams.map((col) => ({
          aggregationMode: col.aggregationMode,
          label: col.headerName,
          parameter: allParams.find((param) => param.name === col.colId)
        }));

        setCreatePanelAppearance((prev) => ({ ...prev, columns: cols }));
        setColsOnce.current = true;
      }
    }
    return () => {};
  }, [
    allParams,
    createPanelAppearance.columnState,
    createPanelAppearance.columns,
    setCreatePanelAppearance
  ]);

  const isValid = useCallback(() => {
    let hasErrors = false;
    let errors: ITableError = { ...error };

    if (!inputValues.dataPoints?.length && !inputValues.shadow) {
      errors.dataSource = true;
      hasErrors = true;
    } else if (createPanelAppearance.columns?.length === 0) {
      errors.columns = true;
      hasErrors = true;
    } else {
      errors.dataSource = false;
      hasErrors = false;
    }

    setErrors(errors);

    return !hasErrors;
  }, [error, inputValues, createPanelAppearance.columns]);

  useEffect(() => {
    if (isValid()) {
      enableCreatebutton && enableCreatebutton(false);
      onChangeHandler &&
        onChangeHandler([
          { ...inputValues, columns: createPanelAppearance.columns }
        ]);
    } else {
      enableCreatebutton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues, createPanelAppearance.columns]);

  const newParamInNewColumn = useMemo(() => {
    let param =
      allParams.find(
        (p) =>
          !createPanelAppearance.columns?.find(
            (c) => c.parameter.value === p.value
          )
      ) ?? null;
    return param;
  }, [allParams, createPanelAppearance.columns]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <div className="mb-2">
          <div className="flex flex-col gap-4">
            {inputValues.dataPoints.length ? (
              newParamInNewColumn && (
                <div className="flex gap-2">
                  <Badge
                    size="xs"
                    className="cursor-pointer select-none"
                    onClick={() => {
                      let param = newParamInNewColumn;
                      setColumns([
                        {
                          label:
                            param.type === "string"
                              ? param.name + "_freq"
                              : param.name,
                          parameter: param,
                          aggregationMode: "avg"
                        },
                        ...(createPanelAppearance.columns ?? [])
                      ]);
                    }}
                  >
                    <span className="flex gap-1 text-xs">
                      <PlusIcon width={14} />
                      Add Column
                    </span>
                  </Badge>
                  <Badge
                    color="emerald"
                    size="xs"
                    className="cursor-pointer select-none"
                    onClick={() => {
                      setColumns([
                        ...allParams
                          .filter(
                            (p) =>
                              !createPanelAppearance.columns?.find(
                                (c) => c.parameter.value === p.value
                              )
                          )
                          .map((param) => ({
                            label:
                              param.type === "string"
                                ? param.name + "_freq"
                                : param.name,
                            parameter: param,
                            aggregationMode: "avg"
                          })),
                        ...(createPanelAppearance.columns ?? [])
                      ]);
                    }}
                  >
                    <span className="flex gap-1 text-xs">
                      <SparklesIcon width={14} />
                      Add All Columns
                    </span>
                  </Badge>
                </div>
              )
            ) : (
              <div className="flex justify-center items-center h-28">
                Select a data source to add columns!
              </div>
            )}
            {(inputValues.dataPoints.length || inputValues.shadow?.id) &&
              createPanelAppearance.columns?.map((col, idx) => (
                <ColumnItem
                  key={idx}
                  index={idx}
                  setColumns={(columns) => {
                    setColumns(columns);
                  }}
                  setSelectedColumnToEdit={setSelectedColumnToEdit}
                  paramOptions={
                    allParams.filter(
                      (p) =>
                        !createPanelAppearance.columns?.find(
                          (c) => c.parameter.value === p.value
                        )
                    ) ?? []
                  }
                  editOpen={selectedColumnToEdit === idx}
                  allParams={allParams}
                  createPanelAppearance={createPanelAppearance}
                />
              ))}
          </div>
        </div>
      </Form>
    </Formik>
  );
};
