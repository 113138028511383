import { IOption } from "./shared.interface";

export interface IQuery {
  dataPointId: string;
  tableName: string;
  select: ISelect[] | null;
  alias: string;
  view: string;
  withClause?: IWith[] | null;
  group: TGroupBy;
  where?: TWhere | null;
  order: TOrderBy;
  limit: TLimit;
  offset: TOffset;
  joinClause?: IJoin[] | null;
  createView: boolean;
}

export interface ISelect {
  distinct: boolean;
  param: string;
  alias: string;
  agg: string;
  expression?: IExpression | null;
  subQuery?: IQuery | null;
  caseClause?: ICaseClause | null;
}

export interface IExpression {
  pattern: string;
  values: { [key: string]: any };
}

export interface ICaseClause {
  when: IWhenThen[];
  else: ISelect | null;
}

export interface IWhenThen {
  when: TWhere;
  then: ISelect | null;
}

export interface IWith {
  query: IQuery;
  alias: string;
}

export enum Operator {
  lt = "lt",
  gt = "gt",
  lte = "lte",
  gte = "gte",
  eq = "eq",
  neq = "neq",
  is = "is",
  in = "in",
  iNN = "iNN",
  iN = "iN"
}

export const OPERATOR_OPTIONS: Record<Operator, IOption> = {
  [Operator.eq]: { value: Operator.eq, label: "EQUAL TO" },
  [Operator.neq]: { value: Operator.neq, label: "NOT EQUAL TO" },
  [Operator.lt]: { value: Operator.lt, label: "LESS THAN" },
  [Operator.gt]: { value: Operator.gt, label: "GREATER THAN" },
  [Operator.lte]: { value: Operator.lte, label: "LESS THAN EQUAL TO" },
  [Operator.gte]: { value: Operator.gte, label: "GREATER THAN EQUAL TO" },
  [Operator.is]: { value: Operator.is, label: "IS" },
  [Operator.iN]: { value: Operator.iN, label: "IS NULL" },
  [Operator.in]: { value: Operator.in, label: "IN" },
  [Operator.iNN]: { value: Operator.iNN, label: "IS NOT NULL" }
};

// AND
export type TWhere = Array<
  // OR
  Array<{
    param: string;
    // OR
    condition: Array<{
      operator: Operator;
      value: string | IQuery;
      valueError?: boolean | null;
    }>;
  }>
>;

export type TGroupBy = string[];

export enum Order {
  ASC = 1,
  DESC = -1
}

export const ORDER_BY_OPTIONS: Record<Order, IOption> = {
  [Order.ASC]: { value: Order.ASC.toString(), label: "ASCENDING" },
  [Order.DESC]: { value: Order.DESC.toString(), label: "DESCENDING" }
};

export type TOrderBy = Array<{ parameter: string; order: Order }>;

export type TLimit = number;

export type TOffset = number;

export interface IJoin {
  type: string;
  dataPointId: string;
  table?: string;
  alias?: string;
  on: Array<{ a: string; b: string }>;
  where?: TWhere | null;
}
