import React from 'react';
import OTAReleaseHeader from '../components/ota-release-header.component';
import ReleaseMain from './ota-release-main.component';
import CreateReleaseForm from '../components/ota-create-release-form.component';
import ReleaseArtifactForm from '../components/ota-release-artifact-form.component';

const Projects: React.FC = () => {

    return (
        <>
            <OTAReleaseHeader />
            <ReleaseMain />
            <CreateReleaseForm />
            <ReleaseArtifactForm />
        </>
    )
}

export default Projects;
