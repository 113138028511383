import React, { useMemo, useState } from "react";
import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import { useFleetAndDevicesStore } from "@store/index";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  MagnifyingGlassIcon
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { DebouncedInput } from "@app/shared/components/deboundced-input.component";
import { reactTableFuzzyFilter } from "@app/shared/utils/helper.util";
import { IDevice, IFleet } from "@interfaces/fad.interface";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import Pagination from "@/app/shared/components/pagination.component";
import ShowLoading from "@/app/shared/components/loading.component";

interface IDataPointUsageRow extends IDevice {
  fleet_name: string;
  fleet: IFleet;
  id: string;
}

const DataPointDefinitionUsage = ({ dataPointDef }) => {
  const [setSelectedFleet] = useFleetAndDevicesStore((state) => [
    state.setSelectedFleet
  ]);
  const [globalFilter, setGlobalFilter] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const { data: fleets } = useGetFleets();
  const { data: devicesResponse, isLoading: isDevicesLoading } = useGetDevices(
    {
      data_point_definition_id: dataPointDef.id,
      page,
      limit: pageSize
    }
  );

  const rows = useMemo(() => {
    if (!fleets || !devicesResponse) return [];
    let filteredDevices = [];

    if (devicesResponse?.devices?.length && fleets.length) {
      devicesResponse?.devices?.forEach((device) => {
        const fleet = fleets.find((f) => f.id === device.fleet_id);
        filteredDevices.push({
          ...device,
          fleet,
          fleet_name: fleet.fleet_name,
          version: "Latest"
        });
      });
    }

    return filteredDevices;
  }, [devicesResponse, fleets]);

  const columns = useMemo<ColumnDef<IDataPointUsageRow, any>[]>(
    () => [
      {
        id: "name",
        accessorKey: "device_name",
        header: "Device Name",
        cell: ({ row, getValue }) => {
          return (
            <div className="flex flex-row items-center gap-2">
              {/* {
                <ChevronDownIcon
                  width={14}
                  className={`cursor-pointer transition-transform min-w-[14px] transform ${
                    row.getIsExpanded() ? "rotate-180" : "rotate-0"
                  }`}
                  onClick={() => row.toggleExpanded()}
                />
              } */}
              <span
                className="hover:underline flex-grow cursor-pointer"
                onClick={() => {
                  setSelectedFleet(row.original.fleet);
                  navigate(
                    `/fleet-and-devices/projects/device-details?code=${row.original.id}`
                  );
                }}
              >
                {getValue()}
              </span>
            </div>
          );
        },
        size: 30
      },
      {
        id: "fleet_name",
        accessorKey: "fleet_name",
        header: "Fleet Name",
        cell: ({ row, getValue }) => {
          return (
            <div className="flex flex-row items-center gap-2">
              <span
                className="hover:underline flex-grow cursor-pointer"
                onClick={() => {
                  setSelectedFleet(row.original.fleet);
                  navigate(`/fleet-and-devices/projects`);
                }}
              >
                {getValue()}
              </span>
            </div>
          );
        },
        size: 30
      },
      {
        id: "version",
        accessorKey: "version",
        header: "Version",
        cell: ({ getValue }) => {
          return <div className="text-contentColorLight">{getValue()}</div>;
        },
        size: 30
      }
    ],
    [navigate, setSelectedFleet]
  );

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data: rows,
    columns,
    state: {
      expanded,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: reactTableFuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    onExpandedChange: setExpanded,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  if (isDevicesLoading) {
    return <ShowLoading />;
  }

  return (
    <div>
      <div className="my-6 max-w-sm">
        <DebouncedInput
          value={globalFilter ?? ""}
          icon={MagnifyingGlassIcon}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-background-layer3 bg-background text-contentColor"
          placeholder="Search all columns..."
        />
      </div>
      <hr className="border-background-layer3" />
      {isDevicesLoading ? null : (
        <>
          {/* table */}
          <table className="w-full h-full my-6">
            <thead className="">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.column.getSize()
                        }}
                        className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler()
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <ArrowUpIcon width={10} />,
                                desc: <ArrowDownIcon width={10} />
                              }[header.column.getIsSorted() as string] ??
                                (header.column.getCanSort() ? (
                                  <ArrowsUpDownIcon width={10} />
                                ) : null)}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div>{""}</div>
                            ) : null}
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <>
                    <tr
                      key={row.id}
                      className="bg-background-layer1 border-b border-background-layer3"
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="mx-2 text-sm text-center p-2 py-3"
                            style={{
                              width: cell.column.getSize()
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    {/* FEAT: For showing devices: */}
                    {/* {row.getIsExpanded() ? (
                    <tr className="bg-gray-100">
                      <td colSpan={row.getVisibleCells().length}>
                        <div className="w-full flex p-4 justify-center items-center">
                          <ShadowDefinitionUsageDevices
                            fleetDevices={fleetDevices}
                            setFleetDevices={setFleetDevices}
                            fleet={row.original}
                            dataPointDef={dataPointDef}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : null} */}
                  </>
                );
              })}
            </tbody>
          </table>
          <Pagination<IDataPointUsageRow>
            table={table}
            setPage={setPage}
            setPageSize={setPageSize}
            meta={devicesResponse?.meta}
          />
        </>
      )}
    </div>
  );
};

export default DataPointDefinitionUsage;
