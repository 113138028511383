import LoadingSpin from "@/app/shared/components/loading-spin.component";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import { IOption } from "@/interfaces";
import { ITrigger } from "@/interfaces/triggers.interface";
import useRuleEngineStore, {
  IRule
} from "@/store/rule-engine/rule-engine.store";
import React, { useMemo, useState } from "react";
import { UseQueryResult } from "react-query";
import ReactSelect from "react-select";

interface IHTTPTriggExistingProps {
  rule: IRule;
  getTriggersQuery: UseQueryResult<ITrigger[], unknown>;
}

const HTTPTriggExisting: React.FC<IHTTPTriggExistingProps> = ({
  rule,
  getTriggersQuery: { data: triggers, isLoading }
}) => {
  const [setRules] = useRuleEngineStore((state) => [state.setRules]);
  const [selectedTrigger, setSelectedTrigger] = useState<string>(
    rule.triggerId ?? null
  );

  const triggerOptions = useMemo(() => {
    return (
      triggers
        ?.filter((trigger) => !trigger.rule_id)
        .map((trigger) => ({
          label: trigger.name,
          value: trigger.id
        })) ?? []
    );
  }, [triggers]);

  const onTriggerSelect = (opt: IOption) => {
    setSelectedTrigger(opt?.value ?? null);

    setRules((rules) => ({
      ...rules,
      [rule.name]: {
        ...rules[rule.name],
        triggerId: opt?.value ?? null
      }
    }));
  };

  return (
    <div id="abcd" className="nodrag flex items-center justify-center">
      {isLoading ? (
        <LoadingSpin />
      ) : (
        <ReactSelect
          options={triggerOptions}
          onChange={onTriggerSelect}
          value={triggerOptions.find((t) => t.value === selectedTrigger)}
          placeholder="Select a trigger."
          className="w-full min-w-full"
          menuPosition="fixed"
          isClearable
          menuPortalTarget={
            document.getElementsByClassName(
              "reactflow-wrapper"
            )[0] as HTMLElement
          }
          classNames={reactSelectClassNames}
        />
      )}
    </div>
  );
};

export default HTTPTriggExisting;
