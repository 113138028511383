import React, { useId, useMemo, useState } from "react";
import {
  IJoin,
  TOrderBy,
  IQuery,
  ISelect,
  TWhere,
  Operator,
  Order
} from "@interfaces/query-builder";
import { Button } from "@tremor/react";
import {
  ChevronUpIcon,
  PlusIcon,
  QuestionMarkCircleIcon
} from "@heroicons/react/24/outline";

import SelectClause from "./clauses/select.component";
import WhereClause from "./clauses/where.component";
import GroupByClause from "./clauses/group-by.component";
import OrderByClause from "./clauses/order-by.component";
import JoinClause from "./clauses/join.component";
import WithClause from "./clauses/with.component";

import ReactSelect from "react-select";
import { IOption } from "@interfaces/shared.interface";
import { Tooltip } from "react-tooltip";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";

interface QueryComponentProps {
  query: IQuery;
  setQuery: (query: IQuery) => void;
  isSub: boolean;
}

const sourceOptions: IOption[] = [
  {
    label: "Data Point Definition ID",
    value: "DDPI"
  },
  {
    label: "Table Name",
    value: "TN"
  },
  {
    label: "View Name",
    value: "VN"
  }
];

const QueryComponent: React.FC<QueryComponentProps> = ({
  query,
  isSub,
  setQuery
}) => {
  const [sourceType] = useState<IOption>(sourceOptions[0]);
  const [collapsedStates, setCollapsedStates] = useState({
    select: false,
    where: false,
    groupBy: false,
    orderBy: false,
    join: false,
    with: false
  });
  const tooltipId = useId();

  const { data: dataPointDefinitions } = useGetDataPointDefinitions({
    fields: "data_point_proto_structure"
  });

  const handleAddSelectClause = () => {
    const newSelectClause: ISelect = {
      distinct: false,
      param: "",
      alias: "",
      agg: "",
      expression: null,
      subQuery: null,
      caseClause: null
    };

    setQuery({
      ...query,
      select: [...query.select, newSelectClause]
    });
  };

  const handleSelectChange = (select: ISelect, index: number) => {
    const newSelects = [...query.select];
    newSelects[index] = select;
    setQuery({ ...query, select: newSelects });
  };

  const handleAddGroupByClause = () => {
    setQuery({ ...query, group: [""] });
  };

  const handleAddWithClause = () => {
    const newQuery: IQuery = {
      dataPointId: "",
      tableName: "",
      select: [],
      alias: "",
      view: "",
      group: [],
      order: [],
      limit: 0,
      offset: 0,
      createView: false
    };
    setQuery({
      ...query,
      withClause: [
        {
          alias: "",
          query: newQuery
        }
      ]
    });
  };

  const handleAddWhereClause = () => {
    const newWhereClause: TWhere = [
      [
        {
          param: "",
          condition: [
            {
              operator: Operator.eq,
              value: null
            }
          ]
        }
      ]
    ];

    setQuery({ ...query, where: newWhereClause });
  };

  const handleWhereChange = (where: TWhere) => {
    setQuery({ ...query, where });
  };

  const handleAddOrderByClause = () => {
    const newOrderByClause: TOrderBy = [
      {
        order: Order.ASC,
        parameter: ""
      }
    ];

    setQuery({ ...query, order: newOrderByClause });
  };

  const handleAddJoinClause = () => {
    const newJoin: IJoin = {
      type: "",
      dataPointId: "",
      on: [
        {
          a: "",
          b: ""
        }
      ]
    };

    const newJoinClause: IJoin[] = [newJoin];

    setQuery({ ...query, joinClause: newJoinClause });
  };

  const dpdOptions = useMemo(
    () =>
      dataPointDefinitions?.map((ddp) => ({
        label: ddp.name,
        value: ddp.id
      })),
    [dataPointDefinitions]
  );

  const sourceTypeToQueryKey = useMemo(
    () =>
      sourceType.value === "DDPI"
        ? "dataPointId"
        : sourceType.value === "TN"
        ? "tableName"
        : sourceType.value === "VN"
        ? "view"
        : null,
    [sourceType.value]
  );

  const selectedDPDStructure = useMemo(
    () =>
      dataPointDefinitions?.find((def) => def.id === query.dataPointId)
        ?.data_point_proto_structure,
    [dataPointDefinitions, query.dataPointId]
  );

  return (
    <div className="border bg-background border-background-layer3 flex flex-col gap-2 items-start p-4 mb-4">
      <label className="m-2 flex gap-2 items-center ">
        Alias:
        <input
          type="text"
          value={query.alias}
          onChange={(e) =>
            setQuery({
              ...query,
              alias: e.target.value.trim()
            })
          }
          className="ml-2 text-xs border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
        />
        <span
          data-tooltip-id={"query-tooltip-" + tooltipId}
          data-tooltip-html="Optional. You can use this alias to reference columns <br />from this table/view from any part of the query."
        >
          <QuestionMarkCircleIcon width={24} className="text-primary" />
        </span>
      </label>

      <label className="m-2 flex gap-2 items-center ">
        {sourceType.label}:
        <ReactSelect
          value={dpdOptions?.find(
            (op) => op.value === query[sourceTypeToQueryKey]
          )}
          onChange={(val: IOption) => {
            sourceTypeToQueryKey &&
              setQuery({ ...query, [sourceTypeToQueryKey]: val.value });
          }}
          options={dpdOptions}
        />
      </label>

      <hr className="border-background-layer3 w-full mb-2" />

      {query.withClause?.length ? (
        <>
          <div className="flex items-center gap-4 mb-2">
            <h2 className="text-base font-medium">WITH:</h2>
            <Button
              type="button"
              color="red"
              variant="light"
              size="xs"
              onClick={(e) => {
                setQuery({
                  ...query,
                  withClause: []
                });
              }}
              className="bg-red-100 px-2 py-1 rounded-md text-xs hover:bg-red-200"
            >
              DELETE
            </Button>
            <Button
              type="button"
              variant="light"
              size="xs"
              disabled={false}
              icon={(props) => (
                <ChevronUpIcon
                  {...props}
                  className={`transition-transform duration-200 ${
                    collapsedStates.with ? "rotate-180" : "rotate-0"
                  } ${props.className}`}
                />
              )}
              onClick={(e) => {
                setCollapsedStates({
                  ...collapsedStates,
                  with: !collapsedStates.with
                });
              }}
              className="uppercase bg-blue-100  px-2 py-1 rounded-md text-xs hover:bg-blue-200"
            >
              {collapsedStates.with ? "expand" : "collapse"}
            </Button>
            {collapsedStates.with ? (
              <div className="text-contentColorLight text-sm">
                Expand to view
              </div>
            ) : null}
          </div>
          {!collapsedStates.with ? (
            <WithClause
              withClause={query.withClause}
              onChangeHandler={(_with) => {
                setQuery({ ...query, withClause: _with });
              }}
            />
          ) : null}
          <hr className="border-background-layer3 w-full mb-2" />
        </>
      ) : (
        <Button
          type="button"
          variant={isSub ? "secondary" : "primary"}
          size={isSub ? "xs" : "md"}
          icon={PlusIcon}
          onClick={handleAddWithClause}
          className="m-2 justify-start w-min "
        >
          With Clause
        </Button>
      )}

      {/* Render SelectClauses */}
      {query.select?.length ? (
        <>
          <div className="flex items-center gap-4 mb-2">
            <h2 className="text-base font-medium">SELECT:</h2>
            <Button
              type="button"
              color="red"
              variant="light"
              size="xs"
              onClick={(e) => {
                setQuery({
                  ...query,
                  select: []
                });
              }}
              className="bg-red-100 px-2 py-1 rounded-md text-xs hover:bg-red-200"
            >
              DELETE
            </Button>
            <Button
              type="button"
              variant="light"
              size="xs"
              disabled={false}
              icon={(props) => (
                <ChevronUpIcon
                  {...props}
                  className={`transition-transform duration-200 ${
                    collapsedStates.select ? "rotate-180" : "rotate-0"
                  } ${props.className}`}
                />
              )}
              onClick={(e) => {
                setCollapsedStates({
                  ...collapsedStates,
                  select: !collapsedStates.select
                });
              }}
              className="uppercase bg-blue-100  px-2 py-1 rounded-md text-xs hover:bg-blue-200"
            >
              {collapsedStates.select ? "expand" : "collapse"}
            </Button>
            {collapsedStates.select ? (
              <div className="text-contentColorLight text-sm">
                Expand to view
              </div>
            ) : null}
          </div>
          {!collapsedStates.select
            ? query.select.map((select: ISelect, index: number) => (
                <SelectClause
                  selectedDPDStructure={selectedDPDStructure}
                  key={index}
                  select={select}
                  onChangeHandler={(select) =>
                    handleSelectChange(select, index)
                  }
                  onDelete={() => {
                    const newSelects = [...query.select];
                    newSelects.splice(index, 1);
                    setQuery({
                      ...query,
                      select: newSelects
                    });
                  }}
                />
              ))
            : null}
          {!collapsedStates.select ? (
            <Button
              type="button"
              icon={PlusIcon}
              onClick={handleAddSelectClause}
              className="m-2 ml-12 px-3 py-2  rounded-sm"
            >
              Paramter
            </Button>
          ) : null}
          <hr className="border-background-layer3 w-full mb-2" />
        </>
      ) : (
        <Button
          type="button"
          variant={isSub ? "secondary" : "primary"}
          size={isSub ? "xs" : "md"}
          icon={PlusIcon}
          onClick={handleAddSelectClause}
          className="m-2 justify-start w-min "
        >
          Select Clause
        </Button>
      )}

      {query.where?.length ? (
        <>
          <div className="flex items-center gap-4 mb-2">
            <h2 className="text-base font-medium">WHERE:</h2>
            <Button
              type="button"
              color="red"
              variant="light"
              size="xs"
              onClick={(e) => {
                setQuery({
                  ...query,
                  where: []
                });
              }}
              className="bg-red-100 px-2 py-1 rounded-md text-xs hover:bg-red-200"
            >
              DELETE
            </Button>
            <Button
              type="button"
              variant="light"
              size="xs"
              disabled={false}
              icon={(props) => (
                <ChevronUpIcon
                  {...props}
                  className={`transition-transform duration-200 ${
                    collapsedStates.where ? "rotate-180" : "rotate-0"
                  } ${props.className}`}
                />
              )}
              onClick={(e) => {
                setCollapsedStates({
                  ...collapsedStates,
                  where: !collapsedStates.where
                });
              }}
              className="uppercase bg-blue-100  px-2 py-1 rounded-md text-xs hover:bg-blue-200"
            >
              {collapsedStates.where ? "expand" : "collapse"}
            </Button>
            {collapsedStates.where ? (
              <div className="text-contentColorLight text-sm">
                Expand to view
              </div>
            ) : null}
          </div>
          {!collapsedStates.where ? (
            <WhereClause
              selectedDPDStructure={selectedDPDStructure}
              where={query.where}
              onChangeHandler={(where) => handleWhereChange(where)}
              onDelete={() => {
                setQuery({
                  ...query,
                  where: []
                });
              }}
            />
          ) : null}
          <hr className="border-background-layer3 w-full mb-2" />
        </>
      ) : (
        <Button
          type="button"
          variant={isSub ? "secondary" : "primary"}
          size={isSub ? "xs" : "md"}
          icon={PlusIcon}
          onClick={handleAddWhereClause}
          className="m-2 justify-start w-min "
        >
          Where Clause
        </Button>
      )}

      {query.group?.length ? (
        <>
          <div className="flex items-center gap-4 mb-2">
            <h2 className="text-base font-medium">GROUP BY:</h2>
            <Button
              type="button"
              color="red"
              variant="light"
              size="xs"
              onClick={(e) => {
                setQuery({
                  ...query,
                  group: []
                });
              }}
              className="bg-red-100 px-2 py-1 rounded-md text-xs hover:bg-red-200"
            >
              DELETE
            </Button>
            <Button
              type="button"
              variant="light"
              size="xs"
              disabled={false}
              icon={(props) => (
                <ChevronUpIcon
                  {...props}
                  className={`transition-transform duration-200 ${
                    collapsedStates.groupBy ? "rotate-180" : "rotate-0"
                  } ${props.className}`}
                />
              )}
              onClick={(e) => {
                setCollapsedStates({
                  ...collapsedStates,
                  groupBy: !collapsedStates.groupBy
                });
              }}
              className="uppercase bg-blue-100  px-2 py-1 rounded-md text-xs hover:bg-blue-200"
            >
              {collapsedStates.groupBy ? "expand" : "collapse"}
            </Button>
            {collapsedStates.groupBy ? (
              <div className="text-contentColorLight text-sm">
                Expand to view
              </div>
            ) : null}
          </div>
          {!collapsedStates.groupBy ? (
            <GroupByClause
              groupBy={query.group}
              selectedDPDStructure={selectedDPDStructure}
              onChangeHandler={(group) => setQuery({ ...query, group })}
            />
          ) : null}
          <hr className="border-background-layer3 w-full mb-2" />
        </>
      ) : (
        <Button
          type="button"
          variant={isSub ? "secondary" : "primary"}
          size={isSub ? "xs" : "md"}
          icon={PlusIcon}
          onClick={handleAddGroupByClause}
          className="m-2 justify-start w-min "
        >
          Group By Clause
        </Button>
      )}

      {query.order?.length ? (
        <>
          <div className="flex items-center gap-4 mb-2">
            <h2 className="text-base font-medium">ORDER BY:</h2>
            <Button
              type="button"
              color="red"
              variant="light"
              size="xs"
              onClick={(e) => {
                setQuery({
                  ...query,
                  order: []
                });
              }}
              className="bg-red-100 px-2 py-1 rounded-md text-xs hover:bg-red-200"
            >
              DELETE
            </Button>
            <Button
              type="button"
              variant="light"
              size="xs"
              disabled={false}
              icon={(props) => (
                <ChevronUpIcon
                  {...props}
                  className={`transition-transform duration-200 ${
                    collapsedStates.orderBy ? "rotate-180" : "rotate-0"
                  } ${props.className}`}
                />
              )}
              onClick={(e) => {
                setCollapsedStates({
                  ...collapsedStates,
                  orderBy: !collapsedStates.orderBy
                });
              }}
              className="uppercase bg-blue-100  px-2 py-1 rounded-md text-xs hover:bg-blue-200"
            >
              {collapsedStates.orderBy ? "expand" : "collapse"}
            </Button>
            {collapsedStates.orderBy ? (
              <div className="text-contentColorLight text-sm">
                Expand to view
              </div>
            ) : null}
          </div>
          {!collapsedStates.orderBy ? (
            <OrderByClause
              orderBy={query.order}
              selectedDPDStructure={selectedDPDStructure}
              onChangeHandler={(order) => setQuery({ ...query, order })}
            />
          ) : null}
          <hr className="border-background-layer3 w-full mb-2" />
        </>
      ) : (
        <Button
          type="button"
          variant={isSub ? "secondary" : "primary"}
          size={isSub ? "xs" : "md"}
          icon={PlusIcon}
          onClick={handleAddOrderByClause}
          className="m-2 justify-start w-min "
        >
          Order By Clause
        </Button>
      )}

      {query.joinClause?.length ? (
        <>
          <div className="flex items-center gap-4 mb-2">
            <h2 className="text-base font-medium">JOIN:</h2>
            <Button
              type="button"
              color="red"
              variant="light"
              size="xs"
              onClick={(e) => {
                setQuery({
                  ...query,
                  joinClause: []
                });
              }}
              className="bg-red-100 px-2 py-1 rounded-md text-xs hover:bg-red-200"
            >
              DELETE
            </Button>
            <Button
              type="button"
              variant="light"
              size="xs"
              disabled={false}
              icon={(props) => (
                <ChevronUpIcon
                  {...props}
                  className={`transition-transform duration-200 ${
                    collapsedStates.join ? "rotate-180" : "rotate-0"
                  } ${props.className}`}
                />
              )}
              onClick={(e) => {
                setCollapsedStates({
                  ...collapsedStates,
                  join: !collapsedStates.join
                });
              }}
              className="uppercase bg-blue-100  px-2 py-1 rounded-md text-xs hover:bg-blue-200"
            >
              {collapsedStates.join ? "expand" : "collapse"}
            </Button>
            {collapsedStates.join ? (
              <div className="text-contentColorLight text-sm">
                Expand to view
              </div>
            ) : null}
          </div>
          {!collapsedStates.join ? (
            <JoinClause
              selectedDPDStructure={selectedDPDStructure}
              joins={query.joinClause}
              onChangeHandler={(joins) =>
                setQuery({ ...query, joinClause: joins })
              }
            />
          ) : null}
          <hr className="border-background-layer3 w-full mb-2" />
        </>
      ) : (
        <Button
          type="button"
          variant={isSub ? "secondary" : "primary"}
          size={isSub ? "xs" : "md"}
          icon={PlusIcon}
          onClick={handleAddJoinClause}
          className="m-2 justify-start w-min "
        >
          Join Clause
        </Button>
      )}

      {query.limit !== 0 ? (
        <>
          <div className="flex items-center gap-4 mb-2">
            <h2 className="text-base font-medium">LIMIT:</h2>
            <Button
              type="button"
              color="red"
              variant="light"
              size="xs"
              onClick={(e) => {
                setQuery({
                  ...query,
                  limit: 0
                });
              }}
              className="bg-red-100 px-2 py-1 rounded-md text-xs hover:bg-red-200"
            >
              DELETE
            </Button>
          </div>

          <input
            type="number"
            value={query.limit}
            min={1}
            onChange={(e) =>
              setQuery({
                ...query,
                limit: parseInt(e.target.value)
              })
            }
            className="ml-2 text-xs bg-background border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
          />

          <hr className="border-background-layer3 w-full my-2" />
        </>
      ) : (
        <Button
          type="button"
          variant={isSub ? "secondary" : "primary"}
          size={isSub ? "xs" : "md"}
          icon={PlusIcon}
          onClick={() =>
            setQuery({
              ...query,
              limit: 1
            })
          }
          className="m-2 justify-start w-min "
        >
          Limit Clause
        </Button>
      )}

      {query.offset !== 0 ? (
        <>
          <div className="flex items-center gap-4 mb-2">
            <h2 className="text-base font-medium">OFFSET:</h2>
            <Button
              type="button"
              color="red"
              variant="light"
              size="xs"
              onClick={(e) => {
                setQuery({
                  ...query,
                  offset: 0
                });
              }}
              className="bg-red-100 px-2 py-1 rounded-md text-xs hover:bg-red-200"
            >
              DELETE
            </Button>
          </div>

          <input
            type="number"
            value={query.offset}
            min={1}
            onChange={(e) =>
              setQuery({
                ...query,
                offset: parseInt(e.target.value)
              })
            }
            className="ml-2 text-xs bg-background border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
          />

          <hr className="border-background-layer3 w-full my-2" />
        </>
      ) : (
        <Button
          type="button"
          variant={isSub ? "secondary" : "primary"}
          size={isSub ? "xs" : "md"}
          icon={PlusIcon}
          onClick={() =>
            setQuery({
              ...query,
              offset: 1
            })
          }
          className="m-2 justify-start w-min "
        >
          Offset Clause
        </Button>
      )}

      <Tooltip id={"query-tooltip-" + tooltipId} />
    </div>
  );
};

export default React.memo(QueryComponent);
