const TriangularWave = ({ isActive, ...props }) => (
  <svg
    className={` ${
      isActive ? "stroke-primaryLight" : "opacity-50 stroke-contentColor"
    }`}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M16.5 9H13.5L11.25 15.75L6.75 2.25L4.5 9H1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default TriangularWave;
