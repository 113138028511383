import { IOrganization } from "./shared.interface";
import { UserProfile } from "oidc-client-ts";

export enum OnboardingStep {
  Welcome = "0",
  Profile = "1",
  Availability = "2",
  Organization = "3"
}

export interface IServiceProvider extends UserProfile {
  onboardingStatus?: string;
  fleetCreationStatus?: string;
  deviceCreationStatus?: string;
  panelCreationStatus?: string;
  deploymentCreationStatus?: string;
  selectedOrg?: IOrganization;
  organization?: IOrganization[];
}
