import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { ICreateDatapointResponse } from "@interfaces/shared.interface";

export const createDataPointDefinition = async (
  projectId: string,
  orgId: string,
  payload: FormData
) => {
  const { data, ok } = await networkService.post<ICreateDatapointResponse>(
    `projects/${projectId}/data_point_definitions/`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.dataPointDefinitionId;
  } else return null;
};

export const useCreateDataPointDefinition = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: FormData) =>
      await createDataPointDefinition(
        selectedProject.id,
        user.selectedOrg.id,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDataPointDefinitions");
      }
    }
  );

  return res;
};
