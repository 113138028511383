import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface IUpdateReleasePayload {
  props: {
    release_name: string;
  };
}

export const updateRelease = async (
  projectId: string,
  orgId: string,
  releaseId: string,
  payload: IUpdateReleasePayload
) => {
  const { ok } = await networkService.post<{
    data: {};
    ok: number;
  }>(`projects/${projectId}/ota_releases/${releaseId}`, payload, {
    headers: {
      "ORG-ID": orgId
    }
  });

  return ok;
};

export const useUpdateRelease = (releaseId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IUpdateReleasePayload) =>
      await updateRelease(
        selectedProject.id,
        user.selectedOrg.id,
        releaseId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getReleases");
      }
    }
  );

  return res;
};
