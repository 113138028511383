import { useState } from "react";
import DeploymentDetailsTabs from "../components/ota-deployment-details-tabs.component";
import Overview from "./deployment-details/ota-overview.component";
import IndividualStatus from "./deployment-details/ota-individual-status.component";
import Rollback from "./deployment-details/ota-rollback.component";
import { useSearchParams } from "react-router-dom";
import OTADeploymentHeader from "../components/ota-deployment-header.component";

const DeploymentDetails = () => {
  const [searchParams] = useSearchParams();
  const deploymentId = searchParams.get("code");

  /* State Variables */
  const [activeTab, setActiveTab] = useState(0);

  const tabs = {
    0: <Overview deploymentId={deploymentId} />,
    1: <IndividualStatus deploymentId={deploymentId} />,
    2: <Rollback deploymentId={deploymentId} />
  };

  return (
    <>
      <OTADeploymentHeader />
      <main className="flex-1 mx-8 mt-7 pb-8 space-y-4 overflow-y-auto lg:px-8 sm:px-6 bg-background rounded-md">
        <DeploymentDetailsTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <>{tabs[activeTab]}</>
      </main>
    </>
  );
};

export default DeploymentDetails;
