import {
  ArrowDownIcon,
  ArrowPathIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  Bars3Icon,
  ChevronDownIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import dateService from "@services/date.service";
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  RowSelectionState
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Badge, Button, Color } from "@tremor/react";
import { IPendingOTAUpdate, OtaStatusType } from "@interfaces/ota.interface";

import { Tooltip } from "react-tooltip";
import "./ota-individual-status.style.css";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import { useGetPendingReleaseUpdates } from "@app/shared/hooks/get/pending-release-updates";
import { useDeletePendingRelease } from "@app/shared/hooks/delete/delete-pending-ota-update";
import { useUpdatePendingRelease } from "@app/shared/hooks/patch/update-pending-ota-updates";
import { IndeterminateCheckbox } from "@/app/shared/components/indeterminate-checkbox.component";
import Filter from "@/app/shared/components/tanstack-table-filter.component";

const StatusColourMap: Record<OtaStatusType, Color> = {
  [OtaStatusType.Failure]: "red",
  [OtaStatusType.Successful]: "green",
  [OtaStatusType.InProgress]: "blue",
  [OtaStatusType.Pending]: "yellow",
  [OtaStatusType.Retry]: "orange"
};

interface IPendingOTARow {
  id: string;
  device_name: string;
  ota_status: OtaStatusType;
  sheduled_at: string;
  retries: number;
  summary: {
    created_at: string;
    initiated: boolean;
  };
}

interface IIndividualStatusTabProps {
  deploymentId: string;
}

const initialFilteringColumnState: Record<
  keyof Omit<IPendingOTARow, "summary" | "id">,
  boolean
> = {
  device_name: false,
  ota_status: false,
  sheduled_at: false,
  retries: false
};

const IndividualStatus: React.FC<IIndividualStatusTabProps> = ({
  deploymentId
}) => {
  const [filteringColumns, setFilteringColumns] = useState<
    typeof initialFilteringColumnState
  >(initialFilteringColumnState);

  const { openConfirmDeleteModal } = useConfirmDelete();

  const { data: devicesResponse } = useGetDevices();
  const { data: pendingOtaUpdates } =
    useGetPendingReleaseUpdates(deploymentId);

  const data: IPendingOTARow[] = useMemo(() => {
    if (!pendingOtaUpdates?.length || !devicesResponse?.devices?.length)
      return [];
    let _data: IPendingOTARow[] = pendingOtaUpdates.map<IPendingOTARow>(
      (otaUpdate: IPendingOTAUpdate) => ({
        id: otaUpdate.id,
        device_name: devicesResponse?.devices?.find(
          (d) => d.id === otaUpdate.device_id
        ).device_name,
        ota_status: otaUpdate.status,
        sheduled_at: otaUpdate.scheduled_at,
        retries: otaUpdate.attempt,
        summary: {
          created_at: otaUpdate.created_at,
          initiated: otaUpdate.initiated
        }
      })
    );

    return _data;
  }, [devicesResponse, pendingOtaUpdates]);

  const updatePendingReleaseMutation = useUpdatePendingRelease(deploymentId);

  const onRetryDeploymentClick = async (id: string[]) => {
    updatePendingReleaseMutation.mutate({
      id,
      status: OtaStatusType.Pending,
      retries: 0
    });
  };

  const deletePendingUpdateMutation = useDeletePendingRelease(deploymentId);

  const onDeleteDeploymentClick = async (id: string) => {
    deletePendingUpdateMutation.mutate(id);
  };

  const columns = useMemo<ColumnDef<IPendingOTARow, any>[]>(
    () => [
      {
        id: "select",
        size: 20,
        enableResizing: false,
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler()
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler()
              }}
            />
          </div>
        )
      },
      {
        header: "Device Name",
        accessorKey: "device_name",
        enableSorting: true,
        filterFn: "includesString",
        cell: ({ row, getValue }) => {
          return (
            <div className="flex flex-row items-center gap-2">
              {
                <ChevronDownIcon
                  width={14}
                  className={`cursor-pointer transition-transform min-w-[14px] transform ${
                    row.getIsExpanded() ? "rotate-180" : "rotate-0"
                  }`}
                  onClick={() => row.toggleExpanded()}
                />
              }
              <span>{getValue()}</span>
            </div>
          );
        }
      },
      {
        header: "Retries",
        accessorKey: "retries",
        enableSorting: true,
        filterFn: "includesString",
        cell: ({ getValue }) => {
          return (
            <div className="flex flex-row w-full justify-center items-center gap-2">
              {getValue()}
            </div>
          );
        }
      },
      {
        header: "OTA Status",
        accessorKey: "ota_status",
        enableSorting: false,
        filterFn: "includesString",
        cell: ({ getValue, row }) => {
          const isFailure = getValue() === OtaStatusType.Failure;
          return (
            <div
              data-tooltip-id={isFailure ? "retry-item-tooltip" : ""}
              onClick={() =>
                isFailure && onRetryDeploymentClick([row.original.id])
              }
              className={`flex flex-row w-full justify-center items-center gap-2`}
            >
              <Badge
                size="xs"
                color={StatusColourMap[getValue()]}
                className="!text-xs"
                style={{
                  cursor: isFailure ? "pointer" : "default"
                }}
              >
                <span className="flex gap-2">
                  <span className="uppercase text-sm">{getValue()}</span>
                  {isFailure ? <ArrowPathIcon width={16} color="red" /> : null}
                </span>
              </Badge>
            </div>
          );
        }
      },
      {
        header: "Scheduled At",
        accessorKey: "sheduled_at",
        enableSorting: true,
        filterFn: "includesString",
        cell: ({ getValue }) => {
          return (
            <div className="flex flex-row w-full justify-center items-center flex-wrap gap-2">
              {dateService.convertUTCToLocalDate(getValue())}
            </div>
          );
        }
      },
      {
        header: "delete",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <div className="flex flex-row w-full justify-center items-center flex-wrap gap-2">
              <TrashIcon
                onClick={() =>
                  openConfirmDeleteModal(
                    () => onDeleteDeploymentClick(row.original.id),
                    "Are you sure you want to this OTA Update?"
                  )
                }
                width={16}
                color="red"
                className="cursor-pointer"
              />
            </div>
          );
        }
      }
    ],
    []
  );

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data: data,
    columns,
    state: {
      expanded,
      rowSelection
    },
    columnResizeMode: "onChange",
    onExpandedChange: setExpanded,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  const showRetryButton = useMemo(() => {
    return (
      Object.keys(rowSelection).length &&
      !Object.keys(rowSelection).some(
        (rowInd: string) =>
          data[Number(rowInd)].ota_status !== OtaStatusType.Failure
      )
    );
  }, [data, rowSelection]);

  return (
    data && (
      <>
        {showRetryButton ? (
          <Button
            onClick={() =>
              onRetryDeploymentClick(
                data
                  .filter((_, ind) => ind in rowSelection)
                  .map((otaUpdate) => otaUpdate.id)
              )
            }
            className="bg-primary !mt-8 !ml-5 hover:bg-primaryLight !text-white !font-normal"
          >
            <span className="flex gap-2">
              <ArrowPathIcon width={16} />
              <span> Retry Selcted Updates</span>
            </span>
          </Button>
        ) : null}
        <table className="w-full !mt-10 ota-table">
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width: header.column.getSize()
                      }}
                      className="relative whitespace-nowrap py-2 font-normal text-contentColorLight text-xs uppercase"
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            className={`flex gap-2 items-center justify-center`}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: (
                                <ArrowUpIcon
                                  width={16}
                                  className="min-w-[16px] cursor-pointer"
                                  onClick={header.column.getToggleSortingHandler()}
                                />
                              ),
                              desc: (
                                <ArrowDownIcon
                                  width={16}
                                  className="min-w-[16px] cursor-pointer"
                                  onClick={header.column.getToggleSortingHandler()}
                                />
                              )
                            }[header.column.getIsSorted() as string] || null}
                            {header.column.getCanSort() &&
                            !header.column.getIsSorted() ? (
                              <ArrowsUpDownIcon
                                width={16}
                                className="min-w-[16px] cursor-pointer"
                                onClick={header.column.getToggleSortingHandler()}
                              />
                            ) : null}
                            {header.column.getCanFilter() ? (
                              <>
                                <div
                                  className="cursor-pointer"
                                  data-tooltip-id={
                                    "log-table-filter-" + header.column.id
                                  }
                                  onClick={() =>
                                    setFilteringColumns((prev) => {
                                      return {
                                        ...prev,
                                        [header.column.id]: true
                                      };
                                    })
                                  }
                                >
                                  <Bars3Icon
                                    width={16}
                                    className="min-w-[16px]"
                                  />
                                </div>
                                <Tooltip
                                  isOpen={true}
                                  id={"log-table-filter-" + header.column.id}
                                  style={{
                                    zIndex: 30,
                                    display: filteringColumns[header.column.id]
                                      ? undefined
                                      : "none"
                                  }}
                                  openOnClick
                                  variant="light"
                                  border={"1px solid black"}
                                  clickable
                                  render={(props) => {
                                    return (
                                      <Filter
                                        column={header.column}
                                        table={table}
                                        filteringColumns={filteringColumns}
                                        setFilteringColumns={
                                          setFilteringColumns
                                        }
                                      />
                                    );
                                  }}
                                />
                              </>
                            ) : null}
                          </div>
                        </>
                      )}
                      <div
                        {...{
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`
                        }}
                      />
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <>
                  <tr
                    key={row.id}
                    className="border-b border-background-layer3"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="mx-2 px-4 py-2 text-base text-center"
                          style={{
                            width: cell.column.getSize()
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {row.getIsExpanded() ? (
                    <tr className="bg-background-layer1 text-sm">
                      <td colSpan={row.getVisibleCells().length}>
                        <h4 className="text-primaryLight font-semibold mt-4 ml-4">
                          Summary
                        </h4>
                        <div className="w-full flex gap-20 flex-wrap p-4 ">
                          <div className="flex flex-col gap-1">
                            <label>Initiated</label>
                            <span className="text-contentColorLight">
                              {row.original.summary.initiated.toString()}
                            </span>
                          </div>
                          <div className="flex flex-col gap-1">
                            <label>Created At</label>
                            <span className="text-contentColorLight">
                              {dateService.convertUTCToLocalDate(
                                row.original.summary.created_at
                              )}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </>
              );
            })}
          </tbody>
        </table>
        <Tooltip id="retry-item-tooltip" place="top">
          Retry Deployment
        </Tooltip>
      </>
    )
  );
};

export default IndividualStatus;
