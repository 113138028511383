import { useEffect, useState } from "react";
import { AddOverlayLayout, FieldError } from "../../shared/components";
import { Formik, Field, Form } from "formik";
import { IOption } from "@interfaces/index";
import Select from "react-select";
import { toast } from "react-toastify";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IUserPerms } from "@app/shared/hooks/get/user-perms";
import {
  IUpdateUserPermsPayload,
  useEditUserPerms
} from "@app/shared/hooks/patch/update-user-perms";
import ShowLoading from "@app/shared/components/loading.component";

export interface IInputEntry {
  user_id?: string;
  email?: string;
  org_access?: IOption;
  proj_access?: IOption;
  permissions?: IOption[];
  orgPermissions?: IOption[];
}

interface IInputError {
  email: string;
  org_access: string;
  addTo: string;
  permissions: string;
  orgPermissions: string;
}

export const orgAccessOptions: IOption[] = [
  { label: "OWNER", value: "owner" },
  { label: "ADMIN", value: "admin" },
  { label: "MEMBER", value: "member" }
];

export const projAccessOptions: IOption[] = [
  { label: "ADMIN", value: "admin" },
  { label: "MEMBER", value: "member" }
];

export const permissionOptions: IOption[] = [
  { label: "can_read_logs", value: "can_read_logs" },
  { label: "can_manage_context_defs", value: "can_manage_context_defs" },
  { label: "can_manage_shadow_defs", value: "can_manage_shadow_defs" },
  { label: "can_manage_data_defs", value: "can_manage_data_defs" },
  { label: "can_manage_rules", value: "can_manage_rules" },
  { label: "can_manage_dashboards", value: "can_manage_dashboards" },
  { label: "can_manage_certificates", value: "can_manage_certificates" },
  {
    label: "can_manage_consumer_settings",
    value: "can_manage_consumer_settings"
  },
  { label: "can_manage_devices", value: "can_manage_devices" },
  { label: "can_manage_ota_updates", value: "can_manage_ota_updates" }
];

export const orgPermissionOptions: IOption[] = [
  { label: "MANAGE BILLINGS", value: "can_manage_billing" },
  { label: "CREATE PROJECTS", value: "can_create_projects" }
];

interface IEditPermsProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  initialValues: IInputEntry;
}

const EditPerms: React.FC<IEditPermsProps> = ({
  open,
  setOpen,
  initialValues
}) => {
  const selectedProject = useFleetAndDevicesStore(
    (state) => state.selectedProject
  );
  const user = useAuthStore((state) => state.user);

  const [inputFieldValues, setInputFieldValues] =
    useState<IInputEntry>(initialValues);

  const [errorMsgs, setErrorMsgs] = useState<IInputError>({
    email: "",
    org_access: "",
    addTo: "",
    permissions: "",
    orgPermissions: ""
  });

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  useEffect(() => {
    setInputFieldValues(initialValues);
  }, [initialValues, open]);

  const handleInput = (event: any, fieldId: string) => {
    const payload = { ...inputFieldValues };
    const errors = { ...errorMsgs };

    const selector = {
      org_access: true,
      proj_access: true,
      permissions: true,
      orgPermissions: true
    };

    if (selector[fieldId]) {
      payload[fieldId] = event;

      if (errors[fieldId]) {
        errors[fieldId] = "";
        setErrorMsgs(errors);
      }
    } else {
      const value = event.target.value;

      if (!value.trim()) {
        const eMsg = { ...errorMsgs };
        eMsg[fieldId] = "Email is required";
        setErrorMsgs(eMsg);
      } else if (!validateEmail(value)) {
        const eMsg = { ...errorMsgs };
        eMsg[fieldId] = "Email is invalid";
        setErrorMsgs(eMsg);
      } else if (errorMsgs[fieldId]) {
        const eMsg = { ...errorMsgs };
        eMsg[fieldId] = "";
        setErrorMsgs(eMsg);
      }

      payload[fieldId] = value;
    }

    setInputFieldValues(payload);
  };

  const validateForm = () => {
    const errors = { ...errorMsgs };

    if (!inputFieldValues?.email.trim()) {
      errors.email = "Email is required";
    } else {
      errors.email = "";
    }

    if (!inputFieldValues?.org_access?.value) {
      errors.org_access = "Select access";
    } else {
      errors.org_access = "";
    }

    if (
      inputFieldValues?.org_access?.value === "member" &&
      !inputFieldValues?.permissions?.length
    ) {
      errors.permissions = "Select permissions";
    } else {
      errors.permissions = "";
    }

    if (Object.values(errors).some((el) => !!el)) {
      setErrorMsgs(errors);
      return false;
    }

    return true;
  };

  const editPermsMutation = useEditUserPerms();

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const payload: IUpdateUserPermsPayload = {
      permissions: [
        {
          resource_type: "projects",
          actions: [
            inputFieldValues?.proj_access.value,
            ...inputFieldValues?.permissions.map((op) => op.value)
          ],
          resource: selectedProject.id
        },
        {
          resource_type: "organizations",
          actions: [
            inputFieldValues?.org_access.value,
            ...inputFieldValues?.orgPermissions.map((op) => op.value)
          ],
          resource: user.selectedOrg.id
        }
      ],
      org_access: inputFieldValues?.org_access.value,
      user_id: inputFieldValues?.user_id,
      org_id: user.selectedOrg.id
    };

    editPermsMutation.mutate(
      { data: payload, user_id: initialValues.user_id },
      {
        onSuccess: () => {
          toast.success("Updated Permissions!");
          // Clear fields
          setInputFieldValues({} as IInputEntry);
          setErrorMsgs({} as IInputError);
          setOpen(false);
        }
      }
    );
  };

  return (
    <AddOverlayLayout
      title="Edit Permissions"
      overlayOpen={open}
      setOverlayOpen={setOpen}
    >
      <div className="flex flex-col h-full items-center">
        {open && initialValues ? (
          <Formik
            initialValues={{
              collaboratorEmail: ""
            }}
            onSubmit={handleSubmit}
          >
            <Form className="w-full flex flex-col flex-1">
              <div className="w-full flex flex-col max-h-[70vh] overflow-y-auto flex-1 mt-4 px-1">
                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Email
                  </label>

                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={inputFieldValues?.email}
                    className="block w-full p-3 mt-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  {errorMsgs.email ? (
                    <FieldError message={errorMsgs.email} />
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Org Access
                  </label>
                  <Select
                    placeholder="Select"
                    isSearchable={false}
                    options={orgAccessOptions}
                    value={inputFieldValues?.org_access}
                    onChange={(e) => handleInput(e, "org_access")}
                    classNames={reactSelectClassNames}
                  />
                  {errorMsgs.org_access ? (
                    <FieldError message={errorMsgs.org_access} />
                  ) : (
                    ""
                  )}
                </div>

                {inputFieldValues?.org_access?.value !== "admin" ? (
                  <>
                    <div className="mb-5">
                      <label className="text-sm font-medium text-contentColor">
                        Org. Permissions
                      </label>
                      <Select
                        placeholder="Select Permissions"
                        isSearchable={false}
                        options={orgPermissionOptions}
                        value={inputFieldValues?.orgPermissions}
                        onChange={(e) => handleInput(e, "orgPermissions")}
                        classNames={reactSelectClassNames}
                        isMulti
                      />
                      {errorMsgs.orgPermissions ? (
                        <FieldError message={errorMsgs.orgPermissions} />
                      ) : null}
                    </div>
                  </>
                ) : null}

                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Project Access
                  </label>
                  <Select
                    placeholder="Select"
                    isSearchable={false}
                    options={projAccessOptions}
                    value={inputFieldValues?.proj_access}
                    onChange={(e) => handleInput(e, "proj_access")}
                    classNames={reactSelectClassNames}
                  />
                  {errorMsgs.org_access ? (
                    <FieldError message={errorMsgs.org_access} />
                  ) : (
                    ""
                  )}
                </div>

                {inputFieldValues?.proj_access?.value !== "admin" ? (
                  <>
                    <div className="mb-5">
                      <label className="text-sm font-medium text-contentColor">
                        Project Permissions
                      </label>
                      <Select
                        placeholder="Select Permissions"
                        isSearchable={false}
                        options={permissionOptions}
                        value={inputFieldValues?.permissions}
                        onChange={(e) => handleInput(e, "permissions")}
                        classNames={reactSelectClassNames}
                        isMulti
                      />
                      {errorMsgs.permissions ? (
                        <FieldError message={errorMsgs.permissions} />
                      ) : null}
                    </div>
                  </>
                ) : null}
                <hr className="w-full border-background-layer3 mt-auto" />
              </div>

              <div>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="w-full inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  Edit
                </button>
              </div>
            </Form>
          </Formik>
        ) : (
          <ShowLoading />
        )}
      </div>
    </AddOverlayLayout>
  );
};

export default EditPerms;
