import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IProjectApp, IProjectAppsResponse } from "@interfaces/apps.interface";

export const getApps = async (projectId: string, orgId: string, params) => {
  const { data, ok } = await networkService.get<IProjectAppsResponse>(
    `projects/${projectId}/apps/`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.projectApps;
  } else return null;
};

export const useGetApps = (
  params: any = {},
  successCb: ((data: IProjectApp[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getApps", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const apps = await getApps(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(apps);

      return apps;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
