import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import UserAuthenticationHeader from "./components/ua-header.component";
import { UserAuthenticationProjects } from ".";
import UserAuthenticationTabs, { UATabs } from "./ua-tabs.component";
import UaUsersListComponent from "./components/ua-users-list.component";
import { useFleetAndDevicesStore } from "@/store";
import UaOidcSettingsComponent from "./components/ua-oidc-settings.component";

const UserAuthenticationProvider: React.FC = () => {
  const location = useLocation();
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") as UATabs;

  const [activeTab, setActiveTab] = useState<UATabs>(tab ?? UATabs.USERS);

  const flag =
    location.pathname === "/user-authentication" ||
    location.pathname === "/user-authentication/";

  useEffect(() => {
    if (selectedProject.consumer_enabled) {
      setSearchParams(
        { tab: activeTab ?? UATabs.USERS },
        {
          replace: true
        }
      );
    }
  }, [activeTab, setSearchParams, flag, selectedProject.consumer_enabled]);

  return (
    <>
      <UserAuthenticationHeader />
      {!selectedProject.consumer_enabled ? (
        <UserAuthenticationProjects />
      ) : (
        <main className="flex-1 mx-8 mt-7 space-y-4 lg:mr-28 overflow-y-auto lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
          <UserAuthenticationTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {activeTab === UATabs.USERS ? (
            <UaUsersListComponent />
          ) : activeTab === UATabs.SETTINGS ? (
            <UaOidcSettingsComponent />
          ) : null}
        </main>
      )}
    </>
  );
};

export default UserAuthenticationProvider;
