import Editor, { DiffEditor } from "@monaco-editor/react";
import { copyToClipboard } from "../../shared/utils/helper.util";
import { Dialog } from "@headlessui/react";
import { Button } from "@tremor/react";
import { useEffect, useRef, useState } from "react";
import { loggerService, networkService } from "../../../services";
import useShadowStore from "../../../store/shadow/shadow.store";
import {
  ArrowDownTrayIcon,
  ClipboardIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";
import useThemeStore from "@store/theme.store";
import { toast } from "react-toastify";
import Modal from "@app/shared/components/modal.component";

interface IDataPointEditorProps {
  isShadow: boolean;
  label?: string;
  readOnly?: boolean;
  customShadow: string;
  dataPointDef?: any;
  downloadFile: () => void;
  setCustomShadow?: (val: string) => void;
  handleShadowEditor?: (val: string) => void;
  handleMaximizeEditor?: () => void;
  setShadowEditorReadOnly?: (val: boolean) => void;
  onEdit?: () => void;
}

interface IDataPointUpdateResponse {
  data: {
    // something
  };
  ok: number;
}

function DataPointEditor({
  label,
  readOnly,
  isShadow,
  customShadow,
  dataPointDef,
  downloadFile,
  setCustomShadow,
  handleShadowEditor,
  handleMaximizeEditor,
  setShadowEditorReadOnly,
  onEdit = null
}: IDataPointEditorProps) {
  const editorRef = useRef(null);
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const [dataPointEditorOpen, setDataPointEditorOpen] = useState(false);
  const [finalEditorValue, setFinalEditorValue] = useState(customShadow);

  const [dataPointDefProto, setDataPointDefProto] = useState(customShadow);

  const [setDatapoint] = useShadowStore((state) => [state.setDatapoint]);
  const [theme] = useThemeStore((state) => [state.theme]);

  useEffect(() => {
    setDataPointDefProto(customShadow);
    setFinalEditorValue(customShadow);

    return () => {};
  }, [customShadow]);

  const handleApplyDataPoint = () => {
    const file = new File([dataPointDefProto], "datap.proto", {
      type: "text/plain"
    });

    const formData = new FormData();
    formData.append("protoFile", file);
    formData.append("name", dataPointDef.name);
    formData.append("message_name", dataPointDef.message_name);

    networkService
      .put<IDataPointUpdateResponse>(
        `projects/${dataPointDef.project_id}/data_point_definitions/${dataPointDef.id}`,
        formData,
        {
          headers: {
            "ORG-ID": dataPointDef.org_id
          }
        }
      )
      .then((res) => {
        if (res.ok === 1) {
          setDatapoint(dataPointDefProto);
          setFinalEditorValue(dataPointDefProto);
          toast.success("Updated Data Point Definition successfully!");
          editorRef.current.setValue(dataPointDefProto);
        } else {
          setDatapoint(finalEditorValue);
          setDataPointDefProto(finalEditorValue);
        }
      })
      .catch((err) => {
        loggerService.error(err);
        setDatapoint(finalEditorValue);
        setDataPointDefProto(finalEditorValue);
      })
      .finally(() => {
        setConfirmApplyModalOpen(false);
        setDataPointEditorOpen(false);
      });
  };

  return (
    <>
      <div className="form-group mb-5 w-full h-full max-h-[60vh]">
        <label className="flex font-medium text-sm mb-2">{label || ""}</label>
        <div className="p-2 w-full h-full bg-background rounded-lg border border-solid border-background-layer3">
          <div>
            <div className="flex justify-between items-center">
              <ul className="flex justify-center items-center">
                <li className="flex items-center">
                  <button
                    type="button"
                    onClick={() => copyToClipboard(customShadow)}
                    className="text-xs text-contentColorLight flex justify-center items-center"
                  >
                    Copy
                  </button>
                  <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                </li>

                {/* {!!readOnly === false && (
                <li className="flex items-center">
                  <button
                    type="button"
                    onClick={() =>
                      pasteFromClipboard().then((res) => setCustomShadow(res))
                    }
                    className="text-xs text-contentColorLight flex justify-center items-center"
                  >
                    Paste
                  </button>
                  <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                </li>
              )}

              {!!readOnly === false && (
                <li className="flex items-center">
                  <button
                    type="button"
                    className="text-xs text-contentColorLight flex justify-center items-center"
                  >
                    Upload
                  </button>
                  <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                </li>
              )} */}

                <li className="flex items-center">
                  <button
                    type="button"
                    onClick={downloadFile}
                    className="text-xs text-contentColorLight flex justify-center items-center"
                  >
                    Download
                  </button>
                  <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                </li>

                {!readOnly && (
                  <li className="flex items-center">
                    <button
                      type="button"
                      onClick={() => {
                        onEdit ? onEdit() : setDataPointEditorOpen(true);
                      }}
                      className="text-xs text-contentColorLight flex justify-center items-center"
                    >
                      Edit
                    </button>
                  </li>
                )}
              </ul>

              <div className="flex justify-center items-center">
                <button
                  onClick={handleMaximizeEditor}
                  type="button"
                  className="p-1 bg-background rounded"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6663 2H14.6663V6H13.333V3.33333H10.6663V2ZM1.33301 2H5.33301V3.33333H2.66634V6H1.33301V2ZM13.333 12.6667V10H14.6663V14H10.6663V12.6667H13.333ZM2.66634 12.6667H5.33301V14H1.33301V10H2.66634V12.6667Z"
                      fill="#546CCC"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="my-2 border border-solid border-background-layer3"></div>
          </div>
          <Editor
            height="calc(100% - 40px)"
            language="cpp"
            value={finalEditorValue}
            theme={theme === "golain" || theme === "none" ? "vs" : "vs-dark"}
            onMount={(editor) => {
              editorRef.current = editor;
            }}
            options={{
              readOnly: true,
              lineNumbers: "off",
              minimap: { enabled: false },
              scrollbar: { vertical: "hidden" },
              overviewRulerBorder: false,
              overviewRulerLanes: 0,
              folding: false,
              matchBrackets: "never",
              theme: theme === "golain" || theme === "none" ? "vs" : "vs-dark"
            }}
          />
        </div>
      </div>

      <Modal
        open={confirmApplyModalOpen}
        setOpen={setConfirmApplyModalOpen}
        className="max-w-2xl bg-background"
      >
        <div className="p-4 bg-background text-contentColor">
          <Dialog.Title>
            <h1 className="font-medium text-lg">Apply Changes</h1>
          </Dialog.Title>
          <div className={`mt-2 ${theme === "dark-default" ? "dark" : ""}`}>
            <p className="text-sm text-contentColorLight">
              Are you sure you want to apply changes?
            </p>
            <hr className="border-background-layer3 my-3" />
            <div className="gap-2 ">
              <div className="flex gap-2">
                <ExclamationTriangleIcon width={16} color="yellow" />
                <h3>Data Loss</h3>
              </div>
              <p className="text-sm text-contentColorLight pl-6">
                Updating the data point may result in data loss!
              </p>
            </div>
            <div className="gap-2 ">
              <div className="flex gap-2">
                <ExclamationCircleIcon width={16} color="red" />
                <h3>Numbering</h3>
              </div>
              <p className="text-sm text-contentColorLight pl-6">
                Make sure to not reuse the field numbers/IDs in any new field.{" "}
                <br />
                If you want to add a new field, use the next available number
                (even if you have removed a field).
              </p>
            </div>
            <hr className="border-background-layer3 my-3" />
            <div className="flex flex-col">
              <h3>Verify Changes:</h3>
              <DiffEditor
                original={finalEditorValue}
                modified={dataPointDefProto}
                height="40vh"
                language="cpp"
                theme={
                  theme === "golain" || theme === "none" ? "vs" : "vs-dark"
                }
                className="mt-2"
                options={{
                  readOnly: true,
                  renderSideBySide: true,
                  lineNumbers: "off",
                  minimap: { enabled: false },
                  scrollbar: { vertical: "hidden" },
                  overviewRulerBorder: false,
                  overviewRulerLanes: 0,
                  folding: false,
                  matchBrackets: "never"
                }}
              />
            </div>
          </div>

          <div className="mt-4 flex gap-2 justify-end">
            <Button
              variant="secondary"
              onClick={() => setConfirmApplyModalOpen(false)}
              className="text-primary border-primary"
            >
              Cancel
            </Button>
            <Button onClick={handleApplyDataPoint} className="bg-primary">
              Apply
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={!!(dataPointEditorOpen && theme)}
        setOpen={() => {
          setDataPointDefProto(finalEditorValue);
          setDataPointEditorOpen(false);
        }}
        disableClickOutside={true}
        className="w-[70vw] lg:w-[50vw] !bg-background"
      >
        <div className="relative w-full px-3 py-3 bg-background text-contentColor rounded-lg rounded-b-none border border-solid border-background-layer3">
          <div className="flex justify-between items-center ">
            <div className="flex justify-between items-center px-6 flex-grow">
              <h1 className="font-medium text-sm sm:text-lg whitespace-nowrap">
                Data Point Definition
              </h1>
              <div className="flex justify-between items-center w-full ml-6">
                <div className="flex gap-4">
                  <button
                    onClick={() => copyToClipboard(customShadow)}
                    className="text-sm flex justify-center items-center"
                  >
                    <ClipboardIcon width={14} />

                    <span className="pl-1 text-xs ">Copy</span>
                  </button>

                  <button
                    onClick={downloadFile}
                    className="text-sm flex justify-center items-center"
                  >
                    <ArrowDownTrayIcon width={14} />

                    <span className="pl-1 text-xs">Download</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 bg-background">
          <Editor
            height="60vh"
            language="cpp"
            value={dataPointDefProto}
            onChange={setDataPointDefProto}
            theme={theme === "golain" || theme === "none" ? "vs" : "vs-dark"}
            options={{
              theme: theme === "golain" || theme === "none" ? "vs" : "vs-dark",
              lineNumbers: "off",
              minimap: { enabled: false },
              scrollbar: { vertical: "hidden" },
              overviewRulerBorder: false,
              overviewRulerLanes: 0,
              folding: false,
              matchBrackets: "never"
            }}
          />
        </div>

        <div className="flex justify-end mt-2 p-4 bg-background">
          <Button
            variant="secondary"
            onClick={() => {
              setDataPointEditorOpen(false);
              setDataPointDefProto(finalEditorValue);
            }}
            className="mr-2 text-primary border-primary"
          >
            Cancel
          </Button>

          <Button
            onClick={() => setConfirmApplyModalOpen(true)}
            className="bg-primary text-white"
            // className="flex px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
          >
            Apply
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default DataPointEditor;
