const ELEMENT_IDS = {
  COLLABORATORS_ICON: "collaborators-icon",
  SIDEBAR_OPEN: "sidebar-open-button",
  SIDEBAR_DEVICES: "sidebar-devices",
  SIDEBAR_DASHBOARD: "sidebar-dashboard",
  SIDEBAR_OTA: "sidebar-ota",
  SIDEBAR_CONSUMER: "sidebar-consumer",
  SIDEBAR_LOGS: "sidebar-logs"
};

export default ELEMENT_IDS;
