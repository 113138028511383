import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const methodColourMap = {
  get: {
    bg: "bg-green-500",
    text: "text-white"
  },
  post: {
    bg: "bg-blue-500",
    text: "text-white"
  },
  put: {
    bg: "bg-yellow-500",
    text: "text-black"
  },
  patch: {
    bg: "bg-purple-500",
    text: "text-white"
  },
  delete: {
    bg: "bg-red-500",
    text: "text-white"
  }
};

const MethodsMenu = ({ method, setMethod }) => {
  return (
    <TransitionedMenu
      buttonComponent={
        <div
          className={`flex gap-2 px-2 py-1 rounded-sm uppercase text-black items-center ${
            methodColourMap[method.toLowerCase()].bg
          } ${methodColourMap[method.toLowerCase()].text}`}
        >
          <span>{method} </span>
          <ChevronDownIcon width={16} />
        </div>
      }
    >
      <Menu.Item>
        {({ active }) => (
          <button
            className={`w-full flex gap-1 items-center uppercase ${
              active && "bg-background-layer2"
            } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
            onClick={() => setMethod("get")}
          >
            GET
          </button>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button
            className={`w-full flex gap-1 items-center uppercase ${
              active && "bg-background-layer2"
            } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
            onClick={() => setMethod("post")}
          >
            POST
          </button>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button
            className={`w-full flex gap-1 items-center uppercase ${
              active && "bg-background-layer2"
            } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
            onClick={() => setMethod("put")}
          >
            PUT
          </button>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button
            className={`w-full flex gap-1 items-center uppercase ${
              active && "bg-background-layer2"
            } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
            onClick={() => setMethod("patch")}
          >
            PATCH
          </button>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button
            className={`w-full flex gap-1 items-center uppercase ${
              active && "bg-background-layer2"
            } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
            onClick={() => setMethod("delete")}
          >
            DELETE
          </button>
        )}
      </Menu.Item>
    </TransitionedMenu>
  );
};

export default MethodsMenu;
