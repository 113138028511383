import usePyScrtiptStore from "@/store/pyscript.store";
import ShadowEditor from "@app/fleet-and-devices/components/fad-shadow-editor.component";
import {
  copyToClipboard,
  decodeBase64toUTF8,
  downloadFile
} from "@app/shared/utils/helper.util";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import dateService from "@services/date.service";
import { Button } from "@tremor/react";
import { Field, Form, Formik } from "formik";
import JSZip from "jszip";
import React from "react";
import { toast } from "react-toastify";

interface IShadowDefinitionDetailsProps {
  shadowDef: any;
}

const ShadowDefinitionDetails: React.FC<IShadowDefinitionDetailsProps> = ({
  shadowDef
}) => {
  const [loadingState] = usePyScrtiptStore((state) => [state.loadingState]);

  const onDownloadNanoPBFiles = () => {
    if (loadingState === "error") {
      toast.error(
        "Error loading the template generation files, please reload the page and try again."
      );
      return;
    }
    const onProtoFilesGenerate = (header: string, source: string) => {
      const zip = new JSZip();
      zip.file("shadow.pb.h", header);
      zip.file("shadow.pb.c", source);

      zip.generateAsync({ type: "blob" }).then((blob) => {
        downloadFile("header_files", blob, "zip");
        toast.success("Downloaded Shadow header files!");
      });
    };

    const generated = globalThis["generate_header_and_source"](
      shadowDef.shadow_proto_descriptor,
      "shadow"
    );

    onProtoFilesGenerate(generated[0], generated[1]);
  };

  return (
    <div className="flex gap-8 mt-4 overflow-y-scroll">
      <div className="w-[60%]">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <div className="w-full ">
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Shadow ID
                </label>

                <div className="flex items-end w-full">
                  <Field
                    type="text"
                    id="shadowId"
                    name="shadowId"
                    value={shadowDef.id}
                    placeholder="Shadow ID"
                    className="block w-[350px] p-3 mt-2 bg-background-layer1 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() => copyToClipboard(shadowDef.id)}
                      className="block ml-3.5 bg-[#166ff629] p-3 rounded"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                          fill="#546CCC"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Created At
                </label>

                <div className="flex items-end w-full">
                  <Field
                    type="text"
                    id="createdAt"
                    name="createdAt"
                    value={dateService.convertUTCToLocalDate(
                      shadowDef.created_at
                    )}
                    placeholder="Created At"
                    className="block w-[350px] p-3 mt-2 bg-background-layer1 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard(
                          dateService.convertUTCToLocalDate(
                            shadowDef.created_at
                          )
                        )
                      }
                      className="block ml-3.5 bg-[#166ff629] p-3 rounded"
                    >
                      {/* copy to clipboard button */}
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                          fill="#546CCC"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColor">
                  Version
                </label>

                <div className="flex items-end w-full">
                  <Field
                    type="text"
                    id="version"
                    name="version"
                    value={shadowDef.version}
                    placeholder="Created At"
                    className="block w-[350px] p-3 mt-2 bg-background-layer1 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primaryLight focus:border-primaryLight sm:text-sm"
                  />
                  <div>
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard(
                          dateService.convertUTCToLocalDate(shadowDef.version)
                        )
                      }
                      className="block ml-3.5 bg-[#166ff629] p-3 rounded"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                          fill="#546CCC"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
      <div className="border-4 border-background-layer3 rounded mx-3"></div>
      <div className="max-w-[80vw] min-w-[40vw] max-h-[80vh]">
        <div className="p-0 mb-6">
          <Button icon={CloudArrowDownIcon} onClick={onDownloadNanoPBFiles}>
            Download NanoPB Header Files
          </Button>
        </div>
        <ShadowEditor
          isShadow={true}
          label={shadowDef.name}
          shadowDef={shadowDef}
          readOnly={true}
          customShadow={decodeBase64toUTF8(shadowDef.shadow_proto)}
          handleMaximizeEditor={() => {}}
          downloadFile={() =>
            downloadFile(
              shadowDef.name,
              decodeBase64toUTF8(shadowDef.shadow_proto)
            )
          }
        />
      </div>
    </div>
  );
};

export default ShadowDefinitionDetails;
