import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { FleetAndDevicesProjects } from ".";
import FleetHeader from "./components/fleet-header.component";
import { useGetFleets } from "../shared/hooks/get/fleets";
import { useFleetAndDevicesStore } from "@/store";
import ShowLoading from "../shared/components/loading.component";

const FleetAndDevicesProvider: React.FC = () => {
  const location = useLocation();

  const [setFleets] = useFleetAndDevicesStore((state) => [state.setFleets]);

  const { data: fleets, isLoading: fleetsLoading } = useGetFleets(
    {},
    (fleets) => {
      setFleets(fleets);
    }
  );

  const flag =
    location.pathname === "/fleet-and-devices/projects" ||
    location.pathname === "/fleet-and-devices/projects/";

  if (fleetsLoading) {
    return <ShowLoading />;
  }

  return (
    <>
      {fleets?.length > 0 ? (
        <FleetHeader
          baseURL={"/fleet-and-devices/projects"}
          showCreate
          showDelete
          showRefresh
        />
      ) : null}
      {flag ? (
        <>
          <FleetAndDevicesProjects />
        </>
      ) : (
        <>
          <main className="flex-1 flex flex-col mx-8 mt-7 xl:mr-28 lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            <Outlet />
          </main>
        </>
      )}
    </>
  );
};

export default FleetAndDevicesProvider;
