import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IArtifact, IArtifactResponse } from "@interfaces/ota.interface";

export const getArtifacts = async (
  projectId: string,
  orgId: string,
  releaseId: string,
  params
) => {
  const { data, ok } = await networkService.get<IArtifactResponse>(
    `projects/${projectId}/ota_releases/${releaseId}/artifacts`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.ota_release_artifacts;
  } else return null;
};

export const useGetArtifacts = (
  releaseId: string,
  params: any = {},
  successCb: ((data: IArtifact[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getArtifacts",
      selectedProject.id,
      user.selectedOrg.id,
      releaseId,
      params
    ],
    async () => {
      const artifacts = await getArtifacts(
        selectedProject.id,
        user.selectedOrg.id,
        releaseId,
        params
      );
      successCb && successCb(artifacts);

      return artifacts;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id && !!releaseId
    }
  );

  return res;
};
