import FleetHeader from "../components/fleet-header.component";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import dateService from "@services/date.service";
import Pagination from "@app/shared/components/pagination.component";
import { Link } from "react-router-dom";
import { useMemo, useState } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon
} from "@heroicons/react/24/outline";
import { useGetCertificates } from "@app/shared/hooks/get/certificates";
import { useFleetAndDevicesStore } from "@store/index";

interface ICertificatesRow {
  id: string;
  created_at: string;
  type: string;
}

type Props = {};

const FADCertificates = (props: Props) => {
  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: certsResponse } = useGetCertificates("", {
    fleet_id: selectedFleet.id,
    page,
    limit: pageSize
  });

  const columns = useMemo<ColumnDef<ICertificatesRow, any>[]>(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "Certificate ID",
        cell: ({ getValue, row }) => {
          return (
            <Link to={getValue()} className="hover:underline">
              {getValue()}
            </Link>
          );
        },
        size: 50
      },
      {
        id: "type",
        accessorKey: "type",
        header: "Type",
        cell: ({ getValue }) => {
          return <span>{getValue()}</span>;
        },
        size: 30
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        sortingFn: (a, b, colId) =>
          dateService
            .convertDateStrToMoment(a.getValue(colId))
            .diff(dateService.convertDateStrToMoment(b.getValue(colId))),
        cell: ({ getValue }) => {
          return (
            <div className="text-gray-500">
              {dateService.convertUTCToLocalDate(getValue())}
            </div>
          );
        },
        size: 30
      }
    ],
    []
  );

  const table = useReactTable({
    data: certsResponse?.data?.certificates ?? [],
    columns,
    state: {
      pagination: {
        pageIndex: certsResponse?.meta?.currentPage ?? 0,
        pageSize: certsResponse?.meta?.itemsPerPage ?? 0
      }
    },
    manualPagination: true,
    pageCount: certsResponse?.meta?.totalPages ?? 0,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  return (
    <>
      <FleetHeader
        baseURL={"/fleet-and-devices/certificates"}
        showCreate={false}
        showRefresh={false}
        showDelete={false}
      />
      <main className="flex-1 px-4 py-8 pt-5 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
        <h1 className="text-2xl font-medium text-contentColor">
          Certificates
        </h1>
        <hr className="border-background-layer3" />
        <div className="flex flex-col mt-12">
          <div className="-my-2 overflow-x-auto xl:-mx-8 px-4 lg:px-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden">
                <table className="w-full h-full my-6">
                  <thead className="">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              style={{
                                width: header.column.getSize()
                              }}
                              className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                            >
                              {header.isPlaceholder ? null : (
                                <>
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                        : "",
                                      onClick:
                                        header.column.getToggleSortingHandler()
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: <ArrowUpIcon width={10} />,
                                      desc: <ArrowDownIcon width={10} />
                                    }[header.column.getIsSorted() as string] ??
                                      (header.column.getCanSort() ? (
                                        <ArrowsUpDownIcon width={10} />
                                      ) : null)}
                                  </div>
                                  {header.column.getCanFilter() ? (
                                    <div>{""}</div>
                                  ) : null}
                                </>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => {
                      return (
                        <>
                          <tr
                            key={row.id}
                            className="bg-background-layer1 border-b border-background-layer3"
                          >
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td
                                  key={cell.id}
                                  className="mx-2 text-sm text-center p-2 py-3"
                                  style={{
                                    width: cell.column.getSize()
                                  }}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {certsResponse?.meta ? (
                  <Pagination<ICertificatesRow>
                    table={table}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    meta={certsResponse?.meta}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default FADCertificates;
