import React, { useEffect, useId, useMemo, useState } from "react";
import {
  ISelect,
  IExpression,
  IQuery,
  ICaseClause,
  TWhere,
  Operator
} from "@interfaces/query-builder";
import { Button } from "@tremor/react";
import {
  ArrowPathIcon,
  ChevronUpIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import QueryComponent from "../query.component";
import { VariableInput } from "@app/rule-engine/variable-input.component";
import ReactSelect from "react-select";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";
import { IOption } from "@interfaces/shared.interface";
import WhereClause from "./where.component";
import { useGetDataPointDefinitions } from "@/app/shared/hooks/get";

interface SelectClauseProps {
  select: ISelect;
  query?: IQuery;
  selectedDPDStructure: any;
  onDelete?: () => void;
  onChangeHandler: (select: ISelect) => void;
}

const PATTERN_KEY_REGEX = /({\w+})/g;

const aggregationOptions = [
  { value: "avg", label: "Mean" },
  { value: "max", label: "Max" },
  { value: "min", label: "Min" },
  { value: "sum", label: "Sum" },
  { value: "last", label: "Last" },
  { value: "first", label: "First" },
  { value: "count", label: "Count" }
];

const SelectClause: React.FC<SelectClauseProps> = ({
  select,
  selectedDPDStructure,
  onDelete,
  onChangeHandler
}) => {
  const [caseClauseCollapse, setCaseClauseCollapse] = useState(false);

  const randomId = useId();

  const handleAddParam = () => {
    onChangeHandler({
      ...select,
      expression: null,
      subQuery: null,
      caseClause: null,
      param: "",
      agg: ""
    });
  };

  const handleAddExpression = () => {
    const newExpression: IExpression = {
      pattern: "",
      values: []
    };

    onChangeHandler({
      ...select,
      expression: newExpression,
      subQuery: null,
      caseClause: null,
      param: "",
      agg: ""
    });
  };

  const handleAddSubQuery = () => {
    const newSubQuery: IQuery = {
      // Define your IQuery structure here
      dataPointId: "",
      tableName: "",
      alias: "",
      view: "",
      group: [],
      order: [],
      limit: 10,
      offset: 0,
      createView: false,
      select: []
    };

    onChangeHandler({
      ...select,
      subQuery: newSubQuery,
      expression: null,
      caseClause: null,
      param: "",
      agg: ""
    });
  };

  const handleAddCaseClause = () => {
    const newWhen: TWhere = [
      [
        {
          param: "",
          condition: [
            {
              operator: Operator.eq,
              value: null
            }
          ]
        }
      ]
    ];

    const newThen: ISelect = {
      distinct: false,
      param: "",
      alias: "",
      agg: "",
      expression: null,
      caseClause: null,
      subQuery: null
    };

    const newElseSelect: ISelect = {
      distinct: false,
      param: "",
      alias: "",
      agg: "",
      expression: null,
      caseClause: null,
      subQuery: null
    };

    const newCaseClause: ICaseClause = {
      when: [
        {
          when: newWhen,
          then: newThen
        }
      ],
      else: newElseSelect
    };

    onChangeHandler({
      ...select,
      caseClause: newCaseClause,
      expression: null,
      subQuery: null,
      param: "",
      agg: ""
    });
  };

  const handleAddWhenThen = () => {
    const newWhen: TWhere = [
      [
        {
          param: "",
          condition: [
            {
              operator: Operator.eq,
              value: null
            }
          ]
        }
      ]
    ];

    const newThen: ISelect = {
      distinct: false,
      param: "",
      alias: "",
      agg: "",
      expression: null,
      caseClause: null,
      subQuery: null
    };

    const newCaseClause: ICaseClause = {
      ...select.caseClause,
      when: [
        ...select.caseClause.when,
        {
          when: newWhen,
          then: newThen
        }
      ]
    };

    onChangeHandler({
      ...select,
      caseClause: newCaseClause
    });
  };

  useEffect(() => {
    if (!select.expression) return;

    const matches = [...select.expression.pattern.matchAll(PATTERN_KEY_REGEX)];

    const keys = new Set<string>();

    matches.forEach((match) => {
      const key = match[0].substring(1, match[0].length - 1);
      keys.add(key);
    });

    const newExpression: IExpression = {
      ...select.expression,
      values: [...keys.values()].reduce((acc, cur) => {
        acc[cur] = select.expression.values[cur] ?? "";
        return acc;
      }, {})
    };

    onChangeHandler({
      ...select,
      expression: newExpression
    });
  }, [select.expression?.pattern]);

  return (
    <div className="border bg-background-layer1 border-background-layer3 relative p-4 mb-4 ml-12">
      {/* Delete Button */}
      {onDelete ? (
        <Button
          type="button"
          icon={TrashIcon}
          color="red"
          variant="light"
          onClick={(e) => {
            onDelete();
          }}
          className="absolute top-2 right-2"
        />
      ) : null}

      {/* PARAM SELECT: */}
      {!select.expression && !select.subQuery && !select.caseClause ? (
        <>
          <label className="block">
            Parameter:
            <input
              type="text"
              name={randomId + "-select"}
              list={randomId + "-select"}
              value={select.param}
              onChange={(e) =>
                onChangeHandler({
                  ...select,
                  param: e.target.value.trim()
                })
              }
              className="ml-2 text-xs border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
            />
            {selectedDPDStructure ? (
              <datalist id={randomId + "-select"}>
                {[
                  ...Object.keys(selectedDPDStructure),
                  ...["timestamp", "device_id", "fleet_id"]
                ].map((param) => (
                  <option key={param}>{param}</option>
                ))}
              </datalist>
            ) : null}
          </label>

          <label className="block  mt-2">
            Distinct:
            <input
              type="checkbox"
              checked={select.distinct}
              onChange={(e) =>
                onChangeHandler({
                  ...select,
                  distinct: e.target.checked
                })
              }
              className="ml-2"
            />
          </label>

          <label className="flex items-center mt-2 gap-2">
            Aggregation Mode:
            <ReactSelect
              options={aggregationOptions}
              value={
                aggregationOptions.find((opt) => opt.value === select.agg) ||
                null
              }
              isClearable
              placeholder="None"
              onChange={(e: IOption) => {
                onChangeHandler({
                  ...select,
                  agg: e?.value ?? ""
                });
              }}
              classNames={reactSelectClassNames}
            />
          </label>
        </>
      ) : null}

      <label className="mt-2 block">
        Alias:
        <input
          type="text"
          value={select.alias}
          placeholder="Optional"
          onChange={(e) =>
            onChangeHandler({
              ...select,
              alias: e.target.value.trim()
            })
          }
          className="ml-2 mb-2 text-xs border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
        />
      </label>

      {/* EXPRESSION SELECT */}
      {select.expression ? (
        <>
          <label className="flex gap-2">
            Pattern:
            <VariableInput
              type="text"
              placeholder="Pattern"
              value={select.expression.pattern}
              keyRegex={PATTERN_KEY_REGEX}
              onChange={(val) =>
                onChangeHandler({
                  ...select,
                  expression: {
                    ...select.expression,
                    pattern: val
                  }
                })
              }
            />
          </label>

          {Object.keys(select.expression.values).length ? (
            <div className="p-2 border border-background-layer3 rounded-md mt-2">
              <h3 className="text-base mb-3">Values:</h3>
              <div className="flex flex-col gap-2">
                {Object.keys(select.expression.values).map((key) => {
                  return (
                    <label className="flex gap-2 ml-2">
                      {key}:
                      <input
                        type="text"
                        value={select.expression.values[key]}
                        onChange={(e) =>
                          onChangeHandler({
                            ...select,
                            expression: {
                              ...select.expression,
                              values: {
                                ...select.expression.values,
                                [key]: e.target.value.trim()
                              }
                            }
                          })
                        }
                        className="ml-2 text-xs border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 "
                      />
                    </label>
                  );
                })}
              </div>
            </div>
          ) : null}
        </>
      ) : null}

      {/* SUB-QUERY SELECT */}
      {select.subQuery ? (
        <div className="border border-background-layer3 p-4 pt-1 mt-4">
          <h2 className="text-lg font-medium mb-2">Sub Query:</h2>
          <QueryComponent
            isSub
            query={select.subQuery}
            setQuery={(query) =>
              onChangeHandler({
                ...select,
                subQuery: query
              })
            }
          />
        </div>
      ) : null}

      {select.caseClause ? (
        <>
          <div className="flex gap-2">
            <h2 className="text-lg font-medium">Case Clause:</h2>
            <Button
              type="button"
              variant="light"
              size="xs"
              disabled={false}
              icon={(props) => (
                <ChevronUpIcon
                  {...props}
                  className={`transition-transform duration-200 ${
                    caseClauseCollapse ? "rotate-180" : "rotate-0"
                  } ${props.className}`}
                />
              )}
              onClick={(e) => {
                setCaseClauseCollapse(!caseClauseCollapse);
              }}
              className="uppercase bg-blue-100  px-2 py-1 rounded-md text-xs hover:bg-blue-200"
            >
              {caseClauseCollapse ? "expand" : "collapse"}
            </Button>
          </div>
          {!caseClauseCollapse ? (
            <div className="flex flex-col gap-2 border border-background-layer3 p-4 pt-1 mt-2">
              <h2 className="text-base font-medium mb-2">When-Then:</h2>
              {select.caseClause.when.map((whenThen, whenThenInd) => (
                <div className="flex flex-col border border-background-layer3 p-4 rounded-md bg-background-layer2 mt-2">
                  <div className="w-full justify-between flex items-center mb-2">
                    <h2 className="text-base uppercase">When:</h2>
                    <Button
                      type="button"
                      color="red"
                      variant="light"
                      size="xs"
                      disabled={select.caseClause.when.length === 1}
                      onClick={(e) => {
                        const newWhenThen = [...select.caseClause.when];
                        newWhenThen.splice(whenThenInd, 1);

                        onChangeHandler({
                          ...select,
                          caseClause: {
                            ...select.caseClause,
                            when: newWhenThen
                          }
                        });
                      }}
                      className="bg-red-100 px-2 py-1 rounded-md text-xs hover:bg-red-200"
                    >
                      DELETE
                    </Button>
                  </div>
                  <WhereClause
                    where={whenThen.when}
                    selectedDPDStructure={selectedDPDStructure}
                    onChangeHandler={(where) => {
                      const newWhenThen = [...select.caseClause.when];
                      newWhenThen[whenThenInd] = {
                        ...newWhenThen[whenThenInd],
                        when: where
                      };

                      onChangeHandler({
                        ...select,
                        caseClause: {
                          ...select.caseClause,
                          when: newWhenThen
                        }
                      });
                    }}
                  />

                  <h2 className="text-base uppercase mb-2 ">then:</h2>
                  {/* {whenThen.then?.map(
                    (thenSelect: ISelect, thenInd: number) => ( */}
                  <SelectClause
                    selectedDPDStructure={selectedDPDStructure}
                    select={whenThen.then}
                    onChangeHandler={(_select) => {
                      const newWhenThen = [...select.caseClause.when];

                      newWhenThen[whenThenInd] = {
                        ...newWhenThen[whenThenInd],
                        then: _select
                      };

                      onChangeHandler({
                        ...select,
                        caseClause: {
                          ...select.caseClause,
                          when: newWhenThen
                        }
                      });
                    }}
                  />
                  {/* ) */}
                  {/* )} */}
                  {/* <Button
                    type="button"
                    icon={PlusIcon}
                    onClick={() => handleAddThenSelectClause(whenThenInd)}
                    className="m-2 ml-12 px-3 py-2  rounded-sm"
                  >
                    Paramter
                  </Button> */}
                </div>
              ))}

              <Button
                icon={PlusIcon}
                variant="secondary"
                size="xs"
                className="self-center mb-2"
                onClick={handleAddWhenThen}
              >
                When Then
              </Button>

              <hr className="w-full border-background-layer3 my-2" />
              <h2 className="text-base font-medium mb-2 uppercase">Else:</h2>

              <SelectClause
                selectedDPDStructure={selectedDPDStructure}
                select={select.caseClause.else}
                onChangeHandler={(_select) => {
                  onChangeHandler({
                    ...select,
                    caseClause: {
                      ...select.caseClause,
                      else: _select
                    }
                  });
                }}
              />
            </div>
          ) : (
            <div className="text-contentColorLight text-center mt-4 text-sm">
              Expand to view
            </div>
          )}
        </>
      ) : null}

      {/* Render buttons to add expressions, sub-queries, and case clauses */}
      <div className="mt-4 flex gap-2">
        {select.expression || select.subQuery || select.caseClause ? (
          <Button
            type="button"
            variant="light"
            size="xs"
            tooltip="Convert to Parameter"
            className="rounded-2xl border border-primary !px-2"
            icon={ArrowPathIcon}
            onClick={handleAddParam}
          >
            Parameter
          </Button>
        ) : null}
        {select.expression === null ? (
          <Button
            type="button"
            variant="light"
            size="xs"
            tooltip="Convert to Expression"
            className="rounded-2xl border border-primary !px-2"
            icon={ArrowPathIcon}
            onClick={handleAddExpression}
          >
            Expression
          </Button>
        ) : null}
        {select.subQuery === null ? (
          <Button
            type="button"
            variant="light"
            size="xs"
            tooltip="Convert to Sub-Query"
            className="rounded-2xl border border-primary !px-2"
            icon={ArrowPathIcon}
            onClick={handleAddSubQuery}
          >
            Sub-Query
          </Button>
        ) : null}
        {select.caseClause === null ? (
          <Button
            type="button"
            variant="light"
            size="xs"
            tooltip="Convert to Case Clause"
            className="rounded-2xl border border-primary !px-2"
            icon={ArrowPathIcon}
            onClick={handleAddCaseClause}
          >
            Case Clause
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default SelectClause;
