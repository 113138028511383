import { useState, useEffect, useRef } from "react";
import { object, string } from "yup";
import { AddOverlayLayout } from "../../shared/components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FieldError } from "../../shared/components";
import Select from "react-select";
import useOTAReleaseStore from "../../../store/ota/ota-release.store";
import { IOption } from "../../../interfaces";
import { useCreateRelease } from "@app/shared/hooks/post/create-release";
import { useGetReleases } from "@app/shared/hooks/get";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import { Button } from "@tremor/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";

interface ICreateReleaseFormProps {
  //   fetchKeys: () => Promise<void>;
}

const targetByOptions = [
  { label: "Fleets", value: "fleets" },
  { label: "Devices", value: "devices" }
];

const createReleaseFormSchema = object().shape({
  releaseName: string().required("Please enter release name."),
  deviceType: string().required("Please enter device type.")
});

const CreateReleaseForm: React.FC<ICreateReleaseFormProps> = () => {
  const [setActiveRelease, showCreateForm, setShowCreateForm] =
    useOTAReleaseStore((state) => [
      state.setActiveRelease,
      state.showCreateForm,
      state.setShowCreateForm
    ]);

  const [selectedTargetOptions, setSelectedTargetOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedTargetBy, setSelectedTargetBy] = useState<{
    value: string;
    label: string;
  }>(undefined);
  const [targetOptions, setTargetOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [errorMsgs, setErrorMsgs] = useState({
    selectedTargets: ""
  });
  const [lastFetchedDevicesPage, setLastFetchedDevicesPage] = useState(1);
  const deviceOptions = useRef<Record<string, IOption>>({});

  const createReleaseMutation = useCreateRelease();

  const { refetch: refetchReleases } = useGetReleases();
  const { data: fleets } = useGetFleets();
  const { data: devicesResponse, isLoading: isDevicesLoading } = useGetDevices(
    {
      page: lastFetchedDevicesPage
    }
  );

  useEffect(() => {
    deviceOptions.current = {
      ...deviceOptions.current,
      ...devicesResponse?.devices.reduce((acc, cur) => {
        acc[cur.id] = {
          label: cur.device_name,
          value: cur.id
        };
        return acc;
      }, {})
    };

    setTargetOptions(Object.values(deviceOptions.current));
  }, [devicesResponse]);

  useEffect(() => {
    if (!showCreateForm) {
      setSelectedTargetOptions([]);
      setSelectedTargetBy(undefined);
      setTargetOptions([]);
      setErrorMsgs({ selectedTargets: "" });
    }
  }, [showCreateForm]);

  const handleSubmit = async (
    values: {
      releaseName: string;
      deviceType: string;
    },
    { resetForm }
  ) => {
    if (selectedTargetOptions.length <= 0) {
      setErrorMsgs({
        ...errorMsgs,
        selectedTargets: "Please select target"
      });
      return;
    } else {
      setErrorMsgs({ ...errorMsgs, selectedTargets: "" });
    }

    const currentTargetType =
      selectedTargetBy.value === "fleets" ? "target_fleets" : "target_devices";

    const targetIds = selectedTargetOptions.map((target) => target.value);

    const payload = {
      release_name: values.releaseName,
      device_types: values.deviceType,
      [currentTargetType]: targetIds.toString()
    };

    createReleaseMutation.mutate(
      { props: payload },
      {
        onSuccess: (id) => {
          refetchReleases().then((releases) => {
            const activeRel = releases.data.find((rel) => rel.id === id);
            setActiveRelease(activeRel);
            setShowCreateForm(false);
          });
        }
      }
    );
  };

  const handleTargetSelect = async (
    targets: { value: string; label: string }[]
  ) => {
    if (errorMsgs.selectedTargets) {
      setErrorMsgs({ ...errorMsgs, selectedTargets: "" });
    }

    setSelectedTargetOptions([...targets]);
  };

  const handleTargetBy = async (e: IOption) => {
    if (selectedTargetBy?.value !== e.value) {
      setSelectedTargetOptions([]);
      setSelectedTargetBy(e);
      setErrorMsgs({ ...errorMsgs, selectedTargets: "" });
    }

    if (selectedTargetBy?.value !== "fleets" && e.value === "fleets") {
      if (fleets?.length) {
        const options = fleets.map((fleet) => {
          return { label: fleet.fleet_name, value: fleet.id };
        });

        setTargetOptions([...options]);
      } else {
        setErrorMsgs({
          ...errorMsgs,
          selectedTargets:
            "No fleets available, create one in fleets & devices"
        });
        setTargetOptions([]);
      }
    } else if (
      selectedTargetBy?.value !== "devices" &&
      e.value === "devices"
    ) {
      if (devicesResponse?.devices?.length) {
        const options = devicesResponse?.devices.map((device) => {
          return { label: device.device_name, value: device.id };
        });

        setTargetOptions([...options]);
      } else {
        setErrorMsgs({
          ...errorMsgs,
          selectedTargets:
            "No devices available, create one in fleets & devices"
        });
        setTargetOptions([]);
      }
    }
  };

  return (
    <AddOverlayLayout
      title="Create New Release"
      overlayOpen={showCreateForm}
      setOverlayOpen={setShowCreateForm}
    >
      <div className="flex flex-col items-center">
        {showCreateForm && (
          <Formik
            initialValues={{
              releaseName: "",
              deviceType: ""
            }}
            validationSchema={createReleaseFormSchema}
            onSubmit={handleSubmit}
          >
            <Form className="w-full flex flex-col flex-1 mt-7">
              <div className="mb-5">
                <label className="text-sm font-medium text-[#B7B9C1]">
                  Release Name
                </label>

                <Field
                  type="text"
                  id="releaseName"
                  name="releaseName"
                  placeholder="Release Name"
                  className="block w-full p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
                <ErrorMessage name="releaseName">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>

              <div className="mb-5">
                <label className="text-sm font-medium text-[#B7B9C1]">
                  Device Type
                </label>

                <Field
                  type="text"
                  id="deviceType"
                  name="deviceType"
                  placeholder="Device Type"
                  className="block w-full p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
                <ErrorMessage name="deviceType">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>

              <div className="mb-5">
                <label className="flex font-medium text-sm mb-2">
                  Target By
                </label>
                <Select
                  placeholder="Select"
                  isSearchable={false}
                  options={targetByOptions}
                  onChange={handleTargetBy}
                  isClearable={false}
                  classNames={reactSelectClassNames}
                />
              </div>

              <div className="mb-5">
                <label className="flex font-medium text-sm mb-2">
                  Select Targets
                </label>
                <div className="flex w-full gap-2">
                  <Select
                    isDisabled={
                      !targetOptions.length ||
                      (selectedTargetBy?.value === "devices" &&
                        isDevicesLoading)
                    }
                    placeholder="Select"
                    isSearchable={false}
                    options={targetOptions}
                    value={selectedTargetOptions}
                    onChange={handleTargetSelect}
                    isClearable={true}
                    isMulti
                    className="w-full"
                    classNames={reactSelectClassNames}
                  />

                  {selectedTargetBy?.value === "devices" ? (
                    <Button
                      type="button"
                      icon={ArrowPathIcon}
                      variant="light"
                      loading={isDevicesLoading}
                      disabled={
                        lastFetchedDevicesPage >=
                        devicesResponse?.meta?.totalPages
                      }
                      tooltip="Load more devices"
                      onClick={() =>
                        setLastFetchedDevicesPage((prev) => prev + 1)
                      }
                    />
                  ) : null}
                </div>
                {errorMsgs.selectedTargets ? (
                  <FieldError message={errorMsgs.selectedTargets} />
                ) : (
                  ""
                )}
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  Create
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </AddOverlayLayout>
  );
};

export default CreateReleaseForm;
