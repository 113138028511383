import { formatNumber } from "@/app/shared/utils/dashboard.utils";
import { INumericInfoGraph } from "@interfaces/index";
import { useDashboardStore } from "@store/index";
import { BadgeDelta } from "@tremor/react";

/* 
    Component: Metric Graph [ Numeric Graph ]
    Props:  {
        info: numeric info [ number ]
        title : Title of graph [ panel name ]
    }
    Author: Aaditya Kashid
*/

const NumericGasGraph: React.FC<INumericInfoGraph> = ({
  info,
  unit,
  minValue,
  maxValue,
  showEample
}) => {
  const [createPanelAppearance] = useDashboardStore((state) => [
    state.createPanelAppearance
  ]);

  const unitToShow = showEample
    ? createPanelAppearance.unit ?? "KWHr"
    : unit ?? "KWHr";
  const minToShow = showEample
    ? createPanelAppearance.showMin
      ? 0
      : null
    : minValue ?? null;
  const maxToShow = showEample
    ? createPanelAppearance.showMax
      ? 100
      : null
    : maxValue ?? null;

  return (
    <div
      className={`w-full h-[91%] flex flex-col ${
        showEample ? "!h-[250px]" : ""
      }`}
    >
      <div className="relative flex flex-grow flex-col justify-center items-center bg-background text-contentColor">
        {minToShow !== null ? (
          <span className="absolute top-4 left-6 text-xl font-bold flex items-center">
            <BadgeDelta deltaType="increase" isIncreasePositive={true}>
              {formatNumber(minToShow)} L
            </BadgeDelta>

            <span className="text-sm font-normal ml-1"> Gas IN</span>
          </span>
        ) : null}
        {maxToShow !== null ? (
          <span className="absolute top-4 right-6 text-xl font-bold flex items-center">
            <BadgeDelta deltaType="decrease">
              {formatNumber(maxToShow)} L
            </BadgeDelta>

            <span className="text-sm font-normal ml-1"> Gas OUT</span>
          </span>
        ) : null}
        <div
          className={`flex items-center justify-center ${
            minValue || maxValue ? "mt-4" : ""
          }`}
        >
          <h1 className="text-4xl md:text-5xl lg:text-[4rem] font-bold">
            {formatNumber(info)}
          </h1>
          <span className=" inline-block text-xl ml-2 align-bottom">
            {"L"}
          </span>
          <svg
            width="30"
            height="55"
            viewBox="0 0 30 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2"
            transform={info > 0 ? "rotate(0)" : "rotate(180)"}
          >
            <path
              d="M14.0371 43.6621L14.0371 6.66211"
              stroke={info > 0 ? "#00AC47" : "#F44868"}
              strokeWidth="3.71875"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.03711 15.6621L14.5371 5.66211L24.0371 15.6621"
              stroke={info > 0 ? "#00AC47" : "#F44868"}
              strokeWidth="3.71875"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <span className="block text-sm">{unitToShow}</span>
      </div>
    </div>
  );
};

export default NumericGasGraph;
