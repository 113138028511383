import React from "react";
import { validate as isValidUUID } from "uuid";
import { VariableInput } from "@/app/rule-engine/variable-input.component";
import {
  isVariable,
  reactSelectClassNames
} from "@/app/shared/utils/helper.util";
import {
  IUserContextDefinition,
  UserContextMessageType
} from "@/interfaces/user-contexts.interface";
import useThemeStore from "@/store/theme.store";
import { Editor } from "@monaco-editor/react";
import {
  CodeBracketIcon,
  ExclamationTriangleIcon,
  PlusIcon,
  VariableIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { Button } from "@tremor/react";
import ReactSelect from "react-select";
import { IOption } from "@/interfaces";
import { IContext, IInput } from "@/app/rule-engine/rule-engine.helper";

export type TFormData = Record<
  string,
  {
    value: any;
    error: boolean;
    variable?: boolean;
  }
>;

export interface IUserContextFormProps {
  contextDefinition: IUserContextDefinition["definition"];
  formData: TFormData;
  selectedFields: string[];
  fetchedContexts?: IContext[];
  inputs?: IInput[];
  setSelectedFields: (sf: string[] | ((sf: string[]) => string[])) => void;
  setFormData: (fd: TFormData | ((fd: TFormData) => TFormData)) => void;
}

const UserContextForm: React.FC<IUserContextFormProps> = ({
  formData,
  contextDefinition,
  selectedFields,
  fetchedContexts,
  inputs,
  setSelectedFields,
  setFormData
}) => {
  const [theme] = useThemeStore((state) => [state.theme]);

  const handleInputChange = (val: string, fieldName: string) => {
    if (isVariable(val)) {
      // validate variable
      setFormData({
        ...formData,
        [fieldName]: {
          ...formData[fieldName],
          value: val,
          error: false
        }
      });
    } else {
      let value = null,
        error = null;

      switch (contextDefinition.properties[fieldName].type.trim()) {
        case UserContextMessageType.Timestamp:
        case UserContextMessageType.TimestampWithZone:
        case UserContextMessageType.String:
        case UserContextMessageType.JSON:
        case UserContextMessageType.Geometry:
        case UserContextMessageType.Object:
        case UserContextMessageType.Enum:
          value = val;

          if (contextDefinition.required?.includes(fieldName)) {
            if (val !== "") {
              error = false;
            } else {
              error = true;
            }
          } else {
            error = false;
          }
          break;
        case UserContextMessageType.Integer:
          // try converting to int;
          const numInt = Number(val);
          if (Number.isNaN(numInt)) {
            value = val;
            error = true;
          } else if (!Number.isInteger(numInt)) {
            value = val;
            error = true;
          } else {
            value = val;
            error = false;
          }
          break;
        case UserContextMessageType.Float:
          // try converting to float;
          const numFloat = Number(val);
          if (Number.isNaN(numFloat)) {
            value = val;
            error = true;
          } else if (Number.isInteger(numFloat)) {
            value = val;
            error = true;
          } else {
            value = val;
            error = false;
          }

          break;
        case UserContextMessageType.Binary:
          value = val;
          error = /^-{0,1}[10]+$/g.test(val);

          break;
        case UserContextMessageType.Boolean:
          if (val !== "true" && val !== "false") {
            value = val;
            error = true;
          } else {
            value = val;
            error = false;
          }
          break;

        case UserContextMessageType.UUID:
          if (!isValidUUID(val)) {
            value = val;
            error = true;
          } else {
            value = val;
            error = false;
          }
          break;

        default:
          console.log("COULD NOT FIND THE TYPE FOR FIELD: ", fieldName);
          break;
      }

      setFormData({
        ...formData,
        [fieldName]: {
          ...formData[fieldName],
          value,
          error
        }
      });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {Object.keys(formData)
        .filter((f) => selectedFields.includes(f))
        .sort(
          (fieldA, fieldB) =>
            Number(contextDefinition.required?.includes(fieldB)) -
            Number(contextDefinition.required?.includes(fieldA))
        )
        .map((fieldName) =>
          ![
            UserContextMessageType.Array,
            UserContextMessageType.Object,
            UserContextMessageType.Enum,
            UserContextMessageType.JSON,
            UserContextMessageType.Geometry
          ].includes(contextDefinition.properties[fieldName].type) ? (
            <GenericField
              fieldName={fieldName}
              contextDef={contextDefinition}
              formData={formData}
              setFormData={setFormData}
              setSelectedFields={setSelectedFields}
              handleInputChange={handleInputChange}
              showNonVariable={false}
            />
          ) : [
              UserContextMessageType.Object,
              UserContextMessageType.JSON
            ].includes(contextDefinition.properties[fieldName].type) ? (
            <GenericField
              fieldName={fieldName}
              contextDef={contextDefinition}
              formData={formData}
              setSelectedFields={setSelectedFields}
              handleInputChange={handleInputChange}
              setFormData={setFormData}
              showNonVariable={true}
              nonVariableDefaultVal={{}}
              nonVariableButtonTooltip="Enter custom JSON"
              nonVariableComponent={() => (
                <Editor
                  height="200px"
                  width={"300px"}
                  language="json"
                  value={JSON.stringify(formData[fieldName].value, null, 2)}
                  theme={
                    theme === "golain" || theme === "none" ? "vs" : "vs-dark"
                  }
                  onChange={(val) => {
                    try {
                      setFormData({
                        ...formData,
                        [fieldName]: {
                          ...formData[fieldName],
                          value: JSON.parse(val ?? "")
                        }
                      });
                    } catch (err) {}
                  }}
                  options={{
                    readOnly: false,
                    minimap: { enabled: false },
                    scrollbar: { vertical: "hidden" },
                    overviewRulerBorder: false,
                    overviewRulerLanes: 0,
                    folding: true,
                    matchBrackets: "always",
                    lineNumbers: "off",
                    theme:
                      theme === "golain" || theme === "none" ? "vs" : "vs-dark"
                  }}
                />
              )}
            />
          ) : contextDefinition.properties[fieldName].type ===
            UserContextMessageType.Enum ? (
            <GenericField
              fieldName={fieldName}
              contextDef={contextDefinition}
              formData={formData}
              setSelectedFields={setSelectedFields}
              handleInputChange={handleInputChange}
              setFormData={setFormData}
              showNonVariable={true}
              nonVariableDefaultVal={""}
              nonVariableButtonTooltip="Select enum value"
              nonVariableComponent={() => (
                <ReactSelect
                  options={
                    contextDefinition.properties[fieldName]?.values?.map(
                      (v) => ({
                        label: v,
                        value: v
                      })
                    ) ?? []
                  }
                  onChange={(opt: IOption) => {
                    handleInputChange(opt.value, fieldName);
                  }}
                  value={contextDefinition.properties[fieldName]?.values
                    .map((v) => ({
                      label: v,
                      value: v
                    }))
                    .find((opt) => opt.value === formData[fieldName].value)}
                  className="w-full"
                  classNames={reactSelectClassNames}
                />
              )}
            />
          ) : contextDefinition.properties[fieldName].type ===
            UserContextMessageType.Geometry ? (
            <GenericField
              fieldName={fieldName}
              contextDef={contextDefinition}
              formData={formData}
              setSelectedFields={setSelectedFields}
              handleInputChange={handleInputChange}
              setFormData={setFormData}
              showNonVariable
              nonVariableDefaultVal={{ latitude: 0, longitude: 0 }}
              nonVariableButtonTooltip="Enter Custom Location"
              nonVariableComponent={() => (
                <div className="flex gap-2">
                  <label>
                    Latitude
                    <input
                      type="number"
                      step="any"
                      className="mt-1 p-1 block w-full border border-background-layer3 rounded-md bg-background 
                      focus:outline-none focus:border-blue-500 transition duration-150 ease-in-out"
                    />
                  </label>
                  <label>
                    Longitude
                    <input
                      type="number"
                      step="any"
                      className="mt-1 p-1 block w-full border border-background-layer3 rounded-md bg-background 
                      focus:outline-none focus:border-blue-500 transition duration-150 ease-in-out"
                    />
                  </label>
                </div>
              )}
            />
          ) : null
        )}
      <div className="flex gap-2 mt-4 max-w-md flex-wrap">
        {Object.keys(formData)
          .filter((f) => !selectedFields.includes(f))
          .map((field) => (
            <div
              className="flex items-center gap-1 p-1 rounded-md bg-background-layer2 cursor-pointer"
              onClick={() => setSelectedFields((prev) => [...prev, field])}
            >
              <PlusIcon width={16} />
              {field}
            </div>
          ))}
      </div>
      <Tooltip id="insert-context-field-error" variant="error" />
    </div>
  );
};

export default UserContextForm;

interface IGenericFieldProps {
  fieldName: string;
  contextDef: IUserContextDefinition["definition"];
  formData: TFormData;
  setFormData: (fd: TFormData | ((fd: TFormData) => TFormData)) => void;
  setSelectedFields: (
    fields: string[] | ((fields: string[]) => string[])
  ) => void;
  handleInputChange: (val: string, fieldName: string) => void;
  showNonVariable: boolean;
  variableTooltip?: string;
  nonVariableButtonTooltip?: string;
  nonVariableDefaultVal?: any;
  nonVariableComponent?: () => JSX.Element;
}

const GenericField: React.FC<IGenericFieldProps> = ({
  fieldName,
  contextDef,
  formData,
  setFormData,
  setSelectedFields,
  handleInputChange,
  nonVariableComponent,
  showNonVariable,
  variableTooltip,
  nonVariableButtonTooltip,
  nonVariableDefaultVal
}) => {
  return (
    <div key={fieldName} className="nodrag">
      <label htmlFor={fieldName} className="flex items-center">
        {fieldName}
        {contextDef.required?.includes(fieldName) ? (
          <span className="text-lg -mb-2 ml-1">*</span>
        ) : null}
        {showNonVariable ? (
          formData[fieldName].variable ? (
            <Button
              icon={CodeBracketIcon}
              variant="light"
              tooltip={nonVariableButtonTooltip}
              className="ml-2"
              onClick={() =>
                setFormData((prev) => ({
                  ...prev,
                  [fieldName]: {
                    ...prev[fieldName],
                    variable: false,
                    value: nonVariableDefaultVal,
                    error: false
                  }
                }))
              }
            />
          ) : (
            <Button
              icon={VariableIcon}
              variant="light"
              className="ml-2"
              tooltip={variableTooltip ?? "Use Variable"}
              onClick={() =>
                setFormData((prev) => ({
                  ...prev,
                  [fieldName]: {
                    ...prev[fieldName],
                    variable: true,
                    value: "",
                    error: false
                  }
                }))
              }
            />
          )
        ) : null}
      </label>
      <div
        className={`flex ${
          formData[fieldName].variable ? "items-center" : "items-start"
        }`}
      >
        {!showNonVariable ? (
          <div className="flex items-center">
            <VariableInput
              type="text"
              value={formData[fieldName].value}
              placeholder="Value or Variable"
              onChange={(val) => {
                handleInputChange(val.trim(), fieldName);
              }}
            />
            <div className="p-2 bg-background-layer2 border border-l-0 border-background-layer3 rounded-r-md font-mono !text-xs">
              {contextDef.properties[fieldName].type}
            </div>
          </div>
        ) : formData[fieldName].variable ? (
          <VariableInput
            type="text"
            value={formData[fieldName].value}
            placeholder="Value or Variable"
            onChange={(val) => {
              if (!isVariable(val)) {
                setFormData({
                  ...formData,
                  [fieldName]: {
                    ...formData[fieldName],
                    value: val,
                    error: true
                  }
                });
              } else {
                setFormData({
                  ...formData,
                  [fieldName]: {
                    ...formData[fieldName],
                    value: val,
                    error: false
                  }
                });
              }
            }}
          />
        ) : (
          nonVariableComponent()
        )}
        {formData[fieldName].error ? (
          <span
            data-tooltip-id="insert-context-field-error"
            data-tooltip-content={`The value is not valid for type '${
              showNonVariable && formData[fieldName].variable
                ? "variable"
                : contextDef.properties[fieldName].type
            }'`}
            className="ml-2"
          >
            <ExclamationTriangleIcon width={26} color="red" />
          </span>
        ) : null}
        {!contextDef.required?.includes(fieldName) ? (
          <XMarkIcon
            width={20}
            className="cursor-pointer ml-2"
            onClick={() =>
              setSelectedFields((prev) => prev.filter((f) => f !== fieldName))
            }
          />
        ) : null}
      </div>
    </div>
  );
};
