import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface ICreateDeviceTagResponse {
  data: {
    association_status: boolean;
  };
  ok: number;
}

export interface ICreateDeviceTagPayload {
  tag_ids: string[];
}

export const createDeviceTag = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  payload: ICreateDeviceTagPayload
) => {
  const { data, ok } = await networkService.post<ICreateDeviceTagResponse>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/tags`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.association_status;
  } else return null;
};

export const useCreateDeviceTag = (fleetId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({
      data,
      deviceId
    }: {
      data: ICreateDeviceTagPayload;
      deviceId: string;
    }) =>
      await createDeviceTag(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDeviceTags");
      }
    }
  );

  return res;
};
