import { memo, useEffect, useRef, useState } from "react";
import { Handle, Position, useStore, ReactFlowState } from "reactflow";
import NodeTemplate from "../node-template.component";
import { Button } from "@tremor/react";
import { Tooltip } from "react-tooltip";
import { Editor } from "@monaco-editor/react";
import { NodeType } from "@app/rule-engine/node-types.helper";
import useRuleEngineStore from "@store/rule-engine/rule-engine.store";

const isValidConnection = (state: ReactFlowState) => {
  return state.getNodes();
};

const HTTPResponseNode = ({ id, data, isConnectable }) => {
  const nodes = useStore(isValidConnection);

  const [ruleData, responseData, setResponseData] = useRuleEngineStore(
    (state) => [state.ruleData, state.responseData, state.setResponseData]
  );

  const [jsonData, setJsonData] = useState(undefined);
  const [invalidConnectionTooltipOpen, setInvalidConnectionTooltipOpen] =
    useState(false);
  const invalidConnTimer = useRef(null);

  useEffect(() => {
    // the http-response-node renders before the responseData is set.
    // this effect makes sure that whenever the responseData is set,
    // the jsonData is updated.
    if (jsonData === undefined) {
      setJsonData(
        responseData[id]?.body
          ? JSON.stringify(responseData[id]?.body, null, 4)
          : ""
      );
    }
  }, [id, jsonData, responseData]);

  useEffect(() => {
    const timer = invalidConnTimer.current;

    return () => {
      timer && clearTimeout(timer);
    };
  }, []);

  return (
    <NodeTemplate>
      <Handle
        type="target"
        data-tooltip-id="invalid-connection-tooltip-http"
        position={Position.Left}
        id={`http-response-${id}`}
        className="bg-blue-500"
        isConnectable={isConnectable}
        isValidConnection={(connection) => {
          const ruleEditorNode = nodes.find(
            (node) =>
              node.type === NodeType.ruleEditorNode &&
              node.id === connection.source
          );
          if (!ruleEditorNode) {
            return true;
          }

          let [_, conditionIdString, trueFalse] =
            connection.sourceHandle.split("-");

          let conditionId = parseInt(conditionIdString);

          const responseType =
            trueFalse === "true" ? "trueResponse" : "falseResponse";

          const condition = ruleData.conditions[conditionId];

          if (connection.targetHandle.startsWith("http-response")) {
            if (responseType in condition && condition[responseType]) {
              setInvalidConnectionTooltipOpen(true);
              if (invalidConnTimer.current) {
                clearTimeout(invalidConnTimer.current);
                invalidConnTimer.current = null;
              }
              invalidConnTimer.current = setTimeout(() => {
                setInvalidConnectionTooltipOpen(false);
              }, 3000);
              return false;
            }
          }

          return true;
        }}
      />
      <div className="flex justify-center gap-2 mb-3 text-base">
        <div>HTTP Response</div>
      </div>
      <div className="flex flex-col gap-2 text-xs">
        {/* <h3 className="whitespace-nowrap text-base items-center">
          Status Code:{" "}
        </h3> */}
        <input
          placeholder="Status Code"
          type="number"
          className="nodrag bg-background w-full px-1 py-1 text-contentColor border-background-layer3 rounded-sm border"
          onChange={(e) =>
            setResponseData((resData) => ({
              ...resData,
              [id]: { ...resData[id], status_code: parseInt(e.target.value) }
            }))
          }
          value={responseData[id]?.status_code ?? null}
          min={100}
          max={599}
        />
        {responseData[id]?.status_code > 599 ||
        responseData[id]?.status_code < 100 ||
        Number.isNaN(responseData[id]?.status_code) ? (
          <span className="text-red-500 text-left w-full">
            Enter a valid HTTP status code (100-599).
          </span>
        ) : null}
      </div>
      <div className="flex min-w-[300px] gap-2 flex-col nodrag mt-2">
        <div className="flex justify-between">
          <h3>Body:</h3>
          <Button
            variant="light"
            className="font-mono"
            tooltip="Format"
            onClick={() => {
              setJsonData(JSON.stringify(responseData[id]?.body, null, 4));
            }}
          >
            {"{}"}
          </Button>
        </div>
        <Editor
          height="200px"
          width="100%"
          theme="vs-dark"
          language="json"
          value={jsonData ?? ""}
          className="rounded-sm border border-background-layer3"
          onChange={(val) => {
            setJsonData(val);
            try {
              const newJSON = JSON.parse(val);
              setResponseData((resData) => ({
                ...resData,
                [id]: { ...resData[id], body: newJSON }
              }));
            } catch (err) {}
          }}
          options={{
            readOnly: false,
            minimap: {
              enabled: false
            },
            lineNumbers: "off"
          }}
        />
      </div>
      <Tooltip
        id={"invalid-connection-tooltip-http"}
        isOpen={invalidConnectionTooltipOpen}
        place="bottom"
        className="bg-background-layer2 z-50"
      >
        A condition can only have a single true/false response!
      </Tooltip>
    </NodeTemplate>
  );
};

export default memo(HTTPResponseNode);
