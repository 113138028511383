import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";
import { IPermission } from "@interfaces/apps.interface";
import { ICreateInviteResponse } from "@interfaces/invites.interface";

export interface IUpdateUserPermsPayload {
  permissions: IPermission[];
  org_access: string;
  user_id: string;
  org_id: string;
}

export const editPerms = async (
  user_id: string,
  orgId: string,
  payload: IUpdateUserPermsPayload
) => {
  const { data, ok } = await networkService.patch<ICreateInviteResponse>(
    `organizations/users/${user_id}`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useEditUserPerms = () => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({
      data,
      user_id
    }: {
      data: IUpdateUserPermsPayload;
      user_id: string;
    }) => await editPerms(user_id, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUsersInOrg");
      }
    }
  );

  return res;
};
