import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useDashboardStore, useFleetAndDevicesStore } from "@store/index";
import { useLocation } from "react-router-dom";
//graph helpers
import {
  IGaugeDefination,
  ILineDefination,
  ILocationDevice,
  IPieDefinations
} from "@interfaces/index";
import GraphHeader from "./components/graphs/dash-graph-header.component";
import {
  generateGraphData,
  getInfographicSum
} from "./components/graphs/graph.helper";
//graphs
import Gauge from "./components/gauge/gauge";
import { PieChart, LineChart, BarChart } from "./components/graphs";
import MapView from "./components/map/dash-map-component";
import TempMeter from "./components/gauge/temp-meter";
import NumericInfoGraph from "./components/graphs/dash-numeric-graph.component";
import dateService from "@services/date.service";
import HeatMap from "./components/graphs/heatmap.component";
import { PanelErrorProvider } from "../shared/utils/dash-panel-error.context";
import TableComponent from "./components/table/dash-table.component";
import { useGetDashboardPanelData } from "@app/shared/hooks/get/panel-data";
import { DASH_PANEL_TYPE } from "@/interfaces/dashboard-panel.interface";
import { useGetBlueprintPanelData } from "../shared/hooks/get/blueprint-panel-data";
import NumericGasGraph from "./components/graphs/dash-numeric-gas.component";
import DashMapStationsComponent from "./components/map/dash-map-stations.component";
import { useGetDevices } from "../shared/hooks/get/devices";

interface IPanelWrapperProps {
  panel: ILineDefination | IPieDefinations | IGaugeDefination;
  inCarousel?: boolean;
  deviceId?: string;
  handlePanelError?: (
    panelId: string,
    panelTitle: string,
    error: string
  ) => void;
}

const realtimePanels = [
  DASH_PANEL_TYPE.LINE_CHART,
  DASH_PANEL_TYPE.BAR_CHART,
  DASH_PANEL_TYPE.PIE_CHART,
  DASH_PANEL_TYPE.GAUGE_1,
  DASH_PANEL_TYPE.GAUGE_2,
  DASH_PANEL_TYPE.NUMERIC,
  DASH_PANEL_TYPE.GEO_MAP
];

const PanelWrapper: React.FC<IPanelWrapperProps> = React.forwardRef<
  HTMLDivElement,
  IPanelWrapperProps
>(({ panel, deviceId, inCarousel = false, handlePanelError }, ref) => {
  const location = useLocation();

  const [panelError, setPanelError] = useState<string>("");
  const [activeDashboard, activeFilter, editingLayout] = useDashboardStore(
    (state) => [state.activeDashboard, state.activeFilter, state.editingLayout]
  );

  const selectedFleet = useFleetAndDevicesStore(
    (state) => state.selectedFleet
  );

  const boundingBox = useRef([
    73.7115888365174, 18.68774689473389, 73.97315825630326, 18.44895304892448
  ]);

  const duration = activeFilter.span
    ? formatDuration(activeFilter.span)
    : null;

  let start = activeFilter.span
    ? dateService
        .getCurrentUTCDate()
        .subtract(parseInt(activeFilter.span), duration)
        .format()
    : activeFilter.start;
  if (
    panel.panel_type === "LINE_CHART" &&
    panel.definition.meta?.type === "Info"
  ) {
    start = dateService.getCurrentUTCDate().subtract(24, "hours").format();
  }

  let stop = activeFilter.span
    ? dateService.getCurrentUTCDate().format()
    : activeFilter.stop;

  let params = { start, stop };
  if (activeFilter?.span) {
    params["span"] = true;
  }

  if (panel.panel_type === DASH_PANEL_TYPE.GEO_MAP) {
    params["bounding_box"] = boundingBox.current.join(",");
  } else if (realtimePanels.includes(panel.panel_type)) {
    params["where"] = JSON.stringify([
      {
        timestamp: {
          lte: activeFilter.span
            ? dateService.getCurrentUTCDate().format()
            : activeFilter.stop
        }
      },
      {
        timestamp: {
          gte: activeFilter.span
            ? dateService
                .getCurrentUTCDate()
                .subtract(parseInt(activeFilter.span), duration)
                .format()
            : activeFilter.start
        }
      }
    ]);
  }

  const { data: _panelData, error } = useGetDashboardPanelData(
    activeDashboard.id,
    panel.id,
    !editingLayout && location.pathname.startsWith("/dashboard"),
    params,
    { blockErrorToast: true, noLoader: true }
  );

  // for GEO_MAP panel,
  const { data: deviceWithShadows } = useGetDevices({
    formattedShadow: "json",
    device_id:
      panel.panel_type === DASH_PANEL_TYPE.GEO_MAP
        ? _panelData?.devices?.map((d) => d.device_id).join(",")
        : ""
  });

  const { data: blueprintPanelData, error: blueprintPanelErorr } =
    useGetBlueprintPanelData(
      selectedFleet?.id,
      deviceId,
      panel.blueprint_id,
      !editingLayout,
      params,
      { blockErrorToast: true, noLoader: true }
    );

  useEffect(() => {
    const _err = (error || blueprintPanelErorr) as { message: string };
    if (_err) {
      handlePanelError &&
        handlePanelError(panel.id, panel.title, _err.message);
      setPanelError(_err.message);
    }

    return () => {};
  }, [error, blueprintPanelErorr, handlePanelError, panel.id, panel.title]);

  const onChangeBoundingBox = (bBox: []) => {
    if (bBox.length) {
      boundingBox.current = bBox;
    }
  };

  const renderNoDataPanel = () => {
    return (
      <div
        ref={ref}
        className={`flex flex-col w-full h-full bg-background text-contentColor rounded-md`}
        key={panel.id}
      >
        <GraphHeader panel={panel} disableZoom />
        <div className="flex w-full flex-grow justify-center items-center">
          <h1 className="text-base">No Data Available</h1>
        </div>
      </div>
    );
  };

  return (
    <PanelErrorProvider value={{ panelError, setPanelError }}>
      <RenderPanel
        forwardRef={ref}
        panel={panel}
        panelData={_panelData || blueprintPanelData}
        renderNoDataPanel={renderNoDataPanel}
        onChangeBoundingBox={onChangeBoundingBox}
        inCarousel={inCarousel}
        boundingBox={boundingBox.current}
        geoMapDevicesWithShadows={deviceWithShadows}
      />
    </PanelErrorProvider>
  );
});

const RenderPanel = ({
  panel,
  panelData,
  forwardRef,
  renderNoDataPanel,
  onChangeBoundingBox,
  inCarousel,
  boundingBox,
  geoMapDevicesWithShadows
}) => {
  if (panel.panel_type === DASH_PANEL_TYPE.PIE_CHART) {
    let data = [],
      category = "count",
      index = "value";

    if (panelData?.options) {
      data = panelData?.options;
      if (!panelData.options?.length) {
        return renderNoDataPanel();
      }
    } else if (Array.isArray(panelData) && panelData?.length) {
      data = panelData;
      category = "count";
      index = "name";
    }
    if (data.length) {
      return (
        <InnerPanelWrapper panel={panel}>
          <PieChart
            data={data}
            category={category}
            index={index}
            title={
              Object.hasOwn(panel.data_config, "source")
                ? index
                : panel.data_config["group"][0]
            }
            variant="donut"
            key={panel.id}
            colors={panel.definition.options.label_colors}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (
    panel.panel_type === DASH_PANEL_TYPE.GAUGE &&
    panelData?.current_value?.value &&
    panelData?.max_value?.value
  ) {
    if (panel.definition.options["gauge-type"] === "v") {
      return (
        <InnerPanelWrapper panel={panel}>
          <TempMeter
            max={panel.definition.options.max_display}
            min={panel.definition.options.min_display}
            title={panel.title}
            minValue={panelData?.current_value?.value}
            maxValue={panelData?.max_value?.value}
            units={panel.definition.options.unit}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
    return (
      <InnerPanelWrapper panel={panel}>
        <Gauge
          max={panel.definition.options.max_display}
          min={panel.definition.options.min_display}
          title={panel.title}
          minValue={panelData?.current_value?.value}
          maxValue={panelData?.max_value?.value}
          units={panel.definition.options.unit}
          key={panel.id}
          zoom={panel.definition.zoom}
        />
      </InnerPanelWrapper>
    );
  }

  if (
    panel.panel_type === DASH_PANEL_TYPE.GAUGE &&
    Array.isArray(panelData) &&
    panelData?.length
  ) {
    if (panelData[0]?.current_value === null) {
      return renderNoDataPanel();
    }
    if (panel.definition.options["gauge-type"] === "v") {
      return (
        <InnerPanelWrapper panel={panel}>
          <TempMeter
            max={panel.definition.options.max_display}
            min={panel.definition.options.min_display}
            title={panel.title}
            minValue={panelData[0]?.current_value}
            maxValue={panelData[0]?.max_value}
            units={panel.definition.options.unit}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
    return (
      <InnerPanelWrapper panel={panel}>
        <Gauge
          max={panel.definition.options.max_display}
          min={panel.definition.options.min_display}
          title={panel.title}
          minValue={panelData[0]?.current_value}
          maxValue={panelData[0]?.max_value}
          units={panel.definition.options.unit}
          key={panel.id}
          zoom={panel.definition.zoom}
        />
      </InnerPanelWrapper>
    );
  }

  if (
    panel.panel_type === DASH_PANEL_TYPE.LINE_CHART &&
    panel.definition.meta?.type === "Info"
  ) {
    const infoKeys = Object.keys(panelData ?? {});
    const filter = panel.definition.default_range?.start;
    if (infoKeys?.length && !_.isEmpty(panelData)) {
      const resultantValue = getInfographicSum(panelData[infoKeys[0]], filter);
      return (
        <InnerPanelWrapper panel={panel}>
          <NumericInfoGraph
            title={panel.title}
            info={resultantValue}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === DASH_PANEL_TYPE.LINE_CHART) {
    let categories: string[],
      chartData: any,
      index: "value" | "time",
      dataAvailable: boolean;

    let colors = panel.definition.options.label_colors;

    if (panel.data_config.source) {
      const labels = Object.keys(panel.data_config?.source ?? {});
      const dataObj = { value: "" };
      let labelToParam = {};
      labels.forEach((s) => {
        labelToParam[s] = panel.data_config.source[s]["data-point-param"];
        dataObj[labelToParam[s]] = "";
      });
      const { isData, processedData } = generateGraphData(
        panelData || {},
        labels,
        dataObj
      );
      dataAvailable = isData;
      categories = labels;
      chartData = processedData;
      index = "value";
    } else {
      categories = panel.data_config?.select
        .filter((col) => col.alias !== "time")
        .map((col) => col.alias ?? col.param);
      chartData = panelData?.map((data) => ({
        ...data,
        time: dateService.convertToUTC2(data.time).format("DD/MM HH:mm:ss")
      }));
      index = "time";
      dataAvailable = panelData?.length;
    }

    if (dataAvailable) {
      return (
        <InnerPanelWrapper panel={panel}>
          <LineChart
            chartdata={chartData}
            categories={categories}
            colors={colors}
            index={index}
            title={panel.title}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === DASH_PANEL_TYPE.BAR_CHART) {
    let categories: string[],
      chartData: any,
      index: "value" | "time",
      dataAvailable: boolean;
    let colors = panel.definition.options.bar_colors;

    if (panel.data_config.source) {
      const sources = Object.keys(panel.data_config.source);
      const dataObj = { value: "" };
      let definations = {};
      sources.forEach((s) => {
        definations[s] = panel.data_config.source[s]["data-point-param"];
        dataObj[definations[s]] = "";
      });
      const { isData, processedData } = generateGraphData(
        panelData || {},
        sources,
        dataObj
      );
      dataAvailable = isData;
      categories = sources;
      chartData = processedData;
      index = "value";
    } else {
      categories = panel.data_config?.select
        .filter((col) => col.alias !== "time")
        .map((col) => col.alias ?? col.param);
      chartData = panelData?.map((data) => ({
        ...data,
        time: dateService.convertToUTC2(data.time).format("DD/MM")
      }));
      index = "time";
      dataAvailable = panelData?.length;
    }

    if (dataAvailable) {
      return (
        <InnerPanelWrapper panel={panel}>
          <BarChart
            orientation={panel.definition.options.orientation}
            chartdata={chartData}
            categories={categories}
            colors={colors}
            index={index}
            title={panel.title}
            key={panel.id}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === DASH_PANEL_TYPE.NUMERIC) {
    if (Array.isArray(panelData) && panelData?.length) {
      const resultantValue = panelData[0].value;
      let minVal: number, maxVal: number;

      if (panelData[0].min) {
        minVal = panelData[0].min;
      }

      if (panelData[0].max) {
        maxVal = panelData[0].max;
      }

      return (
        <InnerPanelWrapper panel={panel}>
          <NumericInfoGraph
            title={panel.title}
            info={resultantValue}
            unit={panel.definition.options?.display_unit}
            zoom={panel.definition.zoom}
            minValue={minVal ?? null}
            maxValue={maxVal ?? null}
          />
        </InnerPanelWrapper>
      );
    } else {
      const infoKeys = Object.keys(panelData || {});
      if (infoKeys?.length && !_.isEmpty(panelData)) {
        const resultantValue = panelData[infoKeys[0]].value;
        return (
          <InnerPanelWrapper panel={panel}>
            <NumericInfoGraph
              title={panel.title}
              info={resultantValue}
              unit={panel.definition.options?.display_unit}
              zoom={panel.definition.zoom}
              minValue={panelData[infoKeys[0]]?.min_value ?? undefined}
              maxValue={panelData[infoKeys[0]]?.max_value ?? undefined}
            />
          </InnerPanelWrapper>
        );
      }
    }
  }

  if (panel.panel_type === DASH_PANEL_TYPE.GEO_MAP) {
    return (
      <InnerPanelWrapper panel={panel}>
        <MapView
          devices={panelData?.devices || []}
          definition={panel.definition}
          clusters={panelData?.clusters || []}
          bounding_box={boundingBox}
          title={panel.title}
          key={panel.id}
          onChangeBoundingBox={onChangeBoundingBox}
          zoom={panel.definition.zoom}
        />
      </InnerPanelWrapper>
    );
  }

  if (panel.panel_type === DASH_PANEL_TYPE.HEATMAP) {
    if (Array.isArray(panelData) && panelData?.length) {
      const transformedData = {
        [panel.definition.options.param?.value]: panelData.map((d) => ({
          ...d,
          value: {
            [panel.definition.options.aggregationMode]:
              // FIXME: due to some problems with migration, "value" was not set as an alias
              // this covers new heatmaps plus old ones with the wrong config
              d.value || d[panel.definition.options.param?.value]
          }
        }))
      };

      return (
        <InnerPanelWrapper panel={panel}>
          <HeatMap
            title={panel.title}
            configValues={panel.definition.options}
            data={transformedData}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    } else if (
      panelData &&
      Object.keys(panelData).length &&
      panel.definition.options.param &&
      panelData[panel.definition.options.param?.value]?.length
    ) {
      return (
        <InnerPanelWrapper panel={panel}>
          <HeatMap
            title={panel.title}
            configValues={panel.definition.options}
            data={panelData}
            zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    }
  }

  if (panel.panel_type === "GENERIC") {
    if (panel.definition.panel_type === "NUMERIC") {
      const infoKeys = Object.keys(panelData || {});
      if (infoKeys?.length && !_.isEmpty(panelData)) {
        const resultantValue =
          panelData[0].count * (panel.definition.factor || 1);
        return (
          <InnerPanelWrapper panel={panel}>
            <NumericInfoGraph
              title={panel.title}
              info={resultantValue}
              unit={panel.definition.options?.display_unit}
              zoom={panel.definition.zoom}
              minValue={panelData[0].minValue}
              maxValue={panelData[0].maxValue}
            />
          </InnerPanelWrapper>
        );
      }
    } else if (panel.definition.panel_type === "STATIONS") {
      return (
        <InnerPanelWrapper panel={panel}>
          <DashMapStationsComponent
          // devices={panelData?.devices || []}
          // clusters={panelData?.clusters || []}
          // bounding_box={boundingBox}
          // title={panel.title}
          // key={panel.id}
          // onChangeBoundingBox={onChangeBoundingBox}
          // zoom={panel.definition.zoom}
          />
        </InnerPanelWrapper>
      );
    } else if (panel.definition.panel_type === "GAS") {
      const resultantValue = panelData?.[0].value;
      let minVal: number, maxVal: number;

      if (panelData?.[0].min) {
        minVal = panelData?.[0].min;
      }

      if (panelData?.[0].max) {
        maxVal = panelData?.[0].max;
      }

      return (
        <InnerPanelWrapper panel={panel}>
          <NumericGasGraph
            title={panel.title}
            info={resultantValue}
            unit={"Current Volume"}
            zoom={panel.definition.zoom}
            minValue={minVal}
            maxValue={maxVal}
          />
        </InnerPanelWrapper>
      );
    } else {
      // FIXME: add else if for table
      if (
        panelData &&
        Object.keys(panelData).length &&
        panel.definition.columnState
      ) {
        return (
          <InnerPanelWrapper panel={panel}>
            <TableComponent
              title={panel.title}
              data={panelData || []}
              panelColumnDefs={panel.definition.columnState}
              zoom={panel.definition.zoom}
            />
          </InnerPanelWrapper>
        );
      }
    }
  }

  return renderNoDataPanel();
};

const InnerPanelWrapper = ({ panel, children }) => {
  return (
    <div className={` w-full h-full bg-background rounded-md`}>
      <GraphHeader panel={panel} />
      <div
        className="h-[92%]"
        style={{
          zoom: panel.definition.zoom
            ? panel.definition.zoom.toString() + "%"
            : "100%"
        }}
      >
        {children}
      </div>
    </div>
  );
};

function formatDuration(duration) {
  let _duration: any;
  if (duration.includes("s")) _duration = "seconds";
  else if (duration.includes("m")) _duration = "minutes";
  else _duration = "hours";

  return _duration;
}

export default PanelWrapper;
