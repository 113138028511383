import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRouter from "./app/app-router.component";
import { ErrorBoundary } from "./app/shared/components";
import OverlayLoader from "./app/shared/components/overlay-loader.component";
import { DEFAULT_LOCALE, LOCALES, messages } from "./i18n";
import reportWebVitals from "./reportWebVitals";
import loggerService from "./services/logger/logger.service";
import OverlayShadowEditorModal from "./app/shared/components/overlay-shadow-modal.component";
import OverlayDatapointEditorModal from "./app/shared/components/overlay-datapoint-modal.component";
import { ConfirmDeleteProvider } from "./app/shared/hooks/use-confirm-delete.hooks";
import ConfirmDeleteModal from "./app/shared/components/confirm-delete.component";
import { NanoPbFilesGenerator } from "./app/pyscript";
import { TourProvider } from "@reactour/tour";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

// Initilize internationalization.
// @TODO: Explain how to add new language, and how to change default language.
// @shreyas-zagare159
function getUserLocale(): string {
  let userLocale =
    (navigator.languages && navigator.languages[0]) || navigator.language;

  if (!userLocale) {
    return DEFAULT_LOCALE;
  }

  userLocale = userLocale.split("-")[0];
  const isValid = Object.keys(LOCALES).some(
    (key) => LOCALES[key] === userLocale
  );
  return isValid ? userLocale : DEFAULT_LOCALE;
}

const locale = getUserLocale();

// All components are rendered inside the `root` div. This is the entry point of the application.
// This root div is present in the `public/index.html` file.
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
/**
 * StrictMode is a tool for highlighting potential problems in an application.
 * ErrorBoundary is a component that catches errors and displays a fallback UI.
 * BrowserRouter is a component that wraps the application and provides a wrapper for routing.
 * ToastContainer is a component for notifications.
 * AppRouter is the main component of the application. Here we define all the routes.
 */
root.render(
  <QueryClientProvider client={queryClient} contextSharing={true} react-query>
    {/* <React.StrictMode> */}
    <ErrorBoundary fallbackUI={true}>
      <ConfirmDeleteProvider>
        <IntlProvider
          locale={locale}
          defaultLocale={DEFAULT_LOCALE}
          messages={messages[locale]}
        >
          <TourProvider steps={[]}>
            <BrowserRouter>
              <OverlayShadowEditorModal />
              <OverlayDatapointEditorModal />
              <OverlayLoader />
              <ConfirmDeleteModal />
              <AppRouter />
              <ToastContainer
                theme="colored"
                bodyClassName="m-0 py-0"
                toastClassName="text-sm font-medium text-gray-800"
                pauseOnFocusLoss={false}
              />
              <NanoPbFilesGenerator />
            </BrowserRouter>
          </TourProvider>
        </IntlProvider>
      </ConfirmDeleteProvider>
    </ErrorBoundary>
    {/* </React.StrictMode> */}
  </QueryClientProvider>
);

// register global(window) error handler
window.onerror = function (
  event: Event | string,
  source?: string,
  lineno?: number,
  colno?: number,
  error?: Error
) {
  loggerService.error("window.onerror", event, source, lineno, colno, error);
  return false;
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
