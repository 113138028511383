import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const deleteArtifact = async (
  projectId: string,
  orgId: string,
  releaseId: string,
  artifactId: string
) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `projects/${projectId}/ota_releases/${releaseId}/artifacts/${artifactId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteArtifact = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: { releaseId: string; artifactId: string }) =>
      await deleteArtifact(
        selectedProject.id,
        user.selectedOrg.id,
        data.releaseId,
        data.artifactId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getArtifacts");
      }
    }
  );

  return res;
};
