import React from "react";
import { Table } from "@tanstack/react-table";
import Select from "react-select";

const PAGE_SIZE_OPTS = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 }
];

interface IPagination<T> {
  table?: Table<T>;
  setPage?: (p: number | ((p: number) => number)) => void;
  setPageSize?: (pSize: number | ((pSize: number) => number)) => void;
  meta?: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

function Pagination<T>({ table, meta, setPage, setPageSize }: IPagination<T>) {
  return (
    <div className="flex my-4 pb-4 justify-center items-center gap-2 w-full">
      <button
        type="button"
        className="border border-background-layer0.5 rounded px-2 p-1 disabled:bg-background-layer1 disabled:cursor-not-allowed"
        onClick={() => {
          setPage ? setPage(1) : table.setPageIndex(0);
        }}
        disabled={meta ? !(meta.currentPage > 1) : !table.getCanPreviousPage()}
      >
        {"<<"}
      </button>
      <button
        type="button"
        className="border border-background-layer0.5 rounded px-2 p-1 disabled:bg-background-layer1 disabled:cursor-not-allowed"
        onClick={() => {
          setPage ? setPage((p) => p - 1) : table.previousPage();
        }}
        disabled={meta ? !(meta.currentPage > 1) : !table.getCanPreviousPage()}
      >
        {"<"}
      </button>
      <button
        type="button"
        className="border border-background-layer0.5 rounded px-2 p-1 disabled:bg-background-layer1 disabled:cursor-not-allowed"
        onClick={() => {
          setPage ? setPage((p) => p + 1) : table.nextPage();
        }}
        disabled={
          meta
            ? !(meta.currentPage < meta.totalPages)
            : !table.getCanNextPage()
        }
      >
        {">"}
      </button>
      <button
        type="button"
        className="border border-background-layer0.5 rounded px-2 p-1 disabled:bg-background-layer1 disabled:cursor-not-allowed"
        onClick={() =>
          setPage
            ? setPage(meta.totalPages)
            : table.setPageIndex(table.getPageCount() - 1)
        }
        disabled={
          meta
            ? !(meta.currentPage < meta.totalPages)
            : !table.getCanNextPage()
        }
      >
        {">>"}
      </button>
      <span className="flex items-center gap-1">
        <div>Page</div>
        <strong>
          {setPage
            ? meta.currentPage
            : table.getState().pagination.pageIndex + 1}{" "}
          of {meta ? meta.totalPages : table.getPageCount()}
        </strong>
      </span>
      <span className="flex items-center gap-1">
        | Go to page:
        <input
          type="number"
          value={
            setPage
              ? meta.currentPage
              : table.getState().pagination.pageIndex + 1
          }
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            setPage ? setPage(page + 1) : table.setPageIndex(page);
          }}
          min={0}
          max={meta ? meta.totalPages : table.getPageCount()}
          className="border bg-background border-background-layer3 py-1 px-3 rounded w-16"
        />
      </span>
      <Select
        className="w-20"
        menuPlacement="top"
        onChange={(val) => {
          setPage ? setPage(1) : table.setPageIndex(1);
          setPageSize
            ? setPageSize(Number(val.value))
            : table.setPageSize(Number(val.value));
        }}
        value={PAGE_SIZE_OPTS.find(
          (opt) =>
            opt.value ===
            (meta ? meta.itemsPerPage : table.getState().pagination.pageSize)
        )}
        options={PAGE_SIZE_OPTS}
        classNames={{
          menu: () => "!bg-background-layer1 !text-contentColor",
          control: () =>
            "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
          valueContainer: () => "!text-contentColor",
          singleValue: () => "!text-contentColor",
          menuList: () => "!text-contentColor",
          option: () =>
            "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
          noOptionsMessage: () => "!text-contentColor !bg-background-layer1",
          multiValue: () => "!bg-background-layer3 !text-contentColor",
          multiValueLabel: () => "!text-contentColor"
        }}
      />
    </div>
  );
}

export default Pagination;
