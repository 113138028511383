import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface ICreateRuleResponse {
  data: {
    rule_id: {
      id: string;
    };
  };
  ok: number;
}

export const createRule = async (
  projectId: string,
  orgId: string,
  payload: any
) => {
  const { data, ok } = await networkService.post<ICreateRuleResponse>(
    `projects/${projectId}/rules/`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.rule_id.id;
  } else return null;
};

export const useCreateRule = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: any) =>
      await createRule(selectedProject.id, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getRules");
      }
    }
  );

  return res;
};
