import { ActionEditorNode } from "./nodes/action-editor-node";
import { ActionSequenceNode } from "./nodes/action-squence-node";
import { HTTPResponseNodeComponent } from "./nodes/http-response-node";
import { RuleEditorNode } from "./nodes/rule-editor-node";
import { RuleTriggerTypeNode } from "./nodes/rule-trigger-type-node";

export const nodeTypes = {
  httpResponseNode: HTTPResponseNodeComponent,
  actionEditorNode: ActionEditorNode,
  ruleEditorNode: RuleEditorNode,
  actionSequenceNode: ActionSequenceNode,
  ruleTriggerTypeNode: RuleTriggerTypeNode
};

export enum NodeType {
  httpResponseNode = "httpResponseNode",
  actionEditorNode = "actionEditorNode",
  ruleEditorNode = "ruleEditorNode",
  actionSequenceNode = "actionSequenceNode",
  ruleTriggerTypeNode = "ruleTriggerTypeNode"
}
