import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface ICreateReleasePayload {
  props: {
    release_name: string;
    device_types: string;
    target_fleets?: string;
    target_devices?: string;
  };
}

export const createRelease = async (
  projectId: string,
  orgId: string,
  payload: ICreateReleasePayload
) => {
  const { data, ok } = await networkService.post<{
    data: { ota_release_id: string };
    ok: number;
  }>(`projects/${projectId}/ota_releases`, payload, {
    headers: {
      "ORG-ID": orgId
    }
  });

  if (ok) {
    return data.ota_release_id;
  } else return null;
};

export const useCreateRelease = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateReleasePayload) =>
      await createRelease(selectedProject.id, user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getReleases");
      }
    }
  );

  return res;
};
