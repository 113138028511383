import React from "react";

interface IWalkthroughItem {
  title: string;
  content: string | React.ReactNode;
  img?: string;
}

const WalkthroughItem: React.FC<IWalkthroughItem> = ({
  content,
  title,
  img
}) => {
  return (
    <div className="flex flex-col items-center justify-cente border-black ">
      {img ? (
        <img
          src={img}
          alt={title}
          style={{
            border: "1px solid #bbb"
          }}
          className="w-auto max-h-36 rounded-sm"
        />
      ) : null}

      <div className="mt-2">
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-sm">{content}</p>
      </div>
    </div>
  );
};

export default WalkthroughItem;
