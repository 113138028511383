import { NodeType } from "@app/rule-engine/node-types.helper";
import { ICondition } from "@app/rule-engine/rule-engine.helper";
import { VariableInput } from "@app/rule-engine/variable-input.component";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import { Handle, Position, ReactFlowInstance } from "reactflow";

interface IConditionItemProps {
  ind: number;
  condition: ICondition;
  reactFlow: ReactFlowInstance;
  setConditions: (
    cond: ICondition[] | ((cond: ICondition[]) => ICondition[])
  ) => void;
  invalidConnTimer: any;
  setInvalidConnectionTooltipOpen: (val: boolean) => void;
  setElements: any;
}

export const ConditionsItem: React.FC<IConditionItemProps> = ({
  ind,
  setConditions,
  condition,
  reactFlow,
  setInvalidConnectionTooltipOpen,
  invalidConnTimer,
  setElements
}) => {
  return (
    <div
      className={`flex relative flex-col gap-2 border nodrag p-2 rounded-sm border-background-layer3`}
    >
      <div className="flex justify-between items-center">
        <span>Condition {ind + 1}</span>
        <Button
          icon={TrashIcon}
          variant="light"
          color="red"
          size="xs"
          onClick={() => {
            setElements((e) => ({
              nodes: reactFlow.getNodes(),
              edges: reactFlow.getEdges().filter((edge) => {
                return !edge.sourceHandle?.startsWith(`conditions-${ind}-`);
              })
            }));

            setConditions((prev) => {
              const _newConditions = [...prev];
              _newConditions.splice(ind, 1);

              return _newConditions;
            });
          }}
        />
      </div>
      <VariableInput
        placeholder="Statement"
        className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
        onChange={(val) => {
          setConditions((prev) => {
            const _newConditions = [...prev];
            const _newCondition = {
              ..._newConditions[ind],
              statement: val
            };
            _newConditions[ind] = _newCondition;
            return _newConditions;
          });
        }}
        value={condition.statement}
      />

      {condition.statement.trim() !== "" ? (
        <>
          {/* horizontal line */}
          <div className="bg-contentColorLight absolute h-[2px] w-6 -right-6 top-8" />
          {/* vertical line */}
          <div className="bg-contentColorLight absolute h-12 w-[2px] -right-6 top-2" />
          {/* true line */}
          <div className="bg-contentColorLight absolute h-[2px] w-[35px] -right-[3.6rem] top-2" />
          {/* false line */}
          <div className="bg-contentColorLight absolute h-[2px] w-[35px] -right-[3.6rem] top-[3.4rem]" />
          <div className="absolute -right-[3.3rem] top-[10px]">True</div>
          <div className="absolute -right-14 top-[57px]">False</div>

          <Handle
            id={`conditions-${ind}-true`}
            type="source"
            position={Position.Right}
            isValidConnection={(connection) => {
              const targetNode = reactFlow.getNode(connection.target);
              if (
                targetNode.type === NodeType.httpResponseNode ||
                targetNode.type === NodeType.actionSequenceNode
              ) {
                if (
                  ("trueResponse" in condition && condition.trueResponse) ||
                  ("trueSequence" in condition && condition.trueSequence)
                ) {
                  setInvalidConnectionTooltipOpen(true);
                  if (invalidConnTimer.current) {
                    clearTimeout(invalidConnTimer.current);
                    invalidConnTimer.current = null;
                  }
                  invalidConnTimer.current = setTimeout(() => {
                    setInvalidConnectionTooltipOpen(false);
                  }, 3000);
                  return false;
                }
              }

              setInvalidConnectionTooltipOpen(false);
              return true;
            }}
            style={{ top: 3, right: "-63px" }}
            isConnectable={true}
            data-tooltip-id="invalid-connection-tooltip"
            className="h-3 w-3 hover:scale-150 transition-transform transform duration-100 bg-contentColorLight"
          />

          <Handle
            id={`conditions-${ind}-false`}
            type="source"
            position={Position.Right}
            isValidConnection={(connection) => {
              const targetNode = reactFlow.getNode(connection.target);
              if (
                targetNode.type === NodeType.httpResponseNode ||
                targetNode.type === NodeType.actionSequenceNode
              ) {
                if (
                  ("falseResponse" in condition && condition.falseResponse) ||
                  ("falseSequence" in condition && condition.falseSequence)
                ) {
                  setInvalidConnectionTooltipOpen(true);
                  if (invalidConnTimer.current) {
                    clearTimeout(invalidConnTimer.current);
                    invalidConnTimer.current = null;
                  }
                  invalidConnTimer.current = setTimeout(() => {
                    setInvalidConnectionTooltipOpen(false);
                  }, 3000);
                  return false;
                }
              }

              return true;
            }}
            style={{ top: 50, right: "-63px" }}
            isConnectable={true}
            data-tooltip-id="invalid-connection-tooltip"
            className="h-3 w-3 hover:scale-150 transition-transform transform duration-100 bg-contentColorLight"
          />
        </>
      ) : null}
    </div>
  );
};
