import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";

interface IShadowState {
  shadow: string;
  datapoint: string;
  maximizeShadow: { state: boolean; readOnly?: boolean };
  maximizeDatapoint: { state: boolean; readOnly?: boolean };
  setShadow: (x: string) => void;
  setDatapoint: (x: string) => void;
  setMaximizeShadow: (x: { state: boolean; readOnly?: boolean }) => void;
  setMaximizeDatapoint: (x: { state: boolean; readOnly?: boolean }) => void;
}

const useShadowStore = create<IShadowState>()(
  persist(
    devtools(
      (set) => ({
        shadow: "",
        datapoint: "",
        maximizeShadow: { state: false, readOnly: undefined },
        maximizeDatapoint: { state: false, readOnly: undefined },
        setShadow: (customString) =>
          set(
            produce((state) => {
              state.shadow = customString;
            }),
            false
          ),
        setDatapoint: (customString) =>
          set(
            produce((state) => {
              state.datapoint = customString;
            }),
            false
          ),
        setMaximizeShadow: (status) =>
          set(
            produce((state) => {
              state.maximizeShadow = status;
            }),
            false
          ),
        setMaximizeDatapoint: (status) =>
          set(
            produce((state) => {
              state.maximizeDatapoint = status;
            }),
            false
          )
      }),
      { name: "shadow-and-datapoint", serialize: { options: true } }
    ),
    {
      name: "shadow-and-datapoint", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useShadowStore;
