import { useEffect } from "react";
import { useAuthStore } from "../../../store";
import DeploymentSuggestionPanel from "../components/ota-create-deployment-suggestion-panel.component";
import DeploymentCreationSteps from "../components/ota-deployment-creation-steps.component";
import { Link, useNavigate } from "react-router-dom";
import useOTAReleaseStore from "../../../store/ota/ota-release.store";
import dateService from "../../../services/date.service";
import { useCreateReleaseDeployment } from "@app/shared/hooks/post/create-release-deployment";

const SetDeployDetails = () => {
  const navigate = useNavigate();
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);

  const [createDeploymentStepsData, setCreateDeploymentStepsData] =
    useOTAReleaseStore((state) => [
      state.createDeploymentStepsData,
      state.setCreateDeploymentStepsData
    ]);

  useEffect(() => {
    updateAuthUser({ deploymentCreationStatus: "2" });
  }, [updateAuthUser]);

  const createDeploymentMutation = useCreateReleaseDeployment();

  const handleSubmit = async () => {
    const stringFleets = createDeploymentStepsData.targetFleets.map(
      (fleet) => fleet.value
    );

    const payload = {
      ota_release_id: createDeploymentStepsData.release.value,
      scheduled_at: createDeploymentStepsData.scheduledTime,
      props: {
        deployment_name: createDeploymentStepsData.deploymentName,
        max_retries: createDeploymentStepsData.maxRetries,
        retry_interval: createDeploymentStepsData.retryInterval,
        target_fleets: stringFleets.toString()
      }
    };

    createDeploymentMutation.mutate(payload, {
      onSuccess: () => {
        setCreateDeploymentStepsData({});
        navigate("/ota-updates/projects/deployment");
      }
    });
  };

  return (
    <>
      <>
        <div className="flex w-full h-full">
          <div className="w-5/12">
            <div className="mt-7 mb-5 w-10/12">
              <DeploymentCreationSteps />
            </div>
            <div className="flex flex-col flex-1">
              <div>
                <h1 className="text-lg text-left font-medium mb-2.5">
                  Deploy details
                </h1>

                <div className="flex-1 w-10/12">
                  <h1 className="text-left text-base font-medium mb-2.5">
                    General
                  </h1>

                  <div className="flex flex-col my-5">
                    <div className="flex w-full mb-3">
                      <div className="w-full">
                        <label className="font-medium text-xs text-[#B7B9C1] mb-2">
                          Release
                        </label>
                        <div className="font-normal text-sm mb-2">
                          {createDeploymentStepsData?.release?.label}
                        </div>
                      </div>

                      <div className="w-full">
                        <label className="font-medium text-xs text-[#B7B9C1] mb-2">
                          Device type
                        </label>
                        <div className="font-normal text-sm mb-2">
                          Device abc
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-full">
                        <label className="font-medium text-xs text-[#B7B9C1] mb-2">
                          Device Group
                        </label>
                        <div className="font-normal text-sm mb-2">
                          12 group
                        </div>
                      </div>

                      <div className="w-full">
                        <label className="font-medium text-xs text-[#B7B9C1] mb-2">
                          No. of retry
                        </label>
                        <div className="font-normal text-sm mb-2">
                          {createDeploymentStepsData?.maxRetries}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-3.5 border border-solid border-[#E8E8E8]"></div>

                  <h1 className="text-left text-base font-medium mb-2.5">
                    Schedule
                  </h1>

                  <div className="flex flex-col my-5">
                    <div className="flex w-full mb-3">
                      <div className="w-full">
                        <label className="font-medium text-xs text-[#B7B9C1] mb-2">
                          Created at
                        </label>
                        <div className="font-normal text-sm mb-2">
                          {dateService.convertDateObjToISOString(
                            createDeploymentStepsData?.scheduledTime
                          )}
                        </div>
                      </div>

                      <div className="w-full">
                        <label className="font-medium text-xs text-[#B7B9C1] mb-2">
                          Start time
                        </label>
                        <div className="font-normal text-sm mb-2">
                          {dateService.convertDateObjToISOString(
                            createDeploymentStepsData?.scheduledTime
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-full">
                        <label className="font-medium text-xs text-[#B7B9C1] mb-2">
                          End time
                        </label>
                        <div className="font-normal text-sm mb-2">
                          {dateService.convertDateObjToISOString(
                            createDeploymentStepsData?.scheduledTime
                          )}
                        </div>
                      </div>

                      {/* <div className="w-full">
                                                <label className="font-medium text-xs text-[#B7B9C1] mb-2">Rollout pattern</label>
                                                <div className="font-normal text-sm mb-2">Single phase: 100%</div>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-10/12  flex items-center mt-12 space-x-4">
                <Link
                  to="/ota-updates/projects/deployment/create-deployment/rollout-schedule"
                  className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
                >
                  Back
                </Link>
                <button
                  onClick={handleSubmit}
                  className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                >
                  Done
                </button>
              </div>
            </div>
          </div>

          <div className="w-7/12">
            <DeploymentSuggestionPanel />
          </div>
        </div>
      </>
    </>
  );
};

export default SetDeployDetails;
