import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";

export interface IOrgUser {
  user_id: string;
  email: string;
  permission: string;
  invite_id: string | null;
  last_active_at: string;
  joined: string;
}

interface IUsersResponse {
  ok: number;
  data: IOrgUser[];
}

export const getUsersInOrg = async (orgId: string, params) => {
  const { data, ok } = await networkService.get<IUsersResponse>(
    `organizations/users`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data || [];
  } else return null;
};

export const useGetUsersInOrg = (
  params: any = {},
  successCb: ((data: IOrgUser[]) => void) | null = null
) => {
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getUsersInOrg", user.selectedOrg.id, params],
    async () => {
      const users = await getUsersInOrg(user.selectedOrg.id, params);

      successCb && successCb(users);

      return users;
    },
    {
      enabled: !!user.selectedOrg.id
    }
  );

  return res;
};
