import { IServerQuery } from "@/app/query-builder/query-builder.helper";

export interface IPanel {
  id: string;
  blueprint_id?: string;
  panel_description: string;
  panel_type: DASH_PANEL_TYPE;
  title: string;
  definition: any;
  data_config: IServerQuery;
  dashboard_id: string;
  project_id: string;
  org_id: string;
  created_at: string;
}

export enum DASH_PANEL_TYPE {
  HEATMAP = "HEATMAP",
  NUMERIC = "NUMERIC",
  PIE_CHART = "PIE_CHART",
  LINE_CHART = "LINE_CHART",
  BAR_CHART = "BAR_CHART",
  GAUGE = "GAUGE",
  GAUGE_1 = "GAUGE_1",
  GAUGE_2 = "GAUGE_2",
  TABLE = "TABLE",
  GEO_MAP = "GEO_MAP",
  GENERIC = "GENERIC"
}

export const barOrientationOptions = [
  { value: "horizontal", label: "Horizontal" },
  { value: "vertical", label: "Vertical" }
];

export type TConfigurableParams =
  | "parameters"
  | "timePeriod"
  | "aggregate"
  | "timeBucket";

export type TGraphDefinitions = Record<
  string,
  {
    primarySource: string;
    maxParamsLength: number;
    datatype: "number" | "enum";
    hideConfigs: TConfigurableParams[];
  }
>;
