import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export interface IOrgConsumer {
  consumer_id: string;
  last_seen: string;
  issuer: string;
  created_at: string;
}

interface IUsersResponse {
  ok: number;
  data: {
    users: IOrgConsumer[];
  };
}

export const getConsumersInOrg = async (
  projectId: string,
  orgId: string,
  params
) => {
  const { data, ok } = await networkService.get<IUsersResponse>(
    `/projects/${projectId}/consumers`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.users || [];
  } else return null;
};

export const useGetConsumersInOrg = (
  params: any = {},
  successCb: ((data: IOrgConsumer[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getConsumersInOrg", user.selectedOrg.id, selectedProject.id, params],
    async () => {
      const users = await getConsumersInOrg(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(users);

      return users;
    },
    {
      enabled: !!user.selectedOrg.id && !!selectedProject.id
    }
  );

  return res;
};
