import { Field } from "formik";
import Select from "react-select";
import {
  ChartBarIcon,
  Cog6ToothIcon,
  TrashIcon,
  VariableIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import "./table-form.style.css";
import { Badge, Color } from "@tremor/react";
import ColumnParamLabel from "./table-form-column-param-label.component";

interface IColumnOptions {
  [protoOption: string]: Color;
}

export interface IColumn {
  aggregationMode?: "sum" | "avg" | "min" | "max" | "count" | "first" | "last";
  parameter: {
    value: string;
    label: string;
    name: string;
    type: string;
    enumBadgeColours?: IColumnOptions;
    dataSourceName?: string;
    dataSource?: "dataPoint" | "shadow";
  };
  label: string;
}

interface IColumnItemProps {
  index: number;
  setColumns: any;
  paramOptions: any;
  allParams: any;
  setSelectedColumnToEdit: any;
  editOpen: boolean;
  createPanelAppearance: { columns: IColumn[] };
}

const badgeColors: Color[] = [
  "gray",
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose"
];

const badgeOptions = badgeColors.map((color) => ({
  value: color,
  label: <Badge color={color}>{color}</Badge>
}));

const aggregationParams = [
  { value: "avg", label: "Mean" },
  { value: "max", label: "Max" },
  { value: "min", label: "Min" },
  { value: "sum", label: "Sum" },
  { value: "last", label: "Last" },
  { value: "first", label: "First" },
  { value: "count", label: "Count" }
];

const ColumnItem: React.FC<IColumnItemProps> = ({
  index,
  setColumns,
  paramOptions,
  allParams,
  setSelectedColumnToEdit,
  editOpen,
  createPanelAppearance
}) => {
  if (!editOpen) {
    return (
      <div
        className="flex justify-between relative p-4 rounded-md border-gray-200"
        style={{ borderWidth: 1 }}
      >
        <div className="flex gap-2 flex-wrap">
          <Badge
            tooltip="Column Name"
            size="xs"
            color="neutral"
            style={{
              borderRadius: 6
            }}
          >
            {createPanelAppearance.columns[index].label}
          </Badge>
          <Badge
            tooltip={`Parameter (${
              createPanelAppearance.columns[index].parameter.value.split(
                "/"
              )[0]
            })`}
            size="xs"
            color="fuchsia"
            style={{
              borderRadius: 6
            }}
          >
            <div className="flex gap-1">
              <VariableIcon width={14} />
              {createPanelAppearance.columns[index].parameter.name}
            </div>
          </Badge>
          {createPanelAppearance.columns[index].parameter.type !==
            "string" && (
            <Badge
              tooltip="Aggregation Mode"
              size="xs"
              color="lime"
              style={{
                borderRadius: 6
              }}
            >
              <div className="flex gap-1">
                <ChartBarIcon width={12} />
                {
                  aggregationParams.find(
                    (el) =>
                      el.value ===
                      createPanelAppearance.columns[index].aggregationMode
                  ).label
                }
              </div>
            </Badge>
          )}
        </div>
        <div className="flex gap-1">
          <Cog6ToothIcon
            className="cursor-pointer"
            width={20}
            onClick={() => setSelectedColumnToEdit(index)}
          />
          <TrashIcon
            className="cursor-pointer"
            width={20}
            color="red"
            onClick={() => {
              const newCols = [...createPanelAppearance.columns].filter(
                (_, i) => i !== index
              );

              setColumns(newCols);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex relative flex-col p-4 rounded-md border-gray-200"
      style={{ borderWidth: 1 }}
    >
      <XMarkIcon
        className="absolute top-2 cursor-pointer right-10"
        width={20}
        onClick={() => setSelectedColumnToEdit(null)}
      />
      <TrashIcon
        className="absolute top-2 cursor-pointer right-4"
        width={20}
        color="red"
        onClick={() => {
          const newCols = [...createPanelAppearance.columns].filter(
            (_, i) => i !== index
          );

          setColumns(newCols);
        }}
      />
      <div className="w-full flex flex-row">
        <div className="w-6/12">
          <label className="text-sm font-medium text-gray-600">
            Column Name
          </label>
          <Field
            type="text"
            id="column-name"
            name="column-name"
            value={createPanelAppearance.columns[index].label}
            placeholder="Column Name"
            className="block w-10/12 h-10 p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
            onChange={(e) => {
              const newColumns = [...createPanelAppearance.columns].map(
                (col, i) => {
                  if (i === index) {
                    return {
                      ...col,
                      label: e.target.value
                    };
                  }
                  return col;
                }
              );
              setColumns(newColumns);
            }}
          />
        </div>
        <div className="w-6/12 create-table-column-param">
          <label className="text-sm font-medium text-gray-600">
            Parameter
          </label>
          <Field
            as="select"
            id={"table-column-parameter"}
            name={"table-column-parameter"}
            placeholder="Parameter"
            value={{
              ...createPanelAppearance.columns[index].parameter,
              label: (
                <ColumnParamLabel
                  paramName={
                    createPanelAppearance.columns[index].parameter.name
                  }
                  dataSourceName={
                    createPanelAppearance.columns[index].parameter
                      .dataSourceName
                  }
                  dataSource={
                    createPanelAppearance.columns[index].parameter.dataSource
                  }
                />
              )
            }}
            component={Select}
            maxMenuHeight={140}
            maxMenuWidth={270}
            menuStyle={{
              width: 270
            }}
            options={paramOptions}
            className={`block z-50 w-10/12 h-10 2xl:w-4/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
              false
                ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
            }`}
            onChange={(e) => {
              let newColumns = [...createPanelAppearance.columns].map(
                (col, i) => {
                  if (i === index) {
                    const newParam = allParams.find(
                      (param) => param.value === e.value
                    );
                    return {
                      ...col,
                      label:
                        newParam.type === "string"
                          ? newParam.name + "_freq"
                          : newParam.name,
                      parameter: newParam
                    };
                  }
                  return col;
                }
              );

              setColumns(newColumns);
            }}
          />
        </div>
      </div>

      {createPanelAppearance.columns[index].parameter.type !== "string" && (
        <div className="flex flex-row gap-4 mt-2">
          <div className="w-6/12">
            <label className="text-sm font-medium text-gray-600">
              Aggregation Mode
            </label>
            <Field
              as="select"
              id={"table-column-aggregation-mode"}
              name={"table-column-aggregation-mode"}
              placeholder="Aggregation Mode"
              defaultValue={aggregationParams[0]}
              value={aggregationParams.filter(
                (opt) =>
                  opt.value ===
                  createPanelAppearance.columns[index].aggregationMode
              )}
              component={Select}
              options={aggregationParams}
              styles={{}}
              maxMenuHeight={100}
              className={`block  mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                false
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
              }`}
              onChange={(e) => {
                let newColumns = [...createPanelAppearance.columns].map(
                  (col, i) => {
                    if (i === index) {
                      return {
                        ...col,
                        aggregationMode: e.value
                      };
                    }
                    return col;
                  }
                );

                setColumns(newColumns);
              }}
            />
          </div>
        </div>
      )}

      {createPanelAppearance.columns[index].parameter.type === "string" &&
        createPanelAppearance.columns[index].parameter.enumBadgeColours && (
          <div className="mt-4">
            <label className="text-sm mb-2 block font-medium text-gray-600">
              Badge Colors (Enum)
            </label>
            <div className="flex flex-wrap gap-4">
              {Object.keys(
                createPanelAppearance.columns[index].parameter.enumBadgeColours
              )?.map((opt, i) => (
                <div className="flex flex-col gap-1">
                  <div key={i}>
                    <label className="text-sm font-medium text-gray-600">
                      {opt}
                    </label>
                  </div>
                  <Field
                    as="select"
                    id={"table-column-enum-parameter-colour" + i}
                    name={"table-column-enum-parameter-colour" + i}
                    placeholder=""
                    defaultValue={badgeOptions[0]}
                    value={badgeOptions.filter(
                      (badgeOpt) =>
                        badgeOpt.value ===
                        createPanelAppearance.columns[index].parameter
                          .enumBadgeColours[opt]
                    )}
                    component={Select}
                    options={badgeOptions}
                    styles={{}}
                    maxMenuHeight={100}
                    className={`block rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                      false
                        ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                        : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                    }`}
                    onChange={(e) => {
                      let newColumns = [...createPanelAppearance.columns].map(
                        (col, i) => {
                          if (i === index) {
                            return {
                              ...col,
                              parameter: {
                                ...col.parameter,
                                enumBadgeColours: {
                                  ...col.parameter.enumBadgeColours,
                                  [opt]: e.value
                                }
                              }
                            };
                          }
                          return col;
                        }
                      );

                      setColumns(newColumns);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
    </div>
  );
};

export default ColumnItem;
