import React from 'react';

const Projects: React.FC = () => {
    return (
        <>
            Device Rules
        </>
    )
}

export default Projects;
