import { useQuery } from "react-query";

import { ILog, ILogsResponse } from "@interfaces/shared.interface";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

interface IGetLogsPayload {
  start: string;
  stop: string;
}

export const getLogs = async (projectId, orgId, payload) => {
  const { data, ok } = await networkService.get<ILogsResponse>(
    `projects/${projectId}/logs`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.logs;
  } else return null;
};

export const useGetLogs = (
  payload: IGetLogsPayload,
  params: any = {},
  successCb: ((data: ILog[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getLogs", selectedProject.id, user.selectedOrg.id, payload, params],
    async () => {
      const logs = await getLogs(
        selectedProject.id,
        user.selectedOrg.id,
        payload
      );
      successCb && successCb(logs);

      return logs;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id && !!payload
    }
  );

  return res;
};
