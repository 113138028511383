import { useState } from "react";
import useSettingsStore from "@store/settings/settings.store";
import { AddOverlayLayout, FieldError } from "../../shared/components";
import { Formik, Field, Form } from "formik";
import { IOption } from "@interfaces/index";
import Select from "react-select";
import { useCreateInvite } from "@app/shared/hooks/post/create-invite";
import { toast } from "react-toastify";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";
import { useFleetAndDevicesStore } from "@store/index";

interface IInputEntry {
  collabEmail: string;
  addTo: IOption;
  access: IOption;
  permissions: IOption[];
  orgPermissions: IOption[];
}

interface IInputError {
  collabEmail: string;
  access: string;
  addTo: string;
  permissions: string;
  orgPermissions: string;
}

const addToOptions: IOption[] = [
  { label: "TEAM", value: "TEAM" },
  { label: "ORGANIZATION", value: "ORGANIZATION" },
  { label: "PROJECT", value: "PROJECT" }
];

const orgAccessOptions: IOption[] = [
  { label: "ADMIN", value: "admin" },
  { label: "MEMBER", value: "member" }
];

const projAccessOptions: IOption[] = [
  { label: "ADMIN", value: "admin" },
  { label: "MEMBER", value: "member" }
];

const permissionOptions: IOption[] = [
  { label: "can_read_logs", value: "can_read_logs" },
  { label: "can_manage_context_defs", value: "can_manage_context_defs" },
  { label: "can_manage_shadow_defs", value: "can_manage_shadow_defs" },
  { label: "can_manage_data_defs", value: "can_manage_data_defs" },
  { label: "can_manage_rules", value: "can_manage_rules" },
  { label: "can_manage_dashboards", value: "can_manage_dashboards" },
  { label: "can_manage_certificates", value: "can_manage_certificates" },
  {
    label: "can_manage_consumer_settings",
    value: "can_manage_consumer_settings"
  },
  { label: "can_manage_devices", value: "can_manage_devices" },
  { label: "can_manage_ota_updates", value: "can_manage_ota_updates" }
];

const orgPermissionOptions: IOption[] = [
  { label: "MANAGE BILLINGS", value: "can_manage_billing" },
  { label: "CREATE PROJECTS", value: "can_create_projects" }
];

const CreateInvite = ({ initialValues = null }) => {
  const [showCreateInviteForm, setShowCreateInviteForm] = useSettingsStore(
    (state) => [state.showCreateInviteForm, state.setShowCreateInviteForm]
  );

  const selectedProject = useFleetAndDevicesStore(
    (state) => state.selectedProject
  );

  const [inputFieldValues, setInputFieldValues] = useState<IInputEntry>({
    collabEmail: "",
    addTo: {} as IOption,
    access: {} as IOption,
    permissions: [] as IOption[],
    orgPermissions: [] as IOption[]
  });

  const [errorMsgs, setErrorMsgs] = useState<IInputError>({
    collabEmail: "",
    access: "",
    addTo: "",
    permissions: "",
    orgPermissions: ""
  });

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleInput = (event: any, fieldId: string) => {
    const payload = { ...inputFieldValues };
    const errors = { ...errorMsgs };

    const selector = {
      access: true,
      resources: true,
      action: true,
      addTo: true,
      permissions: true,
      orgPermissions: true
    };

    if (selector[fieldId]) {
      payload[fieldId] = event;

      if (payload.addTo.value && fieldId === "addTo") {
        payload.access = {} as IOption;
      }

      if (errors[fieldId]) {
        errors[fieldId] = "";
        setErrorMsgs(errors);
      }
    } else {
      const value = event.target.value;

      if (!value.trim()) {
        const eMsg = { ...errorMsgs };
        eMsg[fieldId] = "Email is required";
        setErrorMsgs(eMsg);
      } else if (!validateEmail(value)) {
        const eMsg = { ...errorMsgs };
        eMsg[fieldId] = "Email is invalid";
        setErrorMsgs(eMsg);
      } else if (errorMsgs[fieldId]) {
        const eMsg = { ...errorMsgs };
        eMsg[fieldId] = "";
        setErrorMsgs(eMsg);
      }

      payload[fieldId] = value;
    }

    setInputFieldValues(payload);
  };

  const validateForm = () => {
    const errors = { ...errorMsgs };

    if (!inputFieldValues.collabEmail.trim()) {
      errors.collabEmail = "Email is required";
    } else {
      errors.collabEmail = "";
    }

    if (!inputFieldValues.addTo?.value?.trim()) {
      errors.addTo = "Select add to";
    } else {
      errors.addTo = "";
    }

    if (!inputFieldValues.access?.value) {
      errors.access = "Select access";
    } else {
      errors.access = "";
    }

    if (
      inputFieldValues.addTo?.value === "PROJECT" &&
      inputFieldValues.access?.value === "member" &&
      !inputFieldValues.permissions?.length
    ) {
      errors.permissions = "Select permissions";
    } else {
      errors.permissions = "";
    }

    if (
      inputFieldValues.addTo?.value === "ORGANIZATION" &&
      inputFieldValues.access?.value === "member" &&
      !inputFieldValues.orgPermissions?.length
    ) {
      errors.orgPermissions = "Select permissions";
    } else {
      errors.orgPermissions = "";
    }

    if (Object.values(errors).some((el) => !!el)) {
      setErrorMsgs(errors);
      return false;
    }

    return true;
  };

  const createInviteMutation = useCreateInvite();

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const addToProject = inputFieldValues.addTo.value === "PROJECT";

    const payload = {
      emails: [inputFieldValues.collabEmail],
      permissions:
        selectedProject?.id && inputFieldValues.permissions?.length
          ? [
              {
                resource_type: "projects",
                resource: selectedProject.id,
                actions:
                  inputFieldValues.access.value === "admin"
                    ? ["admin"]
                    : [
                        "member",
                        ...inputFieldValues.permissions.map(
                          (perm) => perm.value
                        )
                      ]
              }
            ]
          : [],
      org_access: addToProject ? "member" : inputFieldValues.access.value
    };

    if (
      inputFieldValues.addTo.value === "ORGANIZATION" &&
      inputFieldValues.access.value === "member"
    ) {
      payload["org_permissions"] = inputFieldValues.orgPermissions.map(
        (perm) => perm.value
      );
    }

    createInviteMutation.mutate(payload, {
      onSuccess: () => {
        toast.success("Invited Successfully");
        // Clear fields
        setInputFieldValues({} as IInputEntry);
        setErrorMsgs({} as IInputError);
        setShowCreateInviteForm(false);
      }
    });
  };

  return (
    <AddOverlayLayout
      title="Invite a Collaborator"
      overlayOpen={showCreateInviteForm}
      setOverlayOpen={setShowCreateInviteForm}
    >
      <div className="flex flex-col h-full items-center">
        {showCreateInviteForm && (
          <Formik
            initialValues={{
              collaboratorEmail: ""
            }}
            onSubmit={handleSubmit}
          >
            <Form className="w-full flex flex-col flex-1">
              <div className="w-full flex flex-col max-h-[70vh] overflow-y-auto flex-1 mt-4 px-1">
                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Email of collaborator
                  </label>

                  <Field
                    type="email"
                    id="collabEmail"
                    name="collabEmail"
                    placeholder="Email of collaborator"
                    value={inputFieldValues.collabEmail}
                    onChange={(e: any) => handleInput(e, "collabEmail")}
                    className="block w-full p-3 mt-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />
                  {errorMsgs.collabEmail ? (
                    <FieldError message={errorMsgs.collabEmail} />
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Add To
                  </label>
                  <Select
                    placeholder={"Teams / Orgs / Projects"}
                    isSearchable={false}
                    options={addToOptions}
                    isOptionDisabled={(opt: IOption) =>
                      opt.value === "PROJECT" && !selectedProject?.id
                    }
                    value={inputFieldValues.addTo}
                    onChange={(e) => handleInput(e, "addTo")}
                    classNames={reactSelectClassNames}
                  />
                  {errorMsgs.addTo ? (
                    <FieldError message={errorMsgs.addTo} />
                  ) : (
                    ""
                  )}
                </div>

                {inputFieldValues.addTo?.value === "ORGANIZATION" ? (
                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColor">
                      Org Access
                    </label>
                    <Select
                      placeholder="Select"
                      isSearchable={false}
                      options={orgAccessOptions}
                      value={inputFieldValues.access}
                      onChange={(e) => handleInput(e, "access")}
                      classNames={reactSelectClassNames}
                    />
                    {errorMsgs.access ? (
                      <FieldError message={errorMsgs.access} />
                    ) : (
                      ""
                    )}
                  </div>
                ) : null}

                {inputFieldValues.addTo?.value === "PROJECT" ? (
                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColor">
                      Project Access
                    </label>
                    <Select
                      placeholder="Select"
                      isSearchable={false}
                      options={projAccessOptions}
                      value={inputFieldValues.access}
                      onChange={(e) => handleInput(e, "access")}
                      classNames={reactSelectClassNames}
                    />
                    {errorMsgs.access ? (
                      <FieldError message={errorMsgs.access} />
                    ) : (
                      ""
                    )}
                  </div>
                ) : null}

                {inputFieldValues.addTo?.value === "ORGANIZATION" &&
                inputFieldValues.access?.value === "member" ? (
                  <>
                    <div className="mb-5">
                      <label className="text-sm font-medium text-contentColor">
                        Org. Permissions
                      </label>
                      <Select
                        placeholder="Select Permissions"
                        isSearchable={false}
                        options={orgPermissionOptions}
                        value={inputFieldValues.orgPermissions}
                        onChange={(e) => handleInput(e, "orgPermissions")}
                        classNames={reactSelectClassNames}
                        isMulti
                      />
                      {errorMsgs.orgPermissions ? (
                        <FieldError message={errorMsgs.orgPermissions} />
                      ) : null}
                    </div>
                  </>
                ) : null}

                {["PROJECT", "ORGANIZATION"].includes(
                  inputFieldValues.addTo?.value
                ) &&
                inputFieldValues.access?.value === "member" &&
                selectedProject?.id ? (
                  <>
                    <div className="mb-5">
                      <label className="text-sm font-medium text-contentColor">
                        Project Permissions
                      </label>
                      <Select
                        placeholder="Select Permissions"
                        isSearchable={false}
                        options={permissionOptions}
                        value={inputFieldValues.permissions}
                        onChange={(e) => handleInput(e, "permissions")}
                        classNames={reactSelectClassNames}
                        isMulti
                      />
                      {errorMsgs.permissions ? (
                        <FieldError message={errorMsgs.permissions} />
                      ) : null}
                    </div>
                  </>
                ) : null}

                <hr className="w-full border-background-layer3 mt-auto" />
              </div>

              <div>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="w-full inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  Create
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </AddOverlayLayout>
  );
};

export default CreateInvite;
