import React, { useEffect, useMemo, useState } from "react";
import Editor from "@monaco-editor/react";
import Modal from "@app/shared/components/modal.component";
import ActionTypeDropdown from "../action-type-dropdown.component";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  ActionType,
  IContext,
  IInput
} from "@app/rule-engine/rule-engine.helper";
import ReactSelect from "react-select";
import {
  isVariable,
  reactSelectClassNames
} from "@app/shared/utils/helper.util";
import { IOption } from "@interfaces/shared.interface";
import { useGetUserContextDefinitions } from "@/app/shared/hooks/get/context-definitions";
import UserContextForm, { TFormData } from "./insert-context-fields.component";

interface IDeviceShadowActionDefinitionEditor {
  actionDefinition: any;
  setActionDefinition: (x: any) => void;
  setActionType: (v: ActionType) => void;
  actionType: ActionType;
  fetchedContexts: IContext[];
  inputs: IInput[];
}

interface IInsertDataActionDefinition {
  context_id: string;
  payload: any;
}

const UserContextActionDefinitionEditor: React.FC<
  IDeviceShadowActionDefinitionEditor
> = ({
  actionDefinition,
  setActionDefinition,
  setActionType,
  actionType,
  fetchedContexts,
  inputs
}) => {
  const [jsonEditorModalOpen, setJsonEditorModalOpen] = useState(false);
  const [modalJSON, setModalJSON] = useState<IInsertDataActionDefinition>();

  const { data: contexts } = useGetUserContextDefinitions();

  const selectedContextDef = useMemo(
    () =>
      contexts?.find((s) => s.id === actionDefinition.context_definition_id),
    [actionDefinition.context_definition_id, contexts]
  );

  const [selectedFields, setSelectedFields] = useState(
    actionDefinition.contextValues
      ? Object.keys(actionDefinition.contextValues).filter(
          (key) =>
            actionDefinition.contextValues[key].value ||
            selectedContextDef?.definition?.required?.includes(key)
        )
      : [...(selectedContextDef?.definition?.required ?? [])]
  );

  const [formData, setFormData] = useState<TFormData>(
    actionDefinition.contextValues ??
      Object.keys(selectedContextDef?.definition?.properties ?? {}).reduce(
        (acc, fieldName) => {
          acc[fieldName] = {
            value: "",
            error: false
          };

          return acc;
        },
        {}
      ) ??
      {}
  );

  useEffect(() => {
    setActionDefinition((prev) => ({
      ...prev,
      contextValues: formData,
      hasErrors: Object.keys(formData)
        .filter((key) => selectedFields.includes(key))
        .some((field) => formData[field].error)
    }));
  }, [formData, selectedFields, setActionDefinition]);

  const onContextChange = (opt: IOption) => {
    const newContext = contexts.find((c) => c.id === opt.value);
    const newFormData = Object.keys(newContext.definition.properties).reduce(
      (acc, fieldName) => {
        acc[fieldName] = {
          value: "",
          error: newContext.definition.required?.includes(fieldName) ?? false,
          type: newContext.definition.properties[fieldName].type,
          variable: true
        };

        return acc;
      },
      {}
    );

    setActionDefinition((prev) => ({
      ...prev,
      context_definition_id: opt.value,
      contextValues: undefined
    }));

    setSelectedFields(newContext.definition.required ?? []);
    setFormData(newFormData);
  };

  return (
    <>
      <div className="bg-background-layer1 w-full h-full p-4 rounded-md border border-background-layer3">
        <div className="flex justify-between">
          <ActionTypeDropdown
            setActionType={setActionType}
            actionTypes={ActionType}
            setActionDefinition={setActionDefinition}
            className={"relative !z-20"}
            buttonComponent={
              <div className="flex gap-2 bg-background-layer3 rounded-sm px-2 py-1 items-center">
                <span className="uppercase">{actionType}</span>
                <ChevronDownIcon width={16} />
              </div>
            }
          />
          <button
            className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
            onClick={() => setJsonEditorModalOpen(true)}
          >
            JSON
          </button>
        </div>

        {/* Context Def */}
        <div
          className="flex mt-2 nodrag flex-col w-full"
          onScroll={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <label className="w-full mb-0">Context Definition:</label>
          <ReactSelect
            options={
              contexts?.map((c) => ({
                label: c.name,
                value: c.id
              })) || []
            }
            value={
              selectedContextDef
                ? {
                    label: selectedContextDef.name,
                    value: selectedContextDef.id
                  }
                : undefined
            }
            onChange={(e: IOption) => {
              onContextChange(e);
            }}
            classNames={reactSelectClassNames}
            className="z-10"
            captureMenuScroll
            menuShouldBlockScroll
          />
          {!selectedContextDef ? (
            <span className="text-red-500 text-left w-full">
              Select a context definition!
            </span>
          ) : null}
        </div>

        {/* Payload */}
        <div className="flex flex-col gap-2 nodrag mt-2">
          <h3 className="text-sm">Context Values:</h3>
          {selectedContextDef?.definition ? (
            <UserContextForm
              contextDefinition={selectedContextDef.definition}
              formData={formData}
              setFormData={setFormData}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
            />
          ) : (
            <div className="text-contentColorLight text-sm text-center">
              Selct a context definition.
            </div>
          )}
        </div>
      </div>
      <Modal
        open={jsonEditorModalOpen}
        setOpen={setJsonEditorModalOpen}
        title="JSON Editor"
        className="w-full max-w-2xl"
        disableClickOutside
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set Insert Context Data JSON</h1>
          <p>
            All your changes will be overwritten using this JSON editor.
            <br />
            Make sure there are `context_id` and `payload` fields in the JSON.
          </p>
          <Editor
            height="500px"
            width="100%"
            theme="vs-dark"
            language="json"
            value={JSON.stringify(modalJSON ?? {}, null, 2)}
            onChange={(e) => {
              try {
                setModalJSON(JSON.parse(e));
              } catch (err) {}
            }}
            options={{
              readOnly: false,
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => {
                const { context_id, payload } = modalJSON;

                const contextValues: TFormData = {};

                Object.keys(payload).forEach((field) => {
                  if (isVariable(field)) {
                    contextValues[field] = {
                      value: payload[field],
                      error: false,
                      variable: true
                    };
                  } else {
                    contextValues[field] = {
                      value: payload[field],
                      error: false,
                      variable: false
                    };
                  }
                });

                setActionDefinition({
                  context_definition_id: context_id,
                  payload
                });

                setJsonEditorModalOpen(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserContextActionDefinitionEditor;
