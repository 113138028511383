export enum UserContextMessageType {
  String = "string",
  Integer = "integer",
  Float = "float",
  Boolean = "boolean",
  Timestamp = "timestamp",
  TimestampWithZone = "timestamptz",
  UUID = "uuid",
  Binary = "binary",
  // complex types:
  Object = "object",
  Array = "array",
  Enum = "enum",
  Geometry = "geometry",
  JSON = "json"
}

interface IUserContextMessageProperty {
  type: UserContextMessageType;
  values?: string[]; // used with enum type
  format?: any; // used with array type or for email, phone, etc
  description?: string;
}

export interface IUserContextMessageStructure {
  type: UserContextMessageType;
  properties: Record<string, IUserContextMessageProperty>;
  partition: string;
  index?: string[][];
  required?: string[];
}

export interface IUserContextDefinition {
  id: string;
  name: string;
  table_name: string;
  definition: IUserContextMessageStructure;
  project_id: string;
  org_id: string;
  version: number;
  created_at: string;
}

export interface IUserContextResponse {
  data: {
    userContexts: IUserContextDefinition[];
  };
  ok: number;
}
