import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { useAuthStore, useFleetAndDevicesStore } from "../../../store";
import { FieldError } from "../../shared/components";
import SuggestionPanel from "../components/fad-suggestion-panel.component";
import FleetCreationSteps from "../components/fleet-creation-steps.component";

function AboutFleet() {
  const navigate = useNavigate();
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [fleetCreation, setFleetCreation] = useFleetAndDevicesStore(
    (state) => [state.fleetCreation, state.setFleetCreation]
  );

  const [inputFieldValues, setInputFieldValues] = useState({
    fleetName: "",
    description: ""
  });
  const [inputFieldErrors, setInputFieldErrors] = useState({
    fleetName: "",
    description: ""
  });

  useEffect(() => {
    updateAuthUser({ fleetCreationStatus: "0" });
  }, [updateAuthUser]);

  useEffect(() => {
    if (fleetCreation?.fleet_name) {
      setInputFieldValues({
        fleetName: fleetCreation.fleet_name,
        description: fleetCreation.fleet_description
      });
    }
  }, [fleetCreation]);

  const handleSubmit = () => {
    if (!inputFieldValues.fleetName.trim()) {
      return setInputFieldErrors({
        ...inputFieldErrors,
        fleetName: "Please enter fleet name."
      });
    } else if (
      inputFieldValues.fleetName.trim() &&
      inputFieldErrors.fleetName
    ) {
      setInputFieldErrors({ ...inputFieldErrors, fleetName: "" });
    }

    if (inputFieldValues.fleetName) {
      setFleetCreation({
        fleet_name: inputFieldValues.fleetName,
        fleet_description: inputFieldValues.description
      });
      navigate("/fleet-and-devices/projects/provisioning-policy/");
    }
  };

  const handleFieldChange = (e: any, id: string) => {
    const payload = { ...inputFieldValues };
    const data = e.target.value;

    if (id === "fleetName" && data.length > 20) {
      return;
    }

    payload[id] = data;
    setInputFieldValues({ ...payload });
  };

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12  pb-8">
          <div className="mt-7 mb-5 w-10/12">
            <FleetCreationSteps />
          </div>
          <Formik
            initialValues={{ fleetName: "", description: "" }}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col flex-1">
              <div>
                <h1 className="text-lg text-left font-medium mb-2.5">
                  About Fleet
                </h1>

                <div className="flex-1">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColor">
                      Fleet Name
                    </label>

                    <Field
                      type="text"
                      id="fleetName"
                      name="fleetName"
                      placeholder="Fleet Name"
                      value={inputFieldValues.fleetName}
                      onChange={(e) => handleFieldChange(e, "fleetName")}
                      className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                    />
                    {inputFieldErrors.fleetName ? (
                      <FieldError message={inputFieldErrors.fleetName} />
                    ) : null}
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColor">
                      Description
                    </label>

                    <Field
                      as="textarea"
                      rows={6}
                      id="description"
                      name="description"
                      placeholder="Description"
                      value={inputFieldValues.description}
                      onChange={(e) => handleFieldChange(e, "description")}
                      className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="">
                <button
                  type="submit"
                  className="w-44 inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  Next
                </button>
              </div>
            </Form>
          </Formik>
        </div>

        <div className="w-7/12">
          <SuggestionPanel type="fleet" />
        </div>
      </div>
    </>
  );
}

export default AboutFleet;
