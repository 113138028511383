import { Link, useLocation } from "react-router-dom";

function CreateDevice() {
  const { state } = useLocation();

  return (
    <div className="h-full flex-grow flex flex-col my-auto justify-center items-center">
      <div className="mb-[38px]">
        {state ? (
          <h1 className="text-2xl text-center font-medium mb-2.5">
            Congratulations!
          </h1>
        ) : (
          ""
        )}
        <h1 className="text-2xl text-center font-medium mb-2.5">
          {state
            ? `Your ${state.fleetName} Fleet is created successfully`
            : "Let's start with creating a device"}
        </h1>
      </div>
      {/* <div className="text-center">
                    <Link
                        to="/fleet-and-devices/projects/about-fleet/"
                        className="inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                    >
                        Create Fleet Now
                    </Link>
                </div> */}

      <div className="text-center h-full">
        {state ? (
          <Link
            to="/fleet-and-devices/projects"
            className="w-48 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
          >
            Back to home
          </Link>
        ) : (
          ""
        )}

        <Link
          to="/fleet-and-devices/projects/about-device/"
          className="ml-5 w-48 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
        >
          Let's create Device
        </Link>
        {!state && (
          <div className="flex mt-4 text-center justify-center items-center">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14ZM8.75 8H11.75V9.5H7.25V4.25H8.75V8Z"
                  fill="#565759"
                />
              </svg>
            </span>
            <span className="ml-1.5 text-xs">In just 3 mins</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateDevice;
