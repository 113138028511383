import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore } from "@store/index";
import { IPermission } from "@interfaces/apps.interface";
import { ICreateInviteResponse } from "@interfaces/invites.interface";

interface ICreateInvitePayload {
  emails: string[];
  permissions: IPermission[];
  org_access: string;
}

export const createInvite = async (
  orgId: string,
  payload: ICreateInvitePayload
) => {
  const { data, ok } = await networkService.post<ICreateInviteResponse>(
    `organizations/invites`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data;
  } else return null;
};

export const useCreateInvite = () => {
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: ICreateInvitePayload) =>
      await createInvite(user.selectedOrg.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getInvites");
      }
    }
  );

  return res;
};
