import Modal from "@app/shared/components/modal.component";
import useRuleEngineStore, {
  IRule,
  TriggerType
} from "@store/rule-engine/rule-engine.store";
import React, { useMemo, useState } from "react";
import RuleTriggerTypeSelector from "./rule-trigger-type-selector.component";
import { Button } from "@tremor/react";
import { toast } from "react-toastify";

interface ICreateRuleModalProps {
  open: boolean;
  setOpen: (x: boolean | ((x: boolean) => boolean)) => void;
  onCreateRule: (newRuleData: IRule) => void;
}

const CreateRuleModal = ({
  open,
  setOpen,
  onCreateRule
}: ICreateRuleModalProps) => {
  const [newRuleData, setNewRuleData] = useState({
    name: "",
    description: "",
    triggerType: "HTTP" as TriggerType
  });

  const rules = useRuleEngineStore((state) => state.rules);

  const ruleNameExists = useMemo(
    () => rules.hasOwnProperty(newRuleData.name),
    [newRuleData.name, rules]
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="p-4">
        <h1 className="text-2xl mb-6 border-b border-background-layer3 font-medium">
          Create New Rule
        </h1>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <input
              placeholder="Rule Name"
              value={newRuleData.name.trim()}
              className="bg-background max-w-md w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
              onChange={(e) => {
                setNewRuleData({
                  ...newRuleData,
                  name: e.target.value.trim()
                });
              }}
            />
            <RuleTriggerTypeSelector
              ruleTriggerType={newRuleData.triggerType}
              setRuleTriggerType={(newVal) => {
                setNewRuleData({
                  ...newRuleData,
                  triggerType: newVal as TriggerType
                });
              }}
            />
          </div>

          <textarea
            placeholder="Description"
            value={newRuleData.description}
            className="bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
            onChange={(e) => {
              setNewRuleData({
                ...newRuleData,
                description: e.target.value
              });
            }}
          />
        </div>
        <div className="flex w-full justify-end gap-2 mt-6">
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !newRuleData.name ||
              !newRuleData.description.trim() ||
              ruleNameExists
            }
            tooltip={ruleNameExists ? "Rule name already exists!" : null}
            onClick={() => {
              setOpen(false);
              toast.info(
                "A new rule has been created. Click Edit to open the Rule Engine!"
              );
              onCreateRule(newRuleData);
              setNewRuleData({
                name: "",
                description: "",
                triggerType: "HTTP" as TriggerType
              });
            }}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateRuleModal;
