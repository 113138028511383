import React, { useEffect, useState } from "react";
import DatapointDefinitionDetailsTabs, {
  ContextDefinitionTabs
} from "./contexts-tabs.components";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserContextDefinitionDetails from "./tabs/user-context-details.component";
import { useGetUserContextDefinitions } from "@app/shared/hooks/get/context-definitions";
import UserContextDefinitionData from "./tabs/user-context-data.component";
import UserContextRetention from "./tabs/user-context-retention.component";
import UserContextUsage from "./tabs/user-context-usage.component";

const UserContextDefinitionsTabsRenderer: React.FC = () => {
  const location = useLocation();
  const [contextDef, setContextDef] = useState(null);
  const [contextId] = useState(location.pathname.split("/")[3]);

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") as ContextDefinitionTabs;

  useGetUserContextDefinitions(
    {
      ids: contextId
    },
    (contexts) => {
      if (!contexts?.length) {
        toast.error(`User Context with ID ${contextId} not found.`);

        return;
      }

      const _contextDef = contexts[0];
      setContextDef(_contextDef);
    }
  );

  const [activeTab, setActiveTab] = useState<ContextDefinitionTabs>(
    tab ?? ContextDefinitionTabs.DETAILS
  );

  useEffect(() => {
    setSearchParams(
      { tab: activeTab ?? ContextDefinitionTabs.DETAILS },
      {
        replace: true
      }
    );
  }, [activeTab, setSearchParams]);

  return (
    <>
      <DatapointDefinitionDetailsTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === ContextDefinitionTabs.DETAILS && contextDef ? (
        <UserContextDefinitionDetails context={contextDef} />
      ) : activeTab === ContextDefinitionTabs.DATA && contextDef ? (
        <UserContextDefinitionData context={contextDef} />
      ) : activeTab === ContextDefinitionTabs.RETENTION && contextDef ? (
        <UserContextRetention context={contextDef} />
      ) : null}
    </>
  );
};

export default UserContextDefinitionsTabsRenderer;
