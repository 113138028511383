import useRuleEngineStore, {
  IRule
} from "@store/rule-engine/rule-engine.store";
import { Button } from "@tremor/react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowUturnLeftIcon,
  ArrowsUpDownIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { useGetRules } from "@app/shared/hooks/get/rules";
import { deserializeRuleJSON } from "./rule-engine.helper";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { useMemo, useState } from "react";
import CreateRuleModal from "./create-rule-modal.component";
import dateService from "@services/date.service";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import Pagination from "../shared/components/pagination.component";
import { useDeleteRule } from "../shared/hooks/delete/delete-rule";
import { useConfirmDelete } from "../shared/hooks/use-confirm-delete.hooks";
import { toast } from "react-toastify";
import ShowLoading from "../shared/components/loading.component";

type Props = {};

const RuleEngineAll = (props: Props) => {
  const [
    rules,
    localRulesData,
    setRules,
    setRuleData,
    clearRuleData,
    clearLocalRuleDataAndFlowData
  ] = useRuleEngineStore((state) => [
    state.rules,
    state.localRulesData,
    state.setRules,
    state.setRuleData,
    state.clearRuleData,
    state.clearLocalRuleDataAndFlowData
  ]);

  const navigate = useNavigate();

  const params = useParams();
  const id = params["id"];

  const [showRuleModal, setShowRuleModal] = useState(false);

  const selectedProject = useFleetAndDevicesStore(
    (state) => state.selectedProject
  );
  const user = useAuthStore((state) => state.user);

  const deleteRuleMutation = useDeleteRule();

  const { openConfirmDeleteModal, setIsLoading, setIsOpen } =
    useConfirmDelete();

  const onDiscardLocalChanges = (ruleName) => {
    clearLocalRuleDataAndFlowData(ruleName);
    clearRuleData();
  };

  const { isLoading: rulesLoading } = useGetRules({}, (rules) => {
    const _newRules = {};

    if (!rules?.length) {
      setRules({});
      return;
    }
    const loadedLocalRules = new Set<string>();

    rules.forEach((_rule) => {
      const { rule, ruleData } = deserializeRuleJSON(_rule);
      if (localRulesData[rule.name]) {
        if (rule.version > localRulesData[rule.name].ruleDetails.version) {
          // TODO: Give an option to discard local changes and use server side changes.
        }

        // localRuleData will be used (set in rule-editor-node component)
        _newRules[rule.name] = { ...rule, ruleData: undefined };

        loadedLocalRules.add(rule.name);
      } else {
        _newRules[rule.name] = { ...rule, ruleData };
      }
    });

    Object.keys(localRulesData).forEach((ruleName) => {
      if (
        !loadedLocalRules.has(ruleName) &&
        localRulesData[ruleName].orgId === user.selectedOrg.id &&
        localRulesData[ruleName].projId === selectedProject.id
      ) {
        _newRules[ruleName] = {
          ...localRulesData[ruleName].ruleDetails,
          ruleData: undefined
        };
      }
    });

    setRules(_newRules);
  });

  const onCreateRule = (newRuleData: IRule) => {
    clearRuleData();
    const newRules = { ...rules };

    const mqttInputs = [
      {
        key: "mqtt_payload",
        type: "struct",
        deletable: false
      },
      {
        key: "mqtt_device_id",
        type: "string",
        deletable: false
      },
      {
        key: "mqtt_device_name",
        type: "string",
        deletable: false
      }
    ];

    newRules[newRuleData.name] = {
      ...newRuleData,
      createdAt: dateService.getCurrentUTCDate().utc().format()
    };

    if (newRuleData.triggerType === "MQTT") {
      setRuleData((rd) => ({ ...rd, inputs: mqttInputs }));
    }

    setRules(newRules);
  };

  const onDeleteRule = (rule) => {
    openConfirmDeleteModal(() => {
      setIsLoading(true);
      if (rule.id) {
        deleteRuleMutation.mutate(rule.id, {
          onSuccess: (ok) => {
            if (ok) {
              onDiscardLocalChanges(rule.name);
              const newRules = { ...rules };
              delete newRules[rule.name];

              setRules(newRules);
              setIsLoading(false);
              setIsOpen(false);

              toast.success("Deleted Rule Successfully!");
            }
          }
        });
      } else {
        onDiscardLocalChanges(rule.name);
        const newRules = { ...rules };
        delete newRules[rule.name];
        setRules(newRules);
        setIsLoading(false);
        setIsOpen(false);

        toast.success("Deleted Rule Successfully!");
      }
    }, `Are you sure you want to permanently delete the rule "${rule.name}"?`);
  };

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        id: "trigger",
        accessorKey: "triggerType",
        header: "Trigger",
        cell: ({ getValue, row }) => {
          return getValue();
        },
        size: 50
      },
      {
        id: "name",
        accessorKey: "name",
        header: "Name",
        cell: ({ getValue, row }) => {
          return getValue();
        },
        size: 50
      },
      {
        id: "createdAt",
        accessorKey: "createdAt",
        header: "Created At",
        sortingFn: (a, b, colId) =>
          dateService
            .convertDateStrToMoment(a.getValue(colId))
            .diff(dateService.convertDateStrToMoment(b.getValue(colId))),
        cell: ({ getValue }) => {
          return (
            <div className="text-contentColorLight">
              {dateService.convertUTCToLocalDate(getValue())}
            </div>
          );
        },
        size: 30
      },
      {
        id: "delete",
        accessorKey: "delete",
        header: "delete",
        cell: ({ row: { original: rule } }) => {
          return (
            <Button
              color="red"
              variant="light"
              onClick={() => onDeleteRule(rule)}
              icon={TrashIcon}
            />
          );
        },
        size: 30
      },
      {
        id: "edit",
        accessorKey: "edit",
        header: "edit",
        cell: ({ row: { original: rule } }) => {
          return (
            <Button
              disabled={!rule.name || !rule.triggerType || !rule.description}
              onClick={() => {
                rule.id
                  ? navigate(rule.id)
                  : navigate("new-rule", { state: { ruleName: rule.name } });
              }}
              icon={PencilSquareIcon}
            >
              Edit
            </Button>
          );
        },
        size: 30
      }
    ],
    []
  );

  const sortees = useMemo(
    () => [
      {
        id: "createdAt",
        desc: true
      }
    ],
    []
  );

  const notDeletedRules = useMemo(
    () => Object.values(rules).filter((r) => !r.deletedAt),
    [rules]
  );

  const table = useReactTable({
    data: notDeletedRules,
    columns,
    initialState: {
      sorting: sortees
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  if (id) {
    return <Outlet />;
  }

  if (rulesLoading) {
    return <ShowLoading />;
  }

  return (
    <main className="w-full h-auto py-8 overflow-y-auto lg:px-8 sm:px-6">
      <div className="mb-7">
        <h1 className="font-bold text-4xl mb-6">{"Rule Engine"}</h1>
        <p className="font-normal text-base">
          Rule Engine helps you connect your Golain devices to third party
          applications and perform actions!
        </p>
        <p className="font-normal text-base mb-6">
          Create a new Rule to get started!
        </p>
      </div>
      <div className="w-full my-3 mt-5">
        <div className="flex justify-between mb-2">
          <h1 className="font-medium text-lg">Rules:</h1>
          {notDeletedRules.length ? (
            <Button
              className="bg-primary !text-white"
              size="xs"
              onClick={() => setShowRuleModal(true)}
            >
              <span className="flex gap-2 items-center">
                <PlusIcon width={20} /> <span>Create a new Rule!</span>
              </span>
            </Button>
          ) : null}
        </div>
        <hr className="border-background-layer3" />
        {notDeletedRules.length ? (
          <div className="max-h-[55vh] overflow-y-auto">
            {/* table */}
            <table className="w-full h-full my-6">
              <thead className="">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            width: header.column.getSize()
                          }}
                          className="px-2 py-2 text-xs text-center uppercase text-contentColorLight font-normal"
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none flex items-center justify-center gap-1"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler()
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <ArrowUpIcon width={10} />,
                                  desc: <ArrowDownIcon width={10} />
                                }[header.column.getIsSorted() as string] ??
                                  (header.column.getCanSort() ? (
                                    <ArrowsUpDownIcon width={10} />
                                  ) : null)}
                              </div>
                              {header.column.getCanFilter() ? (
                                <div>{""}</div>
                              ) : null}
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <>
                      <tr
                        key={row.id}
                        className="bg-background-layer1 border-b border-background-layer3"
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="mx-2 text-sm text-center p-2 py-3"
                              style={{
                                width: cell.column.getSize()
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <Pagination<any> table={table} />
          </div>
        ) : (
          <div className="flex justify-center items-center gap-2 text-center text-contentColorLight flex-col min-h-[400px]">
            You don't have any rules created yet!
            <Button
              className="bg-primary !text-white"
              size="xs"
              icon={PlusIcon}
              onClick={() => setShowRuleModal(true)}
            >
              Create a new Rule!
            </Button>
          </div>
        )}
      </div>
      <CreateRuleModal
        open={showRuleModal}
        setOpen={setShowRuleModal}
        onCreateRule={onCreateRule}
      />
    </main>
  );
};

export default RuleEngineAll;

const LatestVersionToast = ({ onButtonClick }) => {
  return (
    <>
      A new version of this rule is available. Click here to discard your local
      changes and use the latest version:
      <Button icon={ArrowUturnLeftIcon} color="yellow">
        Discard Changes
      </Button>
    </>
  );
};
