import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const deleteRelease = async (
  projectId: string,
  orgId: string,
  releaseId: string
) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `projects/${projectId}/ota_releases/${releaseId}/`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteRelease = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (releaseId: string) =>
      await deleteRelease(selectedProject.id, user.selectedOrg.id, releaseId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getReleases");
      }
    }
  );

  return res;
};
