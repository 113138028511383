import { useState, useEffect, useMemo, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { isEmpty } from "lodash";
import { Switch } from "@headlessui/react";
import { useDashboardStore } from "@store/index";
import { IOption } from "@/interfaces";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";

interface IInfoGraphFormProps {
  onChangeHandler: (x: any) => void;
  enableCreatebutton: (x: boolean) => void;
  params: (IOption & { data: any })[];
  initialData?: {
    infoParams: IOption;
    showMin?: boolean;
    showMax?: boolean;
    unit: string;
    aggParam: string;
  };
}

interface IInfoInput {
  infoParams: IOption;
  label: string;
  aggParam: string;
  time: string;
  paramName: string;
  labelName: string;
  timeName: string;
  aggParamName: string;
  showMin?: boolean;
  showMax?: boolean;
  unit: string;
}

interface IInfoError {
  infoParams: boolean;
  label: boolean;
  time: boolean;
  aggParam: boolean;
}

const INITIAL_DATA = {
  infoParams: {} as IOption,
  time: "",
  aggParam: "",
  label: "",
  timeName: `infoLabel_${0}`,
  paramName: `infoParam_${0}`,
  labelName: `infoLabel_${0}`,
  aggParamName: `infoAggParam_${0}`,
  showMin: false,
  showMax: false,
  unit: "KWHr"
};

export const InfoGraphForm: React.FC<IInfoGraphFormProps> = ({
  onChangeHandler,
  enableCreatebutton,
  params,
  initialData
}) => {
  const [infoInputs, setInfoInputs] = useState<IInfoInput[]>([
    initialData ? { ...INITIAL_DATA, ...initialData } : INITIAL_DATA
  ]);
  const [error, setErrors] = useState<IInfoError[]>([
    { infoParams: false, time: false, aggParam: false, label: false }
  ]);

  const [selectOptions, setSelectOptions] = useState(params);

  const [setCreatePanelAppearance] = useDashboardStore((state) => [
    state.setCreatePanelAppearance
  ]);

  useEffect(() => {
    setSelectOptions(params);
  }, [params]);

  const initialValues = useMemo(
    () =>
      infoInputs.map((line) => {
        return {
          [line.timeName]: "",
          [line.paramName]: "",
          [line.labelName]: ""
        };
      }),
    [infoInputs]
  );

  const aggregationParams = [
    { value: "max", label: "Max" },
    { value: "min", label: "Min" },
    { value: "avg", label: "Mean" },
    { value: "sum", label: "Sum" },
    { value: "count", label: "Count" },
    { value: "first", label: "First" },
    { value: "last", label: "Last" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (idx: number, key: string, value: any) => {
    let newInputs = [...infoInputs];
    newInputs[idx][key] = value;
    setInfoInputs(newInputs);
    if (key === "showMin" || key === "showMax" || key === "unit") {
      setCreatePanelAppearance((prev) => ({
        ...prev,
        [key]: value
      }));
    }
  };

  const isValid = useCallback(() => {
    let errVal = true;
    let errors = [...error];

    infoInputs.forEach((info, index) => {
      if (!info.aggParam.length) {
        errors[index].aggParam = true;
        errVal = false;
      } else errors[index].aggParam = false;

      if (isEmpty(info.infoParams)) {
        errors[index].infoParams = true;
        errVal = false;
      } else errors[index].infoParams = false;
    });

    setErrors(errors);

    return errVal;
  }, [error, infoInputs]);

  useEffect(() => {
    if (infoInputs.length) {
      if (isValid()) {
        onChangeHandler(infoInputs);
        enableCreatebutton(false);
      } else {
        enableCreatebutton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoInputs]);

  const getBorderStyle = (error: boolean) => {
    return {
      control: (base) =>
        error
          ? {
              ...base,
              borderColor: "red",
              boxShadow: "none",
              padding: "4px",
              ":hover": { borderColor: "red" },
              ":focus": { borderColor: "red" }
            }
          : { ...base, padding: "4px" }
    };
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        {infoInputs.map((ele, index) => (
          <div
            key={index}
            className="mb-2 border-b pb-2 border-background-layer3"
          >
            <div className="flex flex-row">
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Info Parameter
                </label>
                <Field
                  as="select"
                  id={ele.infoParams}
                  name={ele.infoParams}
                  placeholder="Info Parameter"
                  component={Select}
                  onChange={(e) => handleChange(index, "infoParams", e)}
                  value={ele.infoParams}
                  options={selectOptions}
                  classNames={reactSelectClassNames}
                  className={`block w-10/12 bg-background mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                  maxMenuHeight={180}
                />
              </div>
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Unit
                </label>
                <Field
                  type="text"
                  id={ele.unit}
                  name={ele.unit}
                  placeholder="Unit"
                  autoComplete="off"
                  value={ele.unit}
                  onChange={(e) => handleChange(index, "unit", e.target.value)}
                  className={`block w-10/12 bg-background mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                />
              </div>
            </div>
            <div className="flex flex-row my-2">
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Aggregation Parameter
                </label>
                <Field
                  as="select"
                  id={ele.aggParamName}
                  name={ele.aggParamName}
                  classNames={reactSelectClassNames}
                  className={`block w-10/12 bg-background mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                  placeholder="Aggregation Parameter"
                  component={Select}
                  options={aggregationParams}
                  styles={getBorderStyle(error[index]["aggParam"])}
                  onChange={(e) => handleChange(index, "aggParam", e.value)}
                  value={aggregationParams.find(
                    (opt) => opt.value === ele.aggParam
                  )}
                  maxMenuHeight={180}
                />
              </div>
            </div>
            <div className="flex flex-row my-2 mt-4">
              <div className="w-6/12 flex items-center gap-1">
                <label className="text-sm font-medium text-gray-600">
                  Show Min Value
                </label>
                <Switch
                  checked={ele.showMin}
                  onChange={(val) => handleChange(index, "showMin", val)}
                  className={`${
                    ele.showMin ? "bg-green-500" : "bg-gray-200"
                  } relative inline-flex h-5 w-9 items-center rounded-full`}
                >
                  <span className="sr-only">Enable Show Min</span>
                  <span
                    className={`${
                      ele.showMin ? "translate-x-5" : "translate-x-1"
                    } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
              <div className="w-6/12 flex items-center gap-1">
                <label className="text-sm font-medium text-gray-600">
                  Show Max Value
                </label>
                <Switch
                  checked={ele.showMax}
                  onChange={(val) => handleChange(index, "showMax", val)}
                  className={`${
                    ele.showMax ? "bg-green-500" : "bg-gray-200"
                  } relative inline-flex h-5 w-9 items-center rounded-full`}
                >
                  <span className="sr-only">Enable Show Max</span>
                  <span
                    className={`${
                      ele.showMax ? "translate-x-5" : "translate-x-1"
                    } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
            </div>
          </div>
        ))}
      </Form>
    </Formik>
  );
};
