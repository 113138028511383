import ShadowEditor from "@app/fleet-and-devices/components/fad-shadow-editor.component";
import {
  decodeBase64toUTF8,
  downloadFile
} from "@app/shared/utils/helper.util";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import dateService from "@services/date.service";
import { loggerService } from "@services/logger";
import networkService from "@services/network.service";
import useAuthStore from "@store/auth.store";
import { useFleetAndDevicesStore } from "@store/index";
import React, { useEffect, useState } from "react";

interface IShadowDefinitionVersionsProps {
  shadowDef: any;
}

interface IDeprecatedShadowDefinitionResponse {
  data: { deprecatedShadowDefinitions: any[] };
  ok: number;
}

const ShadowDefinitionVersions: React.FC<IShadowDefinitionVersionsProps> = ({
  shadowDef
}) => {
  const [user] = useAuthStore((state) => [state.user]);
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const [versions, setVersions] = useState([shadowDef]);
  const [selectedVersion, setSelectedVersion] = useState(shadowDef);

  useEffect(() => {
    const fetchShadow = async () => {
      try {
        const response =
          await networkService.get<IDeprecatedShadowDefinitionResponse>(
            `projects/${selectedProject.id}/shadow_definitions/${shadowDef.id}/deprecated`,
            {},
            {
              headers: {
                "ORG-ID": user.selectedOrg.id
              }
            }
          );
        const { deprecatedShadowDefinitions } = response.data;
        if (!deprecatedShadowDefinitions?.length) {
          return;
        }

        const shadowDefHistory = deprecatedShadowDefinitions;
        setVersions([
          { ...shadowDef, version: "Latest" },
          ...shadowDefHistory.sort(
            (a, b) => parseInt(b.version) - parseInt(a.version)
          )
        ]);
      } catch (error) {
        loggerService.error(error);
      }
    };

    fetchShadow();
  }, [selectedProject.id, shadowDef, user.selectedOrg.id]);
  return (
    <div className="flex gap-8 mt-4 overflow-y-scroll">
      <div className="w-[60%] flex flex-col gap-4 mt-6 overflow-y-scroll max-h-[60vh]">
        {versions.map((deprecatedShadowDef) => {
          return (
            <div
              key={deprecatedShadowDef.id + deprecatedShadowDef.version}
              className="flex relative flex-col gap-2"
            >
              <h2 className="font-medium text-base">
                Version {deprecatedShadowDef.version}
              </h2>
              <p className="text-contentColorLight text-sm">
                {deprecatedShadowDef.deleted_at
                  ? "Deleted At: " +
                    dateService.convertUTCToLocalDate(
                      deprecatedShadowDef.deleted_at
                    )
                  : "Published At: " +
                    dateService.convertUTCToLocalDate(
                      deprecatedShadowDef.created_at
                    )}
              </p>
              <hr className="mt-3 border-background-layer3" />
              <div className="absolute top-0 right-2">
                <button
                  onClick={() => {
                    setSelectedVersion(deprecatedShadowDef);
                  }}
                >
                  <ArrowRightIcon
                    width={20}
                    className={`${
                      deprecatedShadowDef.version === selectedVersion.version
                        ? "text-primary"
                        : "text-contentColorLight"
                    }`}
                  />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="border-4 border-background-layer3 rounded mx-3"></div>
      <div className="max-w-[80vw] min-w-[40vw] max-h-[80vh]">
        <ShadowEditor
          isShadow={true}
          label={shadowDef.name + "-V" + selectedVersion.version}
          shadowDef={selectedVersion}
          readOnly={true}
          // setShadowEditorReadOnly={setShadowEditorReadOnly}
          customShadow={decodeBase64toUTF8(selectedVersion.shadow_proto)}
          handleMaximizeEditor={() => {}}
          downloadFile={() =>
            downloadFile(
              shadowDef.name + "-V" + selectedVersion.version,
              decodeBase64toUTF8(selectedVersion.shadow_proto)
            )
          }
        />
      </div>
    </div>
  );
};

export default ShadowDefinitionVersions;
