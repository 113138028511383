import { useState } from "react";
import { IDevice } from "../../../../interfaces";
import Details from "../../../dashboard/dash-panel-details.component";

interface IDetailsTab {
  device: IDevice;
}

function DeviceDashboard(props: IDetailsTab) {
  const { device } = props;
  let [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!!device ? (
        <div>
          <Details
            dashboardConfigs={{
              hideAddPanel: true,
              hideDelete: true,
              hideEditLayout: true
            }}
            deviceId={device.id}
            onAddPanel={() => setIsOpen(true)}
          />
        </div>
      ) : null}
    </>
  );
}

export default DeviceDashboard;
