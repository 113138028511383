import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface IWalkthroughState {
  shownOverview: boolean;
  setShownOverview: (x: boolean) => void;
}

const useWalkthroughStore = create<IWalkthroughState>()(
  persist(
    devtools(
      (set) => ({
        shownOverview: false,
        setShownOverview: (x: boolean) =>
          set(
            produce((state: IWalkthroughState) => {
              state.shownOverview = x;
            })
          )
      }),
      { name: "walkthrough", serialize: { options: true } }
    ),
    {
      name: "walkthrough", // unique name
      getStorage: () => localStorage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useWalkthroughStore;
