import produce from "immer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type TNanoPBState = "loading" | "success" | "error";

interface IPyScriptState {
  loadingState: TNanoPBState;
  setLoadingState: (x: TNanoPBState) => void;
}

const usePyScrtiptStore = create<IPyScriptState>()(
  devtools(
    (set) => ({
      loadingState: "loading" as TNanoPBState,
      setLoadingState: (newState: TNanoPBState) =>
        set(
          produce((state: IPyScriptState) => {
            state.loadingState = newState;
          }),
          false,
          "pyScript/setLoadingState"
        )
    }),
    { name: "pyScript", serialize: { options: true } }
  )
);

export default usePyScrtiptStore;
