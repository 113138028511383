import { useMemo } from "react";
import CreateNewReleasePage from "./ota-no-release.component";
import useOTAReleaseStore from "../../../store/ota/ota-release.store";
import RenderArtifacts from "./ota-render-artifacts.component";
import ShowError from "../../shared/components/error.component";
import { useGetReleases } from "@app/shared/hooks/get";

const ReleaseMain = () => {
  const [
    releaseList,
    activeRelease,
    setReleaseList,
    setActiveRelease,
    setShowCreateForm
  ] = useOTAReleaseStore((state) => [
    state.releaseList,
    state.activeRelease,
    state.setReleaseList,
    state.setActiveRelease,
    state.setShowCreateForm
  ]);

  const { error } = useGetReleases({}, (releases) => {
    if (releases?.length) {
      setReleaseList(releases);
      setActiveRelease(releases[0]);
    }
  });

  const handlePanelSelect = (index: number) => {
    setActiveRelease(releaseList[index]);
  };

  const PANELS: { id: string; name: string; description: string }[] =
    useMemo(() => {
      return (
        releaseList?.map((rel) => {
          return {
            id: rel.id,
            name: rel.props.release_name,
            description: rel.props.device_types
          };
        }) || []
      );
    }, [releaseList]);

  if (error) {
    return <ShowError />;
  }

  if (PANELS.length)
    return (
      <>
        <main className="flex-1 items-center mx-8 mt-7 space-y-4 overflow-y-auto pt-9 lg:px-8 sm:px-6 bg-background rounded-md">
          <div className="flex w-full h-[60vh]">
            <div className="w-5/12 flex flex-col  pb-8">
              <div className="h-[54vh]">
                <h1 className="text-lg text-left font-medium mb-2.5">
                  Releases
                </h1>
                <div className="h-[90%] overflow-auto">
                  <div className="w-10/12">
                    {PANELS.map((panel, index) => (
                      <div
                        key={index}
                        className={`flex justify-between items-center w-full h-auto border border-background-layer3 rounded-lg px-5 py-3.5 my-2 mb-[14px] cursor-pointer ${
                          panel.id === activeRelease.id
                            ? "border-primary bg-background-layer1"
                            : ""
                        }`}
                        onClick={() => handlePanelSelect(index)}
                      >
                        <div>
                          <h1 className="text-base text-left font-medium text-contentColor">
                            {panel.name}
                          </h1>
                          <span className="text-sm text-left text-contentColorLight">
                            {panel.description}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="stroke-contentColor"
                          >
                            <path
                              d="M4.99989 3.00006L10 7.99994L5.00012 13.0001"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={() => setShowCreateForm(true)}
                  className="inline-block px-16 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  + Create New Release
                </button>
              </div>
            </div>
            <div className="border border-background-layer3 rounded mx-3 h-[60vh]"></div>
            <div className="w-7/12 ml-3.5 flex flex-col relative overflow-auto">
              <RenderArtifacts />
            </div>
          </div>
        </main>
      </>
    );

  return (
    <>
      <CreateNewReleasePage />
    </>
  );
};

export default ReleaseMain;
