import React, { useEffect, useState } from "react";
import HTTPTriggerNew from "./http-trigger-new/http-trigger-new.component";
import useRuleEngineStore, {
  IRule
} from "@/store/rule-engine/rule-engine.store";
import HTTPTriggExisting from "./http-trigger-existing.component";
import { useGetTriggers } from "@/app/shared/hooks/get/triggers";
import LoadingSpin from "@/app/shared/components/loading-spin.component";
import { deserializeHTTPTrigger } from "./http-trigger.helper";

interface IHTTPTriggerProps {
  rule: IRule;
}

const HTTPTrigger: React.FC<IHTTPTriggerProps> = ({ rule }) => {
  const [activeTab, setActiveTab] = useState("new");
  const [doneSettingExistingTrigger, setDoneSettingExistingTrigger] =
    useState(false);

  const [setTriggerData, setRules] = useRuleEngineStore((state) => [
    state.setTriggerData,
    state.setRules
  ]);

  const triggersQuery = useGetTriggers({
    trigger_type: "HTTP"
  });

  useEffect(() => {
    if (triggersQuery.isLoading) {
      return;
    }

    const selectedTrigger = triggersQuery.data?.find(
      (t) => t.rule_id === rule.id
    );

    if (!selectedTrigger) {
      setDoneSettingExistingTrigger(true);
      return;
    }

    const formState = deserializeHTTPTrigger(
      selectedTrigger.name,
      selectedTrigger.definition
    );

    setRules((rules) => {
      return {
        ...rules,
        [rule.name]: {
          ...rules[rule.name],
          triggerId: selectedTrigger.id
        }
      };
    });
    setActiveTab("new");
    setTriggerData(rule.name, formState);
    setTimeout(() => setDoneSettingExistingTrigger(true));
  }, [
    rule.id,
    rule.name,
    rule.triggerId,
    setRules,
    setTriggerData,
    triggersQuery.data,
    triggersQuery.isLoading
  ]);

  if (triggersQuery.isLoading) {
    return (
      <div className="flex items-center justify-center my-10 ">
        <LoadingSpin />
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-2 rounded-lg">
      <div className="flex justify-center border-b border-background-layer3 mb-6">
        <button
          className={`text-base font-semibold py-1 px-4 ${
            activeTab === "new"
              ? "border-b-2 border-primary text-primary"
              : "text-contentColorLight"
          }`}
          onClick={() => setActiveTab("new")}
        >
          {rule.id && rule.triggerId ? "Update trigger" : "Create new trigger"}
        </button>
        {!rule.id || !rule.triggerId ? (
          <button
            className={`text-base font-semibold py-1 px-4 ${
              activeTab === "existing"
                ? "border-b-2 border-primary text-primary"
                : "text-contentColorLight"
            }`}
            onClick={() => setActiveTab("existing")}
          >
            Use existing trigger
          </button>
        ) : null}
      </div>

      {activeTab === "new" && doneSettingExistingTrigger ? (
        <HTTPTriggerNew rule={rule} />
      ) : activeTab === "existing" ? (
        <HTTPTriggExisting getTriggersQuery={triggersQuery} rule={rule} />
      ) : null}
    </div>
  );
};

export default HTTPTrigger;
