import { reactSelectClassNames } from "@/app/shared/utils/helper.util";
import useThemeStore from "@/store/theme.store";
import { Editor } from "@monaco-editor/react";
import { NumberInput } from "@tremor/react";
import { Field } from "formik";
import React from "react";
import reactSelect from "react-select";

interface QueryParamDefaultValueProps {
  type: "string" | "bool" | "number" | "array" | "struct";

  [key: string]: any;
}

const ParamDefaultValue: React.FC<QueryParamDefaultValueProps> = React.memo(
  ({ type, ...fieldProps }) => {
    const [theme] = useThemeStore((state) => [state.theme]);

    switch (type) {
      case "string":
        return (
          <Field
            type="text"
            className="block w-full bg-background border-background-layer3 text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
            placeholder="Default Value."
            {...fieldProps}
          />
        );

      case "number":
        return (
          <Field
            component={({ field, form, ...props }) => (
              <NumberInput
                {...field}
                {...props}
                value={field.value}
                onValueChange={(val: number) => {
                  form.setFieldValue(fieldProps.name, val);
                }}
                className="placeholder:text-contentColorLight w-full bg-background !text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
              />
            )}
            placeholder="Default Value."
            {...fieldProps}
          />
        );

      case "bool":
        return (
          <Field
            component={reactSelect}
            placeholder="Default Value."
            options={[
              { value: true, label: "true" },
              { value: false, label: "false" }
            ]}
            className="block w-full bg-background text-contentColor !rounded-none focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
            classNames={reactSelectClassNames}
            {...fieldProps}
          />
        );

      case "struct":
        return (
          <Field
            component={({ field, form, ...props }) => (
              <Editor
                {...field}
                {...props}
                value={field.value.toString()}
                onChange={(val: string) => {
                  form.setFieldValue(fieldProps.name, val);
                }}
                height="15vh"
                language="json"
                theme={
                  theme === "golain" || theme === "none" ? "vs" : "vs-dark"
                }
                className="border p-[1px] border-background-layer3 rounded-md"
                options={{
                  readOnly: false,
                  lineNumbers: "off",
                  minimap: { enabled: false },
                  scrollbar: { vertical: "hidden" },
                  overviewRulerBorder: false,
                  overviewRulerLanes: 0,
                  folding: false,
                  matchBrackets: "never",
                  theme:
                    theme === "golain" || theme === "none" ? "vs" : "vs-dark"
                }}
              />
            )}
            {...fieldProps}
          />
        );

      default:
        return null;
    }
  }
);

export default ParamDefaultValue;
