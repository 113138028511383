import React from "react";

interface IOnboardingSliderProps {
  data: {
    heading: string;
    subHeading: string;
  };
}

const OnboardingSlider: React.FC <IOnboardingSliderProps> = ({data}) => {
  return (
    <section className="relative items-end hidden w-full lg:flex lg:w-3/5 bg-primary">
      <div className="max-w-lg px-12 py-12">
        <div className="flex items-center space-x-3">
          <span className="w-6 h-2.5 bg-white rounded-full"></span>
          <span className="w-2.5 h-2.5 bg-white bg-opacity-40 rounded-full"></span>
          <span className="w-2.5 h-2.5 bg-white bg-opacity-40 rounded-full"></span>
        </div>

        <h2 className="mt-12 text-4xl font-bold text-white">
          {data.heading}
        </h2>

        <p className="mt-6 text-lg text-white/80">
          {data.subHeading}
        </p>
      </div>
    </section>
  );
};

export default OnboardingSlider;
