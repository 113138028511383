import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateService from "../../../services/date.service";

interface laaFilterProps {
  setPayload: (data: { start: string; stop: string }) => void;
}

function LogsAndActivitiesFilter({ setPayload }: laaFilterProps) {
  const [activeFilter, setActiveFilter] = useState("1h");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const handleSearch = (event: any) => {
    console.log(event.target.value);
  };

  const onClearFilter = () => {
    const fltrBtns = document.getElementsByName("timeFilters");
    if (fltrBtns.length) {
      fltrBtns.forEach((option) => {
        option["checked"] = false;
      });
      setActiveFilter("");
    }

    clearDateFilter();
  };

  const onChangeDateRange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (end && activeFilter) {
      const fltrBtns = document.getElementsByName("timeFilters");
      if (fltrBtns.length) {
        fltrBtns.forEach((option) => {
          option["checked"] = false;
        });
        setActiveFilter("");
      }
    }

    if (start && end) {
      setPayload({
        start: dateService.convertDateObjToUTCDateFormatted(start),
        stop: dateService.convertDateObjToUTCDateFormatted(end)
      });
    }
  };

  const currentActiveFilter = (event: any) => {
    const currentOption = event.target.value;
    setActiveFilter(currentOption);

    if (startDate && endDate) {
      clearDateFilter();
    }

    const currentTime = dateService.getCurrentUTCDate().format();

    const filters = {
      "1m": dateService.getCurrentUTCDate().subtract(1, "minutes").format(),
      "30m": dateService.getCurrentUTCDate().subtract(30, "minutes").format(),
      "1h": dateService.getCurrentUTCDate().subtract(1, "hours").format(),
      "12h": dateService.getCurrentUTCDate().subtract(12, "hours").format()
    };

    const start = filters[currentOption];
    const stop = currentTime;

    setPayload({ start, stop });
  };

  const clearDateFilter = () => {
    setStartDate(new Date());
    setEndDate(null);

    setToDefaultFilter();
  };

  const setToDefaultFilter = () => {
    /* Default Filter */
    setActiveFilter("1h");
    const start = dateService
      .getCurrentUTCDate()
      .subtract(1, "hours")
      .format();
    const stop = dateService.getCurrentUTCDate().format();
    setPayload({ start, stop });
  };

  return (
    <>
      <section>
        <div className="flex justify-between">
          <div className="flex"></div>
          <div className="flex items-center">
            <ul className="flex justify-center items-center">
              <li className="mr-4 flex items-center">
                <h1 className="text-contentColorLight text-sm">
                  Show data for last
                </h1>
              </li>
              <li className="mr-2 flex items-center cursor-pointer">
                <button
                  onClick={onClearFilter}
                  className="py-2 px-2.5 border border-dashed border-contentColorLight rounded text-xs text-contentColorLight"
                >
                  Clear
                </button>
              </li>

              <li className="relative mr-2 flex items-center cursor-pointer">
                <input
                  className="cursor-pointer absolute w-full h-full bg-none bg-transparent border-0 rounded-none pointer checked:bg-none checked:bg-transparent checked:border-0 checked:focus:bg-none checked:focus:bg-transparent checked:focus:border-0 checked:hover:bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="radio"
                  id="1m"
                  name="timeFilters"
                  value="1m"
                  onChange={currentActiveFilter}
                />
                {activeFilter === "1m" ? (
                  <button className="py-2 px-2.5 flex justify-center items-center bg-background-layer1 border border-solid border-primary rounded text-xs">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.85598 3.02267L8.96205 2.9166L8.85598 2.81054L8.26848 2.22304L8.16247 2.11702L8.0564 2.22299L3.74984 6.52548L2.15169 4.93124L2.04562 4.82543L1.93969 4.93137L1.35219 5.51887L1.24612 5.62493L1.35219 5.731L3.64385 8.02267L3.74992 8.12873L3.85598 8.02267L8.85598 3.02267Z"
                        fill="#546CCC"
                        stroke="#546CCC"
                        strokeWidth="0.3"
                      />
                    </svg>

                    <h1 className="ml-2 text-primary">1m</h1>
                  </button>
                ) : (
                  <button className="py-2 px-2.5 border border-dashed border-contentColorLight rounded text-xs text-contentColor">
                    1m
                  </button>
                )}
              </li>

              <li className="relative mr-2 flex items-center cursor-pointer">
                <input
                  className="cursor-pointer absolute w-full h-full bg-none bg-transparent border-0 rounded-none pointer checked:bg-none checked:bg-transparent checked:border-0 checked:focus:bg-none checked:focus:bg-transparent checked:focus:border-0 checked:hover:bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="radio"
                  id="30m"
                  name="timeFilters"
                  value="30m"
                  onChange={currentActiveFilter}
                />
                {activeFilter === "30m" ? (
                  <button className="py-2 px-2.5 flex justify-center items-center bg-background-layer1 border border-solid border-primary rounded text-xs">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.85598 3.02267L8.96205 2.9166L8.85598 2.81054L8.26848 2.22304L8.16247 2.11702L8.0564 2.22299L3.74984 6.52548L2.15169 4.93124L2.04562 4.82543L1.93969 4.93137L1.35219 5.51887L1.24612 5.62493L1.35219 5.731L3.64385 8.02267L3.74992 8.12873L3.85598 8.02267L8.85598 3.02267Z"
                        fill="#546CCC"
                        stroke="#546CCC"
                        strokeWidth="0.3"
                      />
                    </svg>

                    <h1 className="ml-2 text-primary">30m</h1>
                  </button>
                ) : (
                  <button className="py-2 px-2.5 border border-dashed border-[#B7B9C1] rounded text-xs text-contentColor">
                    30m
                  </button>
                )}
              </li>

              <li className="relative mr-2 flex items-center cursor-pointer">
                <input
                  className="cursor-pointer absolute w-full h-full bg-none bg-transparent border-0 rounded-none pointer checked:bg-none checked:bg-transparent checked:border-0 checked:focus:bg-none checked:focus:bg-transparent checked:focus:border-0 checked:hover:bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="radio"
                  id="1h"
                  name="timeFilters"
                  value="1h"
                  onChange={currentActiveFilter}
                />
                {activeFilter === "1h" ? (
                  <button className="py-2 px-2.5 flex justify-center items-center bg-background-layer1 border border-solid border-primary rounded text-xs">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.85598 3.02267L8.96205 2.9166L8.85598 2.81054L8.26848 2.22304L8.16247 2.11702L8.0564 2.22299L3.74984 6.52548L2.15169 4.93124L2.04562 4.82543L1.93969 4.93137L1.35219 5.51887L1.24612 5.62493L1.35219 5.731L3.64385 8.02267L3.74992 8.12873L3.85598 8.02267L8.85598 3.02267Z"
                        fill="#546CCC"
                        stroke="#546CCC"
                        strokeWidth="0.3"
                      />
                    </svg>

                    <h1 className="ml-2 text-primary">1h</h1>
                  </button>
                ) : (
                  <button className="py-2 px-2.5 border border-dashed border-[#B7B9C1] rounded text-xs text-contentColor">
                    1h
                  </button>
                )}
              </li>

              <li className="relative mr-2 flex items-center cursor-pointer">
                <input
                  className="cursor-pointer absolute w-full h-full bg-none bg-transparent border-0 rounded-none checked:bg-none checked:bg-transparent checked:border-0 checked:focus:bg-none checked:focus:bg-transparent checked:focus:border-0 checked:hover:bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="radio"
                  id="12h"
                  name="timeFilters"
                  value="12h"
                  onChange={currentActiveFilter}
                />
                {activeFilter === "12h" ? (
                  <button className="py-2 px-2.5 flex justify-center items-center bg-background-layer1 border border-solid border-primary rounded text-xs">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.85598 3.02267L8.96205 2.9166L8.85598 2.81054L8.26848 2.22304L8.16247 2.11702L8.0564 2.22299L3.74984 6.52548L2.15169 4.93124L2.04562 4.82543L1.93969 4.93137L1.35219 5.51887L1.24612 5.62493L1.35219 5.731L3.64385 8.02267L3.74992 8.12873L3.85598 8.02267L8.85598 3.02267Z"
                        fill="#546CCC"
                        stroke="#546CCC"
                        strokeWidth="0.3"
                      />
                    </svg>

                    <h1 className="ml-2 text-primary">12h</h1>
                  </button>
                ) : (
                  <button className="py-2 px-2.5 border border-dashed border-[#B7B9C1] rounded text-xs text-contentColor">
                    12h
                  </button>
                )}
              </li>

              <li className="relative mr-2 flex items-center cursor-pointer">
                <div className="absolute">
                  <DatePicker
                    className="opacity-0 w-full h-full cursor-pointer bg-none bg-transparent border-0 rounded-none focus:outline-none focus:ring-0 focus:ring-offset-0"
                    onChange={onChangeDateRange}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                  />
                </div>
                <button className="py-2 px-2.5 flex justify-center items-center border border-dashed border-[#B7B9C1] rounded text-xs text-contentColor">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.66667 7.33342H6V8.66675H4.66667V7.33342ZM14 3.33341V12.6667C14 13.4067 13.4067 14.0001 12.6667 14.0001H3.33333C2.59333 14.0001 2 13.4001 2 12.6667V3.33341C2 2.60008 2.6 2.00008 3.33333 2.00008H4V0.666748H5.33333V2.00008H10.6667V0.666748H12V2.00008H12.6667C13.4067 2.00008 14 2.60008 14 3.33341ZM3.33333 4.66675H12.6667V3.33341H3.33333V4.66675ZM12.6667 12.6667V6.00008H3.33333V12.6667H12.6667ZM10 8.66675V7.33342H11.3333V8.66675H10ZM7.33333 8.66675V7.33342H8.66667V8.66675H7.33333ZM4.66667 10.0001H6V11.3334H4.66667V10.0001ZM10 11.3334V10.0001H11.3333V11.3334H10ZM7.33333 11.3334V10.0001H8.66667V11.3334H7.33333Z"
                      fill="#565759"
                    />
                  </svg>
                  <h1 className="ml-2">Custom</h1>
                </button>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        {startDate && endDate ? (
          <div className="mt-3.5 flex items-center">
            <div className="px-3 py-2 flex items-center bg-background-layer1 border border-solid border-primary rounded text-contentColor text-sm">
              <p>Showing data for: </p>
              <h1 className="font-bold">
                &nbsp;
                {`${dateService
                  .convertDateObjToMoment(startDate)
                  .format("DD/MM/YYYY")} - ${dateService
                  .convertDateObjToMoment(endDate)
                  .format("DD/MM/YYYY")}`}
              </h1>
              <button onClick={clearDateFilter} className="ml-2.5">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.3 4.7002L4.70039 11.2999"
                    stroke="#B3B3B3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.70044 4.7002L11.3001 11.2999"
                    stroke="#B3B3B3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="mt-3.5 border border-solid border-background-layer3"></div>
      </section>
    </>
  );
}

export default LogsAndActivitiesFilter;
