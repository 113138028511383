import { useMemo } from "react";
import { Link } from "react-router-dom";
import DeploymentGenericTable from "../components/ota-generic-table-deployment.component";
import CreateNewDeploymentPage from "./ota-no-deployments.component";
import OTADeploymentHeader from "../components/ota-deployment-header.component";
import useOTAReleaseStore from "@store/ota/ota-release.store";
import ShowError from "@app/shared/components/error.component";
import dateService from "@services/date.service";
import ShowLoading from "@app/shared/components/loading.component";
import { useGetReleases, useGetDeployments } from "@app/shared/hooks/get";

function RenderDeployment() {
  const [deploymentList, setDeploymentList, setReleaseList, setActiveRelease] =
    useOTAReleaseStore((state) => [
      state.deploymentList,
      state.setDeploymentList,
      state.setReleaseList,
      state.setActiveRelease
    ]);

  const { isLoading: releasesLoading, error: releasesError } = useGetReleases(
    {},
    (releases) => {
      if (releases?.length) {
        setReleaseList(releases);
        setActiveRelease(releases[0]);
      }
    }
  );

  const { isLoading: deploymentsLoading, error: deploymentsError } =
    useGetDeployments({}, (deployments) => {
      setDeploymentList(deployments ?? []);
    });

  // const optionsToRender = [
  //     {
  //         deployment:  (
  //             <Link
  //                 className='hover:text-primary'
  //                 to={{
  //                     pathname: "/ota-updates/projects/deployment/deployment-details",
  //                     //search: `?code=${id}`, // inject code value into template
  //                 }}
  //             >Deployment abc</Link>),
  //         targets: "eni-08787f87g787a788g-0all",
  //         individual_status: "Completed",
  //         deployment_status: "Running",
  //         created_at: "2019-07-22 10:33 UTC-7"
  //     }
  // ];

  const optionsToRender = useMemo(() => {
    return deploymentList.map((deployment) => {
      const targetString = deployment.props.target_fleets;

      return {
        deployment: (
          <Link
            className="hover:text-primary"
            to={{
              pathname: "/ota-updates/projects/deployment/deployment-details",
              search: `?code=${deployment.id}&name=${deployment.props.deployment_name}`
            }}
          >
            {deployment.props.deployment_name}
          </Link>
        ),
        targets:
          targetString.length > 36
            ? `${targetString.substring(0, 33)}...`
            : targetString,
        initiated: deployment.initiated ? "True" : "False",
        created_at: dateService.convertUTCToLocalDate(deployment.created_at)
      };
    });
  }, [deploymentList]);

  if (releasesError || deploymentsError) {
    return <ShowError />;
  }

  if (releasesLoading || deploymentsLoading) {
    return <ShowLoading />;
  }

  if (!!optionsToRender.length) {
    return (
      <>
        <OTADeploymentHeader options={true} />
        <main className="flex-1 px-4 py-8 pt-0 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto xl:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden rounded-xl">
                  {!!optionsToRender.length && (
                    <DeploymentGenericTable
                      column={Object.keys(optionsToRender[0])}
                      row={optionsToRender}
                      searchField={
                        optionsToRender.length
                          ? Object.keys(optionsToRender[0])[1]
                          : ""
                      }
                      searchTerm=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }

  return (
    <>
      <CreateNewDeploymentPage />
    </>
  );
}

export default RenderDeployment;
