import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IDevice, IDeviceResponse } from "@interfaces/fad.interface";

export const getFleetDevices = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  params
) => {
  const { data, ok, meta } = await networkService.get<IDeviceResponse>(
    `projects/${projectId}/fleets/${fleetId}/devices`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return { devices: data.devices, meta, ok };
  } else return null;
};

export const useGetFleetDevices = (
  fleetId: string,
  params: any = {},
  successCb: ((data: IDevice[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getFleetDevices",
      selectedProject.id,
      user.selectedOrg.id,
      fleetId,
      params
    ],
    async () => {
      const devicesResponse = await getFleetDevices(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        params
      );

      successCb && successCb(devicesResponse.devices);

      return devicesResponse;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id && !!fleetId
    }
  );

  return res;
};
