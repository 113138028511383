import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IBlueprint } from "./blueprints";

interface IDevicePanelsResponse {
  ok: number;
  data: {
    blueprints: IBlueprint[];
  };
}

export const getDevicePanels = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  params
) => {
  const { data, ok } = await networkService.get<IDevicePanelsResponse>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/panels`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.blueprints;
  } else return null;
};

export const useGetDevicePanels = (
  fleetId: string,
  deviceId: string,
  params: any = {},
  successCb: ((data: IBlueprint[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getDevicePanels",
      selectedProject.id,
      user.selectedOrg.id,
      fleetId,
      deviceId,
      params
    ],
    async () => {
      const devicePanels = await getDevicePanels(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        params
      );

      successCb && successCb(devicePanels);

      return devicePanels;
    },
    {
      enabled:
        !!selectedProject.id &&
        !!user.selectedOrg.id &&
        !!fleetId &&
        !!deviceId
    }
  );

  return res;
};
