import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const deletePendingReleaseUpdate = async (
  deploymentId: string,
  pendingOtaUpdateId: string,
  projectId: string,
  orgId: string
) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `projects/${projectId}/ota_release_deployments/${deploymentId}/pending-ota-updates/${pendingOtaUpdateId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeletePendingRelease = (deploymentId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (pendingOtaUpdateId: string) =>
      await deletePendingReleaseUpdate(
        deploymentId,
        pendingOtaUpdateId,
        selectedProject.id,
        user.selectedOrg.id
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getPendingReleaseUpdates");
      }
    }
  );

  return res;
};
