const UserAuthenticationHeader = () => {
  return (
    <>
      <div className="bg-background border-b border-background-layer3 text-contentColor lg:px-8 sm:px-6 ">
        <div className="flex justify-between items-center py-5">
          <div className="flex flex-col">
            <div className="flex items-center">
              <h1 className="text-2xl font-medium mr-1.5">
                User Authentication
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAuthenticationHeader;
