import React from "react";
import { CreateProject, CreateFleet } from ".";
import { useOverlayLoaderStore } from "../../store";
import useFleetAndDevicesStore from "../../store/fleet-and-devices/fleet-and-devices.store";
import RenderDevices from "./device/fad-render-devices.component";

const Projects: React.FC = () => {
  const [loader] = useOverlayLoaderStore((state) => [state.loader]);
  const [projects, fleets] = useFleetAndDevicesStore((state) => [
    state.projects,
    state.fleets
  ]);

  if (loader) return null;

  return (
    <>
      {projects && projects.length <= 0 ? (
        <CreateProject />
      ) : fleets && fleets.length <= 0 ? (
        <CreateFleet />
      ) : (
        <RenderDevices />
      )}
    </>
  );
};

export default Projects;
