import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { OtaStatusType } from "@interfaces/ota.interface";

interface IUpdatePendingReleasePayload {
  id: string[];
  status: OtaStatusType;
  retries: number;
}

export const updatePendingReleaseUpdate = async (
  projectId: string,
  orgId: string,
  deploymentId: string,
  payload: IUpdatePendingReleasePayload
) => {
  const { ok } = await networkService.post<{
    data: {};
    ok: number;
  }>(
    `projects/${projectId}/ota_release_deployments/${deploymentId}/pending-ota-updates`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUpdatePendingRelease = (deploymentId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IUpdatePendingReleasePayload) =>
      await updatePendingReleaseUpdate(
        selectedProject.id,
        user.selectedOrg.id,
        deploymentId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getPendingReleaseUpdates");
      }
    }
  );

  return res;
};
