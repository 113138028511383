import { useEffect, useRef } from "react";

function useInterval(callback: () => void, timer: number) {
  const callbackRef = useRef(null);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      callbackRef.current();
    }

    if (timer !== null) {
      let iId = setInterval(tick, timer);

      return () => clearInterval(iId);
    }
  }, [timer]);
}

export default useInterval;
