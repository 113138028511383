export const data = {
    loginPage: {
        left: {
            heading: "Welcome",
            subHeading: "Start your journey with us"
        },
        right: {
            heading: "Accelerate your IoT journey with us",
            subHeading: "Get started for free today !"
        }
    },
    onboarding: {
        orgDetails: {
            left: {
                heading: "Organisation Details",
                subHeading: "Start with a new organisation"
            },
            right: {
                heading: "Accelerate your IoT journey with Golain",
                subHeading: "Get started for free today !"
            }
        },
        indivDetails: {
            left: {
                heading: "Individual Details",
                subHeading: "Some basic details about you"
            },
            right: {
                heading: "Accelerate your IoT journey with Golain",
                subHeading: "Get started for free today !"
            }
        }
    },
    createProject: {
        subHeading: ["All resources in Golain are organised into projects", "Create a project to get started."],
    },
    createFleet: {
        heading: "Let’s kick start with creating a Fleet",
        subHeading: ["Fleets are collections of devices", "These devices can be managed together", "Create a fleet to get started."],
    },
    createNewReleasePage: {
        heading: "Create New Release",
        subHeading: "A release is a collection of artifacts that can be deployed to a group of devices. Create a release to get started."
    },
    createNewArtifactSection: {
        subHeading: "Artifacts are the files that are deployed to the devices. Create an artifact to get started.",
    },
    createDeploymentPage: {
        heading: "Create New Deployment",
        subHeading: "A deployment is a collection of releases that can be scheduled to be deployed to a group of devices. Create a deployment to get started.",
    },
    userAuthPageEmptState: {
        heading: "Consumer Authentication",
        subHeading: ["Consumer authentication allows you to authenticate and grant device permissions to end-users of the devices in your entire project.", "Do not enable this if you're not sure about it - read the documentation for more."],
    }
}
