import { useState, useEffect } from "react";
import { loggerService } from "../../../services";
import { AddOverlayLayout } from "../../shared/components";
import { Formik, Field, Form } from "formik";
import { FieldError } from "../../shared/components";
import Select from "react-select";
import useOTAReleaseStore from "../../../store/ota/ota-release.store";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import { useGetReleaseDetails } from "@app/shared/hooks/get/release-details";
import { useDeleteRelease } from "@app/shared/hooks/delete/delete-release";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";
import { useUpdateRelease } from "@app/shared/hooks/post/update-release";
import { reactSelectClassNames } from "@/app/shared/utils/helper.util";

const targetByOptions = [
  { label: "Fleets", value: "fleets" },
  { label: "Devices", value: "devices" }
];

const ReleaseDetails: React.FC = () => {
  const [activeRelease, showReleaseDetails, setShowReleaseDetails] =
    useOTAReleaseStore((state) => [
      state.activeRelease,
      state.showReleaseDetails,
      state.setShowReleaseDetails
    ]);

  const [selectedTargetOptions, setSelectedTargetOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedTargetIds, setSelectedTargetIds] = useState<string[]>([]);
  const [selectedTargetBy, setSelectedTargetBy] = useState<{
    value: string;
    label: string;
  }>(undefined);
  const [targetOptions, setTargetOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [inputFieldValues, setInputFieldValues] = useState({
    releaseName: "",
    deviceType: ""
  });
  const [errorMsgs, setErrorMsgs] = useState({
    releaseName: ""
  });
  const [deviceIds, setDeviceIds] = useState("");

  const { openConfirmDeleteModal } = useConfirmDelete();

  const { data: fleets } = useGetFleets();
  const { data: devicesResponse } = useGetDevices({ device_id: deviceIds });
  const deleteReleaseMutation = useDeleteRelease();

  useEffect(() => {
    if (selectedTargetBy?.value === "devices") {
      setSelectedTargetOptions(
        devicesResponse?.devices?.map((dev) => ({
          label: dev.device_name,
          value: dev.id
        }))
      );
    }

    return () => {};
  }, [devicesResponse, selectedTargetBy?.value]);

  useGetReleaseDetails(activeRelease.id, {}, (release) => {
    const { ota_release } = release;

    if (ota_release) {
      setInputFieldValues({
        releaseName: ota_release.props.release_name,
        deviceType: ota_release.props.device_types
      });

      if (ota_release.props.target_devices) {
        setSelectedTargetBy(targetByOptions[1]);
        setSelectedTargetIds(ota_release.props.target_devices.split(","));
        setDeviceIds(ota_release.props.target_devices);

        if (devicesResponse?.devices?.length) {
          const options = devicesResponse?.devices?.map((device) => {
            return { label: device.device_name, value: device.id };
          });

          setTargetOptions([...options]);
        } else {
          setTargetOptions([]);
        }
      }

      if (
        !ota_release.props.target_devices &&
        ota_release.props.target_fleets
      ) {
        setSelectedTargetBy(targetByOptions[0]);
        setSelectedTargetIds(ota_release.props.target_fleets.split(","));
        if (fleets?.length) {
          const options = fleets.map((fleet) => {
            return { label: fleet.fleet_name, value: fleet.id };
          });

          setTargetOptions([...options]);
        } else {
          setTargetOptions([]);
        }
      }
    }
  });

  useEffect(() => {
    if (!showReleaseDetails) {
      setSelectedTargetOptions([]);
      setSelectedTargetBy(undefined);
      setTargetOptions([]);
      setErrorMsgs({ releaseName: "" });
    }
  }, [showReleaseDetails]);

  const updateReleaseMutation = useUpdateRelease(activeRelease.id);

  const handleSubmit = async () => {
    if (errorMsgs?.releaseName) {
      return;
    }

    if (activeRelease.props.release_name === inputFieldValues?.releaseName) {
      setShowReleaseDetails(false);
      return;
    }

    try {
      const payload = {
        release_name: inputFieldValues?.releaseName
      };

      updateReleaseMutation.mutate(
        { props: payload },
        {
          onSuccess: () => {
            setShowReleaseDetails(false);
          }
        }
      );
    } catch (error) {
      loggerService.error(error);
    }
  };

  const handleFieldChange = (e: any, id: string) => {
    const payload = { ...inputFieldValues };
    payload[id] = e.target.value;

    if (!payload[id].trim()) {
      setErrorMsgs({ releaseName: "Release name is required" });
    } else if (errorMsgs?.releaseName) {
      setErrorMsgs({ releaseName: "" });
    }

    setInputFieldValues({ ...payload });
  };

  const deleteRelease = async () => {
    openConfirmDeleteModal(
      () =>
        deleteReleaseMutation.mutate(activeRelease.id, {
          onSuccess: () => {
            setShowReleaseDetails(false);
          }
        }),
      "Are you sure you want to delete this release?"
    );
  };

  useEffect(() => {
    if (targetOptions.length && selectedTargetIds.length) {
      const opts = targetOptions.filter((target) =>
        selectedTargetIds.includes(target.value)
      );

      setSelectedTargetOptions([...opts]);
    }
  }, [targetOptions, selectedTargetIds]);

  return (
    <AddOverlayLayout
      title={activeRelease.props.release_name}
      overlayOpen={showReleaseDetails}
      setOverlayOpen={setShowReleaseDetails}
    >
      <div className="flex flex-col items-center">
        {showReleaseDetails && (
          <Formik
            initialValues={{
              releaseName: ""
            }}
            onSubmit={handleSubmit}
          >
            <Form className="w-full flex flex-col flex-1 mt-7">
              <div className="mb-5">
                <label className="text-sm font-medium text-contentColorLight">
                  Release Name
                </label>

                <Field
                  type="text"
                  id="releaseName"
                  name="releaseName"
                  placeholder="Release Name"
                  value={inputFieldValues?.releaseName}
                  onChange={(e) => handleFieldChange(e, "releaseName")}
                  className="block w-full p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
                {errorMsgs?.releaseName ? (
                  <FieldError message={errorMsgs?.releaseName} />
                ) : (
                  ""
                )}
              </div>

              <div className="mb-5">
                <label className="text-sm font-medium text-contentColorLight">
                  Device Type
                </label>

                <Field
                  disabled={true}
                  type="text"
                  id="deviceType"
                  name="deviceType"
                  placeholder="Device Type"
                  value={inputFieldValues.deviceType}
                  // onChange={(e) => handleFieldChange(e, "deviceType")}
                  className="block w-full p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
              </div>

              <div className="mb-5">
                <label className="flex font-medium text-sm mb-2">
                  Target By
                </label>
                <Select
                  placeholder="Select"
                  isSearchable={false}
                  value={selectedTargetBy}
                  // options={targetByOptions}
                  // onChange={handleTargetBy}
                  isClearable={false}
                  isDisabled
                  classNames={reactSelectClassNames}
                />
              </div>

              <div className="mb-5">
                <label className="flex font-medium text-sm mb-2">
                  Select Targets
                </label>
                <Select
                  //isDisabled={!targetOptions.length}
                  isDisabled
                  placeholder="Select"
                  isSearchable={false}
                  options={targetOptions}
                  value={selectedTargetOptions}
                  // onChange={handleTargetSelect}
                  isClearable={true}
                  isMulti
                  classNames={reactSelectClassNames}
                />
              </div>

              <div className="w-full flex mt-6">
                <button
                  type="button"
                  onClick={deleteRelease}
                  className="w-1/2 mr-1 px-7 py-3 space-x-3 font-medium text-red-500 text-center transition-colors duration-200 transform border rounded-md focus:outline-none border-red-500 hover:bg-background-layer1"
                >
                  Delete
                </button>
                <button
                  onClick={handleSubmit}
                  type="button"
                  disabled={!!errorMsgs?.releaseName}
                  className="w-1/2 ml-1 inline-block px-7 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  Save and Close
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </AddOverlayLayout>
  );
};

export default ReleaseDetails;
