import { memo, useEffect, useMemo, useState } from "react";
import NodeTemplate from "../node-template.component";
import { ArrowDownIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Handle, Position } from "reactflow";
import { Tooltip } from "react-tooltip";
import StackedList from "../stacked-list.component";
import { HTTPActionDefinitionEditor } from "./http-action-definition-editor";
import FetchedContextItem from "./fetched-context-item.component";
import ActionTypeDropdown from "./action-type-dropdown.component";
import { DeviceShadowActionDefinitionEditor } from "./device-shadow-action-definition-editor";
import Modal from "@app/shared/components/modal.component";
import { Editor } from "@monaco-editor/react";
import { UserMetadataActionDefinitionEditor } from "./user-metadata-action-definition-editor";
import {
  ActionType,
  IContext,
  IInput,
  contextFieldRequiredMap
} from "@app/rule-engine/rule-engine.helper";
import InputsItem from "./input-item.component";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import UserContextActionDefinitionEditor from "./context-action-definition-editor/user-context-action-definition-editor.component";
import { AddFetchedContextTooltip } from "../../add-fetched-context-tooltip.component";
import { FCMActionDefinitionEditor } from "./fcm-action-definition-editor";

const ActionEditorNode = ({ data, isConnectable }) => {
  const [inputs, setInputs] = useState<IInput[]>(data.actionData.inputs || []);
  const [fetchedContexts, setFetchedContexts] = useState<IContext[]>(
    data.actionData.fetchedContexts || []
  );

  const [actionDefinition, setActionDefinition] = useState(
    data.actionData.actionDefinition || {}
  );
  const [actionType, setActionType] = useState<ActionType | undefined>(
    data.actionData.actionType
  );
  const [newContextType, setNewContextType] = useState("device-shadow");

  const [jsonEditorModalOpen, setJsonEditorModalOpen] = useState(false);
  const [modalJSON, setModalJSON] = useState({});

  useEffect(() => {
    const disabled = fetchedContexts.some((context) => {
      const requiredFields = contextFieldRequiredMap[context.type];
      return requiredFields.some((field) => context[field].trim() === "");
    });

    if (!disabled) {
      data.setActionData((actionData) => ({
        ...actionData,
        fetchedContexts
      }));
    }
  }, [fetchedContexts, data]);

  // TODO: Add input errors
  useEffect(() => {
    if (inputs.length && inputs[inputs.length - 1].key.trim() === "") return;
    data.setActionData((actionData) => ({
      ...actionData,
      inputs
    }));
  }, [inputs, data]);

  useEffect(() => {
    data.setActionData((actionData) => ({
      ...actionData,
      actionDefinition
    }));
  }, [actionDefinition, data]);

  useEffect(() => {
    data.setActionData((actionData) => ({
      ...actionData,
      actionType
    }));
  }, [actionType, data]);

  const disabledInput = useMemo(() => {
    if (inputs[inputs.length - 1]?.key.trim() === "") {
      return true;
    }

    const inpMap = {};
    let foundDuplicate = false;

    inputs.forEach((el) => {
      if (el.key in inpMap) {
        foundDuplicate = true;
      }
      inpMap[el.key] = true;
    });

    return foundDuplicate;
  }, [inputs]);

  const { data: shadowDefs } = useGetShadowDefinitions({
    fields: "shadow_proto_structure"
  });

  return (
    <NodeTemplate hideDelete={true}>
      <Handle
        type="target"
        position={Position.Right}
        id="cusheuiodfseu"
        className="bg-blue-500"
        onConnect={() => {}}
        isConnectable={true}
      />
      <div className="flex text-xs min-h-[300px]">
        <div className="min-w-[250px] pr-3 flex flex-col border-dashed border-r border-background-layer3">
          <div className="text-center mb-3 text-base">Inputs</div>
          <StackedList
            data={inputs}
            draggable={false}
            render={(input: IInput, ind: number, inputs: IInput[]) => (
              <InputsItem
                ind={ind}
                input={input}
                inputs={inputs}
                setInputs={setInputs}
              />
            )}
          />
          <div className="nodrag mt-3">
            <button
              disabled={disabledInput}
              className="bg-green-500 text-white w-full px-2 py-1 rounded-sm disabled:opacity-50"
              onClick={() => {
                setInputs((prev) => [
                  ...prev,
                  {
                    key: "",
                    type: "string"
                  }
                ]);
              }}
            >
              Add Input
            </button>
          </div>
        </div>
        <div className="min-w-[300px] px-2 flex flex-col border-dashed border-r border-background-layer3">
          <div className="flex justify-center gap-2 mb-3 text-base">
            <div>Fetched Context</div>
            <button
              className="bg-background-layer3 text-xs text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(true)}
            >
              JSON
            </button>
          </div>
          <StackedList
            data={fetchedContexts}
            setData={setFetchedContexts}
            draggable={true}
            separatorElement={() => (
              <span data-tooltip-id="fetched-context-arrow-down-tooltip">
                <ArrowDownIcon width={20} className="text-center mx-auto" />
              </span>
            )}
            ignoreLastElementForDrag={
              fetchedContexts[fetchedContexts.length - 1]?.key.trim() === ""
            }
            render={(
              context: IContext,
              ind: number,
              allContexts: IContext[]
            ) => (
              <FetchedContextItem
                context={context}
                ind={ind}
                setFetchedContexts={setFetchedContexts}
                contextFieldRequiredMap={contextFieldRequiredMap}
                allContexts={allContexts}
                inputs={inputs}
                shadowDefs={shadowDefs}
                renderedIn="action"
              />
            )}
          />
          <div className="nodrag mt-3">
            <button
              data-tooltip-id="fetched-context-add-tooltip"
              disabled={fetchedContexts.some((c) => c.error)}
              className="bg-yellow-500 text-white w-full px-2 py-1 rounded-sm disabled:opacity-50"
              onClick={() => {}}
            >
              Add Context
            </button>
          </div>
        </div>
        <div className="min-w-[350px] pl-3 flex flex-col">
          <div className="text-center mb-3 text-base">Action</div>
          {actionType === undefined ? (
            <div className="mt-20 text-center flex items-center flex-col">
              <ActionTypeDropdown
                actionTypes={ActionType}
                setActionDefinition={setActionDefinition}
                buttonComponent={
                  <div
                    className={`flex px-2 py-1 rounded-sm text-white items-center bg-blue-500 `}
                  >
                    Pick an action type to start!
                    <ChevronDownIcon width={16} className="ml-2" />
                  </div>
                }
                setActionType={setActionType}
              />
            </div>
          ) : actionType === ActionType.HTTP ? (
            <HTTPActionDefinitionEditor
              actionDefinition={actionDefinition}
              setActionDefinition={setActionDefinition}
              actionTypes={ActionType}
              setActionType={setActionType}
              actionType={actionType}
            />
          ) : actionType === ActionType.DEVICE_SHADOW ? (
            <DeviceShadowActionDefinitionEditor
              actionDefinition={actionDefinition}
              setActionDefinition={setActionDefinition}
              setActionType={setActionType}
              actionType={actionType}
              fetchedContexts={fetchedContexts}
              inputs={inputs}
              shadowDefs={shadowDefs}
            />
          ) : actionType === ActionType.USER_METADATA ? (
            <UserMetadataActionDefinitionEditor
              actionDefinition={actionDefinition}
              setActionDefinition={setActionDefinition}
              setActionType={setActionType}
              actionType={actionType}
              fetchedContexts={fetchedContexts}
              inputs={inputs}
            />
          ) : actionType === ActionType.INSERT_DATA ? (
            <UserContextActionDefinitionEditor
              actionDefinition={actionDefinition}
              setActionDefinition={setActionDefinition}
              setActionType={setActionType}
              actionType={actionType}
              fetchedContexts={fetchedContexts}
              inputs={inputs}
            />
          ) : actionType === ActionType.FCMNotification ? (
            <FCMActionDefinitionEditor
              actionDefinition={actionDefinition}
              setActionDefinition={setActionDefinition}
              actionTypes={ActionType}
              setActionType={setActionType}
              actionType={actionType}
            />
          ) : (
            <div className="flex flex-col mt-20 items-center gap-2">
              <p>This action type is coming sool!</p>
              <ActionTypeDropdown
                actionTypes={ActionType}
                setActionDefinition={setActionDefinition}
                buttonComponent={
                  <div
                    className={`flex px-2 py-1 rounded-sm text-white items-center bg-blue-500 `}
                  >
                    Please pick another action type to start!
                    <ChevronDownIcon width={16} className="ml-2" />
                  </div>
                }
                setActionType={setActionType}
              />
            </div>
          )}
        </div>
      </div>

      <Tooltip
        id={"fetched-context-arrow-down-tooltip"}
        place="bottom"
        className="bg-background-layer2"
      >
        Fetched Contexts are run sqeuentially, from top to bottom. <br />
        You can use the output of one context in any subsequent contexts.
      </Tooltip>
      {AddFetchedContextTooltip(
        fetchedContexts,
        newContextType,
        setNewContextType,
        setFetchedContexts
      )}
      <Modal
        open={jsonEditorModalOpen}
        setOpen={setJsonEditorModalOpen}
        title="JSON Editor"
        className="w-full max-w-2xl"
        disableClickOutside
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set Fetched Context</h1>
          <p>
            All your changes will be overwritten using this JSON editor.
            <br />
          </p>
          <Editor
            height="500px"
            width="100%"
            theme="vs-dark"
            language="json"
            value={JSON.stringify(modalJSON, null, 2)}
            onChange={(e) => {
              try {
                setModalJSON(JSON.parse(e));
              } catch (err) {}
            }}
            options={{
              readOnly: false,
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => {
                setFetchedContexts(modalJSON as IContext[]);
                setJsonEditorModalOpen(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </NodeTemplate>
  );
};

export default memo(ActionEditorNode);
