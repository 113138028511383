import React, { useEffect, useState } from "react";
import ShadowDefinitionDetailsTabs, {
  ShadowDefinitionTabs
} from "./shadow-definition-tabs.component";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ShadowDefinitionDetails from "./tabs/shadow-definition-details.component";
import ShadowDefinitionVersions from "./tabs/shadow-definition-versions.component";
import ShadowDefinitionUsage from "./tabs/shadow-definition-usage.component";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";

const ShadowDefinition: React.FC = () => {
  const location = useLocation();
  const [shadowDef, setShadowDef] = useState(null);
  const [shadowId] = useState(location.pathname.split("/")[3]);

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") as ShadowDefinitionTabs;

  useGetShadowDefinitions(
    {
      ids: shadowId,
      fields: "shadow_proto_structure,shadow_proto,shadow_proto_descriptor"
    },
    (shadowDefinitions) => {
      if (!shadowDefinitions?.length) {
        toast.error(`Shadow definition with ID ${shadowId} not found.`);

        return;
      }

      const shadowDef = shadowDefinitions[0];
      setShadowDef(shadowDef);
    }
  );

  const [activeTab, setActiveTab] = useState<ShadowDefinitionTabs>(
    tab ?? ShadowDefinitionTabs.DETAILS
  );

  useEffect(() => {
    setSearchParams(
      { tab: activeTab ?? ShadowDefinitionTabs.DETAILS },
      { replace: true }
    );
  }, [activeTab, setSearchParams]);

  return (
    <>
      <ShadowDefinitionDetailsTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === ShadowDefinitionTabs.DETAILS && shadowDef ? (
        <ShadowDefinitionDetails shadowDef={shadowDef} />
      ) : activeTab === ShadowDefinitionTabs.VERSIONS && shadowDef ? (
        <ShadowDefinitionVersions shadowDef={shadowDef} />
      ) : activeTab === ShadowDefinitionTabs.USAGE && shadowDef ? (
        <ShadowDefinitionUsage shadowDef={shadowDef} />
      ) : null}
    </>
  );
};

export default ShadowDefinition;
